import { Box, TextField, Typography, IconButton, ClickAwayListener } from "@mui/material";
import Knowledge_Icon from "assets/images/PathwayCreator/Knowledge_icon.svg";
import Demonstration_Icon from "assets/images/PathwayCreator/Demonstration_icon.svg";
import Dots_Vertical from "assets/images/PathwayCreator/Drag_vertical.svg";
import { godzilla as theme } from "util/themes/godzilla";
import EditModal from "./EditModal";
import { useEffect, useState } from "react";
import Error_Icon from "assets/images/erroricon.svg";
import { IContextData, IViewMilestoneProps, MilestoneDataType } from "../outline.helper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IPathwayError, useAppContext } from "components/app-context/appContext";
import { useNavigate, useParams } from "react-router-dom";

const styles = {
  inputStyle: {
    "& .MuiInputBase-root": {
      width: "396px",
      height: "34px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5B67FF",
        borderRadius: "4px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "& .MuiInputBase-input": {
      padding: "6px 9px 7px 9px",
    },
  },
};

export const RgxPathwayMilestoneTitle = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*()_+,\-.\/:;<=>?@\[\]\\\{\}|\~\` ]*/;
const RequiredErrorText = "The Milestone title cannot be blank. Please add a Milestone title.";
const MaxErrorText = "Must be atmost of 100 characters";

const ViewMilestone = (props: IViewMilestoneProps) => {
  const { isEditCard, isError = false, milestoneType, milestoneTitle, activeIndex, index, setMilestoneData, milestoneData, setMilestoneDeleteData, selectedMilestoneIndex = null, setSelectedMilestoneIndex } = props;
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [knowledgeTitle, setKnowledgeTitle] = useState<string>("");
  const [demonstrationTitle, setDemonstrationTitle] = useState<string>("");
  const [onMenuOpen, setOnMenuOpen] = useState<boolean>(false);
  const [errorFlags, setErrorFlags] = useState({
    requiredError: false,
    maxLength: false,
  });
  const { pathwayError, setPathwayError, pathwayData, setPathwayData }: IContextData = useAppContext();
  const { milestoneListError } = pathwayError;
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (errorFlags.maxLength || errorFlags.requiredError) {

      if (!milestoneListError.includes(index)) {
        const newErrorData: IPathwayError = { ...pathwayError, milestoneListError: [...milestoneListError, index] };
        setPathwayError(newErrorData)
      }
    } else if (activeIndex === 0) {
      if (milestoneListError.includes(index)) {
        const newErrorData: IPathwayError = { ...pathwayError, milestoneListError: milestoneListError.filter(item => item !== index) };
        setPathwayError(newErrorData)
      }
    }
  }, [errorFlags, pathwayError, milestoneListError, activeIndex, index, setPathwayError]);

  useEffect(() => {
    milestoneType === "KNOWLEDGE"
      ? setKnowledgeTitle(milestoneTitle)
      : setDemonstrationTitle(milestoneTitle);
    if (milestoneTitle === '') {
      setErrorFlags({ requiredError: true, maxLength: false });
      setIsEdit(true);
    } else if (milestoneTitle.length > 100) {
      setErrorFlags({ requiredError: false, maxLength: true });
      setIsEdit(true);
    } else if (!milestoneTitle.match(RgxPathwayMilestoneTitle)) {
      setErrorFlags({ requiredError: true, maxLength: false });
      setIsEdit(true);
    } else {
      setErrorFlags({ requiredError: false, maxLength: false });
    }
  }, [milestoneData]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setTimeout((): void => {
      setOnMenuOpen(true);
      setSelectedMilestoneIndex(index);
    }, 50);
  };

  const onChangeMilestoneTitle = (e: any, milestoneType: string) => {
    milestoneType === "KNOWLEDGE"
      ? setKnowledgeTitle(e.target.value)
      : setDemonstrationTitle(e.target.value);
    setErrorFlags({
      requiredError: false,
      maxLength: false,
    });
  };

  const onCheckMilestone = (
    title: string,
    milestoneType: string,
    index: number
  ) => {
    if (title.trim() === "") {
      setErrorFlags({
        requiredError: true,
        maxLength: false,
      });
    } else if (title.length > 100) {
      setErrorFlags({
        requiredError: false,
        maxLength: true,
      });
    } else if (
      !title.match(
        RgxPathwayMilestoneTitle
      )
    ) {
      setErrorFlags({
        requiredError: true,
        maxLength: false,
      });
    } else {
      setErrorFlags({
        requiredError: false,
        maxLength: false,
      });
      const cloneData: MilestoneDataType[] = [...milestoneData];
      if (!cloneData[index]?.sdoId) {
        cloneData[index].title = title;
        setMilestoneData(cloneData);
      } else {
        cloneData[index].title = title;
        cloneData[index].action = "UPDATE";
        setMilestoneData(cloneData);
      }
      setIsEdit(false)
    }
  };

  const onEditMilestoneTitle = (
    e: any,
    onKeyPress: boolean,
    milestoneType: string,
    index: number
  ) => {
    if (onKeyPress) {
      if (e.key === "Enter") {
        if (milestoneType === "KNOWLEDGE") {
          onCheckMilestone(knowledgeTitle, milestoneType, index);
        } else {
          onCheckMilestone(demonstrationTitle, milestoneType, index);
        }
      }
    } else {
      if (milestoneType === "KNOWLEDGE") {
        onCheckMilestone(knowledgeTitle, milestoneType, index);
      } else {
        onCheckMilestone(demonstrationTitle, milestoneType, index);
      }
    }
  };

  const onDeleteMilestone = (index: number) => {
    let cloneData = JSON.parse(JSON.stringify(milestoneData));
    let findIndex = cloneData.findIndex((_: any, itemIndex: number) => itemIndex === index);
    if (findIndex > -1) {
      let newError = milestoneListError.filter(item => item !== findIndex).map((item) => {
        if (item > findIndex) {
          item = item - 1;
        }
        return item;
      })
      setPathwayError({ ...pathwayError, milestoneListError: newError })
      if (cloneData[findIndex].action === 'NEW') {
        cloneData.splice(findIndex, 1);
      } else {
        cloneData[findIndex].action = 'DELETE';
        cloneData[findIndex].order = '';
      }
    }
    setMilestoneData(cloneData);
  }

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"column"}
      sx={{
        cursor: "pointer",
        "& .editContainer": {
          visibility: "hidden",
        },
        "&:hover , &:active": {
          cursor: "pointer",
          "& .editContainer": {
            visibility: isEditCard ? "initial" : "hidden",
          },
        },
      }}
      onClick={() => !isEditCard && (milestoneType === 'KNOWLEDGE' ? navigate(`/pathwayCreator/build/${params.id}/${milestoneTitle}/${index}/module`) : navigate(`/pathwayCreator/build/${params.id}/${milestoneTitle}/${index}/demonstrationModule`))}
    >
      <Box
        width={"500px"}
        height={"auto"}
        border={`${errorFlags.requiredError || errorFlags.maxLength || isError
          ? "2px solid #FF4F4F"
          : `2px solid ${theme?.palette?.grayScale[200]}`
          }`}
        borderRadius={"8px"}
        sx={{
          "&:hover, &:active": {
            border: isError ? '2px solid #FF4F4F' : '2px solid #26C5D2'
          }
        }}
      >
        <Box display={"flex"} flexDirection={"row"}>
          <Box ml={"7.5px"} mt={"16.5px"} className={"editContainer"}>
            <img
              src={Dots_Vertical}
              alt="ICON"
              style={{ height: "24px", width: "8px" }}
            />
          </Box>
          <Box ml={"8px"} mt={"16.5px"}>
            <img
              src={
                milestoneType === "KNOWLEDGE"
                  ? Knowledge_Icon
                  : Demonstration_Icon
              }
              alt="ICON"
              style={{ height: "24px", width: "24px" }}
              draggable="false"
            />
          </Box>
          {isEdit ? (
            <ClickAwayListener onClickAway={(e: any) => onEditMilestoneTitle(e, false, milestoneType, index)}>
              <Box ml={"8px"} mt={"11px"} mb={'11px'}>
                <TextField
                  sx={{
                    ...styles.inputStyle,
                  }}
                  value={
                    milestoneType === "KNOWLEDGE"
                      ? knowledgeTitle
                      : demonstrationTitle
                  }
                  onChange={(e: any) => onChangeMilestoneTitle(e, milestoneType)}
                  onKeyDown={(e: any) =>
                    onEditMilestoneTitle(e, true, milestoneType, index)
                  }
                  onBlur={(e: any) => {
                    const selectedMilestone = JSON.parse(JSON.stringify(milestoneData));
                    selectedMilestone[index].title = e.target.value;
                    setPathwayData({ ...pathwayData, milestones: selectedMilestone });
                    onEditMilestoneTitle(e, false, milestoneType, index)
                  }
                  }
                  autoFocus={true}
                />
              </Box>
            </ClickAwayListener>
          ) : (
            <Box ml={"8px"} mt={"17.5px"} height={"auto"} width={"397px"} mb={'17.5px'}>
              <Typography
                sx={{
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.medium,
                  color: "#3D4655",
                  wordBreak: 'break-all'
                }}
              >
                {milestoneTitle}
              </Typography>
            </Box>
          )}
          {isEditCard && <Box ml={"2px"} zIndex={'2'}>
            <IconButton onClick={handleClick} sx={{
              padding: '0px',
              mt: "16.5px",
              "&:hover": {
                backgroundColor: `${(selectedMilestoneIndex === index || selectedMilestoneIndex === null) ? theme?.palette?.secondary[10] : "transparent"}`,
              },
            }}>
              <MoreVertIcon />
            </IconButton>
            <EditModal isEdit={isEdit} setIsEdit={setIsEdit} setData={setMilestoneData} index={index} data={milestoneData} setDeleteData={setMilestoneDeleteData} onMenuOpen={onMenuOpen} setOnMenuOpen={setOnMenuOpen} isDeleteDoubleConfirmModal={true} setSelectedIndex={setSelectedMilestoneIndex} onDeleteItem={onDeleteMilestone} />
          </Box>}
        </Box>
      </Box>
      {errorFlags.requiredError || errorFlags.maxLength ? (
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          mt={"7px"}
        >
          <Box mt={"-2px"}>
            <img
              src={Error_Icon}
              alt="ICON"
              style={{ height: "10px", width: "10px" }}
            />
          </Box>
          <Box ml={"2px"}>
            <Typography
              sx={{
                ...theme?.typography?.captions?.default,
                ...theme?.typography?.captions?.regular,
                color: theme?.palette?.error?.dark,
              }}
            >
              {errorFlags.requiredError ? RequiredErrorText : MaxErrorText}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default ViewMilestone;
