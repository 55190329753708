import React, { useContext } from "react";

import { useAuth } from "core/contexts";
import { ThemeContext } from "util/themes/currentThemeContext";
import { getQueryData } from "./dictionaryListHelper";
import { useQuery } from "react-query";
import { getDefaultHeaders } from "core/util/other";
import { PageHeader } from "components/page-header/PageHeader";

export const DictionaryList = () => {
  const { getToken } = useAuth();
  const { currentTheme } = useContext(ThemeContext);

  const headers = getDefaultHeaders(getToken());

  // NOTE: this is using React Query, though the old way with useEffect can work
  let dictionaryQuery = useQuery("dictionaryList", async () =>
    getQueryData("/metadata/dictionary", headers)
  );

  return (
    <>
      <PageHeader title="Dictionary List" />
      {!dictionaryQuery.isLoading ? (
        <>{JSON.stringify(dictionaryQuery.data)}</>
      ) : (
        <>loading</>
      )}
    </>
  );
};
