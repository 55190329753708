import { useCallback, useEffect, useMemo, useState } from "react";
import { Backdrop, Box, CircularProgress, ClickAwayListener, Grid, IconButton, Typography } from "@mui/material"
import { useMutation, useQuery } from "react-query";

import { Button } from "core/components";
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useAuth } from "core/contexts";
import { godzilla as theme } from "util/themes/godzilla";
import OnlyIconSVG from 'assets/images/only_plus.svg';
import emptyDraftsFilterImage from 'assets/images/Pathway-drafts.svg';
import SortSVG from 'assets/images/sort.svg';
import FilterSVG from 'assets/images/filter.svg';
import DeleteSVG from 'assets/images/delete.svg';
import PublishSVG from 'assets/images/publish.svg';
import EditSVG from 'assets/images/edit.svg';
import DraftModuleList from "./PathwayModules/DraftModuleList";
import { IPathwayModuleCardDataProps } from "pages/drafts/DraftsModule/types";
import { useNavigate } from "react-router-dom";
import SortModal, { ISortFieldTypes } from "./components/SortModal";
import DeleteModal from "./components/DeleteModal";
import DraftFilterModal, { IDraftFilterFieldTypes } from "pages/drafts/components/DraftFilterModal";
import emptyDraftsImageSVG from 'assets/images/draftsimg.svg';
import { PublishConfirmModal } from "./components/PublishConfirmModal";
import PublishWarningModal from "./components/PublishWarningModal";
import Toast, { IToastTypes } from "components/toast/Toast";

const styles = {
    container: (isShowList?: boolean) => ({
        width: "100%",
        height: '100vh',
        display: 'flex',
        px: '10%',
        pt: isShowList ? '44px' : '122px',
        pb: 6.25,
        background: theme?.palette?.background?.paper,
        overflow: 'auto'
    }),
    boxHeader: {
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
        pb: 2,
        mt: 1.5,
        width: '100%'
    },
    draftsHeader: {
        fontFamily: 'Museo Sans Rounded',
        fontStyle: 'normal',
        fontWeight: '400',
        color: theme.palette.grayScale[500]
    },
    filterBtnText: {
        ...theme?.typography?.Components?.button?.default,
        ...theme?.typography?.Components?.button?.medium,
        textTransform: 'capitalize',
        pl: 1,
        color: theme?.palette?.grayScale[700],
    },
    filterBtn: {
        p: '12px 20px',
        height: '36px',
        border: `2px solid ${theme.palette.tertiary[600]}`,
        '&:hover': {
            background: '#ffffff',
        }
    },
    listIconContainer: {
        '& .MuiIconButton-root': {
            width: 40,
            height: 40
        }
    },
    listIconButtonStyle: (isOpen: boolean) => ({
        background: isOpen ? theme?.palette?.secondary?.[50] : '',
        '&:hover': { background: 'rgba(0, 173, 187, 0.04)' },
        '> .MuiTouchRipple-root span': {
            background: 'rgba(0, 173, 187, 0.3)'
        },
    }),
    sortModalContainer: {
        zIndex: 2,
        position: 'absolute',
        top: '36px',
        left: '16px'
    },
    deleteModalContainer: {
        zIndex: 2,
        position: 'absolute',
        top: '15px',
        right: '15px'
    },
    publishModalContainer: {
        zIndex: 2,
        position: 'absolute',
        top: '25px',
        right: '15px'
    },
    image: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        mb: '30px',
    },
    boxImportFiles: {
        border: `2px solid ${theme?.palette?.grayScale['A1']}`,
        borderRadius: '5px',
        py: '50px',
        position: 'relative',
        background: '#F9FAFC'
    },
    importText: {
        textAlign: "center",
        color: '#676F7C',
    },
    createPathwayButtonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    createPathwayButtonStyle: {
        py: '12.5px',
        px: '58.5px'
    },
    createPathwayBtnTextStyle: {
        fontFamily: 'Nunito',
        textTransform: 'none',
        color: theme?.palette?.secondary[50]
    },
    newPathwayButtonStyle: {
        py: '5px',
        px: '16px',
        ml: '12px'
    },
    newPathwayBtnTextStyle: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: '700',
        textTransform: 'none',
        fontSize: '15px',
        lineHeight: '20px',
        ml: '7px',
        color: theme?.palette?.secondary[50]
    },
    listContainer: {
        borderRadius: '20px',
        backgroundColor: '#F9FAFC',
        height: 'calc(92vh - 180px)',
        position: 'relative',
        px: '38px',
        pt: '26px',
        pb: '10px',
        overflow: 'auto'
    },
    importButtonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
};

const Pathway = () => {
    const { getToken } = useAuth();
    const headers = getDefaultHeaders(getToken());
    const navigate = useNavigate();
    const [selectedPathwayData, setSelectedPathwayData] = useState<IPathwayModuleCardDataProps[]>([]);
    const [deletedPathwayData, setDeletedPathwayData] = useState<IPathwayModuleCardDataProps[]>([]);
    const [openSortModal, setSortModalState] = useState(false);
    const [openDeleteModal, setDeleteModalState] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [openFilterModal, setFilterModalState] = useState(false);
    const [isAscending, setIsAscending] = useState<boolean>(true);
    const [selSortType, setSelSortType] = useState<ISortFieldTypes>(ISortFieldTypes.PathwayDate);
    const [fieldType, setFieldType] = useState<IDraftFilterFieldTypes>();
    const [openPublishModal, setOpenPublishModal] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState(false);
    const [isPublishing, setIsPublishing] = useState(false);
    const [publishToast, setPublishToast] = useState({
        show: false,
        message: '',
        type: IToastTypes.Success
    });

    const onPublishPathway = useMutation(() => {
        const formData = selectedPathwayData.map((item) => item.header.id);;
        return axiosInstance().put(
            "/contentcreator/pathways/publish",
            formData,
            {
                headers: {
                    Authorization: headers.Authorization,
                },
            }
        );
    });

    const { data, isLoading, refetch } = useQuery<IPathwayModuleCardDataProps[]>('drafts', async () => {
        const response = (await axiosInstance().get('/contentcreator/pathways/unpublished', {
            headers: {
                "Authorization": headers.Authorization,
                "Content-Type": "application/json"
            },
            data: {}
        })).data;
        return response ? response.map((item: any) => ({ ...item.pathway })) : [];
    }, {
        cacheTime: 0,
        refetchOnWindowFocus: false,
        onSuccess: () => {
            setDeletedPathwayData([]);
            setSelectedPathwayData([]);
        },
        onError: () => {
            throw new Error('There is some issue in fetching pathways');
        },
    });

    const getFilteredArray = useMemo((): any => {
        return fieldType ? data?.filter((x: IPathwayModuleCardDataProps) => {
            if (fieldType === IDraftFilterFieldTypes.ReadyToPublish) {
                if (x.data.status === fieldType) {
                    return true
                }
                else { return false }
            } else {
                if (!x.data.status || x.data.status === IDraftFilterFieldTypes.Drafts || x.data.status === IDraftFilterFieldTypes.Unpublished) {
                    return true
                }
                else { return false }
            }
        }) : data;
    }, [fieldType, data])

    const sortedPathwayList = useMemo(() => {
        return data?.length ? [...getFilteredArray].sort((a, b) => {
            let fieldA: any, fieldB: any;
            switch (selSortType) {
                case ISortFieldTypes.PathwayDate:
                    fieldA = new Date(a.data.createDate).getTime();
                    fieldB = new Date(b.data.createDate).getTime();
                    break;
                default:
                    fieldA = a.data.title.toLowerCase();
                    fieldB = b.data.title.toLowerCase();
                    break;
            }

            if (ISortFieldTypes.PathwayDate === selSortType) {
                if (!isAscending)
                    return fieldA - fieldB;
                else
                    return fieldB - fieldA;
            } else {
                if (isAscending)
                    return fieldA < fieldB ? -1 : Number(fieldA < fieldB);
                else
                    return fieldA > fieldB ? -1 : Number(fieldA > fieldB);
            }
        }) : [];
    }, [data, isAscending, selSortType, fieldType]);

    const showFilters = !!data?.length;
    const showList = !!sortedPathwayList.length;
    const emptyFilteredList = getFilteredArray?.length === 0;
    const showEmptyState = isLoading || !data || data?.length === 0;

    const onSelectPathwayData = (cardData: IPathwayModuleCardDataProps, e: React.ChangeEvent<HTMLInputElement>) => {
        let newData: IPathwayModuleCardDataProps[];
        if (!e.target.checked) {
            newData = selectedPathwayData.filter(pathway => {
                return pathway.header.id !== cardData.header.id
            })
            setSelectedPathwayData(newData);
        } else {
            setSelectedPathwayData(prev => {
                let clonePrev: IPathwayModuleCardDataProps[] = JSON.parse(JSON.stringify(prev));
                if (prev.length) {
                    let findIndex = clonePrev.findIndex(item => item.header.id === cardData.header.id);
                    if (findIndex === -1) {
                        clonePrev.push(cardData);
                    }
                    return clonePrev;
                } else {
                    return [cardData]
                }
            });
        }
    };

    const handleCloseFilterModal = useCallback(() => {
        setFilterModalState(false);
    }, [])

    const onClickFilterModal = useCallback(() => {
        setFilterModalState(!openFilterModal);
    }, [openFilterModal])

    const handleCloseSortModal = useCallback(() => {
        setSortModalState(false);
    }, [])

    const onClickSortModal = useCallback(() => {
        setSortModalState(!openSortModal);
    }, [openSortModal])

    const handleCloseDeleteModal = () => {
        setDeleteModalState(false);
    }

    const onClickDeleteModal = () => {
        setDeleteModalState(true);
    }

    const onClickPublishModal = () => {
        const listDraft = selectedPathwayData.filter(item => item?.data?.status?.toLowerCase() !== 'readytopublish')
        if (listDraft.length) {
            setOpenWarningModal(true)
            setOpenPublishModal(false)
        } else {
            setOpenPublishModal(true)
            setOpenWarningModal(false)
        }
    }

    const handleClosePublishModal = () => {
        setOpenPublishModal(false);
        setOpenWarningModal(false);
    }

    useEffect(() => {
        if (selectedPathwayData.length === deletedPathwayData.length && isDeleting) {
            setIsDeleting(false);
            refetch();
        }
    }, [selectedPathwayData, deletedPathwayData, isDeleting, refetch])

    const removePathwayFromList = useCallback((pathwayId: string) => {
        const module = selectedPathwayData.find(item => item.header.id === pathwayId)
        if (module) {
            setDeletedPathwayData(prv => [...prv, module])
        }
    }, [selectedPathwayData])

    const handlePublishToast = (isSuccess: boolean) => {
        if (isSuccess) {
            setPublishToast({
                show: true,
                type: IToastTypes.Success,
                message: 'Pathway published successfully.'
            })
        } else {
            setPublishToast({
                show: true,
                type: IToastTypes.Error,
                message: 'Some error occored during publishing pathway.'
            })
        }
    }

    const handleOnPublishPathway = () => {
        setOpenPublishModal(false);
        setIsPublishing(true)
        onPublishPathway
            .mutateAsync()
            .then((res) => {
                refetch();
                handlePublishToast(!!res.data?.published?.length)
                setIsPublishing(false)
            })
            .catch((err) => {
                console.log("ERROR", err);
                handlePublishToast(false);
                setIsPublishing(false)
            });
    };

    const handlePublishShowToast = (isShow: boolean) => {
        setPublishToast(prv => ({ ...prv, show: isShow }))
    }

    const renderNewPathwayBtn = () => {
        return (
            <Button
                sx={styles.newPathwayButtonStyle}
                variant="contained"
                color='primary'
                size="medium"
                onClick={() => navigate('/addNewPathway')}
            >
                <img src={OnlyIconSVG} alt="Plus Icon" />
                <Typography sx={styles.newPathwayBtnTextStyle}>New Pathway</Typography>
            </Button>
        )
    }
    return (
        <Box sx={styles.container(showList)} flexDirection="column" >
            <Toast
                message={publishToast.message}
                type={publishToast.type}
                open={publishToast.show}
                successMessageStyle={{ maxWidth: 'auto' }}
                setOpen={handlePublishShowToast}
                style={{ width: '320px' }}
            />
            <Grid flexDirection={'row'} display='flex' alignItems={'center'} justifyContent={'space-between'}>
                <Grid flexDirection={'row'} display='flex' alignItems={'center'}>
                    <Typography sx={{ ...theme?.typography?.h1, ...styles.draftsHeader }}>Drafts</Typography>
                </Grid>
                {!showFilters && renderNewPathwayBtn()}
            </Grid>
            {showFilters && <Grid sx={styles.boxHeader}>
                <Grid item md={5} display={'flex'} flexDirection='row'>
                    <Box>
                        <Box sx={{ position: 'relative' }}>
                            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleCloseFilterModal}>
                                <Box>
                                    <Button
                                        variant="outlined"
                                        size='medium'
                                        sx={{
                                            ...styles.filterBtn,
                                        }}
                                        onClick={onClickFilterModal}>
                                        <img src={FilterSVG} alt="filterIcon" width={18} height={12} />
                                        <Typography sx={{ ...theme?.typography?.subtitle2, ...styles.filterBtnText }}>
                                            Filter
                                        </Typography>
                                    </Button>
                                    <Box sx={styles.sortModalContainer}>
                                        {openFilterModal ? <DraftFilterModal selFilterFieldType={fieldType} setSelFilterFieldType={setFieldType} /> : null}
                                    </Box>
                                </Box>
                            </ClickAwayListener>
                        </Box>
                    </Box>

                    <Box>
                        <Box sx={{ position: 'relative' }}>
                            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleCloseSortModal}>
                                <Box>
                                    <Button
                                        variant="outlined"
                                        size='medium'
                                        sx={{
                                            ...styles.filterBtn,
                                            ml: 2
                                        }}
                                        onClick={onClickSortModal}>
                                        <img src={SortSVG} alt="sortIcon" width={20} height={18} />
                                        <Typography sx={{ ...theme?.typography?.subtitle2, ...styles.filterBtnText }}>
                                            Sort
                                        </Typography>
                                    </Button>
                                    <Box sx={styles.sortModalContainer}>
                                        {openSortModal ? <SortModal isAscending={isAscending} selSortFieldType={selSortType} setIsAscending={setIsAscending} setSelSortFieldType={setSelSortType} /> : null}
                                    </Box>
                                </Box>
                            </ClickAwayListener>
                        </Box>
                    </Box>
                </Grid>
                <Grid item md={7} display={'flex'} flexDirection='row' alignItems={'center'} justifyContent={'flex-end'} sx={styles.listIconContainer}>
                    {!!selectedPathwayData.length && <Grid item >
                        <Grid item sx={{ position: 'relative' }}>
                            <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleCloseDeleteModal}>
                                <Box>
                                    <IconButton onClick={onClickDeleteModal} sx={styles.listIconButtonStyle(openDeleteModal)}>
                                        <img src={DeleteSVG} alt="delete_modules" />
                                    </IconButton>
                                    <Box sx={styles.deleteModalContainer}>
                                        {openDeleteModal ? <DeleteModal handleCloseDeleteModal={handleCloseDeleteModal} pathwayData={selectedPathwayData} removePathwayFromList={removePathwayFromList} setIsDeleting={setIsDeleting} /> : null}
                                    </Box>
                                </Box>
                            </ClickAwayListener>
                        </Grid>
                    </Grid>}
                    {!!selectedPathwayData.length && <Grid item sx={{ position: 'relative' }}>
                        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClosePublishModal}>
                            <Box>
                                <IconButton onClick={onClickPublishModal} sx={styles.listIconButtonStyle(openPublishModal || openWarningModal)}>
                                    <img src={PublishSVG} alt="publish_modules" />
                                </IconButton>
                                <Box sx={styles.publishModalContainer}>
                                    {openWarningModal ? <PublishWarningModal setWarningModal={setOpenWarningModal} /> : null}
                                    {openPublishModal ? <PublishConfirmModal setPublishConfirmModal={setOpenPublishModal} onPublishClick={handleOnPublishPathway} /> : null}
                                </Box>
                            </Box>
                        </ClickAwayListener>
                    </Grid>}
                    {selectedPathwayData.length === 1 && <Grid item >
                        <IconButton onClick={() => navigate(`/pathwayCreator/outline/${selectedPathwayData[0]?.header?.id}`)} sx={styles.listIconButtonStyle(false)}>
                            <img src={EditSVG} alt="edit_modules" />
                        </IconButton>
                    </Grid>}
                    <Grid item display={'flex'} alignItems={'center'}>
                        {renderNewPathwayBtn()}
                    </Grid>
                </Grid>
            </Grid>}
            {showEmptyState ?
                <Box sx={{ ...styles.boxImportFiles, background: theme?.palette?.info?.contrastText }}>
                    <Box sx={styles.image}>
                        <img src={emptyDraftsImageSVG} alt="add_icon" />
                    </Box>
                    <Box>
                        <Typography sx={{ ...theme?.typography?.h1, ...styles.importText, mb: '29px' }}>
                            You don't have anything yet.
                            <br />Let's get started!
                        </Typography>
                    </Box>
                    <Box sx={styles.importButtonBox}>
                        <Button
                            sx={{ width: '250px', height: '51px' }}
                            variant="contained"
                            color='primary'
                            size="medium"
                            onClick={() => navigate('/addNewPathway')}
                        >
                            <Typography sx={{
                                ...theme?.typography?.body?.default,
                                ...theme?.typography?.body?.bold,
                                color: theme?.palette?.secondary[50],
                                fontSize: '18px',
                                lineHeight: '25px',
                                textTransform: 'capitalize'
                            }}>Create Pathway</Typography>
                        </Button>
                    </Box>
                    {isLoading && <CircularProgress size={60} sx={{ position: 'absolute', top: 'calc(50% - 80px)', right: 'calc(50% - 42px)', color: theme?.palette?.tertiary[600] }} />}
                </Box>
                :
                emptyFilteredList ?
                    <Box sx={styles.boxImportFiles}>
                        <Box sx={styles.image}>
                            <img src={emptyDraftsFilterImage} alt="add_icon"
                                width={'165px'}
                                height={'141px'} />
                        </Box>
                        <Box>
                            <Typography sx={{ ...theme?.typography?.h1, ...styles.importText, pb: '25px' }}>
                                We don't have anything that fits that criteria.
                            </Typography>
                            <Typography sx={{ ...theme?.typography?.h1, ...styles.importText, fontSize: '20px', lineHeight: '28px' }}>
                                Adjust your filters to see more pathway drafts.
                            </Typography>
                        </Box>
                        {isLoading && <CircularProgress size={60} sx={{ position: 'absolute', top: 'calc(50% - 80px)', right: 'calc(50% - 42px)', color: theme?.palette?.tertiary[600] }} />}
                    </Box>
                    :
                    <Box sx={styles.listContainer} flex={'1'} className='customScroll'>
                        <DraftModuleList selectedPathwayData={selectedPathwayData} data={sortedPathwayList} onSelectPathwayData={onSelectPathwayData} />
                    </Box>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isPublishing || isDeleting || (isLoading && showList)}
            >
                <CircularProgress size={60} sx={{ color: theme?.palette?.primary[500] }} />
            </Backdrop>
        </Box>
    )
}

export default Pathway;