import { useContext } from 'react';
import { Box, Typography } from '@mui/material';

import { ThemeContext } from 'util/themes/currentThemeContext';

export const PageHeader = (props: any) => {
    const title = props.title;
    const subTitle = props.subTitle;
    const tagline = props.tagline;
    const { currentTheme } = useContext(ThemeContext);

    return (
        <Box>
            <Typography
                variant="h4"
                gutterBottom
                color="primary"
                style={{
                    fontFamily: "'Raleway'",
                    fontWeight: '600',
                    fontSize: '28px',
                    lineHeight: '33px',
                    color: currentTheme.palette.grayScale[900],
                }}
            >
                {title}
            </Typography>
            <Typography variant="h5" color="secondary">
                {subTitle}
            </Typography>
            <Typography variant="body1">{tagline}</Typography>
        </Box>
    );
};

PageHeader.defaultProps = {
    title: 'Page Title',
    subTitle: '',
};
