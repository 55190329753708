import { Avatar as MuiAvatar } from '@mui/material';

const styles = {
  default: {
    height: '32px',
    width: '32px',
    margin: '0 auto',
  },
  medium: {
    height: '48px',
    width: '48px',
    margin: '0 auto'
  },
  large: {
    height: '96px',
    width: '96px',
    margin: '0 auto',
  },
  xlarge: {
    height: '144px',
    width: '144px',
    margin: '0 auto'
  }
};

interface AvatarProps {
  firstName: string;
  lastName: string;
  avatarImg?: string;
  size: 'large' | 'default' | 'medium' | 'xlarge';
  sx?: any;
}

const Avatar = (props: AvatarProps) => {
  const firstName = props?.firstName || '';
  const lastName = props?.lastName || '';
  const fullName = `${firstName} ${lastName}`.replace(/\"/g, '');
  const initials = firstName.charAt(0) + lastName.charAt(0);
  return (
    <>
      {props.avatarImg ? (
        <MuiAvatar
          alt={fullName}
          src={props.avatarImg}
          sx={{ ...styles[props.size], ...props.sx }}
        />
      ) : (
        <MuiAvatar sx={{ ...styles[props.size], ...props.sx }}>{initials}</MuiAvatar>
      )}
    </>
  );
};

Avatar.defaultProps = {
  size: 'default',
};

export default Avatar;