import { Box, Typography, Button, ClickAwayListener } from "@mui/material";
import { Button as ControlBtn } from "core/components";
import { godzilla as theme } from "util/themes/godzilla";
import ErrorIcon from "../../../assets/images/erroricon.svg";

const styles = {
  cancelUploadBox: {
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px rgb(0 0 0 / 14%), 0px 1px 18px rgb(0 0 0 / 12%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "10px",
    py: 2,
    cursor: "initial",
  },
  cancelText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    mb: 2,
    color: theme?.palette?.grayScale[500],
  },
  cancelYesButton: {
    background: theme?.palette?.error?.main,
    borderRadius: "100px",
    padding: "6px 20px",
    gap: "8px",
    mb: "6px",
    textWrap: 'nowrap',
  },
  removeBtnText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    letterSpacing: "-0.01em",
    textTransform: "none",
    color: theme?.palette?.error?.contrast,
  },
  cancelNoButton: {
    borderRadius: "100px",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 700,
    color: theme?.palette?.primary[500],
    textTransform: "none",
    textWrap: 'nowrap',
    px: 2,
    py: "4px",
    "&:hover": { background: "rgba(0, 61, 166, 0.04)" },
    "> .MuiTouchRipple-root span": {
      background:
        "radial-gradient(36.59% 100.8% at 50% 50%, rgba(122, 132, 255, 0.7) 99.54%, rgba(255, 255, 255, 0) 100%)",
    },
  },
  linkDependencyText: {
    fontFamily: "Museo Sans Rounded",
    fontSize: "21px",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#4B4D58",
    textTransform: "none",
  },
};

interface IActionsDeleteModal {
  handleCloseDeleteModal: () => void;
  onClickConfirmationDelete: () => void;
  openConfirmationDeleteModal: boolean;
  setOpenConfirmationDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const deleteModal = {
  deleteText: "Are you sure you want to delete the selected module?",
  confirmationDeleteText:
    "Deleting it will remove the dependencies. Are you sure you want to delete this module?",
};

export const ActionsDeleteModal = (props: IActionsDeleteModal) => {
  const {
    handleCloseDeleteModal,
    onClickConfirmationDelete,
    openConfirmationDeleteModal,
    setOpenConfirmationDeleteModal,
  } = props;

  const onHandleClick = () => {
    if (openConfirmationDeleteModal) {
      onClickConfirmationDelete();
    } else {
      setOpenConfirmationDeleteModal(true);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleCloseDeleteModal}>
      <Box
        sx={{
          ...styles.cancelUploadBox,
          width: `${openConfirmationDeleteModal ? "382px" : "242px"}`,
          px: `${openConfirmationDeleteModal ? "26px" : "12px"}`,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {openConfirmationDeleteModal && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"flex-start"}
            mb={"10px"}
          >
            <img src={ErrorIcon} alt="ERRORICON" />
            <Typography sx={styles.linkDependencyText} ml={"11px"}>
              This module is linked as a dependency on other modules.{" "}
            </Typography>
          </Box>
        )}
        <Typography
          sx={{
            ...styles.cancelText,
            textAlign: `${openConfirmationDeleteModal ? "left" : "center"}`,
            px: `${openConfirmationDeleteModal && "11px"}`,
          }}
        >
          {openConfirmationDeleteModal
            ? deleteModal.confirmationDeleteText
            : deleteModal.deleteText}
        </Typography>
        <ControlBtn
          variant="contained"
          color="error"
          size="medium"
          sx={{ ...styles.cancelYesButton, width: `${openConfirmationDeleteModal ? "155px" : '111px'}`}}
          onClick={onHandleClick}
        >
          <Typography
            sx={{
              ...theme?.typography?.Components?.button?.default,
              ...theme?.typography?.Components?.button?.medium,
              ...styles.removeBtnText,
            }}
          >
            Yes, delete
          </Typography>
        </ControlBtn>
        <Button sx={{ ...styles.cancelNoButton, width: `${openConfirmationDeleteModal ? "155px" : '111px'}`}} onClick={handleCloseDeleteModal}>
          No, don’t delete
        </Button>
      </Box>
    </ClickAwayListener>
  );
};
