import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  Breadcrumbs,
  Link,
  TextField,
  InputAdornment,
  Tooltip,
  Popper,
  Chip,
} from "@mui/material";
import { Button } from "core/components";
import LifeTrekLogo from "../../../assets/images/LifeTrekLogo.svg";
import CrossIcon from "../../../assets/images/crossicon.svg";
import ErrorIcon from "../../../assets/images/erroricon.svg";
import { godzilla as theme } from "util/themes/godzilla";
import { useCallback, useState, ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useAuth } from "core/contexts";
import { useMutation, useQuery } from "react-query";
import HelpIcon from "@mui/icons-material/Help";
import Autocomplete from "@mui/material/Autocomplete";
import SearchIcon from "../../../assets/images/PathwayCreator/searchicon.svg";
import CancelIcon from "@mui/icons-material/Cancel";

const inputTextStyle = (border: string, autoComplete: boolean) => ({
  "& .MuiInputBase-root": {
    width: "300px",
    height: "33px",
    "& .MuiOutlinedInput-notchedOutline": {
      border: border,
      borderRadius: autoComplete ? "100px" : "4px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: border,
      borderRadius: autoComplete ? "100px" : "4px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: border,
      borderRadius: autoComplete ? "100px" : "4px",
    },
    "& .MuiInputBase-input": {
      padding: "6px 9px 7px 9px",
      ...theme?.typography?.body?.default,
      ...theme?.typography?.body?.body1,
      ...theme?.typography?.body?.light,
      color: "#505866",
    },
  },
});

const styles = {
  modalStyle: {
    "& .MuiPaper-root": {
      height: "100vh",
      width: "800px",
      maxWidth: "inherit",
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
      borderRadius: "0px",
      margin: "0px",
      maxHeight: "100vh",
    },
  },
  autocompleteField: {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      "& .MuiAutocomplete-input": {
        padding: "0px",
      },
    },
  },
  chipStyle: {
    background: "#4653f6",
    borderRadius: "100px",
    mx: "5px",
    my: 1,
    "& .MuiChip-label": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "150%",
      letterSpacing: "-0.01em",
      color: "#FFFFFF",
    },
    "& .MuiChip-deleteIcon": {
      color: "#E9EAFF",
      borderRadius: "50%",
      "&:hover": {
        color: "#E9EAFF",
      },
    },
  },
  NoEmployerFoundComponentBox: {
    height: "146px",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
  },
  optionsComponentBox: {
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    "& .MuiAutocomplete-option": {
      "&:hover": {
        backgroundColor: theme?.palette?.primary[10],
      },
    },
  },
  exitButtonStyle: {
    width: "100px",
    '&:hover': { background: 'rgba(0, 0, 0, 0.04)' },
    '> .MuiTouchRipple-root span': {
      background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(0, 0, 0, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
    },
  }
};

const ONLY_SPECIAL_CHAR_REGEX = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*()_+,\-.\/:;<=>?@\[\]\\\{\}|\~\` ]*/

const NoEmployerFoundComponent = () => {
  return (
    <Box
      sx={styles.NoEmployerFoundComponentBox}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      <Box my={"24px"} mx={"36px"}>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
          <Typography>
            <img src={SearchIcon} alt="ICON" />
          </Typography>
          <Typography
            sx={{
              ...theme?.typography?.h6,
              color: "#3D4655",
              ml: "11.5px",
              mt: "-2px",
            }}
          >
            Employer Not Found
          </Typography>
        </Box>
        <Box mt={"8px"}>
          <Typography
            sx={{
              ...theme?.typography?.body?.default,
              ...theme?.typography?.body?.body1,
              color: "#3D4655",
            }}
          >
            Oops! No employer with that title was found. Try searching a
            different name.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const AddNewPathway = () => {
  const [pathwayTitle, setPathwayTitle] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [maxErrorFlag, setMaxErrorFlag] = useState(false);
  const [organizations, setOrganizations] = useState<Array<any>>([]);
  const [filteredList, setFilteredList] = useState<Array<any>>([]);
  const [value, setValue] = useState<Array<any>>([]);
  const [noDataFlag, setNoDataFlag] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [organizationsErrorFlag, setOrganizationsErrorFlag] = useState(false);
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());

  const OptionsPopoverComponent = useCallback((props: any) => {
    return (
      <Popper {...props} sx={styles.optionsComponentBox} placement="bottom" />
    );
  }, []);

  const getPreparedData = () => {
    return {
      pathway: { data: { title: pathwayTitle } },
      publishedTo: value.map((employer: any) => {
        return {
          action: "NEW",
          header: {
            id: employer.sdoId
          },
          data: {
            organizationId: employer.organizationId,
            name: employer.name
          },
        };
      }),
    };
  };

  const onCreatePathway = useMutation(() => {
    const formData = getPreparedData();
    return axiosInstance().post("/contentcreator/pathways", formData, {
      headers: {
        Authorization: headers.Authorization,
      },
    });
  });

  const addPathwayTitle = (e: any) => {
    setPathwayTitle(e.target.value);
    setErrorFlag(false);
    setMaxErrorFlag(false);
  };

  const CreatePathway = () => {
    if (pathwayTitle.trim() === "" && value.length === 0) {
      setErrorFlag(true);
      setMaxErrorFlag(false);
      setOrganizationsErrorFlag(true);
    } else if (pathwayTitle.trim() === "" && value.length > 0) {
      setErrorFlag(true);
      setMaxErrorFlag(false);
      setOrganizationsErrorFlag(false);
    } else if (pathwayTitle.trim() !== "" && value.length === 0) {
      setOrganizationsErrorFlag(true);
      if (pathwayTitle.trim().length > 100) {
        setMaxErrorFlag(true);
      } else {
        setMaxErrorFlag(false);
      }
      if (
        !pathwayTitle.match(ONLY_SPECIAL_CHAR_REGEX)
      ) {
        setErrorFlag(true);
        setMaxErrorFlag(false);
      } else {
        setErrorFlag(false);
      }
    } else if (pathwayTitle.trim() !== "" && value.length > 0) {
      setOrganizationsErrorFlag(false);
      let error = false;
      if (pathwayTitle.trim().length > 100) {
        setMaxErrorFlag(true);
        error = true;
      } else {
        setMaxErrorFlag(false);
      }
      if (
        !pathwayTitle.match(ONLY_SPECIAL_CHAR_REGEX)
      ) {
        error = true;
        setErrorFlag(true);
        setMaxErrorFlag(false);
      } else {
        setErrorFlag(false);
      }

      if (!error) {
        setButtonLoading(true);
        onCreatePathway
          .mutateAsync()
          .then((res) => {
            setErrorFlag(false);
            setMaxErrorFlag(false);
            setOrganizationsErrorFlag(false);
            setButtonLoading(false);
            navigate(`/pathwayCreator/outline/${res.data.pathway.header.id}`);
          })
          .catch((err) => {
            setButtonLoading(false);
            console.log("ERROR", err);
          });
      }
    }
  };

  const { data, isLoading } = useQuery(
    "organizations",
    async () => {
      const response = await axiosInstance().get(
        "/contentcreator/organization",
        {
          headers: {
            Authorization: headers.Authorization,
            "Content-Type": "application/json",
          },
          data: {},
        }
      );
      return response.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!isLoading) {
      let cloneData = data?.data.map((orgData: any) => {
        return { ...orgData.data, sdoId: orgData.header.id }
      }) || [];
      if(cloneData.length !== 0) {
        cloneData = [
          {
            name: "All organizations",
            organizationId: "All",
          },
          ...cloneData,
        ];
      }
      setOrganizations(cloneData);
      setFilteredList(cloneData);
    }
  }, [isLoading]);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue === "") {
      let cloneData = organizations.filter(
        (item1: any) =>
          !value.some((item2) => item1.organizationId === item2.organizationId)
      );
      if (cloneData.length === 1 && cloneData[0].organizationId === "All") {
        setFilteredList([]);
        setNoDataFlag(false);
      } else {
        setFilteredList(cloneData);
        setNoDataFlag(false);
      }
    } else {
      let cloneData =
        organizations
          .filter(
            (item1: any) =>
              !value.some(
                (item2) => item1.organizationId === item2.organizationId
              )
          )
          .filter((item, index) => {
            if (index === 0) {
              return false;
            }
            return item?.name.toLowerCase().includes(inputValue.toLowerCase());
          }) || [];
      if (cloneData.length === 0) {
        setNoDataFlag(true);
        setFilteredList([]);
      } else {
        setFilteredList(cloneData);
        setNoDataFlag(false);
      }
    }
  };

  const onDelete = (organizationId: string) => () => {
    setOrganizationsErrorFlag(false);
    let findIndex = filteredList.findIndex(
      (item: any) => item.organizationId === "All"
    );
    const filteredValue = value.filter(
      (x: any) => x.organizationId !== organizationId
    );
    if (findIndex >= -1) {
      setValue(filteredValue);
      let cloneData = organizations.filter(
        (item1: any) =>
          !filteredValue.some(
            (item2) => item1.organizationId === item2.organizationId
          )
      );
      setFilteredList(cloneData);
    } else {
      setValue(filteredValue);
      let cloneData = [
        {
          name: "All organizations",
          organizationId: "All",
        },
        ...filteredList,
      ];
      setFilteredList(cloneData);
    }
  };

  const onChangeSetEmployersData = (newValue: any[]) => {
    let findIndex = newValue.findIndex(
      (item: any) => item.organizationId === "All"
    );
    if (findIndex > -1) {
      setValue(organizations.slice(1));
      setFilteredList([]);
    } else {
      setValue(newValue);
      let cloneData = organizations.filter(
        (item1: any) =>
          !newValue.some(
            (item2) => item1.organizationId === item2.organizationId
          )
      );
      if (cloneData.length === 1 && cloneData[0].organizationId === "All") {
        setFilteredList([]);
      } else {
        setFilteredList(cloneData);
      }
    }
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={styles.modalStyle}
      BackdropProps={{
        sx: {
          backgroundColor: theme?.palette?.background?.default,
          opacity: "0",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box display={"flex"} flexDirection={"row"} justifyContent={"center"}>
          <Box mt={"60px"}>
            <img src={LifeTrekLogo} alt="LOGO" />
          </Box>
          <Box mt={"36px"} position={"absolute"} right={"40px"}>
            <IconButton
              sx={{
                "&.MuiIconButton-root": {
                  height: "44px",
                  width: "44px",
                  backgroundColor: "#F9FAFC",
                  "&:hover": {
                    backgroundColor: "#F9FAFC",
                  },
                },
              }}
              onClick={() => navigate("/pathway")}
            >
              <img src={CrossIcon} alt="CROSSICON" />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className="customScroll">
        <Box display={"flex"} flexDirection={"column"} mx={"220px"} mt={"40px"}>
          <Box display={"flex"} justifyContent={"center"}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ '& .MuiBreadcrumbs-separator': { color: theme?.palette?.grayScale?.[300] } }}>
              <Link
                underline="hover"
                color="inherit"
                href="/pathway"
                sx={{
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.medium,
                  textDecoration: "underline",
                  textDecorationColor: theme?.palette?.secondary[400],
                  color: "#7E8592",
                }}
              >
                Drafts
              </Link>
              <Link
                color="inherit"
                sx={{
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  textDecoration: "none",
                  color: "#505866",
                }}
              >
                Create New Pathway
              </Link>
            </Breadcrumbs>
          </Box>
          <Box mt={"4px"}>
            <Typography sx={{ ...theme?.typography?.h1, color: "#3D4655" }}>
              Create New Pathway
            </Typography>
          </Box>
          <Box mt={"40px"}>
            <Box>
              <Typography sx={{ ...theme?.typography?.h3, color: "#1C222D" }}>
                Pathway Title<span style={{ color: theme?.palette?.error?.light }}>*</span>
              </Typography>
            </Box>
            <Box mt={"16px"}>
              <TextField
                sx={{
                  ...inputTextStyle("1px solid #959CA8", false),
                  ...(errorFlag && inputTextStyle("2px solid #FF4F4F", false)),
                }}
                value={pathwayTitle}
                onChange={(e) => addPathwayTitle(e)}
              />
              {errorFlag && (
                <Box display={"flex"} flexDirection={"row"} mt={"3px"}>
                  <Box mt={"-4px"}>
                    <img
                      src={ErrorIcon}
                      alt="ERRORICON"
                      style={{
                        height: "10px",
                        width: "10px",
                      }}
                    />
                  </Box>
                  <Box ml={"2px"}>
                    <Typography
                      sx={{
                        ...theme?.typography?.captions?.default,
                        ...theme?.typography?.captions?.regular,
                        color: "#CF0000",
                      }}
                    >
                      Please enter pathway title
                    </Typography>
                  </Box>
                </Box>
              )}
              {maxErrorFlag && (
                <Box display={"flex"} flexDirection={"row"} mt={"3px"}>
                  <Box mt={"-4px"}>
                    <img
                      src={ErrorIcon}
                      alt="ERRORICON"
                      style={{
                        height: "10px",
                        width: "10px",
                      }}
                    />
                  </Box>
                  <Box ml={"2px"}>
                    <Typography
                      sx={{
                        ...theme?.typography?.captions?.default,
                        ...theme?.typography?.captions?.regular,
                        color: "#CF0000",
                      }}
                    >
                      Must be atmost of 100 characters
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              mt={"24px"}
            >
              <Box>
                <Typography sx={{ ...theme?.typography?.h3, color: "#1C222D" }}>
                  Created For<span style={{ color: theme?.palette?.error?.light }}>*</span>
                </Typography>
              </Box>
              <Box ml={"4px"} mt={"4px"}>
                <Tooltip
                  title="‘Created for’ indicates who the Pathway was designed for."
                  placement="top"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: theme?.palette?.grayScale[800],
                        borderRadius: "4px",
                        height: "48px",
                        width: "213px",
                        ...theme?.typography?.body?.default,
                        ...theme?.typography?.body?.body2,
                        ...theme?.typography?.body?.light,
                        color: "#FFFFFF",
                      },
                    },
                  }}
                >
                  <HelpIcon sx={{ color: "#2F7BFF" }} />
                </Tooltip>
              </Box>
            </Box>
            <Box mt={"16px"}>
              <Autocomplete
                disableClearable
                multiple
                blurOnSelect
                filterOptions={(x) => x}
                getOptionLabel={(option: any) => option.name}
                options={filteredList}
                forcePopupIcon={false}
                sx={styles.autocompleteField}
                ListboxProps={{
                  style: {
                    maxHeight: 300,
                    overflow: "auto",
                    ...theme?.typography?.body?.default,
                    ...theme?.typography?.body?.body1,
                    ...theme?.typography?.body?.regular,
                    color: "#2C3442",
                    paddingTop: "20px",
                    paddingBottom: "16px",
                  },
                }}
                PopperComponent={OptionsPopoverComponent}
                noOptionsText={noDataFlag ? <NoEmployerFoundComponent /> : ""}
                renderTags={() => null}
                value={value}
                onChange={(e: any, newValue: any) => {
                  if (e?.target.value !== "") {
                    onChangeSetEmployersData(newValue);
                    setOrganizationsErrorFlag(false);
                  }
                }}
                onFocus={() => {
                  setNoDataFlag(false);
                  setOrganizationsErrorFlag(false);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search for Employers"
                    onChange={handleInputChange}
                    sx={{
                      ...inputTextStyle("1px solid #959CA8", true),
                      ...(organizationsErrorFlag &&
                        inputTextStyle("2px solid #FF4F4F", true)),
                    }}
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          <InputAdornment position="start" sx={{ ml: "16px" }}>
                            <img src={SearchIcon} alt="ICON" />
                          </InputAdornment>
                          {params.InputProps.startAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
              {organizationsErrorFlag && value.length === 0 ? (
                <Typography
                  sx={{
                    ...theme?.typography?.captions?.default,
                    ...theme?.typography?.captions?.regular,
                    textTransform: "none",
                    color: theme?.palette?.error?.dark,
                  }}
                  display={"flex"}
                >
                  <img
                    src={ErrorIcon}
                    style={{
                      height: "10px",
                      width: "10px",
                      marginTop: "2px",
                      marginRight: "4px",
                    }}
                    alt="ICON"
                  />
                  Please choose at least one employer.
                </Typography>
              ) : (
                ""
              )}
              <Box
                mt={3}
                sx={{
                  "& > :not(:last-child)": { mr: 1 },
                  "& > *": { mr: 1 },
                }}
              >
                {value.map((v: any) => (
                  <Chip
                    sx={styles.chipStyle}
                    deleteIcon={<CancelIcon />}
                    key={v.organizationId}
                    label={v.name}
                    onDelete={onDelete(v.organizationId)}
                  />
                ))}
              </Box>
            </Box>
            <Box mt={"30px"}>
              <Box display={"flex"} justifyContent={"center"}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: "150px" }}
                  onClick={CreatePathway}
                  loading={buttonLoading}
                >
                  <Typography
                    sx={{
                      ...theme?.typography?.Components?.button?.default,
                      ...theme?.typography?.Components?.button?.medium,
                      textTransform: "none",
                      color: theme?.palette?.secondary[50],
                    }}
                  >
                    Create
                  </Typography>
                </Button>
              </Box>
              <Box mt={"12px"} display={"flex"} justifyContent={"center"}>
                <Button
                  variant="text"
                  color="primary"
                  sx={styles.exitButtonStyle}
                  onClick={() => navigate("/pathway")}
                >
                  <Typography
                    sx={{
                      ...theme?.typography?.Components?.button?.default,
                      ...theme?.typography?.Components?.button?.large,
                      textTransform: "none",
                      color: "#202124",
                    }}
                  >
                    Exit
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewPathway;
