import { Box, TextField, Typography, IconButton } from "@mui/material";
import Knowledge_Icon from "assets/images/PathwayCreator/Knowledge_icon.svg";
import Demonstration_Icon from "assets/images/PathwayCreator/Demonstration_icon.svg";
import Error_Icon from "assets/images/erroricon.svg";
import EditModal from "./EditModal";
import { godzilla as theme } from "util/themes/godzilla";
import { useEffect, useState } from "react";
import { IAddMilestoneProps, IContextData, IDemonstrationMilestoneData, IKnowledgeMilestoneData, MilestoneDataType } from "../outline.helper";
import { IPathwayError, useAppContext } from "components/app-context/appContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { v4 as uuidv4 } from 'uuid';

const styles = {
  inputStyle: {
    "& .MuiInputBase-root": {
      width: "396px",
      height: "34px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5B67FF",
        borderRadius: "4px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "& .MuiInputBase-input": {
      padding: "6px 9px 7px 9px",
    },
  },
};

const MaxErrorText = "Must be atmost of 100 characters";
const RequiredErrorText = "The Milestone title cannot be blank. Please add a Milestone title.";

const AddMilestone = (props: IAddMilestoneProps) => {
  const { milestoneType, milestoneData, setMilestoneData, setAddNewDemonstrationMilestone, setAddNewKnowledgeMilestone } = props;
  const [knowledgeTitle, setKnowledgeTitle] = useState<string>("");
  const [demonstrationTitle, setDemonstrationTitle] = useState<string>("");
  const [onMenuOpen, setOnMenuOpen] = useState<boolean>(false);
  const [errorFlags, setErrorFlags] = useState<{
    requiredError: boolean,
    maxLength: boolean
  }>({
    requiredError: false,
    maxLength: false
  });
  const { pathwayError, setPathwayError }: IContextData = useAppContext();

  useEffect(() => {
    const isTypeKnowledge = milestoneType === 'KNOWLEDGE';
    const milestoneError = isTypeKnowledge ? pathwayError.knowledgeMilestoneError : pathwayError.demonstrationMilestoneError;
    if (errorFlags.maxLength || errorFlags.requiredError) {
      if (!milestoneError) {
        const newErrorData: IPathwayError = { ...pathwayError };
        isTypeKnowledge ? newErrorData.knowledgeMilestoneError = true : newErrorData.demonstrationMilestoneError = true;
        setPathwayError(newErrorData)
      }
    } else {
      if (milestoneError) {
        const newErrorData: IPathwayError = { ...pathwayError };
        isTypeKnowledge ? newErrorData.knowledgeMilestoneError = false : newErrorData.demonstrationMilestoneError = false;
        setPathwayError(newErrorData)
      }
    }
  }, [errorFlags, milestoneType, pathwayError, setPathwayError]);

  const onChangeMilestoneTitle = (e: any, milestoneType: string) => {
    milestoneType === 'KNOWLEDGE' ? setKnowledgeTitle(e.target.value) : setDemonstrationTitle(e.target.value);
    setErrorFlags({
      requiredError: false,
      maxLength: false
    })
  };

  const onCheckMilestone = (title: string, milestoneType: string) => {
    if (title.trim() === "") {
      setErrorFlags({
        requiredError: true,
        maxLength: false
      })
      milestoneType === "KNOWLEDGE" ? setKnowledgeTitle("") : setDemonstrationTitle("")
    } else if (title.length > 100) {
      setErrorFlags({
        requiredError: false,
        maxLength: true
      })
    } else if (!title.match(/^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*()_+,\-.\/:;<=>?@\[\]\\\{\}|\~\` ]*/)) {
      setErrorFlags({
        requiredError: true,
        maxLength: false
      })
    } else {
      setErrorFlags({
        requiredError: false,
        maxLength: false
      })

      if (milestoneType === "KNOWLEDGE") {
        setAddNewKnowledgeMilestone(false)
        const newMilestoneData: IKnowledgeMilestoneData[] = [
          ...milestoneData,
          {
            uuid: uuidv4(),
            title: knowledgeTitle,
            order: milestoneData.length + 1,
            type: "KNOWLEDGE",
            action: "NEW"
          }
        ]
        setMilestoneData(newMilestoneData)
      } else {
        setAddNewDemonstrationMilestone(false)
        const newMilestoneData: IDemonstrationMilestoneData[] = [
          ...milestoneData,
          {
            uuid: uuidv4(),
            title: demonstrationTitle,
            order: milestoneData.length + 1,
            type: "DEMONSTRATION",
            action: "NEW"
          }
        ]
        setMilestoneData(newMilestoneData)
      }
    }
  }

  const onAddMilestoneTitle = (e: any, onKeyPress: boolean, milestoneType: string) => {
    if (onKeyPress) {
      if (e.key === 'Enter') {
        if (milestoneType === "KNOWLEDGE") {
          onCheckMilestone(knowledgeTitle, milestoneType)
        } else {
          onCheckMilestone(demonstrationTitle, milestoneType)
        }
      }
    } else {
      if (milestoneType === "KNOWLEDGE") {
        onCheckMilestone(knowledgeTitle, milestoneType)
      } else {
        onCheckMilestone(demonstrationTitle, milestoneType)
      }
    }
  }

  const handleDeleteEmptyMilestone = () => {
    const isTypeKnowledge = milestoneType === 'KNOWLEDGE';
    if (isTypeKnowledge) {
      setAddNewKnowledgeMilestone(false);
    } else {
      setAddNewDemonstrationMilestone(false);
    }
    const newErrorData: IPathwayError = { ...pathwayError };
    isTypeKnowledge ? newErrorData.knowledgeMilestoneError = false : newErrorData.demonstrationMilestoneError = false;
    setPathwayError(newErrorData)
  }

  const handleClick = () => {
    setOnMenuOpen(true)
  }

  return (
    <>
      <Box display={"flex"} justifyContent={"center"} flexDirection={'column'}>
        <Box
          width={"500px"}
          height={"auto"}
          border={`${errorFlags.requiredError || errorFlags.maxLength ? "2px solid #FF4F4F" : "2px solid #26C5D2"}`}
          borderRadius={"8px"}
        >
          <Box display={"flex"} flexDirection={"row"}>
            <Box ml={"24px"} mt={"16.5px"}>
              <img
                src={
                  milestoneType === "KNOWLEDGE"
                    ? Knowledge_Icon
                    : Demonstration_Icon
                }
                alt="ICON"
                style={{ height: "24px", width: "24px" }}
                draggable="false"
              />
            </Box>
            <Box ml={"8px"} mt={"11px"} mb={'11px'}>
              <TextField
                sx={{
                  ...styles.inputStyle,
                }}
                value={milestoneType === 'KNOWLEDGE' ? knowledgeTitle : demonstrationTitle}
                onChange={(e: any) => onChangeMilestoneTitle(e, milestoneType)}
                onKeyDown={(e: any) => onAddMilestoneTitle(e, true, milestoneType)}
                onBlur={(e: any) => onAddMilestoneTitle(e, false, milestoneType)}
                onFocus={() => {
                  setErrorFlags({
                    requiredError: false,
                    maxLength: false
                  })
                }}
                autoFocus={true}
              />
            </Box>
            <Box ml={"2px"} mt={"9.5px"}>
              <IconButton
                onClick={handleClick}
                sx={{
                  padding: '0px',
                  mt: "7.5px"
                }}
              >
                <MoreVertIcon />
              </IconButton>
              <EditModal isDeleteDoubleConfirmModal={false} isEdit={true} onMenuOpen={onMenuOpen} setOnMenuOpen={setOnMenuOpen} onHandleEmptyStateDelete={handleDeleteEmptyMilestone}/>
            </Box>
          </Box>
        </Box>
        {errorFlags.requiredError || errorFlags.maxLength ? <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mt={'7px'}>
          <Box mt={'-2px'}>
            <img src={Error_Icon} alt="ICON" style={{ height: "10px", width: "10px" }} />
          </Box>
          <Box ml={'2px'}>
            <Typography sx={{
              ...theme?.typography?.captions?.default,
              ...theme?.typography?.captions?.regular,
              color: '#CF0000'
            }}>{errorFlags.requiredError ? RequiredErrorText : MaxErrorText}</Typography>
          </Box>
        </Box> : null}
      </Box>
    </>
  );
};

export default AddMilestone;
