import { Children } from 'react';
import { Grid, Typography, Breadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';
import { godzilla as theme } from 'util/themes/godzilla';

const styles = {
	breadCrumbs: {
		color: theme?.palette?.grayScale[400],
	},
	breadCrumbsLinks: {
		color: theme?.palette?.grayScale[400],
	}
}

const BreadCrumbComponent = ({ breadCrumbPath, title = '' }: { breadCrumbPath: any[], title?: string }) => {
	return (
		<Grid>
			<Grid>
				<Breadcrumbs sx={{
					...styles.breadCrumbs,
					"& .MuiBreadcrumbs-separator": {
						...theme?.typography?.body?.default,
						...theme?.typography?.body?.body1,
						...theme?.typography?.body?.medium,
						color: theme?.palette?.grayScale[300]
					}
				}} aria-label="breadcrumb">
					{Children.toArray(
						breadCrumbPath.map((i, index, row) => {
							return (
								<Link style={{
									...styles.breadCrumbsLinks,
									textDecoration: (index + 1 === row.length) ? 'none' : 'underline',
									textDecorationColor: theme?.palette?.secondary[400],
									cursor: (index + 1 === row.length) ? 'default' : 'pointer',
								}} to={i?.url}>
									<Typography sx={{
										...theme?.typography?.body?.default,
										...theme?.typography?.body?.body1,
										...theme?.typography?.body?.medium,
										color: (index + 1 === row.length) ? theme?.palette?.grayScale[600] : theme?.palette?.grayScale[400]
									}}>
										{i?.name}
									</Typography>
								</Link>
							)
						})
					)}
				</Breadcrumbs>
			</Grid>
			{title && <Grid>
				<Typography variant={"h3"} sx={{
					color: theme?.palette?.grayScale[700]
				}}>
					{title}
				</Typography>
			</Grid>}
		</Grid>
	)
}

export default BreadCrumbComponent;