import { godzilla as theme } from "util/themes/godzilla";

export const exitModalData = {
  content:
    "Wait! Are you sure you want to exit? If you haven’t saved, you will lose any changes you have made up to this point. You cannot undo this action.",
  confirmBtnText: "Yes, exit",
  cancelBtnText: "No, continue creating my module",
  anchorOrigin: {
    vertical: "bottom" as const,
    horizontal: "right" as const,
  },
  transformOrigin: {
    vertical: "top" as const,
    horizontal: "right" as const,
  },
};

export interface IPageData {
  title: string;
  richText: string;
  estimatedTimeToCompleteInMinutes: number;
}

export const styles = {
  saveButtonTextStyle: {
    fontWeight: 700,
    fontSize: "19px",
    lineHeight: "26px",
  },
  buildButtonTextStyle: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "25px",
  },
  button: {
    border: "1px dashed #676F7C",
    borderRadius: "10px",
    width: "300px",
    height: "60px",
  },
  pageOrganizer: {
    fontFamily: "Museo Sans Rounded",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "24px",
    lineHeight: "29px",
    color: "#3D4655",
    mt: "8px",
    mb: "22px",
  },
  hidePages: {
    color: "#505866",
    textTransform: "capitalize",
    pl: "9px",
  },
  newPageTitle: {
    textTransform: "capitalize",
    fontSize: "20px",
    lineHeight: "27px",
  },
  titleInput: (moduleTitle: string) => ({
    width: "62vw",
    "& .MuiOutlinedInput-root": {
      fontSize: moduleTitle.length > 60 ? "24px" : "28px",
      fontWeight: 400,
      fontFamily: "Museo Sans Rounded",
      p: 0,
      "& fieldset": {
        borderColor: "rgba(0,0,0,0)",
      },
      "&:hover fieldset": {
        borderColor: theme.palette?.primary[300],
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette?.primary[300],
        boxShadow: "0px 0px 3px rgba(0, 118, 222, 0.5)",
      },
      "& textarea ": {
        p: "8px 9px",
        width: "62vw",
      },
    },
  }),
  titleErrorConatiner:{
    display:'flex',
    flexDirection:'column'
  },
  container: {
    display: "flex",
    flexDirection: "column",
    background: theme?.palette?.info.contrastText,
    height: "100vh",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    ml: "40px",
    mt: "29px",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "row",
    ml: "80px",
    mt: "36px",
    height: "100%",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  titleTextContainer: {
    ml: "18px",
    display: "flex",
    alignItems: "center",
  },
  settingGearContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  actionBtnContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mt: "2px",
    mr: "40px",
  },
  exitText: (theme: any) => ({
    ...theme?.typography?.Components?.button?.default,
    ...theme?.typography?.Components?.button?.large,
    color: "#202124",
    textTransform: "none",
  }),
  saveBtn: {
    width: "87px",
    height: "42px",
  },
  saveBtnText: (theme: any) => ({
    ...theme?.typography?.Components?.button?.default,
    ...styles.saveButtonTextStyle,
    color: "#003DA6",
    textTransform: "none",
  }),
  nextBtn: (theme: any) => ({
    width: "114px",
    height: "41px",
    "& .MuiButton-endIcon": {
      color: theme?.palette?.secondary[50],
    },
  }),
  nextBtnText: (theme: any) => ({
    ...theme?.typography?.Components?.button?.default,
    ...styles.buildButtonTextStyle,
    color: theme?.palette?.secondary[50],
    textTransform: "none",
  }),
  pageContainer: (hide: boolean) => ({
    transition: "left 0.5s ease 0s",
    width: "25%",
    position: hide ? "absolute" : "fixed",
    left: hide ? "-25%" : "80px",
  }),
  hidPageBtn: {
    p: 0,
    height: "30px",
    width: "96px",
  },
  hidePageBtnText: (theme: any) => ({
    ...theme?.typography?.body?.default,
    ...theme?.typography?.body?.body2,
    ...theme?.typography?.body?.bold,
    ...styles.hidePages,
  }),
  plusIconContainer: {
    left: "54px",
    position: "absolute",
  },
  newPageText: (theme: any) => ({
    ...styles.newPageTitle,
    ...theme?.typography?.body?.default,
    ...theme?.typography?.body?.regular,
  }),
  editorContainer: (hide: boolean) => ({
    display: "flex",
    transition: "all 0.5s ease 0s",
    width: hide ? "100%" : "70%",
    marginLeft: hide ? "0px" : "calc(25% + 40px)",
    justifyContent: "flex-start",
    padding: "0px 107px 20px 38px",
  }),
  settingGearIcon: {
    height: "20px",
    width: "20px",
  },
  chevronLeftIcon: {
    height: "12px",
    width: "7px",
  },
  settingButton: {
    border: "none",
    minWidth: "20px",
    "&:hover": {
      border: "none",
      backgroundColor: "transparent",
    },
  },
};

export const exitModalStyles = {
  container: {
    "& .MuiPaper-root": {
      minHeight: "205px",
      maxWidth: "318px",
      px: "20px",
      py: "24px",
      borderRadius: "10px",
    },
  },
  contentText: {
    color: theme?.palette?.grayScale[700],
    fontFamily: "Nunito",
    fontSize: "16px",
    fontWeight: 400,
    pb: 2,
  },
  actionButtonsContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  exitBtn: {
    "&.MuiButtonBase-root": {
      height: "28px",
      minWidth: "98px",
      marginBottom: "8px",
      background: theme?.palette?.error?.light,
    },
  },
  exitBtnText: {
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontWeight: 700,
    textTransform: "none",
  },
  cancelBtnText: {
    color: theme?.palette?.primary[500],
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontWeight: 600,
    textTransform: "none",
  },
};
