import {v4 as uuidv4 } from 'uuid'

export const getPathwayCreatorObject = (data: any) => {

  const knowledgeMilestones = data?.knowledgeMilestones || [];
  const demoMilestones = data?.demoMilestones || [];

  return {
    pathway: {
      data: data?.pathway?.data,
      header: {
        id: data?.pathway?.header?.id
      }
    }, 
    publishedTo: data?.publishedTo?.map((org: any) => {
      return {
        header: {
          id: org.sdoId
        },
        data: {
          organizationId: org.organizationId,
          name: org.name,
          createDate: org?.createDate || ""
        }
      }
    }), 
    milestones: [
    ...knowledgeMilestones.map((knowledgeMilestone: any, index: number)=>{
      return {
        uuid: uuidv4(),
        action: "NONE",
        type: "KNOWLEDGE",
        order: knowledgeMilestone?.properties?.order,
        ...knowledgeMilestone
      }
    }),
    ...demoMilestones.map((demoMilestone: any, index: number)=>{
      return {
        uuid: uuidv4(),
        action: "NONE",
        type: "DEMONSTRATION",
        order: demoMilestone?.properties?.order,
        ...demoMilestone
      }
    })
    ].sort((a, b) => a.order - b.order),
    pathwaySkills: data?.pathwaySkills?.map((skill: any) => {
        return {
          action: "NONE",
          header: {
            id: skill.header.id
          },
          data: {
            ...skill.data
          }
        }
      })
  }
}