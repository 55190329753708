import axios from 'axios';
import { baseApiUrl } from '../../constants';

const instance = (history = null) => {
    const axiosInstance = axios.create({ baseURL: baseApiUrl });

    axiosInstance.interceptors.response.use(
        (response) => Promise.resolve(response),
        (error) => {
            if (axios.isCancel(error)) {
                return Promise.reject(error.message);
            } 
            
            console.log('ERROR', error.response);

            if (!error.response) {
                Promise.reject(error);
            }

            if (
                error.response.status === 403 ||
                error.response.status === 401
            ) {
                localStorage.clear();

                if (history) {
                    console.log('ERROR: History', history);
                    (window as any).history.push('/login');
                } else {
                    console.log('ERROR: window.');
                    (window as any).location = '/login';
                }
                return Promise.reject(error.response);
            } else {
                return Promise.reject(error.response);
            }
        }
    );

    return axiosInstance;
};

const withCredentials = (token: string) => {
    const headers: any = {};

    if (token) {
        headers.Authorization = `Bearer ${token}`;
    } else {
        console.error('JWT NOT FOUND');
    }

    return { headers: headers };
};




const withHeaders = (headers = {}) => {
  return { headers: headers };
};

export { instance, withCredentials, withHeaders };

