import { theme } from "util/themes/godzilla";

export const reviewTagWarningModalText = `You must select and assign at least one tag per module. Please review your module(s) and confirm that at least one tag has been selected and assigned.`;

export const draftWarningModalText = `Oops! You selected a module or modules that isn't / aren't ready to be published. Review and add tags by clicking on the card(s).`;

export const inputTextStyle = (border: string) => ({
    "& .MuiInputBase-root": {
        "& .MuiOutlinedInput-notchedOutline": {
            border: border,
            borderRadius: "100px",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
            border: border,
            borderRadius: "100px",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: border,
            borderRadius: "100px",
        },
        "& .MuiInputBase-input": {
            padding: "0px",
            "&::placeholder": {
                opacity: 1,
            },
        },
    },
    "& .MuiOutlinedInput-input": {
        fontFamily: "Nunito",
        fontStyle: "normal",
        fontWeight: 300,
        fontSize: "16px",
        color: theme?.palette?.grayScale?.[700],
    }
});