import { createTheme } from '@mui/material/styles';

const baseTheme = createTheme({
    typography: {
        fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
        overline: {
            letterSpacing: '0.0625em',
        },
    },
    palette: {
        primary: {
            main: '#3FA2A1',
            dark: '#35919D',
            light: '#70D9E7',
            contrastText: '#FEFEFE',
        },
        secondary: {
            main: '#3F72A2',
            dark: '#223E58',
            light: '#52C0BE',
            contrastText: '#FEFEFE',
        },
        error: {
            main: '#F34236',
            dark: '#D22E2E',
            light: '#E47272',
            contrastText: '#FEFEFE',
        },
        warning: {
            main: '#FE9700',
            dark: '#F47B00',
            light: '#FEB64C',
            contrastText: '#FEFEFE',
        },
        info: {
            main: '#1F95F2',
            dark: '#1875D1',
            light: '#63B4F5',
            contrastText: '#FEFEFE',
        },
        success: {
            main: '#4BAE4E',
            dark: '#378D3B',
            light: '#80C683',
            contrastText: '#FEFEFE',
        },
        divider: '#dddfe2',
    },
    components: {
        MuiToolbar: {
            styleOverrides: {
                root: {
                    '@media (min-width: 600px)': {
                        minHeight: '56px',
                    },
                },
            },
        },
        MuiSkeleton: {
            styleOverrides: {
                wave: true,
            },
        },
    },
});

export { baseTheme };
