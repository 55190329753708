import { currentSDZ, customerName } from "constants/app.constants";
import { instance as axiosInstance } from "core/util";

export const getDefaultHeaders = (token: any) => {
  return {
    BIQ_CUSTOMER_NAME: customerName,
    BIQ_SDZ_NAME: currentSDZ?.name,
    Authorization: `Bearer ${token}`,
  };
};

export const getQueryData = async (url: string, headers: any, params?: any) => {
  const response = await axiosInstance().get(url, {
    headers,
  });

  return response.data;
};
