import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import {
  Grid,
  Typography,
  Checkbox,
  Card,
  ClickAwayListener,
  Box,
  CircularProgress,
  Backdrop
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { godzilla as theme } from 'util/themes/godzilla';
import { IModuleCardDataProps, IModulePageDataProps } from '../types';
import { Button } from 'core/components';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteModal from '../../components/DeleteModal';
import { ModuleItem } from './ModuleItem';


interface IModuleCardProps {
  id: string,
  pageModuleId: string,
  index: number,
  accordionTitle: string,
  status: string,
  isTagRequired: boolean,
  isPageRequired: boolean,
  checkedModule: boolean,
  setModuleData?: (id: string, cardData: IModuleCardDataProps, e: React.ChangeEvent<HTMLInputElement>) => void,
  cardData: IModuleCardDataProps,
  onSelectCard?: (data: IModuleCardDataProps) => void,
  onSelectPage?: (moduleId: string, data: IModulePageDataProps) => void,
  hasSelection: boolean,
  hasDeleteSelection: boolean,
  onSelectModuleData?: (index: number, cardData: IModuleCardDataProps) => void,
  isFirstModuleExpanded?: boolean,
  isModuleSorting?: boolean,
  removeModuleFromList: (course: IModuleCardDataProps) => void,
  coursesLength: number
}

const styles = {
  accordionContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  accordionHeader: (open: boolean, hasSelection: boolean) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    padding: `15px ${hasSelection ? '36px' : '20px'} ${open ? '0px' : '15px'} ${hasSelection ? '25px' : '22px'}`,
    minHeight: open ? '57px' : '72px',
    transition: 'all 0.2s linear',
    cursor: 'pointer'
  }),
  accordionContent: (hasSelection: boolean) => ({
    pl: `${hasSelection ? '67px' : '32px'}`,
    height: '0px',
    transition: 'all 0.2s linear',
    '& .open': {
      backgroundColor: 'red'
    }
  }),
  accordion_title: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    color: '#3D4655'
  },
  accordion_subtitle: {
    fontWeight: 400
  },
  accordion_expand_icon: {
    open: {
      transform: 'rotate(-180deg)'
    }
  },
  expandIconStyle: (open: boolean) => ({
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.primary[500],
    transition: 'ease 0.2s',
    ...(!open && styles.accordion_expand_icon.open),
    '&:hover': {
      backgroundColor: '#D6E5FF',
      borderRadius: '50%'
    }
  }),
  deleteText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    display: 'flex',
    alignItems: 'center',
    color: theme?.palette?.grayScale[700],
    textTransform: 'capitalize',
  },
  deleteModalContainer: {
    zIndex: 2,
    position: 'absolute',
    top: '30px',
    right: '8px'
  },
  loader: {
    container: {
      position: 'absolute',
      height: 'inherit',
      left: '50%',
      transform: 'translateX(50%)'
    }
  },
  checkboxStyle: {
    '&:hover': { background: 'rgba(0, 173, 187, 0.08)' },
    '> .MuiTouchRipple-root span': {
        background: 'rgba(0, 173, 187, 0.3)'
    },
},
};

const Accordion = (props: IModuleCardProps) => {
  const { coursesLength, onSelectModuleData, index, hasSelection, hasDeleteSelection, accordionTitle, status, id, pageModuleId, checkedModule, setModuleData, onSelectCard, onSelectPage, cardData, isFirstModuleExpanded, removeModuleFromList, isTagRequired, isPageRequired, isModuleSorting } = props;
  const [open, setOpen] = useState(!!isFirstModuleExpanded);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPageLayoutOpen, setIsPageLayoutOpen] = useState(false);

  let contentRef: any = useRef();
  let delBtnRef: any = useRef();

  useEffect(() => {
    setOpen(!!isFirstModuleExpanded)
    if (isFirstModuleExpanded) {
      onSelectCard && onSelectCard(cardData);
    }
  }, [isFirstModuleExpanded, coursesLength]);

  const onClickExpand = useCallback((e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation();
    setOpen(!open);
  }, [open]);

  const getContentHeight = useMemo(() => {
    return contentRef?.current?.scrollHeight + (hasDeleteSelection ? 14 : 26);
  }, [contentRef, hasDeleteSelection]);

  const onCheckModule = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (setModuleData) {
      setModuleData(id, cardData, e);
    }
  }

  const onSelectModule = () => {
    if (!cardData?.modules[0]?.selected || isPageLayoutOpen) {
      onSelectCard && onSelectCard(cardData);
      setIsPageLayoutOpen(false);
    }
    onSelectModuleData && onSelectModuleData(index, cardData);
  }

  const onClickOpenPageLayout = (moduleId: string, pageData: IModulePageDataProps) => {
    onSelectPage && onSelectPage(moduleId, pageData);
    setIsPageLayoutOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  }

  const onClickDelete = (e: any) => {
    e.stopPropagation();
    setOpenDeleteModal(true);
  };

  const getDeleteModalPos = () => {
    if (delBtnRef) {
      return {
        top: `${(delBtnRef?.current ? delBtnRef?.current?.offsetTop : 0) + 30}px`
      }
    }
    return {
      top: '0px'
    }
  }

  let errorBorderStyle;

  if (isPageRequired || isTagRequired) {
    errorBorderStyle = !cardData?.modules[0]?.selected
      ? theme.card.errorBoxLeftBorder
      : theme.card.editingViewBoxLeftBorder;
  } else if (cardData?.modules?.length && !!cardData?.modules[0]?.selected) {
    errorBorderStyle = theme.card.editingViewBoxLeftBorder;
  } else if (
    status?.toLowerCase() === 'readytopublish' ||
    status?.toLowerCase() === 'published'
  ) {
    errorBorderStyle = theme.card.readytopublishLeftBorder;
  } else {
    errorBorderStyle = theme.card.needreviewandopenLeftBorder;
  }

  const errorBorder = errorBorderStyle;

    let message = '';

    if (isPageRequired) {
      message = 'Page required';
    } else if (isTagRequired) {
      message = 'Tag required';
    } else if (status.toLowerCase() === 'readytopublish') {
      message = 'Ready to Publish';
    } else if (status.toLowerCase() === 'published') {
      message = status;
    }
    
    const textColor = (isPageRequired || isTagRequired) ? theme.palette.error.dark : '#999CAF';

  return (
    <Card sx={{
      ...theme.card.cardBox,
      ...((isPageRequired || isTagRequired) && !cardData?.modules[0]?.selected ? {
        border: `2px solid ${theme.palette.error.light}`,
        '&:hover': {
          border: `2px solid ${theme.palette.error.light}`,
        }
      } : (cardData?.modules?.length && !!cardData?.modules[0]?.selected) && {
        border: `2px solid ${theme.palette.primary[500]}`,
        '&:hover': {
          border: `2px solid ${theme.palette.primary[500]}`,
        }
      }),
      mb: 2,
      cursor: 'pointer'
    }} onClick={onSelectModule}>
      <Grid className='accordion' sx={{
        ...errorBorder,
        ...styles.accordionContainer
      }}>
        <Grid className='accordion-header' justifyContent={'space-between'} sx={{
          ...styles.accordionHeader(open, hasSelection),
        }}>
          <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} zIndex={1}>
            {hasSelection && <Checkbox sx={styles.checkboxStyle} onClick={e => e.stopPropagation()} onChange={onCheckModule} checked={checkedModule} />}
            <Grid sx={{ ml: 1.25 }}>
              <Typography sx={{
                ...styles.accordion_title,
                ...theme.typography.Components.cardAccordion.headerText
              }}>
                {accordionTitle}
              </Typography>
              {isModuleSorting && cardData.title && <Typography sx={{
                ...theme.typography.Components.cardAccordion.headerText,
                ...styles.accordion_title,
                ...styles.accordion_subtitle,
              }}>
                {cardData.title}
              </Typography>}
            </Grid>
          </Grid>
          <Grid display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <Typography sx={{
              pr: 1.75,
              ...theme.typography.Components.cardAccordion.statusText,
              color: textColor
            }}>
              {message}
            </Typography>
              <ExpandMoreIcon
                width={24}
                height={24}
                onClick={onClickExpand}
                sx={styles.expandIconStyle(open)}
              />
          </Grid>
        </Grid>
        <Grid className='accordion-content' sx={{
          ...styles.accordionContent(hasSelection),
          height: open ? getContentHeight : "0px",
          pb: open ? `${hasDeleteSelection ? '14px' : '26px'}` : '0px',
        }} ref={contentRef}>
          {open && <ModuleItem hasSelection={hasSelection} moduleId={id} pageModuleId={pageModuleId} onClickOpenPageLayout={onClickOpenPageLayout} />}
          {hasDeleteSelection ? <Grid display={'flex'} justifyContent='flex-end' pr={1}>
            <ClickAwayListener onClickAway={handleCloseDeleteModal} mouseEvent="onMouseDown" touchEvent="onTouchStart">
              <Box ref={delBtnRef}>
                <Button
                  startIcon={<DeleteIcon sx={{ color: theme?.palette?.error?.light }} />}
                  variant={'text'}
                  color='error'
                  size='medium'
                  onClick={onClickDelete}
                >
                  <Typography sx={styles.deleteText}>
                    Delete
                  </Typography>
                </Button>
                {openDeleteModal ? <Box sx={{
                  ...styles.deleteModalContainer,
                  right: '66px',
                  top: getDeleteModalPos()?.top || '0px'
                }}
                >
                  <DeleteModal handleCloseDeleteModal={handleCloseDeleteModal} moduleData={[cardData]} removeModuleFromList={removeModuleFromList} isMultipleDelete={false} setIsDeleting={setIsDeleting} onSelectCard={onSelectCard} />
                </Box> : null}
              </Box>
            </ClickAwayListener>
          </Grid> : null}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isDeleting}
          >
            <CircularProgress size={60} sx={{ color: theme?.palette?.primary[500] }} />
          </Backdrop>
        </Grid>
      </Grid>
    </Card>
  )
}

export default Accordion;