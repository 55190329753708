import { instance as axiosInstance, withHeaders } from "core/util";
// import { getDefaultHeaders } from "core/util/other";

export interface Dictionary {
  name: string;
  description: string;
  undefinedAttributesAction: string;
  attributes: any[];
  indexes: any[];
}


export const getQueryData = async (url: string, headers: any, params?: any) => {
  const response = await axiosInstance().get(url, {
    headers,
  });

  return response.data;
};

// export const getDictionaries = async (token: string) => {
//   const headers = getDefaultHeaders(token);

//   const response = await axiosInstance().get(
//     "/metadata/dictionary",
//     withHeaders(headers)
//   );

//   return response.data;
// };
