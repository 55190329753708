import { Box, Grid, Typography } from "@mui/material";
import { godzilla as theme } from "util/themes/godzilla";
import PathwayLayout from "../../PathwayLayout/PathwayLayout"
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import BreadCrumbComponent from 'pages/pathway/components/BreadCrumb';
import { useAppContext } from "components/app-context/appContext";
import { useEffect, useState, useMemo, useCallback } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PageList } from './BuildModules/components/PageList';
import CrossIcon from 'assets/images/crossicon.svg';
import DragAndDropComponent from "components/drag-and-drop/DragAndDrop";
import Dots_Vertical from "assets/images/PathwayCreator/Drag_vertical.svg";
import { IContextData, IKnowledgeMilestoneData, IPathwayData } from "../PathwayOutline/outline.helper";

const styles = {
  moduleCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    cursor: "pointer"
  },
  itemContainer: (isSelected: boolean) => ({
    mb: '16px',
    border: `2px solid ${isSelected ? theme?.palette?.primary?.[500] : '#C6CAD1'}`,
    borderRadius: '8px',
    p: '17px',
    pr: isSelected ? '16px' : '20px',
    pb: isSelected ? '26px' : '17px',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: isSelected ? theme.card.editingViewBoxLeftBorder : '2px solid #C6CAD1'
  }),
  list_expand_icon: {
    open: {
      transform: 'rotate(-180deg)'
    }
  },
  expandIconStyle: (open: boolean) => ({
    display: 'flex',
    cursor: 'pointer',
    color: theme.palette.primary[500],
    transition: 'ease 0.2s',
    ...(!open && styles.list_expand_icon.open),
    '&:hover': {
      backgroundColor: '#D6E5FF',
      borderRadius: '50%'
    }
  }),
  moduleTitle: (isSelected: boolean) => ({
    ...theme?.typography?.Components?.toast?.successText,
    color: theme.palette.grayScale[700],
    fontWeight: isSelected ? '700' : '500',
    mr: '20px'
  })
}

const MilestoneModules = () => {
  const { pathwayData, setPathwayData }: IContextData = useAppContext();
  const params = useParams();
  const navigate = useNavigate();
  const [selectedModuleId, setSelectedModuleId] = useState<string>('');
  const [milestoneModulesData, setMilestoneModulesData] = useState<Array<any>>();

  useEffect(() => {
    if (!pathwayData.pathway?.data?.title) {
        navigate(`/pathwayCreator/build/${params.id}`)
    }
  }, []);

  const breadCrumbPath = [
    { name: 'Drafts', url: '/pathway' },
    { name: pathwayData.pathway?.data?.title, url: `/pathwayCreator/build/${params.id}` },
    { name: params.mName, url: '' }
  ];

  const milestoneIndex = useMemo(() => Number(params.mIndex) ?? -1, [params]);

  const milestoneModules = useMemo(() => {
    return (pathwayData.milestones[milestoneIndex] as IKnowledgeMilestoneData)?.modules || [];
  }, [pathwayData.milestones, milestoneIndex])
  
  useEffect(() => {
    setMilestoneModulesData(milestoneModules)
  }, [milestoneModules])

  const onUpdateModuleData = (items: any) => {
    setMilestoneModulesData(items);
    const updatedMilestones = pathwayData.milestones;
    const selectedMilstone = updatedMilestones[milestoneIndex] as IKnowledgeMilestoneData;
    if(selectedMilstone.action === 'NONE') {
      selectedMilstone.action = 'UPDATE';
    }
    selectedMilstone.modules = items; 
    setPathwayData({
      ...pathwayData,
      milestones: updatedMilestones
    })
  }
  
  const onSelectItem = (moduleItem: any) => setSelectedModuleId(selectedModuleId === (moduleItem?.id || moduleItem?.sdoId) ? '' : (moduleItem?.id || moduleItem?.sdoId))

  const onDeleteModule = useCallback((moduleItem: any) => {
    const dataToUpdate: IPathwayData = JSON.parse(JSON.stringify(pathwayData))
    const milestone = dataToUpdate.milestones[milestoneIndex];
    (milestone as IKnowledgeMilestoneData).modules = milestoneModules.filter((item: any) => (item?.id || item?.sdoId) !== (moduleItem?.id || moduleItem?.sdoId));
    if (milestone.sdoId && milestone.action !== "NEW") {
      milestone.action = "UPDATE";
    }
    dataToUpdate.milestones[milestoneIndex] = milestone;
    setPathwayData(dataToUpdate)
  }, [pathwayData, milestoneIndex, milestoneModules, setPathwayData])

  return (
    <PathwayLayout
      activeIndex={1}
      buttonText={"Save"}
      hasExitToBuildButton={true}
      exitBtnText={"No, continue reviewing my milestone"}
      exitNavigation={'/pathway'}
      pathwayTitle={pathwayData.pathway?.data?.title}
      isBreadCrumb={true}
      BreadCrumbCmp={() => <BreadCrumbComponent breadCrumbPath={breadCrumbPath} title={params.mName} />}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box width={milestoneModules.length ? '460px' : '350px'}>
          <Box display={'flex'} justifyContent={'flex-start'} mb={'20px'}>
            <Typography sx={{
              ...theme?.typography?.h3,
              color: theme?.palette?.grayScale[700],
            }}>Modules</Typography>
          </Box>
          <DragAndDropComponent
            draggableData={milestoneModulesData || []}
            setDraggableUpdateData={(items) => onUpdateModuleData(items)}
            isDraggableDisable={milestoneModulesData?.length === 1 ? true : false}
            renderItem={(item: any) => {
              const isSelected = (item?.id || item.sdoId) === selectedModuleId
              return (
                <>
                  <Grid 
                  sx={{
                    ...styles.itemContainer(isSelected),
                    "& .editContainer": {
                      visibility: "hidden",
                    },
                    "&:hover, &:active": {
                      borderColor: theme?.palette?.secondary[400],
                      "& .editContainer": {
                        visibility: "initial",
                      },
                    }
                  }}
                  >
                    <Grid sx={styles.moduleCard}>
                      <Box mt={"2px"} mr={'6px'} className={"editContainer"}>
                        <img
                          src={Dots_Vertical}
                          alt="ICON"
                          style={{ height: "24px", width: "8px" }}
                        />
                      </Box>
                      <Box sx={{ marginRight: '6px' }}>
                        <ExpandMoreIcon
                          width={24}
                          height={24}
                          onClick={() => onSelectItem(item)}
                          sx={styles.expandIconStyle(isSelected)}
                        />
                      </Box>
                      <Typography sx={{
                        ...styles.moduleTitle(isSelected),
                      }}>
                        {item.title}
                      </Typography>
                      <Box sx={{
                        right: '0px',
                        position: 'absolute',
                        cursor: "pointer"
                      }}
                        onClick={() => onDeleteModule(item)}>
                        <img
                          src={CrossIcon}
                          alt="CROSSICON"
                        />
                      </Box>
                    </Grid>
                    {
                      isSelected &&
                      <Grid sx={{ ml: '35.64px', marginTop: '14px' }}>
                          <PageList moduleId={item?.id || item?.sdoId} isPageClickable={true} />
                      </Grid>
                    }
                  </Grid>
                </>
              );
            }}
          />
          <Box display={'flex'} justifyContent={'center'} height={'60px'} m={'24px 0px'} border={'1px dashed #676F7C'} borderRadius={'10px'} sx={{
            cursor: "pointer",
            "&:hover": {
              cursor: "pointer"
            },
          }}>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'row'} onClick={() => navigate(`/pathwayCreator/build/${params.id}/${params.mName}/${params.mIndex}/addModule`)}>
              <Box mt={'5px'}>
                <AddIcon sx={{ color: '#4653F6' }} />
              </Box>
              <Box ml={'4px'}>
                <Typography sx={{
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body3,
                  ...theme?.typography?.body?.regular,
                  color: theme?.palette?.grayScale[700]
                }}>New Module</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </PathwayLayout>
  )
}

export default MilestoneModules;