import { Box } from "@mui/system";
import { IPathwayModuleCardDataProps } from "pages/drafts/DraftsModule/types";
import { Children } from "react";
import DraftModuleItem from "./components/DraftModuleItem";

interface IPathwayDraftModuleListProps {
    selectedPathwayData: IPathwayModuleCardDataProps[],
    data: IPathwayModuleCardDataProps[],
    onSelectPathwayData: (cardData: IPathwayModuleCardDataProps, e: React.ChangeEvent<HTMLInputElement>) => void
}

const DraftModuleList = (props: IPathwayDraftModuleListProps) => {
    const { data, selectedPathwayData, onSelectPathwayData } = props;
    return (
        <Box width={'100%'}>
            {Children.toArray(data.map(item =>
                <DraftModuleItem
                    isChecked={!!selectedPathwayData.find(x => x.header.id === item.header.id)}
                    item={item}
                    onSelectPathwayData={onSelectPathwayData} />
            ))}
        </Box>
    )
}

export default DraftModuleList;