const getUserDataFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem('userData') ?? '{}');
};

const updateUserDataLoginTermAcceptedStatus = (value: boolean) => {
  const data = getUserDataFromLocalStorage();
  data.isLoginTermAccepted = value;
  localStorage.setItem('userData', JSON.stringify(data));
};

export {
  getUserDataFromLocalStorage,
  updateUserDataLoginTermAcceptedStatus
};