import { Button } from "core/components";
import ExpandChevron from "../../../../assets/images/expandChevron.svg";

const styles = {
  button: {
    "&.MuiButtonBase-root": {
      position: "absolute",
      left: 0,
      marginTop: "58px",
      border: "none",
      borderRadius:'0px 100px 100px 0px',
      boxShadow:
        "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
      height: "76px",
      width: '63px',
      zIndex:99,
    },
  },
};

type IExpandButton = {
  onClick: () => void;
};

const ExpandButton = (props: IExpandButton) => {
  const { onClick } = props;
  return (
    <Button onClick={onClick} sx={styles.button}>
      <img src={ExpandChevron} alt="expand-chevron-icon" />
    </Button>
  );
};

export default ExpandButton;
