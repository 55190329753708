import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import * as feature from "../../pages";
import { ProtectedRoute } from "core/components";
import { requiredRoles } from "constants/app.constants";

// This is where the routes are setup
// ProtectedRoute should be used to ensure roles are respected. * Will work to make that automatic, at some pont
// if requiredRoles is not set then anyone can visit the route

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route
        path="/home"
        element={<ProtectedRoute><feature.HomePage /></ProtectedRoute>}
      />
      <Route path='/drafts'>
        <Route index element={
          <ProtectedRoute>
            <feature.Drafts />
          </ProtectedRoute>
        } />
        <Route path='import-checklist' element={
          <ProtectedRoute>
            <feature.DraftImportChecklist />
          </ProtectedRoute>
        } />
        <Route path='import-file' element={
          <ProtectedRoute>
            <feature.DraftImportFile />
          </ProtectedRoute>
        } />
        <Route path='add-new-module' element={
          <ProtectedRoute>
            <feature.AddNewModule />
          </ProtectedRoute>
        } />
        <Route path='review-module' element={
          <ProtectedRoute>
            <feature.DraftReviewModule />
          </ProtectedRoute>
        } />
      </Route>
      <Route
        path="/library"
        element={
          <ProtectedRoute>
            <feature.ContentLibrary />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pathway"
        element={
          <ProtectedRoute>
            <feature.Pathway />
          </ProtectedRoute>
        }
      />
      <Route
        path="/addNewPathway"
        element={
          <ProtectedRoute>
            <feature.AddNewPathway />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pathwayCreator/build/:id/:mName/:mIndex/module"
        element={
          <ProtectedRoute>
            <feature.PathwayBuild.MilestoneModules />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pathwayCreator/build/:id/:mName/:mIndex/demonstrationModule"
        element={
          <ProtectedRoute>
            <feature.PathwayBuild.DemonstrationMilestone />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pathwayCreator/build/:id"
        element={
          <ProtectedRoute>
            <feature.PathwayBuild.PathwayMilestones />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pathwayCreator/build/:id/:mName/:mIndex/addModule"
        element={
          <ProtectedRoute>
            <feature.PathwayBuild.AddModule />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pathwayCreator/build/:id/:mName/:mIndex/pagePreview"
        element={
          <ProtectedRoute>
            <feature.PathwayBuild.PagePreview />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pathwayCreator/outline/:id"
        element={
          <ProtectedRoute>
            <feature.PathwayOutline.PathwayOutline />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pathwayCreator/settings/:id"
        element={
          <ProtectedRoute>
            <feature.Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pathwayLibrary"
        element={
          <ProtectedRoute>
            <feature.PathwayLibrary />
          </ProtectedRoute>
        }
      />
      <Route
        path="/moduleEditor/:id"
        element={
          <ProtectedRoute>
            <feature.ModuleCreator />
          </ProtectedRoute>
        }
      />
       <Route
        path="/moduleSettings/:id"
        element={
          <ProtectedRoute>
            <feature.ModuleSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/admin"
        element={
          <ProtectedRoute requiredRoles={requiredRoles.adminTools}>
            <feature.Admin />
          </ProtectedRoute>
        }
      />
      <Route
        path="/dictionary"
        element={
          <ProtectedRoute requiredRoles={requiredRoles.dictionary}>
            <feature.DictionaryList />
          </ProtectedRoute>
        }
      />
      <Route
        path="/terms-and-conditions"
        element={
          <ProtectedRoute>
            <feature.TermsAndConditions />
          </ProtectedRoute>
        }
      />
      <Route
        path="InsufficientPermissionsError"
        element={<feature.InsufficientPermissionsError />}
      />
      <Route path="*" element={<feature.NotFound />} />
    </Routes>
  );
};