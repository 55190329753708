import React, { Children } from 'react'
import { Box } from '@mui/material';
import { IPublishedModuleCardDataProps } from 'pages/drafts/DraftsModule/types';
import ModuleItem, { IModuleItem } from './components/ModuleItem';

interface IPublishedModuleProps {
    data: IPublishedModuleCardDataProps[],
    selectedItemId: string,
    onSelectItem: (moduleId: string) => void,
    onChangeItem: (item: IModuleItem) => void
}

const PublishedModulesList = (props: IPublishedModuleProps) => {
    const { data, selectedItemId, onChangeItem, onSelectItem } = props;

    return (
        <Box width={'100%'}>
            {Children.toArray(data.map((item: IPublishedModuleCardDataProps) => (
                <ModuleItem item={item} isSelected={item.id === selectedItemId} onSelectItem={onSelectItem} onChangeItem={onChangeItem} />
            )))}
        </Box>
    )
}

export default PublishedModulesList;