import {
  Box,
  ClickAwayListener,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { godzilla as theme } from "util/themes/godzilla";
import HelpIcon from "@mui/icons-material/Help";
import { useState, useEffect, useRef } from "react";
import PlusIcon from "assets/images/plusicon.svg";
import ChecklistItem from "./components/ChecklistItemCard";
import { IChecklistComponentProps } from "../demonstrationmilestone.helper";
import DragAndDropComponent from "components/drag-and-drop/DragAndDrop";

const ChecklistComponent = (props: IChecklistComponentProps) => {
  const { checklistData, setChecklistData, setErrorMessage } = props;
  const [onTooltipOpen, setonTooltipOpen] = useState(false);
  const [onAddNewChecklistItem, setOnAddNewChecklistItem] = useState(false);
  const [checklistDeleteData, setChecklistDeleteData] = useState<Array<any>>([]);
  const [selectedChecklistIndex, setSelectedChecklistIndex] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasScroll, setHasScroll] = useState(false);
  
  const handleAddNewChecklistItem = () => {
    setOnAddNewChecklistItem(true);
  };

  const onChecklistUpdate = (items:any)=>{
    setChecklistData(items);
  }

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const hasScroll = container.scrollHeight > container.clientHeight;
      setHasScroll(hasScroll);
    }
  }, [checklistData.length]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        mt={"40px"}
        ml={"57px"}
      >
        <Box>
          <Typography
            sx={{
              ...theme?.typography?.h2,
              color: theme?.palette?.grayScale[700],
            }}
          >
            Demonstration Milestone Checklist
          </Typography>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          mt={"20px"}
        >
          <Box>
            <Typography
              sx={{
                ...theme?.typography?.h3,
                color: theme?.palette?.grayScale[700],
              }}
            >
              Checklist
              <span style={{ color: theme?.palette?.error?.light }}>*</span>
            </Typography>
          </Box>
          <Box ml={"4px"} mt={"3px"} position={'relative'}>
            <ClickAwayListener onClickAway={() => setonTooltipOpen(false)}>
              <Tooltip
                title="The ‘Checklist’ should communicate to the learner the steps they need to take to pass the demonstration."
                placement="top"
                arrow
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={() => setonTooltipOpen(false)}
                open={onTooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: theme?.palette?.grayScale[800],
                      borderRadius: "4px",
                      height: "100px",
                      width: "157px",
                      ...theme?.typography?.body?.default,
                      ...theme?.typography?.body?.body2,
                      ...theme?.typography?.body?.regular,
                      color: "#FFFFFF",
                    },
                  },
                  arrow: {
                    sx: {
                      color: theme?.palette?.grayScale[800]
                    }
                  }
                }}
              >
                <HelpIcon
                  sx={{ color: "#2F7BFF", height: "16px", width: "16px",
                        cursor: "pointer"
                      }}
                  onClick={() => setonTooltipOpen(true)}
                />
              </Tooltip>
            </ClickAwayListener>
          </Box>
        </Box>
        <Box
          maxHeight={"calc(100vh - 167px)"}
          overflow={"auto"}
          className={"customScroll"}
          width={'100%'}
          ref={containerRef}
          position={'relative'}
          mt={onAddNewChecklistItem || checklistData.length === 0 ? 0 : 2}
        >
          {onAddNewChecklistItem ? (
            <Box width={'calc(100% - 40px)'} mb={checklistData.length === 0 ? "0" : '12px'} mt={2}>
              <ChecklistItem
                action="ADD"
                checklistData={checklistData}
                setChecklistData={setChecklistData}
                setOnAddNewChecklistItem={setOnAddNewChecklistItem}
                setErrorMessage={setErrorMessage}
                selectedChecklistIndex={selectedChecklistIndex}
                setSelectedChecklistIndex={setSelectedChecklistIndex}
                hasScroll={hasScroll}
              />
            </Box>
          ) : null}
          {checklistData.length ? (
            <Box width={'calc(100% - 40px)'}>
              <DragAndDropComponent
                draggableData={checklistData}
                setDraggableUpdateData={onChecklistUpdate}
                itemStyle={{
                  marginBottom: '14px',
                }}
                isDraggableDisable={checklistData.length === 1 ? true : false}
                renderItem={(item:any,index:number)=>{
                  return(
                    <ChecklistItem
                      index={index}
                      setChecklistDeleteData={setChecklistDeleteData}
                      action="VIEW"
                      checklistData={checklistData}
                      setChecklistData={setChecklistData}
                      checklistItemName={item}
                      selectedChecklistIndex={selectedChecklistIndex}
                      setSelectedChecklistIndex={setSelectedChecklistIndex}
                      setErrorMessage={setErrorMessage}
                      hasScroll={hasScroll}
                    />
                  )
                }}
              />
            </Box>
          ) : null}
        </Box>
        <Box
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            mt={checklistData.length ? "2px" : "16px"}
            mb={2}
          >
            <Box>
              <IconButton
                onClick={handleAddNewChecklistItem}
                sx={{ height: "32px", width: "32px", p: 0 }}
              >
                <img src={PlusIcon} alt="ICON" />
              </IconButton>
            </Box>
            <Box ml={"8px"}>
              <Typography
                sx={{
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body3,
                  ...theme?.typography?.body?.semibold,
                  color: theme?.palette?.grayScale[700],
                }}
              >
                New Checklist Item
              </Typography>
            </Box>
          </Box>
      </Box>
    </>
  );
};

export default ChecklistComponent;
