import { SxProps, Theme, Typography, TextField as MuiTextField, Box, TextFieldProps as MuiTextFieldProps } from "@mui/material"
import { godzilla as theme } from 'util/themes/godzilla';
import SmallErrorIcon from "assets/images/smallerroricon.svg";

const styles = {
    labelStyle: {
        ...theme?.typography?.Components?.inputLabel,
        color: theme?.palette?.grayScale?.[700],
        mb: "6px"
    },
    inputStyle: (isError: boolean) => ({
        "& .MuiInputBase-root": {
            p: '8px',
            "& .MuiInputBase-input": {
                ...theme?.typography?.body?.default,
                ...theme?.typography?.body?.body1,
                ...theme?.typography?.body?.light,
                p: 0
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: isError ? `2px solid ${theme?.palette?.error?.dark}` : `1px solid ${theme?.palette?.grayScale?.[300]}`,
                borderRadius: "4px",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                border: isError ? `2px solid ${theme?.palette?.error?.dark}` : `1px solid ${theme?.palette?.grayScale?.[300]}`,
                borderRadius: "4px",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: isError ? `2px solid ${theme?.palette?.error?.dark}` : `1px solid ${theme?.palette?.grayScale?.[300]}`,
                borderRadius: "4px",
            },
        },
    }),
    bottomContainer: (isError?: boolean) => ({
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        mt: isError ? '4px' : 0
    }),
    errorStyle: {
        ...theme?.typography?.captions?.default,
        ...theme?.typography?.captions?.regular,
        color: theme?.palette?.error?.dark,
    },
    maxLengthStyle: {
        ...theme?.typography?.captions?.default,
        ...theme?.typography?.captions?.regular,
        color: theme?.palette?.grayScale?.[700]
    }
};

type TextFieldProps = {
    required?: boolean;
    maxLength?: number;
    isValidateOnChange?: boolean;
    label?: string;
    inputProps?: MuiTextFieldProps;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    style?: SxProps<Theme>;
    labelStyle?: SxProps<Theme>;
    error?: boolean;
    errorText?: string;
    errorStyle?: SxProps<Theme>;
    errorIcon?: any;
    showErrorIcon?: boolean;
    characterLengthStyle?: SxProps<Theme>;
}

export const TextField = (props: TextFieldProps) => {
    const { required, maxLength, isValidateOnChange = false, label, style = {}, labelStyle = {}, inputProps = {}, error, errorText, errorStyle = {}, characterLengthStyle = {}, errorIcon = SmallErrorIcon, value = "", onChange } = props;

    return (
        <Box display={"flex"} flexDirection={"column"} sx={{
            ...style,
        }}>
            {!!label && <Typography sx={{ ...styles.labelStyle, ...labelStyle }}>{label}{required && <span className="required">*</span>}</Typography>}
            <MuiTextField
                {...inputProps}
                sx={{ ...styles.inputStyle(error || !!errorText), ...inputProps.sx }}
                inputProps={{
                    className: "customScroll"
                }}
                value={value}
                onChange={(e) => {
                    const { value } = e.target;
                    if ((!isValidateOnChange || !maxLength || value.length <= maxLength) && onChange) {
                        onChange(e);
                    }
                }}
            />
            <Box sx={styles.bottomContainer(!!errorText)}>
                <Box>
                    {!!errorText && (
                        <Typography sx={{ ...styles.errorStyle, ...errorStyle }} display={"flex"}>
                            <img src={errorIcon} style={{ marginRight: "4px" }} alt="" />
                            {errorText}
                        </Typography>
                    )}
                </Box>
                {!!maxLength && <Typography sx={{ ...styles.maxLengthStyle, ...characterLengthStyle }}>{`${value.trim().length}/${maxLength}`}</Typography>}
            </Box>
        </Box>
    )
}