import { Box, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import sortAlphAscSVG from 'assets/images/sort-alph-asc.svg';
import sortAlphDescSVG from 'assets/images/sort-alph-desc.svg';
import sortCalAscSVG from 'assets/images/sort-cal-asc.svg';
import sortCalDescSVG from 'assets/images/sort-cal-desc.svg';
import checkCircleSVG from 'assets/images/checkcircle.svg';
import { useCallback } from 'react';
import { godzilla as theme } from "util/themes/godzilla";

const styles = {
  sortDraftBox: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px rgb(0 0 0 / 14%), 0px 1px 18px rgb(0 0 0 / 12%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    width: '263px',
    cursor: 'initial',
    padding: '10px 16px'
  },
  sortItemIcon: {
    ml: '24px',
    minWidth: '23px',
    height: '18px'
  },
  sortListItem: (isSelected: boolean) => ({
    width: '263px',
    pr: '20px',
    py: '10px',
    '&:hover': {
      backgroundColor: theme?.palette?.primary?.[10]
    },
    '& .MuiListItemIcon-root': {
      minWidth: '16px',
      mr: '12px'
    },
    '& .MuiTypography-root': {
      fontWeight: isSelected ? '600' : '400',
      fontFamily: 'Nunito',
      color: '#3D4655'
    }
  }),
}

export enum ISortFieldTypes {
  PathwayName = 'pathwayName',
  PathwayDate = 'pathwayDate',
}

interface IHandleSortModalProps {
  isAscending: boolean,
  selSortFieldType: ISortFieldTypes,
  setIsAscending: React.Dispatch<React.SetStateAction<boolean>>,
  setSelSortFieldType: React.Dispatch<React.SetStateAction<ISortFieldTypes>>
}

export default function SortModal({ setIsAscending, setSelSortFieldType, isAscending, selSortFieldType }: IHandleSortModalProps) {
  const isPathwayDate = selSortFieldType === ISortFieldTypes.PathwayDate;
  const isPathwayName = selSortFieldType === ISortFieldTypes.PathwayName;

  const handleFieldTypeClick = useCallback((type: ISortFieldTypes) => {
    if (selSortFieldType !== type) {
      setSelSortFieldType(type);
      setIsAscending(true);
    } else {
      setIsAscending(prv => !prv);
    }
  }, [selSortFieldType, setSelSortFieldType, setIsAscending])

  return (
    <Box sx={styles.sortDraftBox} onClick={(e) => e.stopPropagation()}>
      <MenuList autoFocusItem={true} id="menu-list-grow">
        <MenuItem sx={styles.sortListItem(isPathwayDate)} onClick={() => handleFieldTypeClick(ISortFieldTypes.PathwayDate)}>
          <ListItemIcon >
            {isPathwayDate && <img src={checkCircleSVG} alt="sortSelectedCheckIcon" width={16} height={16} />}
          </ListItemIcon>
          <ListItemText>Date Created</ListItemText>
          <Box sx={styles.sortItemIcon}>
            {isPathwayDate && <img src={isAscending ? sortCalAscSVG : sortCalDescSVG} alt="sortDateIcon" />}
          </Box>
        </MenuItem>
        <MenuItem sx={styles.sortListItem(isPathwayName)} onClick={() => handleFieldTypeClick(ISortFieldTypes.PathwayName)}>
          <ListItemIcon >
            {isPathwayName && <img src={checkCircleSVG} alt="sortSelectedCheckIcon" width={16} height={16} />}
          </ListItemIcon>
          <ListItemText>Pathway Name</ListItemText>
          <Box sx={styles.sortItemIcon}>
            {isPathwayName && <img src={isAscending ? sortAlphAscSVG : sortAlphDescSVG} alt="sortNameIcon" />}
          </Box>
        </MenuItem>
      </MenuList>
    </Box>
  )
}
