import PathwayLayout from "pages/pathway/PathwayLayout/PathwayLayout";
import { useAppContext } from "components/app-context/appContext";
import { CircularProgress, Grid, InputAdornment, TextField, Tooltip, Typography, Chip, Popper, Box, Select, MenuItem, ClickAwayListener } from "@mui/material";
import { Button } from "core/components";
import { godzilla as theme } from "util/themes/godzilla";
import CreatedFor_Icon from "assets/images/PathwayCreator/Settings_createdFor_icon.svg";
import HelpIcon from "@mui/icons-material/Help";
import SearchIcon from "assets/images/PathwayCreator/searchicon.svg";
import SmallErrorIcon from "assets/images/smallerroricon.svg";
import Autocomplete from "@mui/material/Autocomplete";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from "@mui/icons-material/Cancel";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TextField as MultilineTextField } from "core/components/controls/textField/TextField";
import { ChangeEvent, Children, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useAuth } from "core/contexts";
import { MilestoneDataType, IKnowledgeMilestoneData } from "../PathwayOutline/outline.helper";
import { useQuery } from "react-query";
import { getPathwayDetail } from "util/getPathwayDetail.api";
import { useNavigate, useParams } from "react-router-dom";
import Error_Icon from "assets/images/erroricon.svg";
import { containsOnlyWhitespaceOrSpecialChars } from "../PathwayBuild/builder.helper";

const styles = {
  publishButtonTextStyle: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "25px",
  },
  saveAndExitButtonTextStyle: {
    fontWeight: 700,
    fontSize: "19px",
    lineHeight: "26px",
  },
  autocompleteField: (isError: boolean) => ({
    width: "347.65px",
    background: '#FFFFFF',
    borderRadius: "100px",
    "& .MuiInputBase-root": {
      p: "7px 12px 7px 16px",
      "& .MuiOutlinedInput-notchedOutline": {
        border: isError ? `2px solid ${theme?.palette?.error?.light}` : `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "100px",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: isError ? `2px solid ${theme?.palette?.error?.light}` : `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "100px",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: isError ? `2px solid ${theme?.palette?.error?.light}` : `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "100px",
      },
      "& .MuiInputBase-input": {
        ...theme?.typography?.body?.default,
        ...theme?.typography?.body?.body1,
        ...theme?.typography?.body?.light,
        color: theme?.palette?.grayScale?.[600],
        padding: "0px",
        "&::placeholder": {
          opacity: '1'
        },
      },
    },
    "& .MuiInputAdornment-positionStart": {
      mr: "10px"
    },
    ".MuiInputAdornment-positionEnd": {
      ml: '10px'
    }
  }),
  searchErrorTextStyle: {
    ...theme?.typography?.captions?.default,
    ...theme?.typography?.captions?.regular,
    color: theme?.palette?.error?.dark,
    mt: '4px'
  },
  clearBtnStyle: {
    ...theme?.typography?.captions?.default,
    ...theme?.typography?.captions?.bold,
    textTransform: 'none',
    color: theme?.button?.contained?.error?.color,
    background: theme?.palette?.error?.main,
    padding: '0 8px',
    ml: '5px',
    '&:hover': {
      background: theme?.palette?.error?.dark
    }
  },
  suggestedChipStyle: {
    border: "1px solid #4DD8E5",
    background: "rgba(255, 255, 255, 0.6)",
    borderRadius: "16px",
    height: "24px",
    p: "3px 4px 3px 10px",
    "& .MuiChip-label": {
      pl: 0,
      pr: '4px',
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "13px",
      lineHeight: "18px",
      color: theme?.palette?.primary[500],
    },
    "& .MuiChip-deleteIcon": {
      m: 0,
      color: theme?.palette?.secondary[500],
      borderRadius: "50%",
      height: "14px",
      width: "14px",
      "&:hover": {
        color: theme?.palette?.secondary[500],
      },
    },
  },
  optionsComponentBox: (isNoData: boolean) => ({
    "& .MuiPaper-root ": {
      boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
      borderRadius: "8px",
      mt: isNoData ? 0 : '8px'
    }
  }),
  NoTagFoundComponentBox: {
    background: "#FFFFFF",
    padding: "26px 36px 24px 30px"
  },
  tagNotFoundText: {
    ...theme?.typography?.h6,
    color: theme?.palette?.grayScale[700],
    ml: '11.5px'
  },
  tagNotFoundOopsText: {
    ...theme?.typography?.body.default,
    ...theme?.typography?.body.body1,
    ...theme?.typography?.body.regular,
    color: theme?.palette?.grayScale[700],
  },
  suggestedTagContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  list_expand_icon: {
    open: {
      transform: "rotate(-180deg)",
    },
  },
  expandIconStyle: (open: boolean) => ({
    display: "flex",
    cursor: "pointer",
    color: theme.palette.primary[500],
    transition: "ease 0.2s",
    ...(!open && styles.list_expand_icon.open),
  }),
  viewMoreBtnStyle: (showViewMore: boolean) => ({
    ...theme?.typography?.Components?.button?.default,
    ...theme?.typography?.Components?.button?.large,
    color: theme?.palette?.primary[500],
    padding: "0 5px",
    textTransform: "none",
    visibility: showViewMore ? "visible" : "hidden",
  }),
  selectedChipStyle: {
    background: theme?.palette?.primary?.[400],
    borderRadius: "16px",
    height: "32px",
    padding: "6px 6px 6px 10px",
    "& .MuiChip-label": {
      pl: 0,
      pr: 0,
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "13px",
      lineHeight: "18px",
      color: theme?.palette?.primary[10],
    },
    "& .MuiChip-deleteIcon": {
      margin: '0 0 0 8px',
      color: theme?.palette?.primary[10],
      opacity: '0.7',
      borderRadius: "50%",
      "&:hover": {
        color: theme?.palette?.primary[10],
      },
    },
  },
  tagSelectStyle: (isError: boolean) => ({
    "&.MuiInputBase-root": {
      width: "214px",
      borderRadius: "4px",
      background: "#ffffff",
      "& .MuiInputBase-input": {
        ...theme?.typography?.Components?.inputText,
        color: theme?.palette?.grayScale?.[700],
        fontFamily: 'Nunito',
        p: '8px 10px'
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: isError ? `2px solid ${theme?.palette?.error?.light}` : `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "4px",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: isError ? `2px solid ${theme?.palette?.error?.light}` : `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "4px",
      },
      "& .MuiSelect-icon": {
        color: "#2C39DA",
        width: "32px",
        height: "24px",
      },
    },
  }),
  tagSelectItemStyle: {
    ...theme?.typography?.body?.default,
    ...theme?.typography?.body?.body1,
    ...theme?.typography?.body?.regular,
    color: theme?.palette?.grayScale?.[800],
    py: '8px',
    pl: '27px',
    '&:hover': {
      backgroundColor: 'rgba(233, 234, 255, 0.5)'
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(233, 234, 255, 0.5)',
      '&:hover': {
        backgroundColor: 'rgba(233, 234, 255, 0.5)'
      },
    },
  },
  maxLengthStyle: (isError: boolean) => ({
    color: isError ? theme?.palette?.error?.dark : theme?.palette?.grayScale?.[700]
  })
}

const SUGGESTED_TAGS_CONTAINER_HEIGHT = 58;
const SKILL_LEVEL_OPTIONS = ['Beginner', 'Intermediate', 'Advanced', 'Master', 'Trainer'];
const SKILL_OUTCOME_OPTIONS = ['Reinforce Skill Level', 'Level Up Skill'];
const ERR_MSG_MAX_TAG = 'You can only have three tags. Remove a tag to add a new one.';
const ERR_MSG_REQUIRED_TAG = 'Select at least one tag.';

const NoTagFoundComponent = () => {
  return (
    <Box
      sx={styles.NoTagFoundComponentBox}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
        <img src={SearchIcon} alt="SEARCHICON" />
        <Typography sx={styles.tagNotFoundText}>Tag Not Found</Typography>
      </Box>
      <Box mt={"8px"}>
        <Typography sx={styles.tagNotFoundOopsText}>
          Oops! No tag with that title was found. Try searching another Tag Name.
        </Typography>
      </Box>
    </Box>
  );
};

const Settings = () => {
  const { pathwayData, setPathwayData } = useAppContext();
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());
  const navigate = useNavigate();
  const params = useParams();

  const suggestedTagContainerRef = useRef<HTMLDivElement>(null);

  const [selectedTags, setSelectedTags] = useState<Array<any>>(pathwayData?.pathwaySkills || []);
  const [tags, setTags] = useState<Array<any>>([]);
  const [searchVal, setSearchVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [noDataFlag, setNoDataFlag] = useState(false);
  const [isViewMore, setIsViewMore] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [showTagTooltip, setShowTagTooltip] = useState(false);
  const [showSelTagTooltip, setShowSelTagTooltip] = useState(false);
  const [errorSearchTagFlags, setErrorSearchTagFlag] = useState({
    maxTag: false,
    required: false
  })
  const [errorSuggestedTagFlags, setErrorSuggestedTagFlag] = useState({
    maxTag: false
  })
  const [showGoalTooltip, setShowGoalTooltip] = useState(false);
  const [showDescTooltip, setShowDescTooltip] = useState(false);
  const [goalMessage, setGoalMessage] = useState(pathwayData?.pathway?.data?.goal || '');
  const [descriptionMessage, setDescriptionMessage] = useState(pathwayData?.pathway?.data?.description || '');
  const [errorMessage, setErrorMessage] = useState({
    goalMessage: "",
    descriptionMessage: "",
  })

  const goalErrorMessage = {
    empty: 'Please enter a valid Goal for this Pathway.',
    min: 'Cannot be less than 50 characters.',
    max: 'Cannot exceed 350 characters.',
    onlySpacesAndSpecialCharacters: 'Cannot be only spaces or special characters',
  }
  const descriptionErrorMessage = {
    empty: 'Please enter a valid Description for this Pathway.',
    min: 'Cannot be less than 50 characters.',
    max: 'Cannot exceed 350 characters.',
    onlySpacesAndSpecialCharacters: 'Cannot be only spaces or special characters',
  }

  const { isLoading } = useQuery('pathways', async () => 
    getPathwayDetail(params?.id ?? '', headers ,getToken())
  , {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      event.returnValue = '';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if(selectedTags.length){
      const updatedSelectedTags = selectedTags.map((item: any) => {
        if(item.action !== "DELETE"){
            item.data.levelError = false;
            item.data.outcomeError = false;
        }
        return item;
      });
      setSelectedTags(updatedSelectedTags)
    }
  }, [])

  useEffect(() => {
    if (!pathwayData.pathway?.data?.title) {
        navigate(`/pathwayCreator/outline/${params.id}`)
        // Commenting this line of code for now after confirmation from client will decide
        // what to do
        // setGoalMessage(data?.pathway?.data?.goal || '');
        // setDescriptionMessage(data?.pathway?.data?.description || '');
        // setSelectedTags(data?.pathwaySkills?.map((skill: any) => {
        //   return {
        //     action: "NONE",
        //     header: {
        //       id: skill.header.id
        //     },
        //     data: {
        //       ...skill.data,
        //       levelError: false,
        //       outcomeError: false
        //     }
        //   }
        // }))
        // setPathwayData({...pathwayData, ...getPathwayCreatorObject(data)})
    }
  }, [isLoading]);
  
  useEffect(() => {
    setPathwayData({...pathwayData,
        pathway: {
          header: pathwayData.pathway.header,
          data: {
            ...pathwayData.pathway.data,
            goal: goalMessage,
            description: descriptionMessage
          }
        }
    })
  }, [goalMessage, descriptionMessage]);

  useEffect(() => {
    setSelectedTags(pathwayData?.pathwaySkills);
  }, [pathwayData.pathwaySkills])
  
  const filteredSelectedTags = useMemo(() => selectedTags?.filter((tag: any) => tag.action !== "DELETE"), [selectedTags]);

  const filteredSearchTags = useMemo(() => tags.filter(x => !selectedTags.some(y => y.data.skillId === x.id)), [tags, selectedTags])

  const onValidateSettings = () => {
    let errorFlag = false;
    if (goalMessage?.toString().trim().length < 50 || goalMessage?.toString().trim().length > 350) {
      const goalLength = goalMessage?.toString().trim().length;
      setErrorMessage((prv) => ({ ...prv, goalMessage: !goalLength ? goalErrorMessage.empty : goalLength < 50 ? goalErrorMessage.min : goalErrorMessage.max }));
      errorFlag = true;
    }
    if (descriptionMessage?.toString().trim().length < 50 || descriptionMessage?.toString().trim().length > 350) {
      const descLength = descriptionMessage?.toString().trim().length;
      setErrorMessage((prv) => ({ ...prv, descriptionMessage: !descLength ? descriptionErrorMessage.empty : descLength < 50 ? descriptionErrorMessage.min : descriptionErrorMessage.max }));
      errorFlag = true;
    }
    if(containsOnlyWhitespaceOrSpecialChars(goalMessage)){
      setErrorMessage((prv) => ({ ...prv, goalMessage: goalErrorMessage.onlySpacesAndSpecialCharacters }));
      errorFlag = true;
    }
    if(containsOnlyWhitespaceOrSpecialChars(descriptionMessage)){
      setErrorMessage((prv) => ({ ...prv, descriptionMessage: descriptionErrorMessage.onlySpacesAndSpecialCharacters }));
      errorFlag = true;
    }
    if (!selectedTags?.length) {
      setErrorSearchTagFlag(prv => ({
        ...prv,
        required: true,
        maxTag: false
      }));
      errorFlag = true;
    }else {
      if(!filteredSelectedTags?.length){
        setErrorSearchTagFlag(prv => ({
          ...prv,
          required: true,
          maxTag: false
        }));
        errorFlag = true;
      }
    }
    return errorFlag
  }

  const suggestedTags = useMemo(() => {
    const suggestedTagData = new Map();
    pathwayData.milestones.filter((item: MilestoneDataType) => item.type === "KNOWLEDGE").forEach((item: IKnowledgeMilestoneData) => {
      item?.modules?.forEach(x => {
        if(x?.skills){
          x?.skills?.forEach(y => {
            suggestedTagData.set(y.skillId, ({ id: y.skillId, name: y.skillName }))
          })  
        }})
    });
    // setSuggestedTags(Array.from(suggestedTagData.values()));
    return Array.from(suggestedTagData.values());
  }, [pathwayData])
  
  const filteredSuggestedTags = useMemo(() => suggestedTags.filter(x => !selectedTags.some(y => x.id === y.data.skillId && y.action !== 'DELETE' )), [suggestedTags, selectedTags]);

  useLayoutEffect(() => {
    if (filteredSuggestedTags.length) {
      const containerHeight = suggestedTagContainerRef.current?.scrollHeight ?? 0;
      if (containerHeight > SUGGESTED_TAGS_CONTAINER_HEIGHT) {
        setShowViewMore(true);
      } else {
        setShowViewMore(false);
        setIsViewMore(false);
      }
    }
  }, [suggestedTagContainerRef, filteredSuggestedTags]);

  const publisherNames = () => {
    return pathwayData?.publishedTo?.map(({ data }: any) => {
      return data.name
    })?.join(" | ") || ""
  }

  const OptionsPopoverComponent = useCallback((props: any) => {
    return (
      <Popper {...props} sx={styles.optionsComponentBox(noDataFlag)} placement="bottom" />
    );
  }, [noDataFlag]);

  const handleSearch = async (inputValue: string) => {
    await axiosInstance()
      .get(
        `/contentcreator/pathway/skills?searchSkill=${inputValue}&searchType=ST1&limit=20`,
        {
          headers: {
            Authorization: headers.Authorization,
            "Content-Type": "application/json",
          },
          data: {},
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.length) {
          setTags(response.data);
          setNoDataFlag(false);
        } else {
          setNoDataFlag(true);
        }
      })
      .catch((error) => {
        setTags([]);
        setLoading(false);
      });
  };

  const handleSearchTagInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    setSearchVal(inputValue);
    
    if (filteredSelectedTags.length <= 2) {
      setErrorSearchTagFlag(prv => ({ ...prv, maxTag: false }));
      if (inputValue.length > 2) {
        setLoading(true);
        handleSearch(inputValue);
      } else {
        setTags([]);
        setNoDataFlag(false);
      }
    } else {
      setErrorSearchTagFlag(prv => ({ ...prv, maxTag: true }));
    }
  };

  const handleSuggestedTagSelect = useCallback((item: any) => {
    if (filteredSelectedTags.length <= 2) {
      setErrorSearchTagFlag(prv => ({
        ...prv,
        required: false
      }))
      
      const pathwaySkills = pathwayData?.pathwaySkills || [];
      setPathwayData({...pathwayData, pathwaySkills: [...pathwaySkills, {
        action: "NEW",
        data: {
          skillId: item.id,
          name: item.name,
          pathwaySdoId: params?.id,
          level: "",
          outcome: "",
          levelError: false,
          outcomeError: false
        }
      }]});
    } else {
      setErrorSuggestedTagFlag({
        maxTag: true
      })
    }
  }, [filteredSelectedTags])

  const onClickViewMore = useCallback(() => {
    setIsViewMore((prv) => !prv);
  }, []);

  const onSelectSkillLevelOrOutcome = (value: string, index: number, type: string) => {
    let cloneData = [...pathwayData.pathwaySkills];
    cloneData = cloneData.map((item: any, itemIndex: number) => {
      if(itemIndex === index){
        if(item?.header){
          item.action = "UPDATE";
        }
        if(type === "level"){
          item.data.level = value;
          item.data.levelError = false;
        }else {
          item.data.outcome = value;
          item.data.outcomeError = false;
        }
      }
      return item;
    })
    setPathwayData({...pathwayData, pathwaySkills: cloneData})
  }

  const onDeleteTags = (index: number) => {
    const cloneData = JSON.parse(JSON.stringify(pathwayData?.pathwaySkills));

    const findIndex = cloneData.findIndex((item: any, itemIndex: number) => itemIndex === index)
    if(findIndex > -1){
      if(cloneData[findIndex].action === "NEW"){
        cloneData.splice(findIndex,1);
      }else {
        cloneData[findIndex].action = "DELETE";
      }
      setPathwayData({...pathwayData, pathwaySkills: cloneData})
    }
  }

  const handleOnSave = (): boolean => {
    if(selectedTags.length){
      let cloneData = [...selectedTags];
      cloneData.map((item: any) => {
        if(item.action !== "DELETE"){
            item.data.levelError = false;
            item.data.outcomeError = false;
        }
        return item;
      })
      setSelectedTags(cloneData)
    }
    let isNotValidated = onValidateSettings();
    if(!isNotValidated){
      setPathwayData({...pathwayData, 
        pathwaySkills: pathwayData.pathwaySkills.map((item: any) => {
          delete item.data.levelError
          delete item.data.outcomeError
          return item
        })
      });
    }
    return isNotValidated;
  }

  const handleOnPublish = (): boolean => {
    let isGoalAndDescNotValidated = onValidateSettings();
    let updatedTags = selectedTags.map((item: any) => {
      if(item.action !== "DELETE"){
        if(!item.data.level){
          item.data.levelError = true;
        }else {
          item.data.levelError = false;
        }
        if(!item.data.outcome){
          item.data.outcomeError = true;
        }else {
          item.data.outcomeError = false;
        }
      }
      return item;
    });

    setSelectedTags(updatedTags)
    let isCloneDataValidated = updatedTags.filter((tag: any) => tag.action !== "DELETE").every((item: any) => item.data.levelError === false && item.data.outcomeError === false)
    
    if(!isGoalAndDescNotValidated && isCloneDataValidated){
      setPathwayData({...pathwayData, 
        pathwaySkills: pathwayData.pathwaySkills.map((item: any) => {
          delete item.data.levelError
          delete item.data.outcomeError
          return item
        })
      });
    }
    return isGoalAndDescNotValidated || !isCloneDataValidated
  }

  return (
    <PathwayLayout activeIndex={2} pathwayTitle={pathwayData.pathway?.data?.title} onClickSaveValidate={handleOnPublish} onClickPublish={handleOnPublish} exitBtnText={"No, continue creating my pathway"} exitNavigation={'/pathway'}>
      <Grid
        display={"flex"}
        flexDirection={"row"}
        height="100%"
        maxHeight={"calc(100% - 27px)"}
        margin={"0px 40px 27px 30px"}
      >
        <Grid
          sx={{
            width: "100%",
            height: "auto",
            background: "#F9FAFC",
            borderRadius: '20px',
            overflow: "auto"
          }}
          className="customScroll"
        >
          <Grid display={'flex'} flexDirection={'column'} ml={'60px'} mt={'40px'}>
            <Grid>
              <Typography sx={{
                ...theme?.typography?.h2,
                color: theme?.palette?.grayScale[700]
              }}>Pathway Settings</Typography>
            </Grid>
            <Grid display={'flex'} flexDirection={'row'} alignItems={'center'} mt={2}>
              <Grid>
                <img src={CreatedFor_Icon} alt="ICON" style={{ height: "16px", width: "12px" }} />
              </Grid>
              <Grid ml={"6px"}>
                <Typography sx={{
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.bold,
                  color: theme?.palette?.grayScale[700]
                }}>Created for:</Typography>
              </Grid>
              <Grid ml={"7px"}>
                <Typography sx={{
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  color: theme?.palette?.grayScale[700]
                }}>{publisherNames()}</Typography>
              </Grid>
            </Grid>
            <Grid display={'flex'} flexDirection={'column'} mt={'30px'}>
              <Grid display={"flex"} flexDirection={"row"} position={'relative'}>
                <Typography sx={{ ...theme?.typography?.h3, color: theme?.palette?.grayScale[700] }}>Tagging</Typography>
                <Typography color={theme?.palette?.error?.light}>*</Typography>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => setShowTagTooltip(false)}
                >
                  <Grid ml={"4px"} mt={"6px"}>
                    <Tooltip
                      title="‘Tagging’ allows you to add keywords related to the content in the Pathway. Adding tags will aid in finding a Pathway that contains similar information."
                      placement="top"
                      arrow
                      open={showTagTooltip}
                      componentsProps={{
                        popper: {
                          disablePortal: true
                        },
                        tooltip: {
                          sx: {
                            bgcolor: theme?.palette?.grayScale[800],
                            borderRadius: "4px",
                            boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2)",
                            padding: "4px 8px",
                            height: "114px",
                            width: "262px",
                            ...theme?.typography?.body?.default,
                            ...theme?.typography?.body?.body2,
                            ...theme?.typography?.body?.regular,
                            color: "#FFFFFF",
                          },
                        },
                        arrow: {
                          sx: {
                            color: theme?.palette?.grayScale[800]
                          }
                        }
                      }}
                    >
                      <HelpIcon sx={{ color: "#2F7BFF", height: "16px", width: "16px", cursor: "pointer" }} onClick={() => setShowTagTooltip(true)} />
                    </Tooltip>
                  </Grid>
                </ClickAwayListener>
              </Grid>
              <Grid mt={'20px'} mb={errorSearchTagFlags.maxTag ? '5px' : '13px'}>
                <Autocomplete
                  disableClearable
                  options={filteredSearchTags}
                  getOptionLabel={(option: any) => option.name}
                  PopperComponent={OptionsPopoverComponent}
                  multiple
                  forcePopupIcon={false}
                  sx={styles.autocompleteField(errorSearchTagFlags.maxTag || errorSearchTagFlags.required)}
                  renderTags={() => null}
                  ListboxProps={{ className: "customScroll" }}
                  noOptionsText={noDataFlag ? <NoTagFoundComponent /> : ""}
                  inputValue={searchVal}
                  onChange={(e: any, newValue: any) => {
                    if (e?.target.value !== "") {
                      if (newValue.length) {
                        setTags([]);
                        const pathwaySkills = pathwayData?.pathwaySkills || [];
                        setPathwayData({...pathwayData, pathwaySkills: [...pathwaySkills, {
                          action: "NEW",
                          data: {
                            skillId: newValue[newValue.length - 1].id,
                            name: newValue[newValue.length - 1].name,
                            pathwaySdoId: params?.id,
                            level: "",
                            outcome: "",
                            levelError: false,
                            outcomeError: false
                        }
                        }]});
                        setErrorSearchTagFlag(prv => ({
                          ...prv,
                          required: false,
                          maxTag: false
                        }));
                      }
                    }
                  }}
                  onFocus={() => {
                    setNoDataFlag(false);
                  }}
                  onBlur={() => {
                    setTags([]);
                    setSearchVal("");
                    setErrorSearchTagFlag(prv => ({
                      ...prv,
                      required: false,
                      maxTag: false
                    }));
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Search Tags"
                      onChange={handleSearchTagInputChange}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <>
                            <InputAdornment position="start">
                              <img src={SearchIcon} alt="ICON" />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                          </>
                        ),
                        endAdornment: (
                          <>
                            <InputAdornment position="end" onClick={() => {
                              !loading && searchVal && setSearchVal("");
                            }}>
                              {loading ? <CircularProgress color="inherit" size={20} /> : searchVal ? <CancelIcon sx={{ width: '16px', height: '16px', cursor: 'pointer' }} /> : null}
                            </InputAdornment>
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
                {!!errorSearchTagFlags.maxTag && (
                  <Typography sx={{ ...styles.searchErrorTextStyle }} display={"flex"}>
                    <img
                      src={SmallErrorIcon}
                      style={{ marginRight: "2px" }}
                      alt=""
                    />
                    {ERR_MSG_MAX_TAG}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {!!filteredSuggestedTags.length && <Grid sx={styles.suggestedTagContainer}>
              <Grid>
                <Typography sx={{
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.semibold,
                  color: theme?.palette?.grayScale[700]
                }}>Suggested:</Typography>
              </Grid>
              <Grid display="flex" alignItems={'flex-end'}>
                <Grid container ml={'4px'} rowGap={"10px"} sx={{
                  "& > :not(:last-child)": { mx: 1 },
                  "& > :last-child": { ml: 1 },
                  maxHeight: isViewMore ? 'auto' : SUGGESTED_TAGS_CONTAINER_HEIGHT,
                  overflow: 'hidden',
                  flex: 1,
                }} ref={suggestedTagContainerRef}>
                  {Children.toArray(filteredSuggestedTags.map((item: any) => (<Chip
                    sx={styles.suggestedChipStyle}
                    deleteIcon={<AddCircleIcon />}
                    label={item.name}
                    onDelete={() => handleSuggestedTagSelect(item)}
                    onClick={() => handleSuggestedTagSelect(item)}
                  />)))}
                </Grid>
                <Button
                  variant="text"
                  sx={styles.viewMoreBtnStyle(showViewMore)}
                  onClick={onClickViewMore}
                >
                  {isViewMore ? "View Less" : "View More"}
                  <ExpandMoreIcon
                    sx={{ ...styles.expandIconStyle(isViewMore) }}
                  />
                </Button>
              </Grid>
            </Grid>}
            {(errorSuggestedTagFlags.maxTag || errorSearchTagFlags.required) && (
              <Grid display={"flex"} my={errorSuggestedTagFlags.maxTag ? '4px' : '2px'}>
                <img
                  src={SmallErrorIcon}
                  style={{ marginRight: "2px" }}
                  alt=""
                />
                <Typography sx={{ ...styles.searchErrorTextStyle, mt: 0 }} display={"flex"} alignItems={"center"}>
                  {errorSearchTagFlags.required ? ERR_MSG_REQUIRED_TAG : ERR_MSG_MAX_TAG}
                </Typography>
                {errorSuggestedTagFlags.maxTag && <Button
                  variant="text"
                  sx={styles.clearBtnStyle}
                  onClick={() => {
                    setErrorSuggestedTagFlag(prv => ({ ...prv, maxTag: false }))
                  }}
                >
                  Clear Error
                </Button>}
              </Grid>
            )}
            <Grid display={'flex'} flexDirection={'column'} mt={(errorSuggestedTagFlags.maxTag || errorSearchTagFlags.required) ? 0 : '20px'}>
              <Grid display={"flex"} flexDirection={"row"} alignItems={"center"} position={'relative'}>
                <Typography sx={{
                  ...theme?.typography?.h4,
                  color: theme?.palette?.grayScale[700]
                }}>Selected Tags</Typography>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => setShowSelTagTooltip(false)}
                >
                  <Grid ml={"4px"} mt={"6px"}>
                    <Tooltip
                      title="Skill outcomes determine the skill level that the learner will earn at the end of the pathway. For example, a pathway with a Beginner  ‘Reinforced Skill Level’ will issue a beginner skill to the learner at the completion of the pathway. A Beginner ‘Level Up Skill’ will take a learner from a beginner to an in an intermediate skill level by the end of the pathway."
                      placement="top"
                      arrow
                      open={showSelTagTooltip}
                      componentsProps={{
                        popper: { disablePortal: true },
                        tooltip: {
                          sx: {
                            bgcolor: theme?.palette?.grayScale[800],
                            borderRadius: "4px",
                            boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2)",
                            padding: "4px 8px",
                            height: "148px",
                            maxWidth: "395px",
                            ...theme?.typography?.body?.default,
                            ...theme?.typography?.body?.body2,
                            ...theme?.typography?.body?.regular,
                            color: "#FFFFFF",
                          },
                        },
                        arrow: {
                          sx: {
                            color: theme?.palette?.grayScale[800]
                          }
                        }
                      }}
                    >
                      <HelpIcon sx={{ color: "#2F7BFF", height: "16px", width: "16px", cursor: "pointer" }} onClick={() => setShowSelTagTooltip(true)} />
                    </Tooltip>
                  </Grid>
                </ClickAwayListener>
              </Grid>
              {!filteredSelectedTags?.length ?
                <Grid display={"flex"} flexDirection={"row"} alignItems={'center'} mt={2}>
                  <LocalOfferIcon sx={{ height: "17px", width: "17px", color: theme?.palette?.primary[300] }} />
                  <Grid ml={'5px'}>
                    <Typography sx={{
                      ...theme?.typography?.body?.default,
                      ...theme?.typography?.body?.body1,
                      ...theme?.typography?.body?.medium,
                      color: theme?.palette?.grayScale[600]
                    }}>Selected tags will show here. Add them by clicking the suggested tags or using the search bar above.</Typography>
                  </Grid>
                </Grid> :
                <Grid>
                  {Children.toArray(selectedTags.map((item, index) => {
                    if(item.action !== "DELETE"){
                      return (
                        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} mt={!index ? "12px" : "24px"}>
                      <Chip
                        sx={styles.selectedChipStyle}
                        deleteIcon={<CancelIcon />}
                        label={item.data.name}
                        onDelete={()=>onDeleteTags(index)}
                      />
                      <Box display={"flex"} flexDirection={"column"} sx={{...(item.data.levelError && {
                        position: "relative",
                        top: '10px',
                      })}}>
                        <Box>
                          <Select
                            displayEmpty
                            value={item.data.level || ''}
                            sx={{ ...styles.tagSelectStyle(item.data.levelError), ml: '19px' }}
                            onChange={(e) => onSelectSkillLevelOrOutcome(e.target.value, index, "level")}
                            renderValue={(value) => <>{value || 'Skill Level'}</>}
                          >
                            {Children.toArray(
                              SKILL_LEVEL_OPTIONS.map((x) => (
                                <MenuItem sx={styles.tagSelectItemStyle} value={x}>
                                  {x}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </Box>
                        {item.data.levelError && <Box display={"flex"} flexDirection={"row"} pl={'20px'}>
                          <Box mt={'-3px'}>
                            <img
                              src={Error_Icon}
                              alt="ICON"
                              style={{ height: "10px", width: "10px" }}
                            />
                          </Box>
                          <Box ml={'6px'}>
                            <Typography
                              sx={{
                                ...theme?.typography?.captions?.default,
                                ...theme?.typography?.captions?.regular,
                                color: theme?.palette?.error?.dark,
                              }}
                            >
                              Select a skill level
                            </Typography>
                          </Box>
                        </Box>}
                      </Box>
                      
                      <Box display={"flex"} flexDirection={"column"} sx={{...(item.data.outcomeError && {
                        position: "relative",
                        top: '10px',
                      })}}>
                        <Box>
                          <Select
                            value={item.data.outcome || ''}
                            displayEmpty
                            sx={{ ...styles.tagSelectStyle(item.data.outcomeError), ml: '16px' }}
                            onChange={(e) => onSelectSkillLevelOrOutcome(e.target.value, index, "outcome")}
                            renderValue={(value) => <>{value || 'Skill Outcome'}</>}
                          >
                            {Children.toArray(
                              SKILL_OUTCOME_OPTIONS.map((x) => (
                                <MenuItem sx={styles.tagSelectItemStyle} value={x}>
                                  {x}
                                </MenuItem>
                              ))
                            )}
                          </Select>
                        </Box>
                        {item.data.outcomeError && <Box display={"flex"} flexDirection={"row"} pl={'20px'}>
                          <Box mt={'-3px'}>
                            <img
                              src={Error_Icon}
                              alt="ICON"
                              style={{ height: "10px", width: "10px" }}
                            />
                          </Box>
                          <Box ml={'6px'}>
                            <Typography
                              sx={{
                                ...theme?.typography?.captions?.default,
                                ...theme?.typography?.captions?.regular,
                                color: theme?.palette?.error?.dark,
                              }}
                            >
                              Select a skill outcome
                            </Typography>
                          </Box>
                        </Box>}
                      </Box>
                    </Box>
                      )
                    }
                  }))}
                </Grid>
              }
            </Grid>
            <Grid display={'flex'} flexDirection={'column'} mt={'24px'}>
              <Grid display={"flex"} flexDirection={"row"} position={'relative'}>
                <Typography sx={{ ...theme?.typography?.h3, color: theme?.palette?.grayScale[700] }}>Goal</Typography>
                <Typography color={theme?.palette?.error?.light}>*</Typography>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => setShowGoalTooltip(false)}
                >
                  <Grid ml={"4px"} mt={"6px"}>
                    <Tooltip
                      open={showGoalTooltip}
                      title="The ‘Goal’ details the desired outcome from this Pathway."
                      placement="top"
                      arrow
                      componentsProps={{
                        popper: {
                          disablePortal: true
                        },
                        tooltip: {
                          sx: {
                            bgcolor: theme?.palette?.grayScale[800],
                            borderRadius: "4px",
                            boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2)",
                            padding: "4px 8px",
                            height: "68px",
                            width: "193px",
                            ...theme?.typography?.body?.default,
                            ...theme?.typography?.body?.body2,
                            ...theme?.typography?.body?.light,
                            color: "#FFFFFF",
                          },
                        },
                        arrow: {
                          sx: {
                            color: theme?.palette?.grayScale[800]
                          }
                        }
                      }}
                    >
                      <HelpIcon
                        onClick={() => setShowGoalTooltip(true)}
                        sx={{ color: "#2F7BFF", height: "16px", width: "16px", cursor: "pointer" }} />
                    </Tooltip>
                  </Grid>
                </ClickAwayListener>
              </Grid>
              <Grid mt={"6px"}>
                <MultilineTextField
                  maxLength={350}
                  characterLengthStyle={styles.maxLengthStyle(!!errorMessage.goalMessage)}
                  inputProps={{
                    multiline: true,
                    rows: 3,
                  }}
                  style={{
                    marginRight: "345px",
                    minWidth: "610px",
                    '& .MuiFormControl-root': {
                      bgcolor: '#fff'
                    }
                  }}
                  errorText={errorMessage.goalMessage}
                  value={goalMessage}
                  onChange={(e) => {
                    setErrorMessage((prv) => ({ ...prv, goalMessage: "" }));
                    setGoalMessage(e.target.value);
                  }}
                  required
                />
              </Grid>
            </Grid>
            <Grid display={'flex'} flexDirection={'column'} mt={'24px'}>
              <Grid display={"flex"} flexDirection={"row"} position={'relative'}>
                <Typography sx={{ ...theme?.typography?.h3, color: theme?.palette?.grayScale[700] }}>Description</Typography>
                <Typography color={theme?.palette?.error?.light}>*</Typography>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => setShowDescTooltip(false)}
                >
                  <Grid ml={"4px"} mt={"6px"}>
                    <Tooltip
                      open={showDescTooltip}
                      title="The ‘Description’ provides a brief summary of what you can expect to learn from this Pathway."
                      placement="top"
                      arrow
                      componentsProps={{
                        popper: {
                          disablePortal: true
                        },
                        tooltip: {
                          sx: {
                            bgcolor: theme?.palette?.grayScale[800],
                            borderRadius: "4px",
                            boxShadow: "0px 3px 3px -2px rgba(0, 0, 0, 0.2)",
                            padding: "4px 8px",
                            height: "88px",
                            width: "193px",
                            ...theme?.typography?.body?.default,
                            ...theme?.typography?.body?.body2,
                            ...theme?.typography?.body?.light,
                            color: "#FFFFFF",
                          },
                        },
                        arrow: {
                          sx: {
                            color: theme?.palette?.grayScale[800]
                          }
                        }
                      }}
                    >
                      <HelpIcon
                        onClick={() => setShowDescTooltip(true)}
                        sx={{ color: "#2F7BFF", height: "16px", width: "16px", cursor: "pointer" }} />
                    </Tooltip>
                  </Grid>
                </ClickAwayListener>
              </Grid>
              <Grid mt={"6px"}>
                <MultilineTextField
                  maxLength={350}
                  characterLengthStyle={styles.maxLengthStyle(!!errorMessage.descriptionMessage)}
                  inputProps={{
                    multiline: true,
                    rows: 3,
                  }}
                  style={{
                    marginRight: "345px",
                    minWidth: "610px",
                    '& .MuiFormControl-root': {
                      bgcolor: '#fff'
                    }
                  }}
                  errorText={errorMessage.descriptionMessage}
                  value={descriptionMessage}
                  onChange={(e) => {
                    setErrorMessage((prv) => ({ ...prv, descriptionMessage: "" }));
                    setDescriptionMessage(e.target.value);
                  }}
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PathwayLayout>
  );
};

export default Settings;
