export const RequiredErrorText = "Enter valid name.";
export const MaxErrorText = "Cannot exceed 100 characters";

export const TextRgx = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*()_+,\-.\/:;<=>?@\[\]\\\{\}|\~\` ]*/;

export interface IChecklistComponentProps {
  checklistData: Array<any>;
  setChecklistData: React.Dispatch<React.SetStateAction<Array<any>>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<any>>;
}

export interface IChecklistItemProps {
  setChecklistDeleteData?:React.Dispatch<React.SetStateAction<Array<any>>>;
  action: "ADD" | "VIEW";
  checklistData: Array<any>;
  setChecklistData: React.Dispatch<React.SetStateAction<Array<any>>>;
  setOnAddNewChecklistItem?: React.Dispatch<React.SetStateAction<boolean>>;
  checklistItemName?: string;
  index?: number;
  selectedChecklistIndex: number | null;
  setSelectedChecklistIndex: React.Dispatch<React.SetStateAction<number | null>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<any>>;
  hasScroll: boolean;
}
