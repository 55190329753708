import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  Breadcrumbs,
  Link,
  TextField
} from "@mui/material";
import { Button } from "core/components";
import LifeTrekLogo from "../../../assets/images/LifeTrekLogo.svg";
import CrossIcon from "../../../assets/images/crossicon.svg";
import ErrorIcon from "../../../assets/images/erroricon.svg";
import { godzilla as theme } from "util/themes/godzilla";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useAuth } from "core/contexts";
import { useMutation } from "react-query";
import { containsOnlyWhitespaceOrSpecialChars } from "pages/pathway/PathwayCreator/PathwayBuild/builder.helper";

const inputTextStyle = (border: string) => ({
  "& .MuiInputBase-root": {
    width: "300px",
    height: '33px',
    "& .MuiOutlinedInput-notchedOutline": {
      border: border,
      borderRadius: "4px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: border,
      borderRadius: "4px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: border,
      borderRadius: "4px",
    },
    "& .MuiInputBase-input": {
      padding: "0px",
      pl: 1
    },
  },
});

const styles = {
  modalStyle: {
    '& .MuiPaper-root': {
      height: '100vh',
      width: '800px',
      maxWidth: 'inherit',
      backgroundColor: "#FFFFFF",
      boxShadow: 'none',
      borderRadius: '0px',
      margin: '0px',
      maxHeight: '100vh',
    },
  },
};

const AddNewModule = () => {
  const [moduleTitle, setModuleTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());

  const onCreateModule = useMutation(() => {
    const formData = {
      title: moduleTitle,
    };

    return axiosInstance().post(
      "/contentcreator/courses/new/modules",
      formData,
      {
        headers: {
          Authorization: headers.Authorization,
        },
      }
    );
  });

  const addModuleTitle = (e: any) => {
    setModuleTitle(e.target.value)
    setErrorMessage("")
  }

  const CreateModule = () => {
    if (moduleTitle.trim() === "") {
      setErrorMessage("Please enter module title")
    } else if (moduleTitle.length > 100) {
      setErrorMessage("Must be atmost of 100 characters")
    } else if (containsOnlyWhitespaceOrSpecialChars(moduleTitle)) {
      setErrorMessage("Module title cannot be only spaces or special characters")
    }
    else {
      onCreateModule
        .mutateAsync()
        .then((res) => {
          setErrorMessage("")
          navigate(`/moduleEditor/${res.data.modules[0].sdoId}`)
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    }
  }

  const errorFlag = useMemo(() => errorMessage !== "", [errorMessage]);

  return (
    <Dialog
      open={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={styles.modalStyle}
      BackdropProps={{
        sx: {
          backgroundColor: '#F5F5F5',
          opacity: '0',
        },
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
          <Box mt={'60px'}>
            <img
              src={LifeTrekLogo}
              alt="LOGO"
            />
          </Box>
          <Box mt={'36px'} position={'absolute'} right={'40px'}>
            <IconButton
              sx={{
                "&.MuiIconButton-root": {
                  height: "44px",
                  width: "44px",
                  backgroundColor:
                    "#F9FAFC",
                  '&:hover': {
                    backgroundColor:
                      "#F9FAFC",
                  },
                }
              }}
              onClick={() => navigate('/drafts')}
            >
              <img
                src={CrossIcon}
                alt="CROSSICON"
              />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent className="customScroll">
        <Box display={'flex'} flexDirection={'column'} mx={'220px'} mt={'40px'}>
          <Box display={'flex'} justifyContent={'center'}>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href="/drafts" sx={{
                ...theme?.typography?.body?.default,
                ...theme?.typography?.body?.body1,
                ...theme?.typography?.body?.medium,
                textDecoration: 'underline',
                textDecorationColor: '#26C5D2',
                color: '#7E8592'
              }}>
                Drafts
              </Link>
              <Link
                color="inherit"
                sx={{
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  textDecoration: 'none',
                  color: '#505866'
                }}
              >
                Create New Module
              </Link>
            </Breadcrumbs>
          </Box>
          <Box mt={'4px'}>
            <Typography sx={{ ...theme?.typography?.h1, color: '#3D4655' }}>Create New Module</Typography>
          </Box>
          <Box mt={'40px'}>
            <Box>
              <Typography sx={{ ...theme?.typography?.inputLabel, color: '#1C222D' }}>Module Title<span style={{ color: '#FF4F4F' }}>*</span></Typography>
            </Box>
            <Box mt={'6px'}>
              <TextField
                sx={{
                  ...inputTextStyle("1px solid #959CA8"),
                  ...(errorFlag && inputTextStyle("2px solid #FF4F4F")),
                }}
                value={moduleTitle}
                onChange={(e) => addModuleTitle(e)}
              />
              {errorFlag && <Box display={'flex'} flexDirection={'row'} mt={'3px'}>
                <Box mt={'-4px'}>
                  <img
                    src={ErrorIcon}
                    alt="ERRORICON"
                    style={{
                      height: '10px',
                      width: '10px'
                    }}
                  />
                </Box>
                <Box ml={'2px'}>
                  <Typography sx={{
                    ...theme?.typography?.captions?.default,
                    ...theme?.typography?.captions?.regular,
                    color: '#CF0000'
                  }}>{errorMessage}</Typography>
                </Box>
              </Box>}
            </Box>
            <Box mt={'30px'}>
              <Box display={'flex'} justifyContent={'center'}>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: '150px' }}
                  onClick={CreateModule}
                >
                  <Typography
                    sx={{
                      ...theme?.typography?.Components
                        ?.button?.default,
                      ...theme?.typography?.Components
                        ?.button?.medium,
                      textTransform: 'none',
                      color: theme?.palette?.secondary[50],
                    }}
                  >
                    Create
                  </Typography>
                </Button>
              </Box>
              <Box mt={'12px'} display={'flex'} justifyContent={'center'}>
                <Button
                  variant="text"
                  color="primary"
                  sx={{ width: '100px' }}
                  onClick={() => navigate('/drafts')}
                >
                  <Typography
                    sx={{
                      ...theme?.typography?.Components
                        ?.button?.default,
                      ...theme?.typography?.Components
                        ?.button?.large,
                      textTransform: 'none',
                      color: '#202124',
                    }}
                  >
                    Exit
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewModule;
