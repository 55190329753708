import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import { StyledEngineProvider } from '@mui/material';

import { godzilla as theme } from './util/themes/godzilla';
import { useAuth } from 'core/contexts';
import { App } from './App';
import { ThemeContext } from './util/themes/currentThemeContext';
import { keyCloakConfig } from 'constants/app.constants';
import AppInitializer from 'components/AppInitializer/AppInitializer';
import { useNavigate } from 'react-router-dom';



export const AppContainer = () => {
    const { init, isInitialized, isLoginTermAccepted, isAppInitialized } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      init(keyCloakConfig);
    }, []);

    useEffect(() => {
        if (!loading) {
            if (isAppInitialized && isLoginTermAccepted) {
                navigate('/home')
            }
        }
    }, [isAppInitialized, isLoginTermAccepted, loading])

    if (!loading && isInitialized) {
        return (
            <StyledEngineProvider injectFirst>
                <ThemeContext.Provider value={{ currentTheme: theme }}>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </ThemeContext.Provider>
            </StyledEngineProvider>
        );
    } else {
        return <AppInitializer setLoading={setLoading} />;
    }
};
