import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, ChangeEvent, Children } from "react";
import PlusIcon from "../../../assets/images/plusicon.svg";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { godzilla as theme } from "util/themes/godzilla";
import SmallErrorIcon from "../../../assets/images/smallerroricon.svg";
import CrossIcon from "../../../assets/images/crossicon.svg";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { useQuery } from "react-query";
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useAuth } from "core/contexts";
import { debounce } from "util/debounce";
import { useAppContext } from "components/app-context/appContext";
import { IContextData } from "../DraftsModule/types";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ActionsDeleteModal } from "./ActionsDeleteModal";

const autoCompleteInputStyle = (border: string, borderRadius: string) => ({
  "& .MuiInputBase-root": {
    "& .MuiOutlinedInput-notchedOutline": {
      border: border,
      borderRadius: borderRadius,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: border,
      borderRadius: borderRadius,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: border,
      borderRadius: borderRadius,
    },
    "& .MuiInputBase-input": {
      padding: "0px",
    },
  },
});

const selectInputStyle = (background: string, border: string) => ({
  "&.MuiInputBase-root": {
    height: "39px",
    width: "160px",
    borderRadius: "5px",
    background: background,
    border: border,
    "& .MuiInputBase-input": {
      color: "#FFFFFF",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "22px",
      textTransform: "none",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: border,
      },
    },
    "& .MuiSelect-icon": {
      color: "#FFFFFF",
      width: "32px",
      height: "24px",
    },
  },
});

const styles = {
  suggesteddependenciesListHeader: {
    fontFamily: "Museo Sans Rounded",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "28px",
    lineHeight: "34px",
    color: theme.palette.grayScale[500],
  },
  newDependencyStyle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "25px",
    color: theme.palette.grayScale[500],
  },
  linkButton: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "22px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "-0.01em",
    textTransform: "none",
    color: theme.palette.secondary[50],
    background: theme.palette.primary[500],
    borderRadius: "100px",
    width: "66px",
    "&:hover": {
      backgroundColor: theme.palette.primary[500],
    },
  },
  cancelButton: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "150%",
    textTransform: "none",
    letterSpacing: "-0.01em",
    borderRadius: '100px',
    mr:'5px',
    color: '#202124',
    '&:hover': { background: 'rgba(0, 0, 0, 0.04)' },
    '> .MuiTouchRipple-root span': {
      background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(0, 0, 0, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
    },
  },
  searchComponentBox: {
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
  },
  errorText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#DF2F2F",
  },
  dependencyBox: {
    maxWidth: "471px",
    height: "36px",
    background: "#FFFFFF",
    border: `1px solid ${theme?.palette?.grayScale?.[300]}`,
    borderRadius: "5px",
  },
  dependencyTextStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.grayScale[700],
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dependencySelectBox: {
    padding: '4px',
    background: theme?.palette?.grayScale?.[50],
    borderRadius: "5px",
  },
  dependencySelectBoxText: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: theme.palette.grayScale[800],
  },
  moduleNameStyle: {
    fontFamily: "Museo Sans Rounded",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "28px",
    lineHeight: "34px",
    color: "#4B4D58",
  },
  actionsMenuContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: '100px',
    px: '10px',
    cursor: 'pointer',
  },
  deleteModalContainer: {
    zIndex: 2,
    position: 'absolute',
    top: '156px',
    right: '68px'
},
};

const DependencyViewComponent = (props: any) => {
  const { relationshipOption, moduleTitle, index, onRemove, moduleName } =
    props;
  return (
    <Grid mt={"13px"} ml={"43px"}>
      <Grid
        sx={styles.dependencyBox}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Box overflow={'hidden'} display={"flex"} flexDirection={"row"} whiteSpace={'nowrap'} alignItems={'center'}>
          {/* As per Figma design we have dropped module name */}
          {/* <Typography sx={styles.dependencyTextStyle} ml={"12px"}>
            {moduleName}
          </Typography> */}
          <Box sx={styles.dependencySelectBox} ml={"5px"}>
            <Typography sx={styles.dependencySelectBoxText}>
              {relationshipOption}
            </Typography>
          </Box>
          <Typography sx={styles.dependencyTextStyle} ml={"9px"}>
            {moduleTitle}
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => onRemove(index)}>
            <img src={CrossIcon} alt="ICON" />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

interface ISuggestedModuleProps {
  moduleName?: string;
  savedRelationShips?: Array<any>;
  dependenciesList: Array<any>,
  setDependenciesList: React.Dispatch<React.SetStateAction<Array<any>>>,
  isLoading: boolean;
  selectedCourseId?: string;
  moduleSdoId?: string;
  moduleId?: string;
}

interface IRelationships {
  key: string;
  name: string;
}

const oppositeRelationshipsArray = [
  {
      key: "MODULE_IS_A_PREREQUISITE_OF",
      name: "is a prerequisite",
      value: {
        key: "MODULE_IS_REQUIRED_AFTER",
        name: "is required after"
      }
  },
  {
      key: "MODULE_IS_REQUIRED_AFTER",
      name: "is required after",
      value: {
        key: "MODULE_IS_A_PREREQUISITE_OF",
        name: "is a prerequisite"
      }
  },
  {
      key: "MODULE_IS_BEFORE",
      name: "is before",
      value: {
        key: "MODULE_IS_AFTER",
        name: "is after"
      }
  },
  {
      key: "MODULE_IS_AFTER",
      name: "is after",
      value: {
        key: "MODULE_IS_BEFORE",
        name: "is before"
      }
  },
  {
      key: "MODULE_RELATES_TO",
      name: "is related",
      value: {
        key: "MODULE_RELATES_TO",
        name: "is related"
      }
  }
]

const SuggestedDependencies = (props: ISuggestedModuleProps) => {
  const { moduleName, savedRelationShips = [], dependenciesList, setDependenciesList, isLoading: savedRelationShipsLoading, selectedCourseId, moduleSdoId, moduleId } = props;
  const [addNewDependency, setAddNewDependency] = useState<boolean>(false);
  const [relationshipName, setRelationshipName] = useState<{
    name: string;
    key: string;
  }>({
    name: "",
    key: "",
  });
  const [relationshipError, setRelationshipError] = useState<boolean>(false);
  const [moduleError, setModuleError] = useState<boolean>(false);
  const [searchedModules, setSearchedModules] = useState([]);
  const [noDataFlag, setNoDataFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());
  const [moduleData, setModuleData] = useState<{
    moduleTitle: string;
    id: string;
  }>({
    moduleTitle: "",
    id: "",
  });
  const [uniqueRelationError, setUniqueRelationError] = useState(false);
  const [uniqueModuleRelationError, setUniqueModuleRelationError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openConfirmationDeleteModal, setOpenConfirmationDeleteModal] = useState(false);

  const open = Boolean(anchorEl);
  const popperId = "header_avatar_dropwdown";

  const { setReviewModuleData, reviewModuleData }: IContextData = useAppContext();

  const { data } = useQuery(
    "moduleRelationships",
    async () => {
      const response = await axiosInstance().get(
        `/contentcreator/relationships`,
        {
          headers: {
            Authorization: headers.Authorization,
            "Content-Type": "application/json",
          },
          data: {},
        }
      );
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleInputChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (inputValue.length > 3) {
      setLoading(true);
      handleSearch(inputValue);
    } else {
      setSearchedModules([]);
    }
  }, 500);

  const handleSearch = async (inputValue: string) => {
    setNoDataFlag(false);
    setSearchedModules([])
    await axiosInstance()
      .get(`/contentcreator/module?query=${inputValue}`, {
        headers: {
          Authorization: headers.Authorization,
          "Content-Type": "application/json",
        },
        data: {},
      })
      .then((response) => {
        setLoading(false);
        if (response.data.length === 0) {
          setNoDataFlag(true);
        } else {
          setNoDataFlag(false);
          let filteredData = response.data.filter((item: any) => item.moduleId !== moduleId) || []
          setSearchedModules(filteredData);
        }
      })
      .catch((error) => {
        setSearchedModules([]);
        setLoading(false);
      });
  };

  useEffect(() => {
    setAddNewDependency(false);
    setRelationshipError(false);
    setUniqueRelationError(false);
    setUniqueModuleRelationError(false);
  }, [moduleSdoId])

  useEffect(() => {
    reviewModuleData.forEach((course) => {
      if (selectedCourseId === course.courseId) {
        let mCourse = course.modules.map((module) => {
          if (module.sdoId === moduleSdoId) {
            if (module?.savedRelations?.length) {
              let relations = module?.savedRelations?.map((savedRelationshipItem) => {
                let moduleTitle = savedRelationshipItem?.toDictData?.title || savedRelationshipItem.moduleTitle;
                let key = savedRelationshipItem?.label || savedRelationshipItem.key;
                let relationshipOption = data.filter((currentItem: any) => {
                  return currentItem.key === key;
                })[0].name;
                return {
                  name: relationshipOption,
                  moduleTitle,
                  key,
                  id: savedRelationshipItem?.toSdoId || savedRelationshipItem?.id,
                };
              })
              setDependenciesList([...relations]);
            } else {
              if (!savedRelationShipsLoading && savedRelationShips?.length) {
                let existedDependencies = savedRelationShips.map(
                  (savedRelationshipItem: any) => {
                    let moduleTitle = savedRelationshipItem?.toDictData?.title;
                    let key = savedRelationshipItem?.label;
                    let relationshipOption = data.filter((currentItem: any) => {
                      return currentItem.key === savedRelationshipItem?.label;
                    }) || [];
                    return {
                      name: relationshipOption.length ? relationshipOption[0].name : data[0].name,
                      moduleTitle,
                      key,
                      id: savedRelationshipItem.toSdoId,
                    };
                  }
                );
                setDependenciesList([...existedDependencies]);
              } else {
                setDependenciesList([]);
              }
            }
          }
          return module
        })
        course.modules = mCourse
      }
      return course
    })

  }, [savedRelationShipsLoading]);

  const SearchPopoverComponent = React.useCallback((props: any) => {
    return (
      <Popper {...props} sx={styles.searchComponentBox} placement="bottom" />
    );
  }, []);

  const handleChange = (event: any) => {
    let selectedOption = data.find((currentItem: any) => {
      return event.target.value === currentItem.name;
    })
    setRelationshipName(selectedOption);
    setRelationshipError(false);
  };

  const handleAddNewDependency = () => {
    setAddNewDependency(true);
    setModuleData({
      moduleTitle: "",
      id: "",
    });
    setRelationshipName({
      name: "",
      key: "",
    })
  };

  const addNewModule = (value: any) => {
    setModuleData({
      moduleTitle: value?.title || '',
      id: value?.id || '',
    });
    setModuleError(false);
  };

  const removeDependencyFromList = (index: number) => {
    let deletedDependency: any[] = [];
    setDependenciesList((prev) =>{ 
      deletedDependency = prev.splice(index, 1)
      return prev
    });
    return deletedDependency[0];
  };
  
  const updateRemoveRelations = (module: any, deletedDependency: any) => {
    if (module.sdoId === moduleSdoId) {
      module.savedRelations = dependenciesList;
    }
    
    if (module.sdoId === deletedDependency?.id) {
      const prevRelationship = oppositeRelationshipsArray.find(
        (oppRelationship: any) =>
          oppRelationship.key === deletedDependency?.key && oppRelationship.name === deletedDependency?.name
      );
      const result = prevRelationship?.value || null;
      if (module?.savedRelations && module.savedRelations.length > 0) {
        const updatedRelations = module?.savedRelations?.filter((relation: any) => {
          if (result?.key === relation.key && result?.name === relation.name && relation.id === moduleSdoId && relation.moduleTitle === moduleName) {
            return false;
          } else {
            return true;
          }
        });
        module.savedRelations = updatedRelations;
      }
    }
    return module
  };
  
  const onRemoveDependency = (index: number) => {
    const deletedDependency = removeDependencyFromList(index);
  
    const updatedReviewModuleData = reviewModuleData.map((course) => {
      if (selectedCourseId === course.courseId) {
        course.modules = course.modules.map((module) => {
          return updateRemoveRelations(module, deletedDependency);
        });
      }
      return course;
    });
    setReviewModuleData(updatedReviewModuleData);
  };

  const onCancelNewDependency = () => {
    if (uniqueRelationError) {
      setUniqueRelationError(false);
    }
    if (uniqueModuleRelationError) {
      setUniqueModuleRelationError(false);
    }
    if (addNewDependency) {
      setAddNewDependency(false);
    }
    if (relationshipError) {
      setRelationshipError(false);
    }
    if (moduleError) {
      setModuleError(false);
    }
    setModuleData({
      moduleTitle: "",
      id: "",
    });
    setRelationshipName({
      name: "",
      key: "",
    })
  };

  const updateSavedRelations = (module: any, moduleSdoId: any, moduleName: any, relationshipName: any, oppositeRelationshipsArray: any) => {
    if (module.sdoId === moduleSdoId) {
      if (Array.isArray(module.savedRelations)) {
        module.savedRelations = [...module.savedRelations, { ...relationshipName, ...moduleData }];
      } else {
        module.savedRelations = [{ ...relationshipName, ...moduleData }];
      }
    }
  
    if (module.sdoId === moduleData.id) {
      let duplicateRelationFlag = false;
      let newRelationship = oppositeRelationshipsArray.find(
        (oppRelationship: any) => oppRelationship.key === relationshipName.key && oppRelationship.name === relationshipName.name
      );
      let result = newRelationship?.value || null;
  
      if (module?.savedRelations && module.savedRelations.length > 0) {
        module?.savedRelations?.forEach((relation: any) => {
          if (moduleSdoId === relation.id || moduleSdoId === relation.toSdoId) {
            if (result?.key === relation.label || result?.key === relation.key) {
              duplicateRelationFlag = true;
            }
          }
        });
  
        if (!duplicateRelationFlag) {
          module.savedRelations = [
            ...module.savedRelations,
            { ...result, ...{ moduleTitle: moduleName, id: moduleSdoId } },
          ];
        }
      } else {
        module.savedRelations = [{ ...result, ...{ moduleTitle: moduleName, id: moduleSdoId } }];
      }
    }
  };
  
  const onChangeSetReviewData = (relationshipName: any, moduleData: any) => {
    const updatedReviewModuleData = reviewModuleData.map((course) => {
      if (selectedCourseId === course.courseId) {
        course.modules = course.modules.map((module) => {
          updateSavedRelations(module, moduleSdoId, moduleName, relationshipName, oppositeRelationshipsArray);
          return module;
        });
      }
      return course;
    });
  
    setReviewModuleData(updatedReviewModuleData);
  
    setDependenciesList([
      ...dependenciesList,
      { ...relationshipName, ...moduleData },
    ]);
  };

  const onLinkNewDependency = () => {
    let errorFlag = false;
    let duplicateRelationFlag = false;
    let duplicateModuleRelationFlag = false;

    if (relationshipName.name === "") {
      errorFlag = true;
      setRelationshipError(true);
    }

    if (moduleData.moduleTitle === "") {
      errorFlag = true;
      setModuleError(true);
    }

    reviewModuleData.forEach((course) => {
      if (selectedCourseId === course.courseId) {
        course.modules.forEach((module) => {
          module?.savedRelations?.forEach((relation) => {
            if (module.sdoId === moduleSdoId){
              if (moduleData.id === relation.id || moduleData.id === relation.toSdoId) {
                if (relationshipName.key === relation.label || relationshipName.key === relation.key) {
                  duplicateRelationFlag = true
                } else {
                  duplicateModuleRelationFlag = true;
                }
              }else if (relationshipName.key === relation.label || relationshipName.key ===   relation.key){
                  duplicateRelationFlag = true
              }
            }
          })
          return module
        })
      }
    })

    if (duplicateRelationFlag) {
      setUniqueRelationError(true);
    } else {
      setUniqueRelationError(false);
    }

    if (duplicateModuleRelationFlag) {
      setUniqueModuleRelationError(true);
    } else {
      setUniqueModuleRelationError(false);
    }

    if (!errorFlag && !duplicateRelationFlag && !duplicateModuleRelationFlag) {
      setRelationshipError(false);
      setModuleError(false);
      setAddNewDependency(false);
      onChangeSetReviewData(relationshipName, moduleData)
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setOpenConfirmationDeleteModal(false);
  };

  const onClickActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onClickDelete = () => {
    setOpenDeleteModal(true);
  };

  const onClickConfirmationDelete = () => {
    setOpenConfirmationDeleteModal(true);
  };
  
  return (
    <Grid display={"flex"} flexDirection={"column"}>
      <Grid mt={"30px"} ml={"41px"} display={"flex"} flexDirection={"row"} justifyContent={'space-between'}>
        <Grid>
          <Typography sx={styles.moduleNameStyle}>{moduleName}</Typography>
        </Grid>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Grid mr={'38px'}>
              <Box sx={styles.actionsMenuContainer} border={`2px solid ${theme.palette.secondary[400]}`} onClick={onClickActionsMenu} aria-describedby={popperId}>
                  <Typography sx={{
                    ...theme?.typography?.Components?.button?.default,
                    ...theme?.typography?.Components?.button?.small,
                    color: theme.palette.primary[500],
                    textTransform: "none",
                  }}>Actions</Typography>
                  <ArrowDropDownIcon sx={{
                    height: '18px',
                    width: '18px',
                    color: theme.palette.primary[400]
                  }}/>
              </Box>
          </Grid>
        </ClickAwayListener>
        <Popper
          id={popperId}
          open={open}
          anchorEl={anchorEl}
          placement={"bottom-end"}
          style={{ zIndex: 2 }}
          >
            <Box
              className="popper-dropdown-box"
              sx={{
                borderRadius: "8px",
                boxShadow:
                  "0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
                backgroundColor: "#FFF",
                width: '211px',
                height: 'auto',
              }}
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} ml={'19px'} mt={'20px'} sx={{
                  cursor: 'pointer'
                }}>
                  <Box>
                    <EditIcon sx={{
                      height: '18px',
                      width: '18px',
                      color: '#7781FF'
                    }}/>
                  </Box>
                  <Box ml={'7px'}>
                    <Typography sx={{
                      ...theme?.typography?.body?.default,
                      ...theme?.typography?.body?.body1,
                      ...theme?.typography?.body?.regular,
                      color: theme.palette.grayScale[700],
                      lineHeight: 'normal',
                    }}>Edit</Typography>
                  </Box>
                  </Box>
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} ml={'19px'} my={'20px'} sx={{
                    cursor: 'pointer'
                  }} onClick={onClickDelete}>
                    <Box>
                      <DeleteIcon sx={{
                        height: '18px',
                        width: '18px',
                        color: '#7781FF'
                      }}/>
                  </Box>
                  <Box ml={'7px'}>
                    <Typography sx={{
                      ...theme?.typography?.body?.default,
                      ...theme?.typography?.body?.body1,
                      ...theme?.typography?.body?.regular,
                      color: theme.palette.grayScale[700],
                      lineHeight: 'normal',
                    }}>Delete</Typography>
                  </Box>
              </Box>
            </Box>
          </Box>
        </Popper>
        {openDeleteModal ? <Box sx={{
            ...styles.deleteModalContainer,
            }}
          >
            <ActionsDeleteModal handleCloseDeleteModal={handleCloseDeleteModal} onClickConfirmationDelete={onClickConfirmationDelete} openConfirmationDeleteModal={openConfirmationDeleteModal} setOpenConfirmationDeleteModal={setOpenConfirmationDeleteModal}/>
          </Box> : null}
      </Grid>
      <Grid mt={"15px"} ml={"41px"}>
        <Typography sx={styles.suggesteddependenciesListHeader}>
          Suggested Dependencies
        </Typography>
      </Grid>
      {savedRelationShipsLoading ? (
        <Box ml={7} mt={2.5}>
          <Skeleton height={"36px"} sx={{ maxWidth: "471px" }} />
          <Skeleton height={"36px"} sx={{ maxWidth: "471px" }} />
          <Skeleton height={"36px"} sx={{ maxWidth: "471px" }} />
          <Skeleton height={"36px"} sx={{ maxWidth: "471px" }} />
        </Box>
      ) : (
        <>
          <Grid display={"flex"} flexDirection={"column"}>
            {dependenciesList.length === 0
              ? ""
              : Children.toArray(
                  dependenciesList.map((dependency: any, index: number) => (
                    <DependencyViewComponent
                      index={index}
                      relationshipOption={dependency.name}
                      moduleTitle={dependency.moduleTitle}
                      onRemove={(key: any) => onRemoveDependency(key)}
                      moduleName={moduleName}
                    />
                  ))
                )}
          </Grid>
          {!addNewDependency ? (
            <Grid
              display={"flex"}
              flexDirection={"row"}
              mt={"16px"}
              ml={"43px"}
            >
              <Box>
                <IconButton
                  onClick={handleAddNewDependency}
                  sx={{ height: "32px", width: "32px", p: 0 }}
                >
                  <img src={PlusIcon} alt="ICON" />
                </IconButton>
              </Box>
              <Box ml={"8px"}>
                <Typography sx={styles.newDependencyStyle}>
                  New Dependency
                </Typography>
              </Box>
            </Grid>
          ) : (
            <Grid
              display={"flex"}
              flexDirection={"column"}
              mt={"13px"}
              ml={"43px"}
              maxWidth={"471px"}
            >
              <Grid display={"flex"} flexDirection={"row"} height={"60px"}>
                <Grid>
                  <Select
                    sx={{
                      ...selectInputStyle(
                        theme.palette.primary[500],
                        "1px solid #003DA6"
                      ),
                      ...(relationshipError &&
                        selectInputStyle("#DF2F2F", "1px solid #DF2F2F")),
                    }}
                    value={relationshipName.name}
                    onChange={handleChange}
                    IconComponent={ExpandMoreRoundedIcon}
                    displayEmpty={true}
                    renderValue={(value) => (value === "" ? "Select" : value)}
                  >
                    {Children.toArray(
                      data.map((item: IRelationships) => (
                        <MenuItem
                          sx={{ py: "9px" }}
                          key={item.key}
                          value={item.name}
                        >
                          {item.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  {relationshipError && (
                    <>
                      <Typography sx={styles.errorText} display={"flex"}>{' '}
                        <img
                          src={SmallErrorIcon}
                          style={{ marginRight: "2px" }}
                          alt=""
                        />
                        Dependency not selected.
                      </Typography>
                      <Typography sx={styles.errorText} ml={"3px"}>
                        Please select a dependency.
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid width={"100%"} ml={"10px"}>
                  <Autocomplete
                    options={searchedModules}
                    filterOptions={(x) => x}
                    ListboxProps={{
                      style: { maxHeight: 300, overflow: "auto" },
                      sx: {
                        "& .MuiAutocomplete-option": {
                          py: "15px",
                        },
                      },
                    }}
                    PopperComponent={SearchPopoverComponent}
                    forcePopupIcon={false}
                    getOptionLabel={(option: any) => {
                      return option?.title || "";
                    }}
                    sx={{
                      ...autoCompleteInputStyle("1px solid #CBD2E0", "6px"),
                      ...(moduleError &&
                        autoCompleteInputStyle("2px solid #FF6363", "5px")),
                    }}
                    noOptionsText={noDataFlag ? "Module not found" : ""}
                    onBlur={() => {
                      setNoDataFlag(false);
                      setSearchedModules([]);
                    }}
                    onChange={(e: any, newValue: any) => {
                      if (e.target.value !== "") {
                        addNewModule(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search for Modules"
                        onChange={handleInputChange}
                        value={moduleData.moduleTitle}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#959CA8 !important",
                            },
                            "&:hover fieldset": {
                              borderColor: "#959CA8 !important",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#959CA8 !important",
                            },
                          },
                        }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              <InputAdornment position="end">
                                {loading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                              </InputAdornment>
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                  {moduleError && (
                    <Typography sx={styles.errorText} display={"flex"}>{' '}
                      <img
                        src={SmallErrorIcon}
                        style={{ marginRight: "4px" }}
                        alt=""
                      />
                      Module not selected. Please select a module.
                    </Typography>
                  )}
                </Grid>
              </Grid>
              {uniqueRelationError && (
                <Typography
                  sx={{ ...styles.errorText, top: "-16px" }}
                  display={"flex"}
                  position={"relative"}
                >{' '}
                  <img
                    src={SmallErrorIcon}
                    style={{ marginRight: "2px" }}
                    alt=""
                  />
                  You’ve already added this dependency
                </Typography>
              )}
              {!relationshipError && uniqueModuleRelationError && (
                <Typography
                  sx={{ ...styles.errorText, top: "-16px" }}
                  display={"flex"}
                  position={"relative"}
                >{' '}
                  <img
                    src={SmallErrorIcon}
                    style={{ marginRight: "2px" }}
                    alt=""
                  />
                  You’ve already selected a dependency to this module. Remove
                  the previous dependency in order to add a new one
                </Typography>
              )}
              <Grid
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"flex-end"}
                height={"32px"}
                mt={"12px"}
              >
                <Button
                  sx={styles.cancelButton}
                  onClick={onCancelNewDependency}
                >
                  Cancel
                </Button>
                <Button sx={styles.linkButton} onClick={onLinkNewDependency}>
                  Link
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default SuggestedDependencies;
