import { Grid, Typography, IconButton } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';
import { godzilla as theme } from "util/themes/godzilla";

const styles = {
  popperContainer: {
    position: 'relative',
    width: '278px',
    height: 'auto',
    background: '#FFFFFF',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    pb: '20px',
    pt: '17px',
    pl: '12px'
  },
  popperContentText: {
    ...theme?.typography?.body?.default,
    ...theme?.typography?.body?.body1,
    ...theme?.typography?.body?.regular,
    color: '#FF820F',
    ml: '10px'
  },
  closeIconStyle: {
    position: 'absolute',
    right: '3px',
    top: '3px',
    '&.MuiIconButton-root': {
      width: '30px', height: '30px'
    }
  },
  errorIconStyle: {
    width: '20px',
    height: '20px',
    color: '#FF5C00'
  }
}

interface IHandleCloseWarningModalProps {
  setWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PublishWarningModal(props: IHandleCloseWarningModalProps) {
  const { setWarningModal } = props;

  return (
    <Grid sx={styles.popperContainer} flexDirection={'row'} display={'flex'}>
      <Grid pt='5px'>
        <ErrorOutlineIcon sx={styles.errorIconStyle} />
      </Grid>
      <Grid width={205}>
        <Typography sx={styles.popperContentText}>
          Oops! You selected one or more pathways that aren’t ready to be published.
        </Typography>
      </Grid>
      <IconButton onClick={() => setWarningModal(false)} sx={styles.closeIconStyle}>
        <CloseIcon sx={{ color: '#7E8592' }} />
      </IconButton>
    </Grid >
  )
}