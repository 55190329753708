const getNavStatus = (pathname: string) => {
  const pathwayRoute = ['/pathwayCreator'];
  const modulesRoute = ['/moduleEditor'];
  const TermsAndConditionsRoute = ['/terms-and-conditions'];  
  const combinedArray = [...pathwayRoute,...modulesRoute,...TermsAndConditionsRoute]
  return { sideNav: combinedArray.some((route)=>pathname.includes(route))  }
}

export {
  getNavStatus
}