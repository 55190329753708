import React, { useEffect, useState, useRef, useLayoutEffect, useMemo } from "react";
import { Box, ClickAwayListener, Grid, Typography } from "@mui/material";
import { Button } from "core/components";
import UnPublishedModuleList from "../DraftsModule/UnpublishedModulesList";
import { useNavigate } from "react-router-dom";
import {
  IModuleCardDataProps,
  IModulePageDataProps,
  IContextData,
} from "../DraftsModule/types";
import ExitReviewModal from "./components/ExitReviewModal";
import { ReviewModuleDependencyContainer } from "./ReviewModuleDependencyContainer";
import { useAuth } from "core/contexts";
import { getDefaultHeaders, instance as axiosInstance } from "core/util";
import { useMutation } from "react-query";
import { useAppContext } from "components/app-context/appContext";
import PublishWarningModal from "../DraftsModule/components/PublishWarningModal";
import { PublishConfirmModal } from "../DraftsModule/components/PublishConfirmModal";
import { reviewTagWarningModalText } from "../draft.helper";
import moment from "moment";
import Toast, { IToastTypes } from "components/toast/Toast";
import { godzilla as theme } from "util/themes/godzilla";
import axios from "axios";

const styles = {
  pageHeading: {
    container: {
      px: 5,
      pt: 5,
      pb: 2.5,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    headingStyle: {
      fontFamily: "Museo Sans Rounded",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "32px",
      lineHeight: "38px",
      color: "#4B4D58",
    },
    btnStyle: {
      height: "44px",
      textTransform: "none",
    },
    textBtnStyle: {
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "15px",
      lineHeight: "20px",
      letterSpacing: "-0.01em",
      color: "#202124",
    },
    outlinedBtnStyle: {
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "19px",
      lineHeight: "22px",
      letterSpacing: "-0.01em",
    },
    containedBtnStyle: {
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "25px",
      letterSpacing: "-0.01em",
    },
    moduleNameStyle: {
      fontFamily: "Museo Sans Rounded",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "28px",
      lineHeight: "34px",
      color: "#4B4D58",
    },
  },
  pageContent: {
    mainContainer: {
      height: "calc(100vh - 134px)",
      width: "100%",
      px: 5,
    },
    moduleContainer: {
      overflow: "auto",
      backgroundColor: "#F9FAFC",
      borderRadius: "20px",
    },
    courseConatiner: {
      overflow: "auto",
      backgroundColor: "#FFFFFF",
      borderRadius: "20px",
    },
    container: {
      width: "100%",
      px: 5.25,
      py: 4.75,
      overflow: "initial",
    },
  },
  exitModalContainer: {
    zIndex: 2,
    position: "absolute",
    top: "30px",
    right: "8px",
  },
  warningModalContainer: {
    zIndex: 2,
    position: "absolute",
    top: "48px",
    right: "0",
  },
  warningTextStyle: {
    textStyle: {
      fontFamily: "Nunito",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "143%",
      color: "#CF0000",
      pl: "4px",
      pt: "4px",
    },
    iconStyle: {
      width: "31px",
      height: "31px",
      color: "#CF0000",
    },
  },
  saveTimeContainer: {
    display: "flex",
    alignItems: "center",
    mr: "12px",
  },
  saveTimeTextStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    color: "#676F7C",
  },
};

export default function ReviewModule() {
  const pageRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [openExitModal, setOpenExitModal] = useState(false);
  const [selectedPageData, setSelectedPageData] =
    useState<IModulePageDataProps | null>(null);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [openPublishConfirmModal, setOpenPublishConfirmModal] = useState(false);

  const [selectedModule, setSelectedModule] =
    useState<IModuleCardDataProps | null>(null);
  const [dependenciesListData, setDependenciesListData] = useState<Array<any>>(
    []
  );
  const [skillData, setSkillData] = useState<any>([]);
  const [skillError, setSkillError] = useState<boolean>(false);
  const [isModuleEdit, setIsModuleEdit] = useState<boolean>(false);
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const headers = getDefaultHeaders(getToken());
  const courseId = selectedModule?.courseId;
  const { setReviewModuleData, reviewModuleData }: IContextData =
    useAppContext();
  const [loadingSaveButton, setLoadingSaveButton] = useState(false);
  const [loadingPublishButton, setLoadingPublishButton] = useState(false);
  const [saveTimeStamp, setSaveTimeStamp] = useState<string>();
  const [showToast, setShowToast] = useState(false);

  const onDownloadLink = async (url: string, fileName: string) => {
     const response = await axios.get(url, {
      headers: {
          "Authorization": headers.Authorization,
          "Content-Type": "application/json"
      },
      responseType: 'arraybuffer'
    });
    let blob = new Blob([response.data], {
      type: "mime" || "application/octet-stream",
    });
    let bloburl = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.href = bloburl;
    link.download = fileName;
    link.click();
  } 

  const handleFileClick = (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    const targetCloset = target.closest('a');
    if (
      target.tagName === "A" &&
      target.getAttribute("data-download") === "true"
    ) {
      event.preventDefault();
      const fileUrl = target.getAttribute("href");
      const fileName = target.getAttribute("title") || fileUrl?.split('/').pop();
      onDownloadLink(fileUrl || '', fileName || 'downloadFile');
    }else if((target.tagName === "EM" || target.tagName === "SPAN") && targetCloset) {
      if(targetCloset.getAttribute("data-download") === "true"){
        event.preventDefault();
        const fileUrl = targetCloset.getAttribute("href");
        const fileName = targetCloset.getAttribute("title") || fileUrl?.split('/').pop();
        onDownloadLink(fileUrl || '', fileName || 'downloadFile');
      }
    }
  };

  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.addEventListener("click", handleFileClick);
    }

    return () => {
      if (containerElement) {
        containerElement.removeEventListener("click", handleFileClick);
      }
    };
  }, [selectedPageData])
  

  useEffect(() => {
    if(reviewModuleData.length === 0) {
      navigate('/drafts')
    }
  }, [])

  useLayoutEffect(() => {
    if(pageRef?.current) {
      const iframeElements = pageRef.current?.querySelectorAll('iframe');
      iframeElements.forEach((iframe) => {
        if (iframe.src.includes(window.location.origin)) {
          iframe.src = '/images/assets/sidenav/file_not_found.svg';
          iframe.style.height = '235px';
          iframe.style.width = '450px';
          iframe.style.border = 'none';
        }
      });
    }
  }, [selectedPageData])

  const noOfModules = useMemo(() => {
    let moduleCount = 0;
    reviewModuleData.forEach(course => {moduleCount += course.modules.length})
    return moduleCount
  }, [reviewModuleData])
  

  const getPreparedFormData = (isSave: boolean) => {
    let formData: Array<any> = [];
    reviewModuleData.forEach((course) => {
      return course.modules.map((module) => {
        if (
          !module?.status ||
          module?.status === "unpublished" ||
          module?.status === "readyToPublish" ||
          module?.status.toLowerCase() === "readytoreview"
        ) {
          let obj: {
            sdoId: string;
            status?: string;
            moduleRelations: Array<any>;
            listModuleSkills: Array<any>;
          } = {
            sdoId: module?.sdoId,
            moduleRelations:
              module?.savedRelations?.map((dependency: any) => {
                return {
                  toModuleId: dependency?.toSdoId || dependency.id,
                  relationshipName: dependency.label || dependency.key,
                };
              }) || [],
            listModuleSkills:
              module?.savedSkills?.map((skill: any) => {
                return {
                  skillId: skill.skillId || skill.id,
                  skillName: skill.skillName || skill.name,
                };
              }) || [],
          };
          if (!isSave) {
            obj = { ...obj, status: "published" };
          }
          formData.push(obj);
        }
        return module;
      });
    });

    return formData;
  };

  const onSaveReview = useMutation((isReview: boolean) => {
    let formData = getPreparedFormData(isReview);
    // if (isModuleEdit) {
    return axiosInstance().put(
      "/contentcreator/skillAndRelationship",
      formData,
      {
        headers: {
          Authorization: headers.Authorization,
        },
      }
    );
    // } else {
    //   return axiosInstance().post('/contentcreator/skillAndRelationship', formData, {
    //     headers: {
    //       'Authorization': headers.Authorization
    //     },
    //   })
    // }
  });

  const onSaveModuleData = () => {
    if (reviewModuleData.length) {
      setLoadingSaveButton(true);
      setSkillError(false);
      onSaveReview
        .mutateAsync(true)
        .then((res) => {
          setSaveTimeStamp(moment().format("MM/DD/YYYY hh:mmA"));
          let newData = reviewModuleData.map((course: IModuleCardDataProps) => {
            let mCourse = course.modules.map((module) => {
              let findIndex = res.data.findIndex(
                (resData: any) => resData.sdoId === module?.sdoId
              );
              if (findIndex > -1) {
                module.status = res.data[findIndex].status;
                module["isTagRequired"] = false;
                module["isPageRequired"] = false;
              }
              return module;
            });
            course.modules = mCourse;
            return course;
          });
          setLoadingSaveButton(false);
          setReviewModuleData(newData);
        })
        .catch((err) => {
          setLoadingSaveButton(false);
          console.log("ERROR", err);
        });
    }
  };

  const onClickPublishBtn = () => {
    if (reviewModuleData.length) {
      let isDataValid = true;
      let newData = reviewModuleData
        .filter((course) => {
          return course?.modules && course.modules.length;
        })
        .map((course: IModuleCardDataProps) => {
          let mCourse = course.modules.map((module) => {
            if (
              !module?.status ||
              module.status.toLowerCase() === "unpublished" ||
              module.status.toLowerCase() === "readytoreview"
            ) {
              if (module.savedSkills?.length) {
                module["isTagRequired"] = false;
              } else {
                module["isTagRequired"] = true;
                setSkillError(true);
                isDataValid = false;
              }
            } else if (
              module.status.toLowerCase() === "readytopublish" &&
              module.savedSkills?.length === 0
            ) {
              module["isTagRequired"] = true;
              setSkillError(true);
              isDataValid = false;
            } else {
              module["isTagRequired"] = false;
            }
            if (module?.totalModuleItems === 0) {
              module["isPageRequired"] = true;
              isDataValid = false;
            }else {
              module["isPageRequired"] = false;
            }
            return module;
          });
          course.modules = mCourse;
          return course;
        });
      setReviewModuleData(newData);

      if (isDataValid) {
        setOpenPublishConfirmModal(true);
      } else {
        setOpenWarningModal(true);
      }
    }
  };

  const onPublishModuleData = () => {
    setOpenPublishConfirmModal(false);
    setLoadingPublishButton(true);
    onSaveReview
      .mutateAsync(false)
      .then((res) => {
        setShowToast(true);
        setTimeout(() => {
          setLoadingPublishButton(false);
          navigate("/drafts");
        }, 1000);
      })
      .catch((err) => {
        setLoadingPublishButton(false);
        console.log("ERROR", err);
      });
  };
  const onSelectCard = (moduleCardData: IModuleCardDataProps) => {
    setSelectedModule(moduleCardData);
    setSelectedPageData(null);
  };

  const onSelectPage = (pageData: IModulePageDataProps) => {
    setSelectedPageData(pageData);
  };

  const handleCloseExitModal = () => {
    setOpenExitModal(false);
  };

  const handleCloseWarningModal = () => {
    setOpenWarningModal(false);
    setOpenPublishConfirmModal(false);
  };

  return (
    <Box height={"100vh"} className="customScroll" sx={{ overflow: "auto",background:'#FFFFFF' }}>
      <Toast message={`${noOfModules} modules published successfully`} type={IToastTypes.Success} open={showToast} setOpen={setShowToast} />
      <Box sx={styles.pageHeading.container} component={Grid}>
        <Grid display={"flex"}>
          <Typography sx={styles.pageHeading.headingStyle}>
            Review Modules
          </Typography>
        </Grid>
        <Grid display={"flex"}>
          {saveTimeStamp && (
            <Grid item sx={styles.saveTimeContainer}>
              <Typography variant="caption" sx={styles.saveTimeTextStyle}>
                Last saved on {saveTimeStamp}
              </Typography>
            </Grid>
          )}
          <Grid item sx={{ position: "relative" }}>
            <ClickAwayListener
              onClickAway={handleCloseExitModal}
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
            >
              <Box>
                <Button
                  variant="text"
                  color="inherit"
                  size="medium"
                  sx={{...styles.pageHeading.btnStyle, mr: '16px'}}
                  onClick={() => setOpenExitModal(true)}
                >
                  <Typography sx={styles.pageHeading.textBtnStyle}>
                    Exit
                  </Typography>
                </Button>
                <Box sx={styles.exitModalContainer}>
                  {openExitModal ? (
                    <ExitReviewModal
                      handleCloseExitReviewModal={handleCloseExitModal}
                    />
                  ) : null}
                </Box>
              </Box>
            </ClickAwayListener>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              sx={{
                ...styles.pageHeading.btnStyle,
                mr: 2.5,
                width: "108px",
                border: "2px solid #7781FF",
                ...(!loadingSaveButton && { px: "32.5px", py: "9px" }),
                "&:hover": {
                  background: "#2C39DA0a",
                },
              }}
              onClick={onSaveModuleData}
              loading={loadingSaveButton}
            >
              <Typography
                sx={{
                  ...styles.pageHeading.outlinedBtnStyle,
                  color: theme?.palette?.primary?.[500]
                }}
              >
                {loadingSaveButton ? "Saving" : "Save"}
              </Typography>
            </Button>
          </Grid>
          <Grid item sx={{ position: "relative" }}>
            <ClickAwayListener
              onClickAway={handleCloseWarningModal}
              mouseEvent="onMouseDown"
              touchEvent="onTouchStart"
            >
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  sx={{
                    ...styles.pageHeading.btnStyle,
                    px: "23px",
                    py: "9.5px",
                  }}
                  onClick={onClickPublishBtn}
                  loading={loadingPublishButton}
                >
                  <Typography
                    sx={{
                      ...styles.pageHeading.containedBtnStyle,
                      color: "#BFFBFF",
                    }}
                  >
                    Publish
                  </Typography>
                </Button>
                <Box sx={styles.warningModalContainer}>
                  {openWarningModal ? (
                    <PublishWarningModal
                      sx={styles.warningTextStyle}
                      warningText={reviewTagWarningModalText}
                      handleCloseWarningModal={handleCloseWarningModal}
                    />
                  ) : null}
                  {openPublishConfirmModal ? (
                    <PublishConfirmModal
                      setPublishConfirmModal={setOpenPublishConfirmModal}
                      onPublishModuleData={onPublishModuleData}
                      isReviewModule={true}
                    />
                  ) : null}
                </Box>
              </Box>
            </ClickAwayListener>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={styles.pageContent.mainContainer}
        display={"flex"}
        flexDirection={"row"}
        className="reviewModulesMainContainer"
      >
        <Grid container>
          <Grid item xs={6} height={'83vh'}>
            <Grid
              height={"100%"}
              width={"100%"}
              mr={"10px"}
              sx={{
                ...styles.pageContent.courseConatiner,
                position: "relative",
              }}
              className="customScroll"
            >
              <Grid sx={{ ...styles.pageContent.container }} item>
                {reviewModuleData.length ? (
                  <UnPublishedModuleList
                    setSelectedModule={setSelectedModule}
                    hasDeleteSelection={true}
                    hasSelection={false}
                    data={reviewModuleData || []}
                    setModuleData={() => {}}
                    selectedModuleData={[]}
                    onSelectCard={onSelectCard}
                    onSelectPage={onSelectPage}
                    isReviewModule={true}
                  />
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} height={'83vh'}>
            <Grid
              height={"100%"}
              width={"100%"}
              ml={"10px"}
              sx={styles.pageContent.moduleContainer}
              className="customScroll"
            >
              {selectedPageData ? (
                <Grid sx={styles.pageContent.container} item ref={containerRef}>
                  <div
                    ref={pageRef}
                    dangerouslySetInnerHTML={{
                      __html: selectedPageData.richText,
                    }}
                  />
                </Grid>
              ) : (
                selectedModule && (
                  <ReviewModuleDependencyContainer
                    selectedModuleData={selectedModule}
                    dependenciesList={dependenciesListData}
                    skillData={skillData}
                    skillError={skillError}
                    setDependenciesList={setDependenciesListData}
                    setSkillData={setSkillData}
                    setSkillError={setSkillError}
                    setIsModuleEdit={setIsModuleEdit}
                    selectedCourseId={courseId}
                    isMessageStyle
                  />
                )
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
