const recordsPerPage = 6;

export const getCurrentPageList = (currentPageNum: number, list: any[], pageSize?: number) => {
    const size = (pageSize ?? recordsPerPage); 
    const start = currentPageNum * size;
    return list.slice(start, start + size);
}

export const getTotalPageCount = (listLength: number, pageSize?: number) => {
    return Math.ceil(listLength / (pageSize ?? recordsPerPage));
}