import { useState } from 'react'
import { getDefaultHeaders, instance as axiosInstance } from 'core/util';
import { godzilla as theme } from 'util/themes/godzilla';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'core/contexts';
import ModuleLayout from '../ModuleLayout/ModuleLayout';
import ArrowBackFilled from 'assets/images/arrow_back_filled.svg';
import InfoIcon from '../../../assets/images/info_icon.svg';
import SearchIcon from "../../../assets/images/searchicon.svg";
import { Box, Dialog, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";
import { Button } from 'core/components';
import { ReviewModuleDependencyContainer } from '../DraftReviewModule/ReviewModuleDependencyContainer';
import { IModuleCardDataProps } from '../DraftsModule/types';
import { inputTextStyle } from '../draft.helper';

const styles = {
    modalStyle: {
        '& .MuiPaper-root': {
            height: '100vh',
            width: '100%',
            maxWidth: 'inherit',
            backgroundColor: "#FFFFFF",
            boxShadow: 'none',
            borderRadius: '0px',
            margin: '0px',
            maxHeight: '100vh',
        },
    },
    tagHeaderStyle: {
        fontFamily: "Museo Sans Rounded",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "24px",
        lineHeight: "29px",
        color: "#4B4D58",
    },
    messageStyle: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "19px",
        color: "#4B4D58",
    },
    moduleBox: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '699px',
        height: '80vh',
        backgroundColor: '#F9FAFC',
        borderRadius: '20px',
        padding: '33px 41px',
        marginBottom: '48px'
    },
    backToEditing: {
        color: theme.palette.primary[500],
        fontSize: '15px',
        fontWeight: '700',
        textTransform: "capitalize",
        paddingLeft: '9px',
        whiteSpace: 'noWrap'
    },
    moduleSettingsHeading: {
        color: theme.palette.primary[700],
        alignSelf: 'center',
        fontSize: '28px',
        fontWeight: '500',
    },
    autocompleteField: {
        width: "303px",
        mb: '4px',
        "& .MuiInputBase-root": {
            "& .MuiInputBase-input": {
                padding: "0px",
            },
        },
    },
};

const ModuleSettings = () => {
    const { getToken } = useAuth();
    const headers = getDefaultHeaders(getToken());
    const params = useParams();
    const navigate = useNavigate();
    const [selectedModule, setSelectedModule] =
        useState<IModuleCardDataProps | null>(null);
    const [dependenciesListData, setDependenciesListData] = useState<Array<any>>(
        []
    );
    const [skillData, setSkillData] = useState<any>([]);
    const [skillError, setSkillError] = useState<boolean>(false);
    const [isModuleEdit, setIsModuleEdit] = useState<boolean>(false);
    const [maxSkillsErrorFlag, setMaxSkillsErrorFlag] = useState(false);
    const courseId = selectedModule?.courseId;
    const getModuleDetail = useQuery('module', async () => {
        const response = await axiosInstance().get(`/contentcreator/module/${params.id}`, {
            headers: {
                "Authorization": headers.Authorization,
                "Content-Type": "application/json"
            },
            data: {}
        });
        return response.data;
    }, {
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });

    const moduleTitle = getModuleDetail?.data?.data?.data?.title

    return (
        <Dialog
            open={true}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={styles.modalStyle}
            BackdropProps={{
                sx: {
                    backgroundColor: '#F5F5F5',
                    opacity: '0',
                },
            }}
        >
            <ModuleLayout moduleTitle={moduleTitle}>
                <Box sx={styles.moduleBox} display={'flex'} className="customScroll" >
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Button variant="text" sx={{ p: 0, height: '30px', width: '96px' }} onClick={() => navigate('/drafts/add-new-module')}>
                            <img src={ArrowBackFilled} alt="ICON" height='24px' width='24px' />
                            <Typography sx={{
                                ...theme?.typography?.body?.default,
                                ...theme?.typography?.body?.body2,
                                ...theme?.typography?.body?.bold,
                                ...styles.backToEditing
                            }}>Back to Editing</Typography>
                        </Button>
                        <Typography sx={styles.moduleSettingsHeading}>Module Settings</Typography>
                        <Button sx={{ height: '0px', width: '96px', border: 'none', p: '0px' }}>
                        </Button>
                    </Box>
                    <Box sx={{ pl: '68px' }}>
                        <ReviewModuleDependencyContainer
                            selectedModuleData={selectedModule}
                            dependenciesList={dependenciesListData}
                            skillData={skillData}
                            skillError={skillError}
                            setDependenciesList={setDependenciesListData}
                            setSkillData={setSkillData}
                            setSkillError={setSkillError}
                            setIsModuleEdit={setIsModuleEdit}
                            selectedCourseId={courseId}
                        />
                        <Grid mt={"42px"} ml={"41px"} display={"flex"} flexDirection={"column"}>
                            <Grid display={"flex"} flexDirection={"row"}>
                                <Typography sx={styles.tagHeaderStyle}>Created for </Typography>
                                <Typography color={"#FF6363"}>*</Typography>
                                <img src={InfoIcon} alt="ICON" />
                            </Grid>
                            <Grid mt={"20px"}>
                                <Autocomplete
                                    disableClearable
                                    options={[]}
                                    filterOptions={(x) => x}
                                    getOptionLabel={(option: any) => option.name}
                                    multiple
                                    ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
                                    // PopperComponent={OptionsPopoverComponent}
                                    forcePopupIcon={false}
                                    sx={styles.autocompleteField}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Search Tags"
                                            sx={{
                                                ...inputTextStyle(`1px solid ${theme?.palette?.grayScale?.[200]}`),
                                                ...(maxSkillsErrorFlag && inputTextStyle("2px solid #FF4F4F")),
                                            }}
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <>
                                                        <InputAdornment position="start" sx={{ ml: '7px', mr: '10px' }}>
                                                            <img src={SearchIcon} alt='Search icon' />
                                                        </InputAdornment>
                                                        {params.InputProps.startAdornment}
                                                    </>
                                                )
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </ModuleLayout >
        </Dialog>
    )
}

export default ModuleSettings