import { Box, Grid, Typography } from "@mui/material";
import LifeTrekLogo from "../../assets/images/LifeTrekLogo.svg";
import { godzilla as theme } from "util/themes/godzilla";
import ScrollArrow from "../../assets/images/scroll_arrow.svg";
import { useEffect, useRef, useState } from "react";
import { Checkbox, Button } from "core/components";
import { useAuth } from "core/contexts";
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useLocation } from "react-router-dom";
import ReactMarkDown from "react-markdown";
import Toast, { IToastTypes } from "components/toast/Toast";

const styles = {
  termsAndConditionsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: "4px",
    boxShadow:
      "0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.20)",
    mt: "5px",
    mx: "348px",
  },
  checkboxStyle: {
    height: "24px",
  },
  checkboxInputStyle: {
    ...theme?.typography?.body?.default,
    ...theme?.typography?.body?.body1,
    ...theme?.typography?.body?.regular,
    lineHeight: "normal",
    color: theme?.palette?.grayScale?.[600],
  },
};

const TermsAndConditions = () => {
  const { setLoginTermAccepted } = useAuth();
  const listInnerRef = useRef<HTMLDivElement>();
  const [reachedBottom, setReachedBottom] = useState(false);
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] =
    useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showToast, setShowToast] = useState({
    show: false,
    message: "",
    type: IToastTypes.Success,
  });
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());
  const location = useLocation();

  let { from, terms } = location.state || {
    from: { pathname: "/" },
    terms: {},
  };
  const [markdown] = useState(
    terms?.documentBase64
      ? atob(terms.documentBase64)
      : "#There was a problem fetching our Terms and Conditions."
  );

  useEffect(() => {
    if (listInnerRef.current) {
      if (
        listInnerRef.current.scrollHeight <= listInnerRef.current.clientHeight
      ) {
        setReachedBottom(true);
      } else {
        setReachedBottom(false);
      }
    }
  }, [markdown, listInnerRef]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight >= scrollHeight * 0.75) {
        if (!reachedBottom) {
          setReachedBottom(true);
        }
      }
    }
  };

  const failureHandler = () => {
    window.location.href = from.pathname;
  };

  const getTextComponent = (props: any, variant: string, style: any) => {
    return <Typography variant={variant} sx={style} {...props} />;
  };
  const handleShowToast = (isShow: boolean) => {
    setShowToast((prv) => ({ ...prv, show: isShow }));
  };

  const onClickAcceptAndContinue = () => {
    if (isTermsAndConditionsAccepted) {
      setButtonLoading(true);
      axiosInstance()
        .put(
          "/contentcreator/terms/agree",
          {
            termsId: terms.termsId,
          },
          {
            headers: {
              Authorization: headers.Authorization,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          // Successful response.
          setShowToast({
            show: true,
            type: IToastTypes.Success,
            message: "You have accepted our Terms and Conditions.",
          });
          setLoginTermAccepted(true);
          setButtonLoading(false);
        })
        .catch(() => {
          // Error response.
          setShowToast({
            show: true,
            type: IToastTypes.Error,
            message: "There was a problem accepting our Terms and Conditions.",
          });
          failureHandler();
          setButtonLoading(false);
        });
    }
  };

  return (
    <>
      <Toast
        message={showToast.message}
        type={showToast.type}
        open={showToast.show}
        setOpen={handleShowToast}
      />
      <Grid ml={6.8} mt={6.25}>
        <img
          src={LifeTrekLogo}
          alt="ICON"
          style={{
            height: "65px",
            width: "134px",
          }}
        />
      </Grid>
      <Grid className="customScroll" sx={styles.termsAndConditionsContainer}>
        <Grid
          p={"12px 32px 10px 16px"}
          borderBottom={`1px solid ${theme?.palette?.grayScale[50]}`}
        >
          <Typography
            sx={{
              ...theme?.typography?.h2,
              color: theme?.palette?.grayScale[700],
            }}
          >
            Terms & Conditions
          </Typography>
        </Grid>
        <Box
          ref={listInnerRef}
          onScroll={onScroll}
          className="customScroll"
          display={"flex"}
          flexDirection={"column"}
          height={"calc(100vh - 358px)"}
          overflow={"auto"}
          px={"24px"}
          pt={"15px"}
        >
          <ReactMarkDown
            children={markdown}
            components={{
              h1: (props: any) =>
                getTextComponent(props, "h1", {
                  mb: 2,
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  color: theme?.palette?.grayScale[700],
                }),
              h2: (props: any) =>
                getTextComponent(props, "h2", {
                  mb: 2,
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  color: theme?.palette?.grayScale[700],
                }),
              h3: (props: any) =>
                getTextComponent(props, "h3", {
                  mb: 2,
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  color: theme?.palette?.grayScale[700],
                }),
              h4: (props: any) =>
                getTextComponent(props, "h4", {
                  mb: 2,
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  color: theme?.palette?.grayScale[700],
                }),
              h5: (props: any) =>
                getTextComponent(props, "h5", {
                  mb: 2,
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  color: theme?.palette?.grayScale[700],
                }),
              h6: (props: any) =>
                getTextComponent(props, "h6", {
                  mb: 2,
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  color: theme?.palette?.grayScale[700],
                }),
              p: (props: any) =>
                getTextComponent(props, "body1", {
                  mb: 2,
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  color: theme?.palette?.grayScale[700],
                }),
              pre: (props: any) =>
                getTextComponent(props, "body1", {
                  mb: 2,
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  color: theme?.palette?.grayScale[700],
                }),
              code: (props: any) =>
                getTextComponent(props, "body1", {
                  mb: 2,
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  color: theme?.palette?.grayScale[700],
                }),
            }}
          />
        </Box>
        <Grid display={"flex"} justifyContent={"center"} pt={"4px"}>
          <Box
            component="img"
            src={ScrollArrow}
            alt="Scroll"
            sx={{ height: "auto" }}
          />
        </Grid>
        <Grid mt={"2px"} mx={"24px"} mb={"27px"}>
          <Grid display={"flex"} justifyContent={"space-between"}>
            <Grid>
              <Checkbox
                disabled={!reachedBottom}
                label={"I have read and accept the terms and conditions."}
                checkedColor={theme?.palette?.tertiary?.[600]}
                unCheckedColor={theme?.palette?.grayScale?.[500]}
                sx={styles.checkboxStyle}
                inputStyleProps={styles.checkboxInputStyle}
                onClick={(e: any) => e.stopPropagation()}
                onChange={() => {
                  setIsTermsAndConditionsAccepted(
                    !isTermsAndConditionsAccepted
                  );
                }}
                value={isTermsAndConditionsAccepted}
              />
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                sx={{
                  height: "31px",
                  p: "6px 16px",
                }}
                onClick={onClickAcceptAndContinue}
                loading={buttonLoading}
                disabled={!reachedBottom || buttonLoading}
              >
                <Typography
                  sx={{
                    ...theme?.typography?.Components?.button?.default,
                    ...theme?.typography?.Components?.button?.medium,
                    lineHeight: "normal",
                    textWrap: "nowrap",
                    textTransform: "capitalize",
                    color: theme?.palette?.secondary?.[50],
                  }}
                >
                  Accept & Continue
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default TermsAndConditions;
