

import { useMemo, useCallback, Children, useEffect, useState } from 'react';
import { Box, CircularProgress, Grid, ClickAwayListener, Pagination, Divider, Typography } from '@mui/material'
import { useQuery } from 'react-query';
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useAuth } from "core/contexts";
import { godzilla as theme } from "util/themes/godzilla";
import DraftsImageSVG from 'assets/images/draftsimg.svg';
import { getCurrentPageList, getTotalPageCount } from './libraryHelper';
import PublishedModulesList from './LibraryModules/PublishedModulesList';
import PaginationItem from './components/PaginationItem';
import { IPublishedModuleCardDataProps } from './types';
import sortSVG from 'assets/images/sort.svg';
import SortModal, { ISortFieldTypes } from './components/SortModal';
import filterImage from 'assets/images/filterImage.svg';
import { Button, SearchField, Checkbox } from 'core/components';
import clear from 'assets/images/clear.svg';
import { useNavigate } from 'react-router-dom';


const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    px: '46px',
    paddingTop: '40px',
    background: theme?.palette?.background?.paper
  },
  libraryHeader: {
    fontFamily: 'Museo Sans Rounded',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '24px',
    color: theme.palette.grayScale[500],
  },
  boxContainer: {
    width: '100%',
    height: 'calc(100vh - 74px)',
    pt: 4,
    display: 'flex',
    flexDirection: 'row',
  },
  filterContainer: {
    width: '222px',
    height: '100%'
  },
  rightContainer: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  loading: {
    position: 'absolute',
    top: 'calc(50% - 80px)',
    right: 'calc(50% - 42px)',
    color: theme?.palette?.tertiary[600]
  },
  image: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    mb: 3,
  },
  boxImportFiles: {
    py: 10,
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  importText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '44px',
    textAlign: 'center',
    letterSpacing: '-0.01em',
    color: theme?.palette?.grayScale[300],
    mb: '29px'
  },
  createPathwayButtonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  createPathwayButton: {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '18px',
    textTransform: 'none',
    lineHeight: 'normal'    
  },
  listContainer: {
    width: '100%',
    backgroundColor: '#F9FAFC',
    borderRadius: '20px',
    px: '22px',
    pt: '4px',
    pb: '20px',
    mt: '14px',
    overflow: 'auto',
    flex: 1
  },
  paginationContainer: {
    height: '78px',
    pt: '15px',
    pr: '22px',
    '& .MuiPaginationItem-root.Mui-selected': {
      backgroundColor: theme?.palette?.primary[500],
      color: theme?.palette?.info?.contrastText
    },
  },
  sortBtn: {
    p: '12px 20px',
    height: '36px',
    border: `2px solid ${theme.palette.tertiary[600]}`,
    '&:hover': {
      background: '#ffffff',
    }
  },
  sortBtnText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    textTransform: 'none',
    pl: 1,
    color: theme.palette.grayScale[500]
  },
  sortModalContainer: {
    zIndex: 2,
    position: 'absolute',
    top: '36px',
  },
  filterInnerContainer: {
    height: 'calc(100vh - 140px)', overflow: 'auto'
  },
  clearBtn: {
    ...theme?.typography?.Components?.button?.small,
    textTransform: 'none',
    color: theme?.palette?.primary[500],
  },
  searchFieldStyle: {
    '& .MuiOutlinedInput-root': {
      p: '7px 12px 7px 16px',
    },
    '& .MuiTextField-root .MuiInputBase-root .MuiInputBase-input': {
      pl: '10px',
    },
    '& .MuiOutlinedInput-input': {
      fontFamily: 'Nunito',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '22px',
      color: theme?.palette?.grayScale?.[700],
      "&::placeholder": {
        color: theme?.palette?.grayScale?.[700],
        opacity: '1'
      },
      ":-ms-input-placeholder ::-ms-input-placeholder": {
        color: theme?.palette?.grayScale?.[700],
        opacity: '1'
      },
    },
    '& .MuiInputAdornment-root': {
      mr: '0.5px'
    },
    '& .MuiTextField-root': {
      borderColor: theme?.palette?.grayScale?.[300],
      borderRadius: "6px",
      '& .MuiOutlinedInput-notchedOutline': {
        p: 0,
        borderColor: theme?.palette?.grayScale?.[300],
        borderRadius: "6px",
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.palette?.grayScale?.[300],
        borderRadius: "6px",
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.palette?.grayScale?.[300],
        borderRadius: "6px",
      },
    },
  },
  searchFieldInputStyle: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "6px",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "6px",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "6px",
      },
    }
  }
}

const PAGE_SIZES = [5, 15, 30];

const Library = () => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const headers = getDefaultHeaders(getToken());
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [openSortModal, setSortModalState] = useState(false);
  const [isAscending, setIsAscending] = useState<boolean>(true);
  const [selSortType, setSelSortType] = useState<ISortFieldTypes>(ISortFieldTypes.PathwayDate);
  const [list, setList] = useState<IPublishedModuleCardDataProps[]>([]);
  const [selectedSkillData, setSelectedSkillData] = useState<any[]>([]);
  const [unSelectedSkillData, setUnSelectedSkillData] = useState<any[]>([]);
  const [isVisibleSkillFlag, setIsVisibleSkillFlag] = useState<boolean>(false);
  const [viewMoreSkillsFlag, setViewMoreSkillsFlag] = useState<boolean>(true);
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const [clearFilters, setClearFliters] = useState(false);
  const [skillLevelData, setSkillLevelData] = useState<any[]>([]);
  const [outcomeData, setOutcomeData] = useState<any[]>([]);


  const getPublishedPathways = useQuery('publishedPathways', async () => {
    const response = await axiosInstance().get<IPublishedModuleCardDataProps[]>('/contentcreator/pathways?status=published', {
      headers: {
        "Authorization": headers.Authorization,
        "Content-Type": "application/json"
      },
      data: {},
    });
    return response.data;
  }, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (getPublishedPathways?.data?.length) {
      setList(getPublishedPathways.data);
      setFilteredList(getPublishedPathways.data);
      let skillDataArr: any[] = [];
      getPublishedPathways?.data?.forEach((course: any) => {
        course?.pathwaySkills?.map((skill: any) => {
          skillDataArr.push({
            skillName: skill.data.name,
            skillId: skill.data.skillId,
            isChecked: false,
            isVisible: skillDataArr.length < 5 ? true : false,
          })
          return skillDataArr
        })
      })
      let uniqueSkillData = skillDataArr.filter((value, index, self) =>
        self.findIndex(v => v.skillId === value.skillId) === index
      );
      setUnSelectedSkillData(uniqueSkillData)
    }
  }, [getPublishedPathways?.data, clearFilters]);

  const sortedPathwayList = useMemo<IPublishedModuleCardDataProps[]>(() => {
    return filteredList?.length ? [...filteredList].sort((a, b) => {
      let fieldA: any, fieldB: any;
      switch (selSortType) {
        case ISortFieldTypes.PathwayDate:
          fieldA = new Date(a.pathway.data.createDate).getTime();
          fieldB = new Date(b.pathway.data.createDate).getTime();
          break;
        default:
          fieldA = a.pathway.data.title.toLowerCase();
          fieldB = b.pathway.data.title.toLowerCase();
          break;
      }

      if (ISortFieldTypes.PathwayDate === selSortType) {
        if (!isAscending)
          return fieldA - fieldB;
        else
          return fieldB - fieldA;
      } else {
        if (isAscending)
          return fieldA < fieldB ? -1 : Number(fieldA < fieldB);
        else
          return fieldA > fieldB ? -1 : Number(fieldA > fieldB);
      }
    }) : [];
  }, [getPublishedPathways.data, isAscending, selSortType, filteredList]);


  useEffect(() => {
    if (getPublishedPathways?.data?.length) {
      const skillDetails: any[] = [];
      getPublishedPathways?.data?.length && getPublishedPathways?.data?.forEach((item: any) => {
        item?.pathwaySkills?.map((skill: any) => {
          skillDetails.push({
            outcome: skill?.data.outcome,
            level: skill?.data.level,
            isSkillLevelChecked: false,
            isOutcomeChecked: false
          })
          return skillDetails
        })
      })
      let uniqueSkillLevelData = skillDetails.filter((value, index, self) =>
        self.findIndex(v => v.level === value.level) === index
      );

      let uniqueOutcome = skillDetails.filter((value, index, self) =>
        self.findIndex(v => v.outcome === value.outcome) === index
      );
      setSkillLevelData(uniqueSkillLevelData)
      setOutcomeData(uniqueOutcome)
    }

  }, [getPublishedPathways?.data, clearFilters,])


  useEffect(() => {
    if (unSelectedSkillData?.length) {
      let data = unSelectedSkillData.map((skill, index) => {
        if (index < 4) {
          skill.isVisible = true;
        } else {
          skill.isVisible = false;
        }
        return skill
      })
      setUnSelectedSkillData(data)
      setIsVisibleSkillFlag(false)
    }
  }, [isVisibleSkillFlag])
  const handlePageChange = useCallback((__: any, value: number) => {
    setCurrentPage(value);
  }, []);

  const handlePageSizeChange = useCallback((value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  }, []);

  const handleCloseSortModal = useCallback(() => {
    setSortModalState(false);
  }, [])

  const onClickSortModal = useCallback(() => {
    setSortModalState(!openSortModal);
  }, [openSortModal])

  const hideList = useMemo<boolean>(() => getPublishedPathways.isLoading || !getPublishedPathways.data?.length, [getPublishedPathways]);
  const setFilterData = (dataArr: any, data: any, flag: boolean, checkCondition: Function) => {
    let dataClone = [...data]
    let findIndex = dataArr.findIndex((item: any) => {
      return checkCondition(item)
    })
    if (findIndex > -1) {
      let filteredItem = dataArr.splice(findIndex, 1)[0];
      filteredItem.isChecked = flag;
      dataClone.push(filteredItem)
    }
    return dataClone
  }

  const filterData = (skills: any) => {
    const selectedSkillIds = skills.map((selectedSkill: any) => { return selectedSkill.skillId });
    const filteredListBySkill = list?.filter((course) => {
      return course?.pathwaySkills?.some((skill) => selectedSkillIds.includes(skill.data.skillId))
    }) || [];

    if (skills.length === 0) {
      setFilteredList(list)
      setCurrentPage(1)
    } else if (skills.length !== 0) {
      setFilteredList(filteredListBySkill)
      setCurrentPage(1)
    } else {
      let filteredLevelData = skillLevelData.filter((item) => item.isSkillLevelChecked).map((item: any) => item.level) || []
      const filteredListByLevel = list?.filter((course) => {
        return course?.pathwaySkills?.some((skill: any) => filteredLevelData.includes(skill.data.level))
      }) || [];

      let filteredOutcome = outcomeData.filter((item) => item.isOutcomeChecked).map((items: any) => items.outcome) || []
      const filteredListByOutcome = list?.filter((course) => {
        return course?.pathwaySkills?.some((skill: any) => filteredOutcome.includes(skill.data.outcome))
      }) || [];

      let uniqueFilteredData = [...filteredListBySkill, ...filteredListByLevel, ...filteredListByOutcome].filter((value, index, self) =>
        self.findIndex(v => v.pathway.header.id === value.pathway.header.id) === index
      );
      setFilteredList(uniqueFilteredData)
      setCurrentPage(1)
    }
  }

  const handleOutcomeAndLevelFilter = (selectedData: string, type: string) => {
    let filteredListByOutcome: Array<any> = [];
    let filteredListByLevel: Array<any> = [];

    const selectedSkillIds = selectedSkillData.map((selectedSkill: any) => { return selectedSkill.skillId });
    const filteredListBySkill = list?.filter((course) => {
      return course?.pathwaySkills?.some((skill) => selectedSkillIds.includes(skill.data.skillId))
    }) || [];

    if (type === 'outcome') {
      setOutcomeData(prv => prv.map(item => {
        return item.outcome === selectedData ? ({ ...item, isOutcomeChecked: !item.isOutcomeChecked }) : ({ ...item })
      }))

      let filteredOutcome = outcomeData.filter((item) => (item.outcome === selectedData && !item.isOutcomeChecked)
        || (item.outcome !== selectedData && item.isOutcomeChecked)).map((items: any) => items.outcome) || []
      filteredListByOutcome = list?.filter((course) => {
        return course?.pathwaySkills?.some((skill: any) => filteredOutcome.includes(skill.data.outcome))
      }) || [];

      let filteredLevelData = skillLevelData.filter((item) => item.isSkillLevelChecked).map((item: any) => item.level) || []
      filteredListByLevel = list?.filter((course) => {
        return course?.pathwaySkills?.some((skill: any) => filteredLevelData.includes(skill.data.level))
      }) || [];

    } else {
      setSkillLevelData(prv => prv.map(item => {
        return item.level === selectedData ? ({ ...item, isSkillLevelChecked: !item.isSkillLevelChecked }) : ({ ...item })
      }))

      let filteredLevelData = skillLevelData.filter((item) => (item.level === selectedData && !item.isSkillLevelChecked)
        || (item.level !== selectedData && item.isSkillLevelChecked)).map((item: any) => item.level) || []
      filteredListByLevel = list?.filter((course) => {
        return course?.pathwaySkills?.some((skill: any) => filteredLevelData.includes(skill.data.level))
      }) || [];

      let filteredOutcome = outcomeData.filter((item) => item.isOutcomeChecked).map((items: any) => items.outcome) || []
      filteredListByOutcome = list?.filter((course) => {
        return course?.pathwaySkills?.some((skill: any) => filteredOutcome.includes(skill.data.outcome))
      }) || [];

    }

    let uniqueFilteredData: Array<any> = [];
    uniqueFilteredData = [...filteredListBySkill, ...filteredListByOutcome, ...filteredListByLevel].filter((value, index, self) =>
      self.findIndex(v => v.pathway.header.id === value.pathway.header.id) === index
    );

    if (selectedSkillData.length === 0 && filteredListByOutcome.length === 0 && filteredListByLevel.length === 0) {
      setFilteredList(list)
    } else {
      setFilteredList(uniqueFilteredData)
    }

    setCurrentPage(1)
  }

  const handleSkillsFilter = (selectedSkillId: string, mode: string) => {
    if (mode === 'UnSelectedData') {
      let data: any[] = [...selectedSkillData];
      data = setFilterData(unSelectedSkillData, data, true, (item: any) => item.skillId === selectedSkillId)
      setUnSelectedSkillData(unSelectedSkillData)
      setSelectedSkillData(data)
      filterData(data);
    } else {
      let data: any[] = [...unSelectedSkillData];
      data = setFilterData(selectedSkillData, data, false, (item: any) => item.skillId === selectedSkillId)
      setSelectedSkillData(selectedSkillData)
      setUnSelectedSkillData(data)
      filterData(selectedSkillData);
    }
  }

  const handleClearFilters = () => {
    setSelectedSkillData([]);
    setClearFliters(prev => !prev);
  }

  const onViewMoreSkills = () => {
    if (viewMoreSkillsFlag) {
      let data = unSelectedSkillData.map((skill, index) => {
        skill.isVisible = true;
        return skill
      })
      setUnSelectedSkillData(data)
      setViewMoreSkillsFlag(false)
    } else {
      setIsVisibleSkillFlag(true)
      setViewMoreSkillsFlag(true)
    }
  }

  return (
    <Box sx={styles.container}>
      <Typography sx={styles.libraryHeader}>Pathway Library</Typography>
      <Grid sx={styles.boxContainer}>
        {!hideList && <Grid item>
          {/* filter code should be inside this container  */}
          <Box sx={styles.filterContainer}>
            <Box display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} mr={2}>
              <Box display={'flex'} alignItems={'center'}>
                <img src={filterImage} alt="filterIcon" width={32} height={21} />
                <Typography sx={{ ...theme?.typography?.h2, ml: '4px', fontSize: '24px' }}>Filters</Typography>
              </Box>
              <Box>
                <Button variant='text' sx={styles.clearBtn} onClick={handleClearFilters}>
                  <img src={clear} alt='clear' />
                  <Typography sx={{ fontWeight: 700, marginLeft: '2px', fontFamily: 'Nunito', fontSize: '14px' }}>Clear</Typography>
                </Button>
              </Box>
            </Box>
            <Box display={'flex'} flexDirection={'column'} className={'customScroll'} sx={styles.filterInnerContainer}>
              <Box mt={'20px'}>
                <Typography sx={{ ...theme?.typography?.h3 }}>Skills</Typography>
              </Box>
              <Box mt={'12px'} width={'189px'} position={'relative'}>
                {unSelectedSkillData.length ? <SearchField
                  placeholder='Search skills'
                  height='38px'
                  width='100%'
                  disablePortal
                  options={unSelectedSkillData}
                  sx={styles.searchFieldStyle}
                  inputStyle={styles.searchFieldInputStyle}
                  keyName={'skillId'}
                  optionKey={'skillName'}
                  optionLabel={(option: any) => option.skillName}
                  onChange={(e: any, selectedSkill: any) => handleSkillsFilter(selectedSkill.skillId, 'UnSelectedData')}

                /> : ''}
                <Box mt={'10px'}>
                  <Box>
                    {Children.toArray(
                      selectedSkillData.map((skill) => {
                        return (
                          <Checkbox
                            label={skill.skillName}
                            checkedColor='#3FB3B0'
                            unCheckedColor='#5D6A71'
                            value={skill.isChecked}
                            inputStyleProps={{ ...theme?.typography?.body?.default, ...theme?.typography?.body?.body1, ...theme?.typography?.body?.regular, color: theme?.palette?.grayScale[500] }}
                            onChange={() => handleSkillsFilter(skill.skillId, 'SelectedData')}
                          />
                        )
                      })
                    )}
                  </Box>
                  {selectedSkillData.length ? <Divider sx={{ mt: '10px', bgcolor: "#ADB3BD" }} /> : ""}
                  {Children.toArray(
                    unSelectedSkillData.map((skill) => {
                      if (skill.isVisible) {
                        return (
                          <Checkbox
                            label={skill.skillName}
                            checkedColor="#3FB3B0"
                            unCheckedColor="#5D6A71"
                            value={skill.isChecked}
                            inputStyleProps={{
                              ...theme?.typography?.body?.default,
                              ...theme?.typography?.body?.body1,
                              ...theme?.typography?.body?.regular,
                              color: theme?.palette?.grayScale[500],
                            }}
                            onChange={() => handleSkillsFilter(skill.skillId, 'UnSelectedData')}
                          />
                        )
                      }
                    })
                  )}
                  {unSelectedSkillData.length < 4 ? "" : <Box mt={'4px'}>
                    <Divider sx={{
                      "&::before, &::after": {
                        borderColor: "#26C5D2",
                      },
                    }}>
                      <Button variant='text' sx={{ ...theme?.typography?.Components?.button?.default, ...theme?.typography?.Components?.button?.large, color: theme?.palette?.primary[500], padding: '0px', textTransform: 'none' }} onClick={onViewMoreSkills}>{viewMoreSkillsFlag ? 'View More' : 'View Less'}</Button>
                    </Divider>
                  </Box>}
                </Box>
              </Box>
              <Box mt={'20px'}>
                <Typography sx={{ ...theme?.typography?.h3 }}>Starting Skill Level</Typography>
              </Box>
              <Box mt={'10px'}>
                <Box>
                  {Children.toArray(
                    skillLevelData.map((skill) => {
                      return (
                        <Checkbox
                          label={skill.level}
                          checkedColor='#3FB3B0'
                          unCheckedColor='#5D6A71'
                          value={skill.isSkillLevelChecked}
                          inputStyleProps={{ ...theme?.typography?.body?.default, ...theme?.typography?.body?.body1, ...theme?.typography?.body?.regular, color: theme?.palette?.grayScale[500] }}
                          onChange={() => handleOutcomeAndLevelFilter(skill.level, 'level')}
                        />
                      )
                    })
                  )}
                </Box>
              </Box>
              <Box mt={'20px'}>
                <Typography sx={{ ...theme?.typography?.h3 }}>Skill Outcome </Typography>
              </Box>
              <Box mt={'10px'}>
                <Box>
                  {Children.toArray(
                    outcomeData.map((skill) => {
                      return (
                        <Checkbox
                          label={skill.outcome}
                          checkedColor='#3FB3B0'
                          unCheckedColor='#5D6A71'
                          value={skill.isOutcomeChecked}
                          inputStyleProps={{ ...theme?.typography?.body?.default, ...theme?.typography?.body?.body1, ...theme?.typography?.body?.regular, color: theme?.palette?.grayScale[500] }}
                          onChange={() => handleOutcomeAndLevelFilter(skill.outcome, 'outcome')}
                        />
                      )
                    })
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>}
        <Grid item width={'100%'}>
          <Box sx={styles.rightContainer}>
            {/* sort button and chip code be inside this container */}
            {!hideList && <Box display={'flex'} width={'100%'} justifyContent={'space-between'}>
              <Box sx={{ position: 'relative', display: 'flex' }}>
                <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleCloseSortModal}>
                  <Box>
                    <Button
                      variant="outlined"
                      size='medium' sx={styles.sortBtn}
                      onClick={onClickSortModal}>
                      <img src={sortSVG} alt="sortIcon" width={20} height={18} />
                      <Typography component={'span'} sx={styles.sortBtnText}>
                        Sort
                      </Typography>
                    </Button>
                    <Box sx={styles.sortModalContainer}>
                      {openSortModal ? <SortModal isAscending={isAscending} selSortFieldType={selSortType} setIsAscending={setIsAscending} setSelSortFieldType={setSelSortType} /> : null}
                    </Box>
                  </Box>
                </ClickAwayListener>
              </Box>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
              }}>
                <Typography sx={{
                  mr: '11px',
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body2,
                  ...theme?.typography?.body?.semibold,
                }} >Show:</Typography>
                <PaginationItem selectedValue={pageSize} data={PAGE_SIZES} onItemClick={handlePageSizeChange} />
              </Box>
            </Box>}
            <Box sx={styles.listContainer} className='customScroll'>
              {(hideList) ?
                <Box sx={styles.boxImportFiles}>
                  <Box sx={styles.image}>
                    <img src={DraftsImageSVG} alt="add_icon" />
                  </Box>
                  <Box>
                    <Typography sx={styles.importText}>
                      You don't have anything yet.
                      <br />Let's get started!
                    </Typography>
                  </Box>
                  <Box sx={styles.createPathwayButtonBox}>
                    <Button
                      sx={{ width: '250px', height: '50px' }}
                      variant="contained"
                      color='primary'
                      size="medium"
                      onClick={() => navigate('/addNewPathway')}
                    >
                      <Typography sx={{
                        ...theme?.typography?.Components?.button?.default,
                        ...theme?.typography?.Components?.button?.medium,
                        ...styles.createPathwayButton
                      }}>Create Pathway</Typography>
                    </Button>
                  </Box>
                  {getPublishedPathways.isLoading && <CircularProgress size={60} sx={styles.loading} />}
                </Box>
                : <PublishedModulesList data={getCurrentPageList((currentPage - 1), sortedPathwayList, pageSize)} />}
            </Box>
            <Box sx={styles.paginationContainer} display='flex' justifyContent={'end'}>
              {!hideList && <Pagination page={currentPage} count={getTotalPageCount(sortedPathwayList.length, pageSize)} siblingCount={1} boundaryCount={2} onChange={handlePageChange} />}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Library;
