import { PageHeader } from "components/page-header/PageHeader";
import React from "react";

export const Admin = () => {
  return (
    <>
      <PageHeader title="Admin Tools" />
      <div>Admin Stuff here</div>
    </>
  );
};
