import { Box, Button, ClickAwayListener, Typography } from '@mui/material'
import { godzilla as theme } from "util/themes/godzilla";
import { Button as ControlBtn } from "core/components";

interface IPublishModalProps {
  setOpenPublishConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  publishPathway: ()=> void;
}

const styles = {
  boxStyle: {
    width: "318px",
    height: "auto",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    px: '20px',
    pt: '25px'
  },
  textStyle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: theme?.palette?.grayScale[700],
  },
  yesTextButtonStyle: {
    background: theme?.palette?.primary[600],
    borderRadius: "100px",
    padding: "12px 20px",
    gap: "8px",
  },
  noTextStyle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "150%",
    color: theme?.palette?.primary[600],
    textTransform: "none"
  },
  buttonTextStyle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "19px",
    color: "#FFFFFF",
  },
};

export const PublishModal = (props: IPublishModalProps) => {

  const { setOpenPublishConfirmModal, publishPathway } = props;

  const onClosePublishModal = () => {
    setOpenPublishConfirmModal(false)
  };

  const onPublishPathway = () => {
    publishPathway();
  };

  return (
    <ClickAwayListener onClickAway={onClosePublishModal}>
      <Box sx={styles.boxStyle}>
        <Typography sx={styles.textStyle}>
          Once you publish, you will not be able to make changes to the pathway.
          Are you sure you’re ready to publish?
        </Typography>
        <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} mt={"9px"}>
          <Box display={"flex"} justifyContent={"center"}>
            <ControlBtn
              variant="contained"
              color="primary"
              size="medium"
              sx={styles.yesTextButtonStyle}
              onClick={onPublishPathway}
            >
              <Typography
                sx={{
                  ...theme?.typography?.Components?.button?.default,
                  ...theme?.typography?.Components?.button?.small,
                  ...styles.buttonTextStyle,
                  textTransform: "none",
                }}
              >
                Yes, publish.
              </Typography>
            </ControlBtn>
          </Box>
          <Box display={"flex"} justifyContent={"center"} mt={"12px"} pb={"25px"}>
            <Button
              sx={styles.noTextStyle}
              onClick={onClosePublishModal}
            >
              No, continue reviewing my module(s).
            </Button>
          </Box>
        </Box>
      </Box>
    </ClickAwayListener>
  )
}
