import { Box, Typography, ClickAwayListener } from "@mui/material";
import { useState } from "react";
import Pencil_Icon from "assets/images/PathwayCreator/Pencil_icon.svg";
import Delete_Icon from "assets/images/PathwayCreator/Delete_icon.svg";
import { godzilla as theme } from "util/themes/godzilla";
import { IEditModal } from "../outline.helper";
import { Button as ControlBtn } from "core/components";

const styles = {
  deleteButtonStyle: {
    padding: "12px 20px",
    gap: "8px",
    background: theme?.palette?.error?.dark,
    borderRadius: "100px",
  },
  deleteButtonTextStyle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "-0.01em",
    color: "#FFFFFF",
  },
  notDeleteButtonTextStyle: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "150%",
    color: theme?.palette?.primary[500],
  },
};

const EditModal = (props: IEditModal) => {
  const {
    isEdit,
    setIsEdit,
    setData,
    index = 0,
    data = [],
    setDeleteData,
    onMenuOpen,
    setOnMenuOpen,
    isDeleteDoubleConfirmModal,
    setSelectedIndex,
    onHandleEmptyStateDelete,
    onDeleteItem
  } = props;
  const [onDelete, setOnDelete] = useState<boolean>(false);
  
  const handleClose = () => {
    setOnMenuOpen && setOnMenuOpen(false);
    setOnDelete(false);
    setSelectedIndex && setSelectedIndex(null);
  };
  const onDeleteMilestone = () => {
    onDeleteItem && onDeleteItem(index)
    setOnMenuOpen && setOnMenuOpen(false);
    setOnDelete(false);
    setSelectedIndex && setSelectedIndex(null);
  };

  const handleDeleteFunction = () => {
    if (!isDeleteDoubleConfirmModal) {
      if(onHandleEmptyStateDelete){
        onHandleEmptyStateDelete()
      }else{
        onDeleteMilestone()
      }
    } else {
      setTimeout((): void => {
        setOnDelete(true);
      }, 50);
      handleClose();
    }
  }

  return (
    <>
      {onDelete && isDeleteDoubleConfirmModal ? (
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            padding={"17px 14px 17px 14px"}
            boxShadow={
              "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)"
            }
            borderRadius={"10px"}
            width={"235px"}
            height={"auto"}
            sx={{ backgroundColor: "#FFFFFF" }}
            ml={"-226px"}
            mt={"-23px"}
            position={"absolute"}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              flexDirection={"column"}
            >
              <Box>
                <Typography
                  sx={{
                    ...theme?.typography?.body?.default,
                    ...theme?.typography?.body?.body2,
                    ...theme?.typography?.body?.medium,
                    color: theme?.palette?.grayScale[700],
                    textAlign: "center",
                  }}
                >
                  Deleting this milestone will remove all milestone details. Are
                  you sure you want to delete this milestone?
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"center"} mt={"16px"}>
                <ControlBtn
                  variant="contained"
                  color="error"
                  size="small"
                  sx={styles.deleteButtonStyle}
                  onClick={onDeleteMilestone}
                >
                  <Typography
                    sx={{
                      ...styles.deleteButtonTextStyle,
                      textTransform: "none",
                    }}
                  >
                    Yes, delete
                  </Typography>
                </ControlBtn>
              </Box>
              <Box display={"flex"} justifyContent={"center"} mt={"9px"}>
                <ControlBtn
                  variant="text"
                  color="primary"
                  size="small"
                  onClick={handleClose}
                >
                  <Typography
                    sx={{
                      ...styles.notDeleteButtonTextStyle,
                      textTransform: "none",
                    }}
                  >
                    No, keep the milestone
                  </Typography>
                </ControlBtn>
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>
      ) : null}
      {onMenuOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"146px"}
            height={"auto"}
            boxShadow={
              "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)"
            }
            borderRadius={"10px"}
            position={"absolute"}
            sx={{ backgroundColor: "#FFFFFF" }}
            ml={"-136px"}
            mt={"-24px"}
          >
            {isEdit ? null : (
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                onClick={() => {
                  setTimeout((): void => {
                    setIsEdit && setIsEdit(true);
                  }, 50);
                  handleClose();
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "#E9EAFF",
                  },
                  mt: "11px",
                  height: "32px",
                  pl: '26px',
                  cursor: 'pointer'
                }}
              >
                <Box>
                  <img src={Pencil_Icon} alt="ICON" />
                </Box>
                <Box ml={"14px"}>
                  <Typography
                    sx={{
                      ...theme?.typography?.body?.default,
                      ...theme?.typography?.body?.body1,
                      ...theme?.typography?.body?.regular,
                      color: "#2C3442",
                    }}
                  >
                    Rename
                  </Typography>
                </Box>
              </Box>
            )}
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              onClick={handleDeleteFunction}
              sx={{
                "&:hover": {
                  backgroundColor: "#E9EAFF",
                },
                mb: `${!isEdit ? "14px" : "10px"}`,
                mt: `${!isEdit ? "2px" : "10px"}`,
                height: "32px",
                pl: '26px',
                cursor: 'pointer'
              }}
            >
              <Box>
                <img src={Delete_Icon} alt="ICON" />
              </Box>
              <Box ml={"15.5px"}>
                <Typography
                  sx={{
                    ...theme?.typography?.body?.default,
                    ...theme?.typography?.body?.body1,
                    ...theme?.typography?.body?.regular,
                    color: "#2C3442",
                  }}
                >
                  Delete
                </Typography>
              </Box>
            </Box>
          </Box>
        </ClickAwayListener>
      )}
    </>
  );
};

export default EditModal;
