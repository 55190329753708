import React from "react";
import ReactDOM from "react-dom/client";

import "./index.css";
import { AppContainer } from "./AppContainer";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { AuthContextProvider } from "./core/contexts";
import { AppProvider } from 'components/app-context/appContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // NOTE: I had to remove StrictMode for now, issue explained here
  // https://github.com/react-keycloak/react-keycloak/issues/182
  // <React.StrictMode>
  <QueryClientProvider client={new QueryClient()}>
    <AuthContextProvider>
      <AppProvider>
        <BrowserRouter>
          <AppContainer />
        </BrowserRouter>
      </AppProvider>
    </AuthContextProvider>
    <ReactQueryDevtools />
  </QueryClientProvider>
  
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
