import { Children } from "react";
import { Grid, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IPublishedModuleCardDataProps } from "pages/drafts/DraftsModule/types";
import { godzilla as theme } from 'util/themes/godzilla';
import { Checkbox } from "core/components";
import TagIcon from 'assets/images/tag.svg';
import { PageList } from "./PageList";

const styles = {
    itemContainer: (isSelected: boolean) => ({
        border: `2px solid ${isSelected ? theme?.palette?.primary?.[500] : '#C6CAD1'}`,
        borderRadius: '8px',
        p: '17px',
        pr: isSelected ? '16px' : '20px',
        pb: isSelected ? '26px' : '17px',
        mb: '12px',
        display: 'flex',
        flexDirection: 'column'
    }),
    titleStyle: {
        fontFamily: 'Museo Sans Rounded',
        fontStyle: 'normal',
        fontWeight: 400,
        color: '#3D4655'
    },
    subtitleStyle: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
        color: '#3D4655',
        mt: '6px'
    },
    skillTagContainer: {
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        mt: '11px',
        alignItems: 'center'
    },
    skillTagStyle: {
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '18px',
        color: '#7C45DD',
        border: '1px solid #7C45DD',
        borderRadius: '100px',
        px: '10px',
        py: '3px',
        mx: '4.5px'
    },
    checkboxStyle: {
        flex: 1,
        height: '26px',
        '& .MuiCheckbox-root': {
            '&:hover': { background: 'rgba(0, 173, 187, 0.08)' },
        },
        '& .MuiTouchRipple-root span': {
            background: 'rgba(0, 173, 187, 0.3)'
        },
    },
    checkboxInputStyle: {
        ...theme?.typography?.body?.default,
        ...theme?.typography?.body?.body1,
        ...theme?.typography?.body?.regular,
        fontWeight: 700,
        color: theme?.palette?.grayScale?.[700]
    },
    list_expand_icon: {
        open: {
            transform: 'rotate(-180deg)'
        }
    },
    expandIconStyle: (open: boolean) => ({
        display: 'flex',
        cursor: 'pointer',
        color: theme.palette.primary[500],
        transition: 'ease 0.2s',
        ...(!open && styles.list_expand_icon.open),
        '&:hover': {
            backgroundColor: '#D6E5FF',
            borderRadius: '50%'
        }
    }),
}

export type IModuleItem = IPublishedModuleCardDataProps & { isChecked?: boolean };

interface IModuleItemProps {
    item: IModuleItem,
    isSelected: boolean,
    onSelectItem: (moduleId: string) => void,
    onChangeItem: (item: IModuleItem) => void
}

const ModuleItem = (props: IModuleItemProps) => {
    const { item, isSelected, onSelectItem, onChangeItem } = props;
    return (
        <Grid sx={styles.itemContainer(isSelected)}>
            <Grid display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Checkbox
                    label={item.title}
                    checkedColor={theme?.palette?.tertiary?.[600]}
                    unCheckedColor={theme?.palette?.grayScale?.[500]}
                    sx={styles.checkboxStyle}
                    inputStyleProps={styles.checkboxInputStyle}
                    onClick={(e: any) => e.stopPropagation()}
                    onChange={() => {
                        onChangeItem({ ...item, isChecked: !item.isChecked })
                    }}
                    value={item.isChecked} />
                <ExpandMoreIcon
                    width={24}
                    height={24}
                    onClick={() => onSelectItem(item.id)}
                    sx={styles.expandIconStyle(isSelected)}
                />
            </Grid>

            {isSelected && <Grid sx={{ ml: '35.64px' }}>
                <PageList moduleId={item.id} />
                <Grid sx={styles.skillTagContainer}>
                    <Grid sx={{display: 'flex', flexDirection:'row', alignItems: 'center'}}>
                        <img src={TagIcon} width={14.2} height={14.2} alt='' />
                        <Typography sx={{
                            ...theme?.typography?.body?.default,
                            ...theme?.typography?.body?.body1,
                            color: theme?.palette?.grayScale?.[700],
                            fontWeight: 700,
                            ml: '4.42px', 
                            mr: '3.5px'
                            }} variant="h5">Tags:</Typography>
                    </Grid>
                    {item?.skills?.length && Children.toArray(item?.skills?.slice(0, 3).map((skill) => (
                        <Typography variant="subtitle2" sx={styles.skillTagStyle}>{skill.skillName}</Typography>
                    )))}
                </Grid>
            </Grid>}
        </Grid>
    );
}

export default ModuleItem;
