import { Typography, Box, Button } from '@mui/material';
import { godzilla as theme } from 'util/themes/godzilla';
import { Button as ControlBtn } from 'core/components';
import { useMutation } from 'react-query';
import { getDefaultHeaders, instance as axiosInstance } from 'core/util';
import { useAuth } from 'core/contexts';
import { IPathwayModuleCardDataProps } from 'pages/drafts/DraftsModule/types';

const styles = {
  cancelUploadBox: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    px: '22px',
    pt: '14px',
    pb: '16px',
    width: '270px',
    cursor: 'initial'
  },
  cancelText: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    mb: '13px',
    color: theme?.palette?.grayScale[700],
  },
  cancelYesButton: {
    borderRadius: '100px',
    padding: '4px 21px',
    mb: '6px',
    gap: '8px',
  },
  removeBtnText: {
    fontStyle: 'normal',
    textAlign: 'center',
    textTransform: 'none',
    color: theme?.palette?.error?.contrastText
  },
  cancelNoButton: {
    borderRadius: '100px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    color: theme?.palette?.primary[500],
    textTransform: 'none',
    px: 2.5,
    '&:hover': { background: 'rgba(0, 61, 166, 0.04)' },
    '> .MuiTouchRipple-root span': {
      background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(122, 132, 255, 0.7) 99.54%, rgba(255, 255, 255, 0) 100%)'
    },
  },
}

interface IHandleCloseDeleteModalProps {
  handleCloseDeleteModal: () => void,
  pathwayData: IPathwayModuleCardDataProps[],
  removePathwayFromList: (pathwayId: string) => void,
  setIsDeleting?: (flag: boolean) => void
}

export default function DeleteModal({ handleCloseDeleteModal, pathwayData, removePathwayFromList, setIsDeleting }: IHandleCloseDeleteModalProps) {
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());

  const deletePathway = useMutation((pathway: IPathwayModuleCardDataProps) => {
    handleCloseDeleteModal();
    return axiosInstance().delete(`contentcreator/pathways/${pathway.header.id}`, {
      headers: {
        'Authorization': headers.Authorization,
        "Content-Type": "application/json"
      },
      data: {}
    })
  }, {
    onSuccess: (_, pathway) => {
      removePathwayFromList(pathway.header.id);
    },
    onError: (error, pathway) => {
      removePathwayFromList(pathway.header.id);
      throw new Error('There is some issue in deleting pathway');
    },
  });

  const removePathway = () => {
    setIsDeleting && setIsDeleting(true);
    if (pathwayData.length) {
      pathwayData.forEach(pathway => {
        deletePathway.mutate(pathway);
      });
    }
  }

  const modalContextConfig = {
    title: 'Are you sure you want to delete the selected pathways?',
    yesBtnText: 'Yes, delete',
    noBtnText: `No, don't delete`
  }

  return (
    <Box sx={styles.cancelUploadBox} onClick={(e) => e.stopPropagation()}>
      <Typography sx={{ ...theme?.typography?.body?.body1, ...styles.cancelText }}>{modalContextConfig.title}</Typography>
      <ControlBtn
        variant='contained'
        color='error'
        size='medium'
        sx={styles.cancelYesButton}
        onClick={removePathway}
      >
        <Typography sx={{
          ...theme?.typography?.Components?.button?.default,
          ...theme?.typography?.Components?.button?.medium,
          ...theme?.typography?.body?.body2,
          ...styles.removeBtnText
        }}>
          {modalContextConfig.yesBtnText}
        </Typography>
      </ControlBtn>
      <Button sx={{ ...theme?.typography?.body?.body2, ...styles.cancelNoButton }}
        onClick={handleCloseDeleteModal}
      >
        {modalContextConfig.noBtnText}
      </Button>
    </Box>
  )
}