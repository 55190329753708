import { Box, MenuItem, Grid, Typography } from "@mui/material";
import sortAlphAscSVG from "assets/images/sort-alph-asc.svg";
import sortAlphDescSVG from "assets/images/sort-alph-desc.svg";
import sortCalAscSVG from "assets/images/sort-cal-asc.svg";
import sortCalDescSVG from "assets/images/sort-cal-desc.svg";
import checkCircleSVG from "assets/images/checkcircle.svg";
import { useEffect } from "react";
const styles = {
  sortContainer: {
    position: "absolute",
    padding: "17px 0 17px 0",
    minWidth: "263px",
    background: "#FFFFFF",
    boxShadow:
      " 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    zIndex: 1,
  },
  text: {
    fontFamily:"Nunito",
    marginLeft: "6px",
    marginTop:"2px",
  },
  menuItem:{
    '&:hover': {
      backgroundColor: '#E9EAFF'
    },
  }
};

export const SortModulesModal = (props: any) => {
  const { sortModule, setSortModule, modules, setModules } = props;
  const handleDateClick = () => {
    if (sortModule?.dateCreated?.checked) {
      setSortModule({
        ...sortModule,
        dateCreated: {
          ...sortModule.dateCreated,
          asec: !sortModule.dateCreated.asec,
        },
      });
    } else {
      setSortModule({
        dateCreated: { checked: true, asec: true },
        moduleName: { checked: false, asec: true },
      });
    }
  };

  const handleModuleClick = () => {
    if (sortModule?.moduleName?.checked) {
      setSortModule((prevSortModule: any) => ({
        ...prevSortModule,
        moduleName: {
          ...prevSortModule.moduleName,
          asec: !prevSortModule?.moduleName?.asec,
        },
      }));
    } else {
      setSortModule({
        dateCreated: { checked: false, asec: true },
        moduleName: { checked: true, asec: true },
      });
    }
  };

  useEffect(() => {
    let sortedItems = [];
    if (sortModule?.dateCreated?.checked) {
      sortedItems = [...modules].sort((a, b) =>
        sortModule?.dateCreated?.asec
          ? Date.parse(b.createDate) - Date.parse(a.createDate)
          : Date.parse(a.createDate) - Date.parse(b.createDate)
      );
    } else {
      sortedItems = modules.sort((a: { title: string }, b: { title: string }) =>
        sortModule?.moduleName?.asec
          ? a.title.localeCompare(b.title)
          : b.title.localeCompare(a.title)
      );
    }
    setModules([...sortedItems]);
  }, [sortModule]);
  return (
    <Box sx={styles.sortContainer}>
      <MenuItem sx={styles.menuItem} onClick={handleDateClick}>
        <Grid container>
          <Grid item xs={1} display={"flex"} alignItems={"center"}>
            {sortModule?.dateCreated?.checked && (
              <img src={checkCircleSVG} alt="" height={"17px"} width={"17px"} />
            )}
          </Grid>
          <Grid item xs={10} display={'flex'} alignItems={'center'}>
            <Typography sx={{
                ...styles.text,
                fontWeight:sortModule?.dateCreated?.checked && 600,
                }} ml={"3px"}>
              Date Created
            </Typography>
          </Grid>
          <Grid item xs={1} display={"flex"}>
            {sortModule?.dateCreated?.checked && (
              <img
                src={
                  sortModule?.dateCreated?.asec ? sortCalAscSVG : sortCalDescSVG
                }
                alt=""
              />
            )}
          </Grid>
        </Grid>
      </MenuItem>
      <MenuItem sx={styles.menuItem} onClick={handleModuleClick}>
        <Grid container>
          <Grid item xs={1} display={"flex"} alignItems={"center"}>
            {sortModule?.moduleName?.checked && (
              <img src={checkCircleSVG} alt="" height={"18px"} width={"18px"} />
            )}
          </Grid>
          <Grid item xs={10} display={'flex'} alignItems={'center'}>
            <Typography sx={{
                ...styles.text,
                fontWeight:sortModule?.moduleName?.checked && 600,
            }} ml={"3px"}>
              Module Name
            </Typography>
          </Grid>
          <Grid item xs={1} display={"flex"}>
            {sortModule?.moduleName?.checked && (
              <img
                src={
                  sortModule?.moduleName?.asec
                    ? sortAlphAscSVG
                    : sortAlphDescSVG
                }
                alt=""
                height={"17px"}
                width={"17px"}
              />
            )}
          </Grid>
        </Grid>
      </MenuItem>
    </Box>
  );
};
