import { getDefaultHeaders, instance as axiosInstance } from 'core/util';
import { useAuth } from 'core/contexts';
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import ModuleLayout from "../ModuleLayout/ModuleLayout";
import { Editor } from '@tinymce/tinymce-react';
import { useState } from 'react';
import { IPageData } from '../ModuleLayout/moduleLayout.helper';

const ModuleCreator = () => {
    const { getToken } = useAuth();
    const headers = getDefaultHeaders(getToken());
    const params = useParams();
    const [pagesArr, setPagesArr] = useState<Array<IPageData>>([
        {
          title: "Introduction",
          richText: "",
          estimatedTimeToCompleteInMinutes: 0,
        },
      ]);
    const [addNewPage, setAddNewPage] = useState<boolean>(false);
    const [currentSelectedPageIndex, setCurrentSelectedPageIndex] = useState<number>(0);

    const getModuleDetail = useQuery('module', async () => {
        const response = await axiosInstance().get(`/contentcreator/module/${params.id}`, {
            headers: {
                "Authorization": headers.Authorization,
                "Content-Type": "application/json"
            },
            data: {}
        });
        return response.data;
    }, {
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });

    const moduleTitle = getModuleDetail?.data?.data?.data?.title

    const handleEditorChange = (content:any) => {
        let cloneData = [...pagesArr];
        cloneData[currentSelectedPageIndex].richText = content;
        setPagesArr(cloneData);
      };
    
    return (
        <ModuleLayout moduleTitle={moduleTitle} isPageOrganizer isSettings addNewPage={addNewPage} setAddNewPage={setAddNewPage} pagesArr={pagesArr} setPagesArr={setPagesArr}currentSelectedPageIndex={currentSelectedPageIndex} setCurrentSelectedPageIndex={setCurrentSelectedPageIndex}>
            <Editor
                apiKey='waz5aikd34rij8mb42uj9bwna0zr1l3akeo6icekm3wtkohx'
                init={{
                    menubar: 'file edit view insert format tools table help',
                    plugins: [
                        'advlist', 'autolink', 'lists', 'pageembed ','link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount','iframe'
                    ],
                    toolbar: 'undo redo |' +
                        'bold italic underline |  blocks | fontfamily |' +
                        'fontsize | link image media pageembed table | alignleft aligncenter alignright alignjustify | ' +
                        'bullist numlist outdent indent print preview media fullscreen | ' +
                        'forecolor backcolor emoticons | help',
                    toolbar_mode: 'floating',
                    content_style: `body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }`,
                    width: '100%',
                    height: '100%',
                }}
                onEditorChange={handleEditorChange}
                value={pagesArr[currentSelectedPageIndex]?.richText}
            />
        </ModuleLayout >
    )
}

export default ModuleCreator;