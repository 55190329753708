import TextField from "@mui/material/TextField";
import { Autocomplete, InputAdornment, SxProps, Theme } from "@mui/material";
import SearchIcon from "../../../../assets/images/searchicon.svg";
import { godzilla as theme } from "util/themes/godzilla";
import { useState } from "react";
import closeIcon from "assets/images/cancel.svg";

const styles = {
  inputTextStyle: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD2E0",
        borderRadius: "6px",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD2E0",
        borderRadius: "6px",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD2E0",
        borderRadius: "6px",
      },
      "& .MuiInputBase-input": {
        padding: "0px",
        "&::placeholder": {
          opacity: 1,
          ...theme?.typography?.body?.default,
          ...theme?.typography?.body?.body1,
          ...theme?.typography?.body?.light,
          color: "#3D4655",
        },
      },
    },
  },
};

const autocompleteFieldStyle = (width: string, height: string) => ({
  width: width,
  "& .MuiInputBase-root": {
    height: height,
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD2E0",
      borderRadius: "6px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD2E0",
      borderRadius: "6px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD2E0",
      borderRadius: "6px",
    },
    "& .MuiInputBase-input": {
      padding: "0px",
    },
  },
});
interface ISearchFieldProps {
  placeholder: string;
  height: string;
  width: string;
  options: any;
  onChange: any;
  optionLabel: any;
  disablePortal?: boolean;
  keyName?: string;
  optionKey?: string;
  sx?: SxProps<Theme>;
  inputStyle?: SxProps<Theme>;
  listboxStyle?: React.CSSProperties;
  other?: any;
  showCancelIcon?: boolean;
}

export const SearchField = (props: ISearchFieldProps) => {
  const { placeholder, height, width, options, onChange, optionLabel, keyName = '', optionKey = '', disablePortal = false, sx = {}, inputStyle = {}, other, listboxStyle = {}, showCancelIcon = false } = props;
  const [inputValue, setInputValue] = useState("");

  const onSelectOption = (e:any, value:any) => {
    onChange(e, value)
    setInputValue("")
  }

  return (
    <Autocomplete
      disableClearable
      options={options}
      filterSelectedOptions
      disablePortal={disablePortal}
      getOptionLabel={optionLabel}
      ListboxProps={{ style: { maxHeight: 300, overflow: "auto", ...listboxStyle }, className: 'customScroll' }}
      forcePopupIcon={false}
      sx={{ ...autocompleteFieldStyle(width, height), ...sx }}
      renderTags={() => null}
      onChange={onSelectOption}
      inputValue={inputValue}
      {...other}
      renderOption={(props, option: any) => {
        return (
          <li {...props} key={option[keyName]}>
            {option[optionKey]}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          onChange={(e: any)=>setInputValue(e.target.value)}
          sx={{
            ...styles.inputTextStyle,
            backgroundColor: "#FFFFFF",
            ...inputStyle
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                <InputAdornment position="start">
                  <img src={SearchIcon} alt="ICON" />
                </InputAdornment>
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: (
              inputValue && showCancelIcon ? <>
                <InputAdornment position="end">
                  <img style={{cursor: 'pointer'}} src={closeIcon} alt="CLOSE_ICON" onClick={() => setInputValue("")} />
                </InputAdornment>
                {params.InputProps.endAdornment}
              </> : <></>
            ),
          }}
        />
      )}
    />
  );
};
