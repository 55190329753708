import React, { Children } from 'react';
import { Box, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { Button } from 'core/components';
import LifeTrekLogo from "assets/images/LifeTrekLogo.svg"
import { homePageConfig } from './homepageHelper';
import { godzilla as theme } from "util/themes/godzilla";

const styles = {
    container: {
        display: 'flex',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        background: '#FFFFFF'
    },
    header: {
        marginTop: '30px',
        fontFamily: 'Museo Sans Rounded',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '80px',
        lineHeight: '96px',
        color: theme?.palette?.grayScale[700],
    },
    subHeader: {
        marginTop: '10px',
        marginBottom: '80px',
        fontFamily: 'Museo Sans Rounded',
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '40px',
        lineHeight: '48px',
        color: '#505866',
    },
    button: {
        marginTop: '26.86px',
        height: '40px',
        width: '200px'
    },
    buttonText: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '18px',
        lineHeight: '25px',
        color: '#BFFBFF',
        textTransform: 'capitalize'
    },
    iconStyle: {
        gap: '89.52px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row'
    },
    iconBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
}

export const HomePage = () => {
    const navigate = useNavigate();
    return (
        <Grid sx={styles.container} className='container'>
            <Box >
                <img
                    alt="Under development"
                    src={LifeTrekLogo}
                    style={{
                        padding: "8px",
                        height: "93px",
                    }}
                />
            </Box>
            <Typography sx={styles.header}>Welcome Back</Typography>
            <Typography sx={styles.subHeader}>What would you like to do today?</Typography>

            <Grid sx={styles.iconStyle}>
                {Children.toArray(homePageConfig.map((item) => (
                    <>
                        <Box sx={styles.iconBoxStyle}>
                            <img
                                alt="HomepageImage"
                                src={item.icon}
                                style={{
                                    width: '93.18px',
                                    height: '89.52px',
                                    padding: "8px",
                                }}
                            />
                            <Button size='medium'
                                variant='contained'
                                color='primary'
                                sx={styles.button}
                                onClick={() => navigate(`${item.routePath}`)}>
                                <Typography sx={styles.buttonText} >
                                    {item.buttonText}
                                </Typography>
                            </Button>
                        </Box >
                    </>
                )))}
            </Grid>
        </Grid >
    )
}
