import { FormControlLabel, FormGroup, Checkbox as MuiCheckbox } from "@mui/material";

interface ICheckboxProps {
  label: string;
  inputStyleProps: any;
  value?: any;
  onChange?: any;
  checkedColor: string;
  unCheckedColor: string;
  [other: string]: any;
}

export const Checkbox = (props: ICheckboxProps) => {
  const { label, value, onChange, unCheckedColor, checkedColor, inputStyleProps, ...other } = props;

  const sx = other?.sx ?? {};

  return (
    <FormGroup sx={{ ...sx }}>
      <FormControlLabel
        onChange={onChange}
        value={value}
        componentsProps={{
          typography: {
            sx: inputStyleProps
          }
        }}
        sx={{
          ...inputStyleProps,
          alignItems: 'baseline',
          "& .MuiFormControlLabel-label": {
            position: 'relative',
            top: '-8px'
          }
        }}
        control={
          <MuiCheckbox
            checked={value || false}
            sx={{
              '&.Mui-checked': {
                color: checkedColor
              },
              color: unCheckedColor
            }}
            onChange={onChange}
          />}
        label={label}
        {...other}
      />
    </FormGroup>
  )
}
