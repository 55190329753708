import { Children } from 'react';
import { Box, Divider, PaginationItem as PI } from '@mui/material'
import { godzilla as theme } from "util/themes/godzilla";

const styles = {
    paginationItemStyle: (isSelected: boolean) => ({
        minWidth: '22px',
        maxHeight: '22px',
        p: 0,
        m: '5px',
        fontFamily: 'Nunito', 
        fontWeight: isSelected ? 700 : 400,
        color: isSelected ? theme?.palette?.primary?.[500] : '#202124'
    })
}

interface IPaginationItemProps {
    data: number[],
    selectedValue: number,
    onItemClick?: (value: number) => void
}

const PaginationItem = (props: IPaginationItemProps) => {
    const { data, selectedValue, onItemClick } = props;

    return (
        <Box display={'flex'} columnGap={'8px'}>
            {Children.toArray(data.map((item) =>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                    <PI
                        sx={styles.paginationItemStyle(selectedValue === item)}
                        page={item.toString()}
                        onClick={() => { onItemClick && onItemClick(item) }} />
                    {selectedValue === item && <Divider sx={{
                        position: 'relative',
                        bottom: 7.5,
                        height: '2px',
                        borderRadius: '2px',
                        width: '20px',
                        background: theme?.palette?.secondary?.[500]
                    }} />}
                </Box>
            ))}
        </Box>
    )
}

export default PaginationItem;