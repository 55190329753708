import { Box, Typography, TextField } from "@mui/material";
import LifeTrekLogo from "assets/images/sidenav_logo.svg";
import { Button } from "core/components";
import PlusIcon from "assets/images/blue_addIcon.svg";
import ChevronLeftIcon from "assets/images/chevronLeftIcon.svg";
import SettingGearIcon from "assets/images/settingGearIcon.svg";
import ErrorIcon from "assets/images/erroricon.svg";
import { godzilla as theme } from "util/themes/godzilla";
import { useEffect, useState, Children } from "react";
import { AddModuleCard, ViewModuleCard } from "../components/ModulePageCard";
import ExpandButton from "./components/ExpandButton";
import { IPageData, exitModalData, styles } from "./moduleLayout.helper";
import { useNavigate, useParams } from "react-router-dom";
import { TextRgx } from "pages/pathway/PathwayCreator/PathwayBuild/BuildDemonstrationMilestone/demonstrationmilestone.helper";
import ExitModal from "./components/ExitModal";

interface IModuleLayoutProps {
  children?: any;
  moduleTitle?: string;
  isSettings?: boolean;
  isPageOrganizer?: boolean;
  addNewPage?: boolean;
  setAddNewPage?: React.Dispatch<React.SetStateAction<boolean>>;
  pagesArr?: Array<IPageData>;
  setPagesArr?: React.Dispatch<React.SetStateAction<Array<IPageData>>>;
  currentSelectedPageIndex?: number;
  setCurrentSelectedPageIndex?: React.Dispatch<React.SetStateAction<number>>;
}

const MaxTextError = "Must be atmost of 100 characters";
const RequiredErrorText = "Please enter a valid title.";

const ModuleLayout = (props: IModuleLayoutProps) => {
  const { children, moduleTitle, isSettings, isPageOrganizer, addNewPage, setAddNewPage, pagesArr, setPagesArr, currentSelectedPageIndex, setCurrentSelectedPageIndex } = props;
  const { id } = useParams();
  const navigate = useNavigate();
  const [hide, setHide] = useState<boolean>(false);
  const [moduleName, setModuleName] = useState(moduleTitle)
  const [errorFlags, setErrorFlags] = useState<{
    requiredError: boolean,
    maxLength: boolean
  }>({
    requiredError: false,
    maxLength: false
  });
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const exitModalId = open ? 'open-exit-modal' : undefined;

  const onChangeModuleTitle = (e: any) => {
    setModuleName(e.target.value);
  }

  const setErrorMessage = () =>{
    setErrorFlags({
      requiredError: true,
      maxLength: false
    });
  }

  const onCheckModule = (title: any) => {
    if (title.trim() === "") {
      setErrorMessage();
    } else if (title.length > 100) {
      setErrorFlags({
        requiredError: false,
        maxLength: true
      })
    } else if (!title.match(TextRgx)) {
      setErrorMessage();
    } else {
      setErrorFlags({
        requiredError: false,
        maxLength: false
      })

    }
  }

  const onAddModuleTitle = (e: any, onKeyPress: boolean) => {
    if (onKeyPress) {
      if (e.key === 'Enter') {
        onCheckModule(moduleName)
      }
    } else {
      onCheckModule(moduleName)
    }
  }

  const hidePages = () => setHide(true);
  const unHidePages = () => setHide(false);

  const updatePagesArr = (data: any) => {
    setPagesArr?.(data);
    if (currentSelectedPageIndex !== undefined) {
      setCurrentSelectedPageIndex?.(currentSelectedPageIndex + 1);
    }
  };

  const handleSelectPage = (index: number) => {
    setCurrentSelectedPageIndex?.(index);
  }
  
  useEffect(() => {
    setModuleName(moduleTitle)
  }, [moduleTitle]);

  const handleExitClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExitModalClose = () => {
      setAnchorEl(null);
  }

  const handleExit = () => {
    navigate('/drafts')
  }

  return (
    <Box>
      <Box sx={styles.container}>
        <Box sx={styles.headerContainer}>
          <Box>
            <img src={LifeTrekLogo} alt="ICON" />
          </Box>
          <Box sx={styles.titleContainer}>
            <Box sx={styles.titleTextContainer}>
              <Box sx={styles.titleErrorConatiner}>
                {moduleTitle && (
                  <TextField
                    multiline={true}
                    onChange={(e: any) => onChangeModuleTitle(e)}
                    onKeyDown={(e: any) => onAddModuleTitle(e, false)}
                    onBlur={(e: any) => onAddModuleTitle(e, false)}
                    sx={{
                      ...styles.titleInput(moduleTitle),
                      border: `${errorFlags.requiredError || errorFlags.maxLength ? "2px solid #FF4F4F" : 'none'}`
                    }}
                    value={moduleName}
                    onFocus={() => {
                      setErrorFlags({
                        requiredError: false,
                        maxLength: false
                      })
                    }}
                    inputProps={{
                      maxLength: 101,
                    }}
                  />
                )}
                {errorFlags.requiredError || errorFlags.maxLength ? <Box display={'flex'} flexDirection={'row'} alignItems={'center'} mt={'7px'}>
                  <Box mt={'-2px'}>
                    <img src={ErrorIcon} alt="ICON" style={{ height: "10px", width: "10px" }} />
                  </Box>
                  <Box ml={'2px'}>
                    <Typography sx={{
                      ...theme?.typography?.captions?.default,
                      ...theme?.typography?.captions?.regular,
                      color: '#CF0000'
                    }}>{errorFlags.requiredError ? RequiredErrorText : MaxTextError}</Typography>
                  </Box>
                </Box> : null}
              </Box>
              {isSettings && (
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => navigate(`/moduleSettings/${id}`)}
                >
                  <Button sx={styles.settingButton}>
                    <img
                      src={SettingGearIcon}
                      alt="ICON"
                      height="20px"
                      width="20px"
                    />
                  </Button>
                </Box>
              )}
            </Box>
            <Box sx={styles.actionBtnContainer}>
              <Box sx={{ position: "relative" }}>
                <Box>
                  <Button variant="text" size="medium" color="primary" onClick={handleExitClick}>
                    <Typography sx={styles.exitText}>Exit</Typography>
                  </Button>
                  <ExitModal 
                    exitModalId={exitModalId} 
                    open={open} 
                    anchorEl={anchorEl} 
                    handleClose={handleExitModalClose}
                    handleExit={handleExit}
                    anchorOrigin={exitModalData.anchorOrigin}
                    transformOrigin={exitModalData.transformOrigin}
                    contentText={exitModalData.content} 
                    confirmBtnText={exitModalData.confirmBtnText}
                    cancelBtntext={exitModalData.cancelBtnText}
                  />
                </Box>
              </Box>
              <Box ml={"16px"}>
                <Button
                  variant="outlined"
                  size="medium"
                  color="primary"
                  sx={styles.saveBtn}
                >
                  <Typography sx={styles.saveBtnText}>Save</Typography>
                </Button>
              </Box>
              <Box ml={"15px"}>
                <Button
                  variant="contained"
                  size="medium"
                  color="primary"
                  sx={styles.nextBtn}
                >
                  <Typography sx={styles.nextBtnText}>Next</Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.contentContainer}>
          {hide && <ExpandButton onClick={unHidePages} />}
          {isPageOrganizer && (
            <Box sx={styles.pageContainer(hide)}>
              <Button onClick={hidePages} variant="text" sx={styles.hidPageBtn}>
                <img
                  src={ChevronLeftIcon}
                  alt="ICON"
                  style={styles.chevronLeftIcon}
                />
                <Typography sx={styles.hidePageBtnText}>Hide Pages</Typography>
              </Button>
              <Typography sx={styles.pageOrganizer}>Page Organizer</Typography>
              <Box className="customScroll" overflow={'auto'} height={'calc(100vh - 270px)'}>
              {pagesArr && pagesArr.length > 0 &&
                Children.toArray(
                  pagesArr.map((page: IPageData, index: number) => {
                    return (
                    <ViewModuleCard pageTitle={page.title} handleSelectPage={handleSelectPage} index={index} currentSelectedPageIndex={currentSelectedPageIndex ?? 0} pagesArr={pagesArr} updatePagesArr={updatePagesArr}/>
                    );
                  })
                )}
              {addNewPage ? (
                <AddModuleCard
                  pagesArr={pagesArr ?? []}
                  setAddNewPage={setAddNewPage}
                  updatePagesArr={updatePagesArr}
                />
              ) : null}
              <Button sx={styles.button} onClick={() => setAddNewPage?.(true)}>
                <Box sx={styles.plusIconContainer}>
                  <img src={PlusIcon} alt="ICON" />
                </Box>
                <Typography sx={styles.newPageText}>New Page</Typography>
              </Button>
              </Box>
            </Box>
          )}
          <Box sx={styles.editorContainer(hide)}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ModuleLayout;
