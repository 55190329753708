
import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import GodzillaSvg from 'assets/images/godzilla.svg';

export function InsufficientPermissionsError() {
    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/', { replace: true });
        }, 2000);
    }, []);

    return (
        <Box
            sx={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
            }}
        >
            <Box>
                <img
                    alt="Under development"
                    src={GodzillaSvg}
                    style={{
                        display: 'inline-block',
                        width: 'auto',
                        margin: '0 auto',
                        height: '250px',
                    }}
                />
            </Box>
            <h1>Insufficient Permissions Error</h1>
        </Box>
    );
}
