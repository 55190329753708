import { Children, useEffect, useMemo, useState } from "react";
import { Paper, Box, ClickAwayListener, MenuItem, Select, Tooltip, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getDefaultHeaders, instance as axiosInstance } from 'core/util';
import BreadCrumbComponent from "pages/pathway/components/BreadCrumb";
import { useAppContext } from "components/app-context/appContext";
import { godzilla as theme } from 'util/themes/godzilla';
import PathwayLayout from "../../../PathwayLayout/PathwayLayout";
import { TextField } from "core/components/controls/textField/TextField";
import { IDemonstrationMilestoneData } from "../../PathwayOutline/outline.helper";
import HelpIcon from "assets/images/helpCircleIcon.svg"
import SmallErrorIcon from "assets/images/smallerroricon.svg";
import ChecklistComponent from "./DemonstrationChecklist/ChecklistComponent";
import Error_Icon from "assets/images/erroricon.svg";
import { containsOnlyWhitespaceOrSpecialChars } from "../builder.helper";
import { useAuth } from "core/contexts";

const styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '30px',
    m: '-19px 39px 31px 22px'
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: "#F9FAFC",
    borderRadius: "20px",
    p: '40px 49px',
    mb: '33px'
  },
  inputStyle: {
    background: "#ffffff",
    borderRadius: '4px'
  },
  wcContainer: {
    display: 'flex',
    flexDirection: 'column',
    mt: '16px',
    justifyContent: 'center'
  },
  wcSelectStyle: (isError: boolean) => ({
    ml: '12px',
    "&.MuiInputBase-root": {
      width: "185px",
      background: "#ffffff",
      borderRadius: "4px",
      // border: `1px solid ${theme?.palette?.grayScale?.[300]}`,
      "& .MuiInputBase-input": {
        ...theme?.typography?.body?.default,
        ...theme?.typography?.body?.body1,
        ...theme?.typography?.body?.light,
        p: '8px 10px'
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: isError ? `2px solid ${theme?.palette?.error?.dark}` : `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "4px",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: isError ? `2px solid ${theme?.palette?.error?.dark}` : `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "4px",
      },
      "& .MuiSelect-icon": {
        color: "#2C39DA",
        width: "32px",
        height: "24px",
      },
    },
  }),
  wcSelectItemStyle: {
    '&:hover': {
      backgroundColor: '#E9EAFF'
    },
    '&.Mui-selected': {
      backgroundColor: '#E9EAFF'
    },
    '&.Mui-selected:hover': {
      backgroundColor: '#E9EAFF'
    },
  },
  wcErrorStyle: {
    ...theme?.typography?.captions?.default,
    ...theme?.typography?.captions?.regular,
    color: theme?.palette?.error?.dark,
    mt: '4px'
  },
  rightContainer: {
    display: 'flex',
    flex: 1,
    borderRadius: "20px",
  }
}

interface IDuration {
  key: string,
  name: string
}

const DemonstrationMilestone = () => {
  const { pathwayData, setPathwayData } = useAppContext();
  const params = useParams();
  const navigate = useNavigate();
  const [checklistData, setChecklistData] = useState<Array<any>>([]);

  const breadCrumbPath = [
    { name: "Drafts", url: "/pathway" },
    { name: pathwayData.pathway?.data?.title, url: `/pathwayCreator/build/${params.id}` },
    { name: params.mName, url: "" },
  ];
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());

  const [defOfSuccess, setDefOfSuccess] = useState("");
  const [desWinOfCompletion, setDesWinOfCompletion] = useState("");
  const [showWcTooltip, setShowWcTooltip] = useState(false);
  const [wcSelect, setWcSelect] = useState("");
  const [wcInput, setWcInput] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    defOfSuccess: "",
    desWinOfCompletion: "",
    wcInput: "",
    wcSelect: "",
    checklistError: ""
  })

  const getDuration = useQuery<IDuration[]>('durationlist', async () => {
    const response = await axiosInstance().get(`/contentcreator/durationlist`, {
      headers: {
        "Authorization": headers.Authorization,
        "Content-Type": "application/json"
      },
      data: {}
    });
    return response.data;
  }, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (!pathwayData.pathway?.data?.title) {
      navigate(`/pathwayCreator/build/${params.id}`)
    }

  }, []);

  const milestoneIndex = useMemo(() => Number(params.mIndex) ?? -1, [params]);

  const milestoneData = useMemo(() => pathwayData.milestones.at(milestoneIndex), [pathwayData, milestoneIndex]);

  useEffect(() => {
    if (milestoneData) {
      const { successDefinition, descriptionOfDuration, completionTime, completionTimeUnit, checklist } = milestoneData as IDemonstrationMilestoneData;

      successDefinition && setDefOfSuccess(successDefinition);
      descriptionOfDuration && setDesWinOfCompletion(descriptionOfDuration);
      completionTime && setWcInput(completionTime.toString());
      let newCompletionTimeUnit = completionTimeUnit;
      if(newCompletionTimeUnit) {
        newCompletionTimeUnit = getDuration.data?.find((item) => item.key === newCompletionTimeUnit)?.name || completionTimeUnit;
      }
      newCompletionTimeUnit && setWcSelect(newCompletionTimeUnit);
      checklist?.length && setChecklistData(checklist);
    }
  }, [milestoneData, getDuration.isLoading]);

  const validateDemoMilestoneDesc = (): boolean => {
    let errorObj = errorMessage;
    const invalidTextDefOfSuccess = containsOnlyWhitespaceOrSpecialChars(defOfSuccess);

    if (checklistData.length === 0) {
      errorObj.checklistError = "Please add at least one checklist item";
    } else {
      errorObj.checklistError = "";
    }

    if (!defOfSuccess || invalidTextDefOfSuccess) {
      errorObj.defOfSuccess = !defOfSuccess ? "Please enter the definition of success." : "Cannot be only spaces or special characters."
    }

    const invalidTextDesWinOfCompletion = containsOnlyWhitespaceOrSpecialChars(desWinOfCompletion);
    if (!desWinOfCompletion || invalidTextDesWinOfCompletion) {
      errorObj.desWinOfCompletion = !desWinOfCompletion ? "Please describe the window of completion to the learner." : "Cannot be only spaces or special characters."
    }

    const invalidWcInput = Number(wcInput) <= 0 || Number(wcInput) > 99;
    if (!wcInput || invalidWcInput || !wcSelect) {
      errorObj.wcInput = !wcInput ? "Please enter recommended window of completion." : invalidWcInput ? "Number must be 1-99." : "";
      errorObj.wcSelect = !wcSelect ? "Please select recommended window of completion." : "";
    }

    setErrorMessage(prv => ({
      ...prv,
      ...errorObj
    }))

    const checklistError = checklistData.length === 0 || !!checklistData.find(item => item.length > 100)

    const hasError = !defOfSuccess || invalidTextDefOfSuccess || !desWinOfCompletion || invalidTextDesWinOfCompletion || !wcInput || invalidWcInput || !wcSelect || checklistError
    return hasError;
  }

  const setMileStoneDataInPathway = (key: string, value: string) => {
    if(key === 'completionTimeUnit') {
      value = getDuration.data?.find((item) => item.name === value)?.key || value
    }
    let updatedMilestoneData = {
      ...milestoneData,
      [key]: value
    };
    const clonePathwayData = JSON.parse(JSON.stringify(pathwayData))
    if (updatedMilestoneData?.sdoId) {
      clonePathwayData.milestones[milestoneIndex] = { ...updatedMilestoneData, action: "UPDATE" };
      setPathwayData(clonePathwayData);
    }
    if(!updatedMilestoneData?.sdoId) {
      clonePathwayData.milestones[milestoneIndex] = updatedMilestoneData;
      setPathwayData(clonePathwayData);
    }
  }

  const handleOnSave = (): boolean => {
    let isNotValidated = validateDemoMilestoneDesc();
    if (!isNotValidated) {
      setPathwayData(pathwayData);
    }
    return validateDemoMilestoneDesc();
  }

  const setCheckListItemsInData = (items: any) => {
    setChecklistData(items);
    setMileStoneDataInPathway('checklist', items);
  }

  return (
    <PathwayLayout
      activeIndex={1}
      buttonText={"Save"}
      hasExitToBuildButton={true}
      exitBtnText={"No, continue reviewing my milestone"}
      exitNavigation={'/pathway'}
      pathwayTitle={pathwayData.pathway?.data?.title}
      isBreadCrumb={true}
      onClickSaveValidate={handleOnSave}
      BreadCrumbCmp={() => (
        <BreadCrumbComponent
          breadCrumbPath={breadCrumbPath}
          title={params.mName}
        />
      )}
    >
      <Box sx={styles.mainContainer}>
        <Box sx={styles.leftContainer}>
          <Typography sx={{ ...theme?.typography?.h2 }}>
            Demonstration Milestone Description
          </Typography>

          <TextField
            style={{ mt: "18px" }}
            maxLength={350}
            label="Definition of Success"
            errorText={errorMessage.defOfSuccess}
            inputProps={{
              multiline: true,
              rows: 9,
              sx: styles.inputStyle
            }}
            value={defOfSuccess}
            characterLengthStyle={defOfSuccess.length > 350 ? {color: theme?.palette?.error?.dark} : {}}
            onChange={(e) => {
              const { value } = e.target;
              setErrorMessage((prv) => ({ ...prv, defOfSuccess: value.length > 350 ? "Cannot exceed 350 characters." : "" }));
              setDefOfSuccess(value);
              setMileStoneDataInPathway('successDefinition', value);
            }}
            required
          />

          <Box sx={styles.wcContainer}>
            <Box display={"flex"}>
              <Typography
                sx={{
                  ...theme?.typography?.h3,
                  color: theme?.palette?.grayScale?.[700],
                }}
              >
                Recommended Window of Completion
                <span className="required">*</span>
              </Typography>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => setShowWcTooltip(false)}
              >
                <Box display={"flex"} ml="2px" position={'relative'}>
                  <Tooltip
                    title="The ‘Recommended Window of Completion’ is a suggestion for how long the Pathway should take for a learner to complete."
                    placement="top"
                    arrow
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={() => setShowWcTooltip(false)}
                    open={showWcTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: theme?.palette?.grayScale[800],
                          borderRadius: "4px",
                          height: "100px",
                          width: "193px",
                          ...theme?.typography?.body?.default,
                          ...theme?.typography?.body?.body2,
                          ...theme?.typography?.body?.regular,
                          color: "#FFFFFF",
                        },
                      },
                      arrow: {
                        sx: {
                          color: theme?.palette?.grayScale[800]
                        }
                      }
                    }}
                  >
                    <img
                      src={HelpIcon}
                      alt="Help Icon"
                      style={{ cursor: "pointer" }}
                      onClick={() => setShowWcTooltip(true)}
                    />
                  </Tooltip>
                </Box>
              </ClickAwayListener>
            </Box>
            <Box>
              <Box display={"flex"} mt="20px">
                <TextField
                  style={{ width: "60px" }}
                  inputProps={{
                    placeholder: "##",
                    sx: styles.inputStyle
                  }}
                  error={!!errorMessage.wcInput}
                  value={wcInput}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (!isNaN(Number(value)) || value === "") {
                      setErrorMessage((prv) => ({ ...prv, wcInput: "" }));
                      setWcInput(e.target.value);
                      setMileStoneDataInPathway('completionTime', e.target.value);
                    }
                  }}
                />
                <Select
                  value={wcSelect}
                  displayEmpty
                  onChange={(e) => {
                    setErrorMessage((prv) => ({ ...prv, wcSelect: "" }));
                    setWcSelect(e.target.value);
                    setMileStoneDataInPathway('completionTimeUnit', e.target.value);
                  }}
                  sx={styles.wcSelectStyle(!!errorMessage.wcSelect)}
                  renderValue={(value) => <>{value || 'Select'}</>}
                >
                  {Children.toArray(
                    getDuration.data?.map((item) => (
                      <MenuItem sx={styles.wcSelectItemStyle} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </Box>

              {(!!errorMessage.wcInput || !!errorMessage.wcSelect) && (
                <Typography sx={{ ...styles.wcErrorStyle }} display={"flex"}>
                  <img
                    src={SmallErrorIcon}
                    style={{ marginRight: "4px" }}
                    alt=""
                  />
                  {!!errorMessage.wcInput
                    ? errorMessage.wcInput
                    : errorMessage.wcSelect}
                </Typography>
              )}
            </Box>
          </Box>

          <TextField
            style={{ mt: "18px" }}
            maxLength={150}
            label="Describe the window of completion to the Learner."
            errorText={errorMessage.desWinOfCompletion}
            inputProps={{
              multiline: true,
              rows: 3,
              sx: styles.inputStyle
            }}
            value={desWinOfCompletion}
            characterLengthStyle={desWinOfCompletion.length > 150 ? {color: theme?.palette?.error?.dark} : {}}
            onChange={(e) => {
              const {value} = e.target;
              setErrorMessage((prv) => ({ ...prv, desWinOfCompletion: value.length > 150 ? "Cannot exceed 150 characters." : ""  }));
              setDesWinOfCompletion(value);
              setMileStoneDataInPathway('descriptionOfDuration', value);
            }}
            required
          />
        </Box>
        <Box
          sx={styles.rightContainer}
          width={"100%"}
          display={"flex"}
          flexDirection={"column"}
        >
          <Paper sx={{ borderRadius: "20px", height: '100%', mb: '33px' }} elevation={3}>
            <Box>
              <ChecklistComponent
                checklistData={checklistData}
                setChecklistData={(items) => setCheckListItemsInData(items)}
                setErrorMessage={setErrorMessage}
              />
            </Box>
            {errorMessage.checklistError !== "" ? <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              mt={"5px"}
              ml={"57px"}
            >
              <Box mt={"-4px"}>
                <img
                  src={Error_Icon}
                  alt="ICON"
                  style={{ height: "10px", width: "10px" }}
                />
              </Box>
              <Box ml={"4px"}>
                <Typography
                  sx={{
                    ...theme?.typography?.captions?.default,
                    ...theme?.typography?.captions?.regular,
                    color: theme?.palette?.error?.dark,
                  }}
                >
                  {errorMessage.checklistError}
                </Typography>
              </Box>
            </Box> : null}
          </Paper>
        </Box>
      </Box>
    </PathwayLayout>
  );
};

export default DemonstrationMilestone;
