import { Box, Grid } from "@mui/material";
import { Children, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface IDragAndDrop {
  draggableData: Array<any>;
  renderItem: (item: any, index: number) => React.ReactNode;
  setDraggableUpdateData: (items: Array<any>) => void;
  isDraggableDisable?: boolean;
  itemStyle?: any;
}

const DragAndDropComponent = (props: IDragAndDrop) => {
  const {
    draggableData,
    renderItem,
    setDraggableUpdateData,
    isDraggableDisable = false,
    itemStyle,
  } = props;
  const [placeholderProps, setPlaceholderProps] = useState<any>({});
  const queryAttr = "data-rbd-drag-handle-draggable-id";

  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: any) => {
    document.body.classList.remove('no-horizontal-scroll');
    setPlaceholderProps({});
    if (!result.destination) {
      return;
    }

    const updatedItems = reorder(
      draggableData,
      result.source.index,
      result.destination.index
    );

    setDraggableUpdateData(updatedItems);
  };

  const getDraggedDom = (draggableId: string) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  const onDragStart = (event: any) => {
    document.body.classList.add('no-horizontal-scroll');
    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = event.source.index;
    var clientY =
      parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop
      ) +
      Array.from(draggedDOM.parentNode!.children)
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          const style = window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft
      ),
    });
  };

  const onDragUpdate = (event: any) => {
    
    if (!event.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = Array.from(draggedDOM.parentNode!.children);
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    var clientY =
      parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop
      ) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft
      ),
    });
  };

  return (
    <DragDropContext
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
    >
      <Droppable droppableId="draggableData">
        {(provided, snapshot) => (
          <Grid
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{ height: "auto", position: "relative" }}
          >
            {draggableData.length
              ? Children.toArray(
                  draggableData.map((item: any, index: number) => {
                    let key;
                    if(typeof item === "string"){
                      key = item
                    }else {
                      key = item?.uuid || item?.name || item?.title
                    }
                    return (
                      <Draggable
                        key={`${key}-${index}`}
                        draggableId={`${key}-${index}`}
                        index={index}
                        isDragDisabled={isDraggableDisable}
                      >
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              userSelect: "none",
                              margin: `0 0 ${16}px 0`,
                              ...provided.draggableProps.style,
                              ...itemStyle,
                            }}
                          >
                            {renderItem(item, index)}
                          </Box>
                        )}
                      </Draggable>
                    );
                  })
                )
              : null}
            {provided.placeholder}
            {snapshot.isDraggingOver && (
              <Grid
                sx={{
                  position: "absolute",
                  backgroundColor: "#DEE1E6",
                  borderRadius: "8px",
                }}
                style={{
                  top: placeholderProps.clientY,
                  left: placeholderProps.clientX,
                  height: placeholderProps.clientHeight,
                  width: placeholderProps.clientWidth,
                }}
              />
            )}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragAndDropComponent;
