import React, { useState, useEffect, memo, useMemo } from 'react'
import {
  Box,
  Typography,
  IconButton,
  LinearProgress
} from '@mui/material';
import CrossIconSVG from '../../../assets/images/crossicon.svg';
import DoneIconSVG from '../../../assets/images/doneicon.svg';
import ErrorIconSVG from '../../../assets/images/erroricon.svg';
import { FileRemovePopover } from './FileRemovePopover';
import { godzilla as theme } from 'util/themes/godzilla';
import { useAppContext } from 'components/app-context/appContext';

const styles = {
  fileList: {
    container: {
      mt: 5,
      width: '60%',
      border: `1px solid ${theme.palette.grayScale['A1']}`,
      height: 'auto',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column'
    },
    headerContainer: {
      backgroundColor: theme?.palette?.primary[500],
      px: 2,
      pt: 1,
      pb: 1.5,
      borderRadius: '4px 4px 0px 0px',
    },
    header: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.5px',
      textTransform: 'uppercase',
      color: '#FFFFFF',
    },
    fileDetail: {
      borderBottom: `1px solid ${theme.palette.grayScale['A1']}`,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      p: '16px 24px 12px 16px',
      background: "#F7F9FA",
    },
    fileNameContainer: {
      width: '50%'
    },
    fileNameText: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.15px',
      color: theme?.palette?.grayScale[500],
    },
    progressContainer: {
      height: '7px',
      mt: '10px',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center'
    }
  },
  errorUploadText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#999CAF'
  },
  inProgress: {
    backgroundColor: `#E1E4EE`,
    borderRadius: '10px',
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme?.palette?.tertiary[600],
      borderRadius: '10px'
    }
  },
  success: {
    backgroundColor: `#E1E4EE`,
    borderRadius: '10px',
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme?.palette?.tertiary[600],
      borderRadius: '10px'
    }
  },
  error: {
    backgroundColor: `#E1E4EE`,
    borderRadius: '10px',
    "& .MuiLinearProgress-bar": {
      backgroundColor: `#FF6363`,
      borderRadius: '10px'
    }
  }
};

interface IFileProgressProps {
  file?: any,
  removeFileFromState: (file: any) => void,
  isUploading: boolean
}

const FileProgress = (props: IFileProgressProps) => {
  const { file, removeFileFromState, isUploading } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const {
    fileUploadMutation,
    abortController,
    uploadProgress,
    isUploading: uploadInProgress
  } = useAppContext();
  
  useEffect(() => {
    if(file && (isUploading && !uploadInProgress)) {
      const data = new FormData();
      data.append('file', file);
      try {
        fileUploadMutation.mutate(data); 
      } catch (error) {
        console.log('error-->', error);
      }
    }
  }, []);

  let open: boolean = Boolean(anchorEl);;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const removeFileInProgress = () => {
    abortController.current.abort('Upload cancelled');
    removeFileFromState(file);
    handleClose();
  }

  const id = open ? 'simple-popover' : undefined;
  
  const removeFile = () => {
    return (
      <Box sx={styles.fileList.iconContainer}>
        <IconButton aria-describedby={id} onClick={handleClick}>
          <img src={CrossIconSVG} alt='remove_icon'/>
        </IconButton>
        <FileRemovePopover id={id} anchorEl={anchorEl} handleClose={handleClose} open={open} removeFile={removeFileInProgress}/>
      </Box>
    )
  }

  const success = () => {
    return <Box>
        <img src={DoneIconSVG} alt='sucess_icon'/>
    </Box>
  }

  const error = () => {
    return <Box sx={styles.fileList.iconContainer}>
      <Box mr={6}>
        <Typography sx={styles.errorUploadText}>An error has occurred</Typography>
      </Box>
      <Box>
        <img src={ErrorIconSVG} alt='error_icon'/>
      </Box>
    </Box>
  }

  const fileStatus = () => {
    // checking status for uploading file
    if(isUploading) {
      switch(fileUploadMutation.status) {
        case 'loading':
          return removeFile();
        case 'success':
          return success();
        case 'error': 
          return error(); 
      }
    } 
    //check status for uploaded file 
    else {
      if(file.errorInUpload) {
        return error();
      } else if(file.uploadProgress === 100 && !file.errorInUpload) {
        return success();
      }
    }    
  }

  const getError = useMemo(() => fileUploadMutation.isError, [fileUploadMutation.isError]);

  const fileProgress = () => {
    let {isLoading, isSuccess} = fileUploadMutation;
    let uploadError;
    if(isUploading) {
      uploadError = getError;
    }else {
      uploadError = file?.errorInUpload; 
    }

    return (
      <LinearProgress 
        variant="determinate" 
        value={isUploading ? uploadProgress : file.uploadProgress} 
        sx={{ 
          ...(isLoading && styles.inProgress),
          ...(isSuccess && styles.success),
          ...(uploadError && styles.error),
          ...styles.fileList.progressContainer,
          '.MuiLinearProgress-bar': {
            transition: 'none'
          } 
        }} 
      />
    );
  }

  return (
    <>
      <Box sx={styles.fileList.fileDetail}>
        <Box sx={styles.fileList.fileNameContainer}>
          <Typography sx={styles.fileList.fileNameText}>
            {file.name}
          </Typography>
          {fileProgress()}
        </Box>
        {fileStatus()}
      </Box>
    </>
  );
};

export default memo(FileProgress);
