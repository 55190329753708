import React, { Children, useState, useEffect } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Accordion from './components/ModuleCard';
import { IModuleCardDataProps, IModuleItem, IModulePageDataProps } from './types';
import { useAppContext } from 'components/app-context/appContext';

const styles = {
  courseTitle: {
    fontFamily: 'Museo Sans Rounded',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: '#4B4D58'
  }
}

interface IUnPublishModuleProps {
  data: IModuleCardDataProps[],
  setModuleData?: (id: string, cardData: IModuleCardDataProps, e: React.ChangeEvent<HTMLInputElement>) => void,
  selectedModuleData?: IModuleCardDataProps[],
  onSelectCard: (cardData: IModuleCardDataProps) => void,
  onSelectPage?: (pageData: IModulePageDataProps) => void,
  hasSelection: boolean,
  hasDeleteSelection: boolean,
  isReviewModule?: boolean,
  isDataUpdated?: boolean,
  isModuleSorting?: boolean,
  setDataUpdated?: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedModule?: React.Dispatch<React.SetStateAction<IModuleCardDataProps | null>>
}

const UnPublishedModuleList = (props: IUnPublishModuleProps) => {
  const { setDataUpdated, setSelectedModule, setModuleData, selectedModuleData, onSelectCard, onSelectPage, hasSelection, hasDeleteSelection, data, isReviewModule = false, isDataUpdated = false, isModuleSorting = false } = props;
  const [courses, setCourses] = useState<IModuleCardDataProps[]>([]);
  const [pageModuleId, setPageModuleId] = useState<string>('');
  const { setReviewModuleData } = useAppContext();

  const getUpdatedCourseData = () => {
    let newData = data.filter(course => {
      return course?.modules && course.modules.length
    }).map((course, cIndex) => {
      let mCourse = course.modules.map((module, mIndex) => {
        if (isReviewModule && !isDataUpdated && cIndex === 0 && mIndex === 0) {
          module.selected = true;
        } else {
          module.selected = false;
        }
        return module
      });
      course.modules = mCourse;
      return course;
    });
    return newData;
  }

  useEffect(() => {
    setCourses(getUpdatedCourseData());
  }, []);

  useEffect(() => {
    if (!isReviewModule && isDataUpdated) {
      setDataUpdated && setDataUpdated(false);
      setCourses(getUpdatedCourseData());
    }
  }, [data, isDataUpdated])

  const onSelectedData = (index: number, cardData: IModuleCardDataProps) => {
    let newData = courses.map((course) => {
      let mCourse = course.modules.map((module, i) => {
        if (index === i && cardData.courseId === course.courseId) {
          module.selected = module.selected ? module.selected : !module.selected;
        } else {
          module.selected = false;
        }
        return module
      });
      course.modules = mCourse;
      return course;
    })
    setCourses(newData);
  }

  const isModuleCardChecked = (module: IModuleItem, courseId: string, courseName: string, moduleName: string) => {
    let ids: Array<string> = [];
    selectedModuleData?.map(course => {
      if (courseId === course.courseId) {
        course.modules.forEach(moduleData => {
          ids.push(moduleData.sdoId);
          return moduleData;
        })
      }
      return course;
    });
    return ids.includes(module.sdoId);
  }

  const removeModuleFromList = (course: IModuleCardDataProps) => {
    const filteredArray = courses.filter(courseData => {
      if (courseData.courseId === course.courseId) {
        courseData.modules = courseData.modules.filter(module => {
          return module.sdoId !== course.modules[0].sdoId
        })
        return !!courseData.modules.length;
      }
      return true
    }).map((courseData, cIndex) => {
      let mCourse = courseData.modules.map((module, mIndex) => {
        if (isReviewModule && cIndex === 0 && mIndex === 0) {
          module.selected = true;
        } else {
          module.selected = false;
        }
        return module;
      });
      courseData.modules = mCourse;
      return courseData
    });
    if (isReviewModule) {
      setSelectedModule && setSelectedModule(null);
      setReviewModuleData(filteredArray);
    }
    setCourses(filteredArray);
  }

  const setFirstModuleExpanded = (cIndex: number, mIndex: number) => {
    return isReviewModule && mIndex === 0 && cIndex === 0
  }

  const onClickSelectCard = (cardData: IModuleCardDataProps) => {
    onSelectCard(cardData);
    setPageModuleId('');
  }

  const onClickOpenPageLayout = (moduleId: string, pageData: IModulePageDataProps) => {
    setPageModuleId(moduleId);
    onSelectPage && onSelectPage(pageData);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      {Children.toArray(courses?.map((course: IModuleCardDataProps, cIndex: number) => {
        return (
          <Grid>
            {!isModuleSorting && <Grid mb={2.5} ml={-1}>
              <Typography sx={styles.courseTitle}>
                {course.title}
              </Typography>
            </Grid>}
            {course.modules.length ? Children.toArray(course.modules.map((module, mIndex: number) => {
              return (
                <Accordion
                  accordionTitle={module.title}
                  checkedModule={!!isModuleCardChecked(module, course.courseId, course.title, module.title)}
                  setModuleData={setModuleData}
                  id={module.sdoId}
                  pageModuleId={pageModuleId}
                  index={mIndex}
                  status={module?.status || 'unpublished'}
                  isTagRequired={module?.isTagRequired ?? false}
                  isPageRequired={module?.isPageRequired ?? false}
                  cardData={{ ...course, modules: [module] }}
                  onSelectCard={onClickSelectCard}
                  onSelectPage={onClickOpenPageLayout}
                  hasSelection={hasSelection}
                  hasDeleteSelection={hasDeleteSelection}
                  onSelectModuleData={onSelectedData}
                  isFirstModuleExpanded={setFirstModuleExpanded(cIndex, mIndex)}
                  isModuleSorting={isModuleSorting}
                  removeModuleFromList={removeModuleFromList}
                  coursesLength={courses.length}
                />)
            })) : null}
          </Grid>
        )
      }))}
    </Box>
  )
}

export default UnPublishedModuleList;