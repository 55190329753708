import React from 'react';
import { Grid, Typography, IconButton } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CloseIcon from '@mui/icons-material/Close';

const styles = {
  popperContainer: {
    position: 'relative',
    width: '278px',
    height: 'auto',
    background: '#FFFFFF',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    pb: 2.5,
    pt: 2.75,
    pl: 1.25
  },
  popperContentText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    color: '#F95F11'
  },
  closeIconStyle: { 
    position: 'absolute', 
    right: '11px', 
    top: '11px' 
  }
}

interface IHandleCloseWarningModalProps {
  handleCloseWarningModal: () => void,
  warningText: string,
  sx: {
    iconStyle: any,
    textStyle: any
  }
}

export default function PublishWarningModal({handleCloseWarningModal, warningText, sx}: IHandleCloseWarningModalProps) {
  return (
    <Grid sx={styles.popperContainer} flexDirection={'row'} display={'flex'}>
      <Grid>
        <ErrorOutlineIcon sx={sx.iconStyle}/>
      </Grid>
      <Grid width={196}>
        <Typography sx={{...styles.popperContentText, ...sx.textStyle}}>
          {warningText}
        </Typography>
      </Grid>
      <Grid sx={styles.closeIconStyle}>
        <IconButton onClick={handleCloseWarningModal}>
          <CloseIcon sx={{color: '#3D4655'}}/>
        </IconButton>
      </Grid>
    </Grid>
  )
}