import React from 'react';
import {
  Popover,
  Box,
  Typography,
  Button
} from '@mui/material';
import { godzilla as theme} from 'util/themes/godzilla';
import { Button as ControlBtn } from 'core/components';

const styles = {
  cancelUploadBox: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px rgb(0 0 0 / 14%), 0px 1px 18px rgb(0 0 0 / 12%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    px: 3,
    py: 2,
    width: '21vw'
  },
  cancelText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    mb: 1.5,
    color: theme?.palette?.grayScale[500],
  },
  cancelYesButton: {
    background: '#DF2F2F',
    borderRadius: '100px',
    padding: '12px 20px',
    gap: '8px',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#DF2F2F'
    }
  },
  removeBtnText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '-0.01em',
    textTransform: 'none'
  },
  cancelNoButton: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    letterSpacing: '-0.01em',
    color: theme?.palette?.primary[500],
    lineHeight: '150%',
    textTransform: 'none'
  },
};

interface IPopover {
  id?: string,
  open: boolean,
  anchorEl: HTMLButtonElement | null,
  handleClose: () => void,
  removeFile: () => void
}

export const FileRemovePopover = (props: IPopover) => {
  const { id, anchorEl, open, handleClose, removeFile }  = props;
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}>
      <Box sx={styles.cancelUploadBox}>
        <Typography sx={styles.cancelText}>Are you sure you want to remove this file from your uploads?</Typography>
        <ControlBtn
          variant='contained'
          color='error'
          size='medium'
          sx={styles.cancelYesButton}
          onClick={removeFile}
        >
          <Typography sx={{
            ...theme?.typography?.Components?.button?.default,
            ...theme?.typography?.Components?.button?.medium,
            ...styles.removeBtnText
          }}>
            Yes, remove.
          </Typography>
        </ControlBtn>
        <Button sx={styles.cancelNoButton}
          onClick={handleClose}
        >
          No, don't remove.
        </Button>
      </Box>
    </Popover>
  )
}