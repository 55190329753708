import React, { Children, useState } from "react";
import {
  Grid,
  Box,
  Drawer,
  ClickAwayListener,
  Button,
  Popper,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { godzilla as theme } from "util/themes/godzilla";
import { sideMenuConfig } from "util/sidemenuconfig";

import Avatar from "components/Avatar/ProfileAvatar";
import { useAuth } from "core/contexts";
import logo from "assets/images/sidenav_logo.svg";
import NavBarItem from "./components/NavBarItem";
import { getNavStatus } from "util/getNavStatus";
import { useLocation } from "react-router-dom";
import LogoutIcon from "assets/images/menu_logout_logo.svg";

const drawerWidth = 100;

const styles = {
  main: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: 1,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF",
    boxShadow:
      "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
    "& .MuiDrawer-paper": {
      width: drawerWidth,
      overflowY: "visible",
    },
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    p: "26px 26px 30px 26px",
  },
  avatarStyle: {
    background: theme?.palette?.tertiary[600],
    "&.MuiAvatar-root": {
      textTransform: "uppercase",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "21px",
      lineHeight: "24px",
      letterSpacing: "-0.01em",
      color: "#FFFFFF",
    },
  },
};

interface IDrawerProps {
  children?: React.ReactNode;
}

export default function SideDrawer(props: IDrawerProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { children } = props;
  const { getUserName, logout } = useAuth();
  const { pathname } = useLocation();

  const getUserNameArr = (): Array<string> => {
    return getUserName()?.split(" ");
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onLogoutClick = () => {
    logout();
  };

  const open = Boolean(anchorEl);
  const popperId = "header_avatar_dropwdown";

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {!getNavStatus(pathname).sideNav ? (
        <Drawer sx={{ ...styles.drawer }} variant="permanent">
          <Grid sx={styles.drawerHeader}>
            <img src={logo} alt="sidenav_logo" />
          </Grid>
          <Grid>
            {Children.toArray(
              sideMenuConfig.map((item, index) => (
                <NavBarItem item={item} isFirst={index === 0} />
              ))
            )}
          </Grid>
          <Grid
            display={"flex"}
            alignItems={"end"}
            flex={1}
            sx={{ mb: 6 }}
            justifyContent={"center"}
          >
            <ClickAwayListener onClickAway={handleClickAway}>
              <Button
                aria-describedby={popperId}
                onClick={handleClick}
                sx={{
                  minWidth: 0,
                }}
              >
                <Avatar
                  firstName={getUserNameArr()[0]}
                  lastName={getUserNameArr()[1]}
                  size="medium"
                  sx={styles.avatarStyle}
                />
              </Button>
            </ClickAwayListener>
            <Popper
              id={popperId}
              open={open}
              anchorEl={anchorEl}
              placement={"right-start"}
              style={{ zIndex: 2 }}
            >
              <Box
                className="popper-dropdown-box"
                sx={{
                  borderRadius: "10px",
                  boxShadow:
                    "0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
                  backgroundColor: "#FFF",
                  position: "absolute",
                  left: "-40px",
                  top: "-26px",
                }}
              >
                <List component="nav">
                  <ListItemButton onClick={onLogoutClick} selected={false}>
                    <img
                      src={LogoutIcon}
                      alt="ICON"
                      style={{
                        marginRight: "9px",
                      }}
                    />
                    <ListItemText primary={"Logout"} />
                  </ListItemButton>
                </List>
              </Box>
            </Popper>
          </Grid>
        </Drawer>
      ) : null}
      <main style={styles.main}>{children}</main>
    </Box>
  );
}
