import { Children, useMemo, useCallback, useEffect, useState } from 'react';
import { Box, CircularProgress, ClickAwayListener, Divider, Grid, Pagination, Typography } from '@mui/material'
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useAuth } from "core/contexts";
import { Button, SearchField, Checkbox } from 'core/components';
import { godzilla as theme } from "util/themes/godzilla";
import DraftsImageSVG from 'assets/images/draftsimg.svg';
import sortSVG from 'assets/images/sort.svg';
import { getCurrentPageList, getTotalPageCount } from './libraryHelper';
import PublishedModulesList from './LibraryModules/PublishedModulesList';
import { IPublishedModuleCardDataProps } from 'pages/drafts/DraftsModule/types';
import filterImage from 'assets/images/filterImage.svg';
import SortModal, { ISortFieldTypes } from './components/SortModal';
import clear from 'assets/images/clear.svg';
import emptyDraftsFilterImage from 'assets/images/contentLibrary.svg';

const styles = {
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    px: '46px',
    paddingTop:'40px',
    background: theme?.palette?.background?.paper
  },
  libraryHeader: {
    fontFamily: 'Museo Sans Rounded',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '24px',
    color: theme.palette.grayScale[500],
  },
  boxContainer: {
    width: '100%',
    height: 'calc(100vh - 74px)',
    pt: 4,
    display: 'flex',
    flexDirection: 'row',
  },
  filterContainer: {
    width: '222px',
    height: '100%'
  },
  rightContainer: {
    height: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },  
  filterBtn: {
    p: '12px 20px',
    height: '36px',
    border: `2px solid ${theme.palette.tertiary[600]}`,
    '&:hover': {
      background: '#ffffff',
    }
  },
  filterBtnText: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    textTransform: 'none',
    pl: 1,
    color: theme.palette.grayScale[500]
  },
  sortModalContainer: {
    zIndex: 2,
    position: 'absolute',
    top: '36px',
  },
  loading: {
    position: 'absolute',
    top: 'calc(50% - 80px)',
    right: 'calc(50% - 42px)',
    color: theme?.palette?.tertiary[600]
  },
  image: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    mb: 3,
  },
  boxImportFiles: {
    py: 10,
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  importText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '32px',
    lineHeight: '44px',
    textAlign: 'center',
    letterSpacing: '-0.01em',
    color: theme?.palette?.grayScale[300],
    mb: 3
  },
  importButtonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  importButton: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '21px',
    textTransform: 'none',
    lineHeight: '29px',
    letterSpacing: '-0.01em'
  },
  listContainer: {
    width: '100%',
    backgroundColor: '#F9FAFC',
    borderRadius: '20px',
    px: '22px',
    pt: '4px',
    pb: '20px',
    mt: '13px',
    overflow: 'auto',
    flex: 1
  },
  paginationContainer: {
    height: '78px',
    pt: '15px',
    pr: '22px',
    '& .MuiPaginationItem-root.Mui-selected': {
      backgroundColor: theme?.palette?.primary[500],
      color: theme?.palette?.info?.contrastText
    },
  },
  filterInnerContainer: {
    height: 'calc(100vh - 140px)', overflow: 'auto'
  },
  clearBtn:{
    ...theme?.typography?.Components?.button?.small,
    textTransform:'none',
    color:theme?.palette?.primary[500],
    ml: '28px'
  },
  searchFieldStyle: {
    '& .MuiOutlinedInput-root': {
      p: '7px 12px 7px 16px',
    },
    '& .MuiTextField-root .MuiInputBase-root .MuiInputBase-input': {
      pl: '10px',
    },
    '& .MuiOutlinedInput-input': {
      fontFamily: 'Nunito',
      fontWeight: 300,
      fontSize: '16px',
      lineHeight: '22px',
      color: theme?.palette?.grayScale?.[700],
      "&::placeholder": {
        color: theme?.palette?.grayScale?.[700],
        opacity: '1'
      },
      ":-ms-input-placeholder ::-ms-input-placeholder": {
        color: theme?.palette?.grayScale?.[700],
        opacity: '1'
      },
    },
    '& .MuiInputAdornment-root': {
      mr: '0.5px'
    },
    '& .MuiTextField-root': {
      borderColor: theme?.palette?.grayScale?.[300],
      borderRadius: "6px",
      '& .MuiOutlinedInput-notchedOutline': {
        p: 0,
        borderColor: theme?.palette?.grayScale?.[300],
        borderRadius: "6px",
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.palette?.grayScale?.[300],
        borderRadius: "6px",
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.palette?.grayScale?.[300],
        borderRadius: "6px",
      },
    },
  },
  searchFieldInputStyle: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "6px",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "6px",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.grayScale?.[300]}`,
        borderRadius: "6px",
      },
    }
  },
  checkboxStyle: {
    '& .MuiTypography-root': {
      display: "-webkit-box",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      wordBreak: "break-all",
      overflow: "hidden",
    }
  }
}
const Library = () => {
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [list, setList] = useState<IPublishedModuleCardDataProps[]>([]);
  const [selectedSkillData, setSelectedSkillData] = useState<any[]>([]);
  const [unSelectedSkillData, setUnSelectedSkillData] = useState<any[]>([]);
  const [isVisibleSkillFlag, setIsVisibleSkillFlag] = useState<boolean>(false);
  const [viewMoreSkillsFlag, setViewMoreSkillsFlag] = useState<boolean>(true);
  const [selectedCourseData, setSelectedCourseData] = useState<any[]>([]);
  const [unSelectedCourseData, setUnSelectedCourseData] = useState<any[]>([]);
  const [isVisibleCourseFlag, setIsVisibleCourseFlag] = useState<boolean>(false);
  const [viewMoreCoursesFlag, setViewMoreCoursesFlag] = useState<boolean>(true);
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const [openSortModal, setSortModalState] = useState(false);
  const [isAscending, setIsAscending] = useState<boolean>(true);
  const [selSortType, setSelSortType] = useState<ISortFieldTypes>(ISortFieldTypes.CourseDate);
  const [clearFilters,setClearFliters] = useState(false);
  const getPublishedModules = useQuery('modules', async () => {
    const response = await axiosInstance().get<IPublishedModuleCardDataProps[]>('/contentcreator/getPublishedModule', {
      headers: {
        "Authorization": headers.Authorization,
        "Content-Type": "application/json"
      },
      data: {}
    });
    return response.data;
  }, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
  
  useEffect(() => {
    if (getPublishedModules?.data?.length) {
      setList(getPublishedModules.data);
      setFilteredList(getPublishedModules.data);
      let skillDataArr: any[] = [];
      getPublishedModules.data?.forEach((course: any) => {
        course?.skills?.map((skill: any) => {
          skillDataArr.push({
            skillName: skill.skillName,
            skillId: skill.skillId,
            isChecked: false,
            isVisible: false,
          })
          return skillDataArr
        })
      });
      let uniqueSkillData = skillDataArr.filter((value, index, self) =>
        self.findIndex(v => v.skillId === value.skillId) === index
      ) || [];
      uniqueSkillData = uniqueSkillData.map((skill, index) => {
        skill.isVisible = index < 4
        return skill
      }) || [];
      setUnSelectedSkillData(uniqueSkillData);
      
      let uniqueCourseData = getPublishedModules?.data?.filter((value, index, self) =>
        self.findIndex(v => v.courseTitle === value.courseTitle) === index
      ) || [];
      let courseDataArr: any[] = [];
      uniqueCourseData?.forEach((course: any, index: number) => {
        courseDataArr.push({
          courseName: course.courseTitle,
          courseId: course.id,
          isChecked: false,
          isVisible: index < 4 ? true : false,
        })
      })
      setUnSelectedCourseData(courseDataArr);
    }
  }, [getPublishedModules?.data, clearFilters]);

  useEffect(() => {
    if (unSelectedSkillData?.length) {
      let data = unSelectedSkillData.map((skill, index) => {
        if (index < 4) {
          skill.isVisible = true;
        } else {
          skill.isVisible = false;
        }
        return skill
      })
      setUnSelectedSkillData(data)
      setIsVisibleSkillFlag(false)
    }
  }, [isVisibleSkillFlag])

  useEffect(() => {
    if (unSelectedCourseData?.length) {
      let data = unSelectedCourseData.map((course, index) => {
        if (index < 4) {
          course.isVisible = true;
        } else {
          course.isVisible = false;
        }
        return course
      })
      setUnSelectedCourseData(data)
      setIsVisibleCourseFlag(false)
    }
  }, [isVisibleCourseFlag])

  const setFilterData = (dataArr: any, data: any, flag: boolean, checkCondition: Function) => {
    let dataClone = [...data]
    let findIndex = dataArr.findIndex((item: any) => {
      return checkCondition(item)
    })
    if (findIndex > -1) {
      let filteredItem = dataArr.splice(findIndex, 1)[0];
      filteredItem.isChecked = flag;
      dataClone.push(filteredItem)
    }
    return dataClone
  }

  const handleSkillsFilter = (selectedSkillId: string, mode: string) => {
    if (mode === 'UnSelectedData') {
      let data: any[] = [...selectedSkillData];
      data = setFilterData(unSelectedSkillData, data, true, (item: any) => item.skillId === selectedSkillId)
      setUnSelectedSkillData(unSelectedSkillData)
      setSelectedSkillData(data)
      filterData(data, selectedCourseData);
    } else {
      let data: any[] = [...unSelectedSkillData];
      data = setFilterData(selectedSkillData, data, false, (item: any) => item.skillId === selectedSkillId)
      setSelectedSkillData(selectedSkillData)
      setUnSelectedSkillData(data)
      filterData(selectedSkillData, selectedCourseData);
    }
  }

  const onViewMoreSkills = () => {
    if (viewMoreSkillsFlag) {
      let data = unSelectedSkillData.map((skill, index) => {
        skill.isVisible = true;
        return skill
      })
      setUnSelectedSkillData(data)
      setViewMoreSkillsFlag(false)
    } else {
      setIsVisibleSkillFlag(true)
      setViewMoreSkillsFlag(true)
    }
  }

  const handleCourseFilter = (selectedCourseName: string, mode: string) => {
    if (mode === 'UnSelectedData') {
      let data: any[] = [...selectedCourseData];
      data = setFilterData(unSelectedCourseData, data, true, (item: any) => item.courseName === selectedCourseName)
      setUnSelectedCourseData(unSelectedCourseData)
      setSelectedCourseData(data)
      filterData(selectedSkillData, data);
    } else {
      let data: any[] = [...unSelectedCourseData];
      data = setFilterData(selectedCourseData, data, false, (item: any) => item.courseName === selectedCourseName)
      setSelectedCourseData(selectedCourseData)
      setUnSelectedCourseData(data)
      filterData(selectedSkillData, selectedCourseData);
    }
  }

  const onViewMoreCourses = () => {
    if (viewMoreCoursesFlag) {
      let data = unSelectedCourseData.map((course, index) => {
        course.isVisible = true;
        return course
      })
      setUnSelectedCourseData(data)
      setViewMoreCoursesFlag(false)
    } else {
      setIsVisibleCourseFlag(true)
      setViewMoreCoursesFlag(true)
    }
  }

  const filterData = (skills: any, courses: any) => {
    const filteredListByCourse = list.filter((course) => {
      if (courses.length === 0) {
        return true
      }
      return courses.map((selectedCourse: any) => selectedCourse.courseName).includes(course.courseTitle)
    }) || [];
    const selectedSkillIds = skills.map((selectedSkill: any) => { return selectedSkill.skillId });
    const filteredListBySkill = list.filter((course) => {
      if (selectedSkillIds.length === 0) {
        return true
      }
      return course.skills.some((skill) => selectedSkillIds.includes(skill.skillId))
    }) || [];
    if (skills.length === 0 && courses.length === 0) {
      setFilteredList(list)
      setCurrentPage(1)
    } else if (skills.length === 0 && courses.length !== 0) {
      setFilteredList(filteredListByCourse)
      setCurrentPage(1)
    } else if (skills.length !== 0 && courses.length === 0) {
      setFilteredList(filteredListBySkill)
      setCurrentPage(1)
    } else {
      let uniqueFilteredData = [...filteredListByCourse, ...filteredListBySkill].filter((value, index, self) =>
        self.findIndex(v => v.id === value.id) === index
      );
      setFilteredList(uniqueFilteredData)
      setCurrentPage(1)
    }
  }

  const handlePageChange = useCallback((__: any, value: number) => {
    setCurrentPage(value);
  }, []);

  const handleCloseSortModal = useCallback(() => {
    setSortModalState(false);
  }, [])

  const onClickSortModal = useCallback(() => {
    setSortModalState(!openSortModal);
  }, [openSortModal])

  const sortedModuleList = useMemo<IPublishedModuleCardDataProps[]>(() => {
    return filteredList.length ? [...filteredList].sort((a: any, b: any) => {
      let fieldA, fieldB;
      switch (selSortType) {
        case ISortFieldTypes.CourseDate:
          fieldA = new Date(a.courseCreateDate).getTime();
          fieldB = new Date(b.courseCreateDate).getTime();
          break;
        case ISortFieldTypes.CourseName:
          fieldA = a.courseTitle.toLowerCase();
          fieldB = b.courseTitle.toLowerCase();
          break;
        case ISortFieldTypes.ModuleDate:
          fieldA = new Date(a.createDate).getTime();
          fieldB = new Date(b.createDate).getTime();
          break;
        default:
          fieldA = a.title.toLowerCase();
          fieldB = b.title.toLowerCase();
          break;
      }

      if ([ISortFieldTypes.CourseDate, ISortFieldTypes.ModuleDate].includes(selSortType)) {
        if (!isAscending)
          return fieldA - fieldB;
        else
          return fieldB - fieldA;
      } else {
        if (isAscending)
          return fieldA < fieldB ? -1 : Number(fieldA < fieldB);
        else
          return fieldA > fieldB ? -1 : Number(fieldA > fieldB);
      }
    }) : [];
  }, [filteredList, isAscending, selSortType]);

  const hideList = useMemo<boolean>(() => getPublishedModules.isLoading, [getPublishedModules.isLoading]);
  const emptyFilteredList = filteredList?.length === 0

  const handleClearFilters = ()=>{
      setSelectedSkillData([]);
      setSelectedCourseData([]);
      setClearFliters(prev=>!prev);
  }
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.libraryHeader}>Content Library</Typography>
      <Grid sx={styles.boxContainer}>
        {!hideList && (
          <Grid item>
            <Box sx={styles.filterContainer}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                mr={2}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <img
                    src={filterImage}
                    alt="filterIcon"
                    width={32}
                    height={21}
                  />
                  <Typography
                    sx={{
                      ...theme?.typography?.h2,
                      ml: "4px",
                      fontSize: "24px",
                    }}
                  >
                    Filters
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant="text"
                    sx={styles.clearBtn}
                    onClick={handleClearFilters}
                  >
                    <img src={clear} alt="clear" />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        marginLeft: "2px",
                        fontFamily: "Nunito",
                        fontSize: "14px",
                      }}
                    >
                      Clear
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box
                display={"flex"}
                flexDirection={"column"}
                className={"customScroll"}
                sx={styles.filterInnerContainer}
              >
                <Box mt={"20px"}>
                  <Typography sx={{ ...theme?.typography?.h3 }}>
                    Skills
                  </Typography>
                </Box>
                <Box mt={"12px"} width={"189px"} position={"relative"}>
                  {unSelectedSkillData.length ? (
                    <SearchField
                      placeholder="Search skills"
                      height="38px"
                      width="100%"
                      disablePortal
                      options={unSelectedSkillData}
                      sx={styles.searchFieldStyle}
                      inputStyle={styles.searchFieldInputStyle}
                      keyName={"skillId"}
                      optionKey={"skillName"}
                      optionLabel={(option: any) => option.skillName}
                      onChange={(e: any, selectedSkill: any) =>
                        handleSkillsFilter(
                          selectedSkill.skillId,
                          "UnSelectedData"
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                  <Box mt={"10px"}>
                    <Box>
                      {Children.toArray(
                        selectedSkillData.map((skill) => {
                          return (
                            <Checkbox
                              label={skill.skillName}
                              checkedColor="#3FB3B0"
                              unCheckedColor="#5D6A71"
                              value={skill.isChecked}
                              inputStyleProps={{
                                ...theme?.typography?.body?.default,
                                ...theme?.typography?.body?.body1,
                                ...theme?.typography?.body?.regular,
                                color: theme?.palette?.grayScale[500],
                                ...styles.checkboxStyle,
                              }}
                              onChange={() =>
                                handleSkillsFilter(
                                  skill.skillId,
                                  "SelectedData"
                                )
                              }
                            />
                          );
                        })
                      )}
                    </Box>
                    {selectedSkillData.length ? (
                      <Divider sx={{ mt: "10px", bgcolor: "#ADB3BD" }} />
                    ) : (
                      ""
                    )}
                    {Children.toArray(
                      unSelectedSkillData.map((skill) => {
                        if (skill.isVisible) {
                          return (
                            <Checkbox
                              label={skill.skillName}
                              checkedColor="#3FB3B0"
                              unCheckedColor="#5D6A71"
                              value={skill.isChecked}
                              inputStyleProps={{
                                ...theme?.typography?.body?.default,
                                ...theme?.typography?.body?.body1,
                                ...theme?.typography?.body?.regular,
                                color: theme?.palette?.grayScale[500],
                                ...styles.checkboxStyle,
                              }}
                              onChange={() =>
                                handleSkillsFilter(
                                  skill.skillId,
                                  "UnSelectedData"
                                )
                              }
                            />
                          );
                        }
                      })
                    )}
                    {unSelectedSkillData.length < 4 ? (
                      ""
                    ) : (
                      <Box mt={"4px"}>
                        <Divider
                          sx={{
                            "&::before, &::after": {
                              borderColor: "#26C5D2",
                            },
                          }}
                        >
                          <Button
                            variant="text"
                            sx={{
                              ...theme?.typography?.Components?.button?.default,
                              ...theme?.typography?.Components?.button?.large,
                              color: theme?.palette?.primary[500],
                              padding: "0px",
                              textTransform: "none",
                            }}
                            onClick={onViewMoreSkills}
                          >
                            {viewMoreSkillsFlag ? "View More" : "View Less"}
                          </Button>
                        </Divider>
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box mt={"20px"}>
                  <Typography sx={{ ...theme?.typography?.h3 }}>
                    Course
                  </Typography>
                </Box>
                <Box mt={"12px"} width={"189px"} position={"relative"}>
                  {unSelectedCourseData.length ? (
                    <SearchField
                      placeholder="Search courses"
                      height="38px"
                      width="100%"
                      disablePortal
                      options={unSelectedCourseData}
                      sx={styles.searchFieldStyle}
                      inputStyle={styles.searchFieldInputStyle}
                      keyName="courseId"
                      optionKey="courseName"
                      optionLabel={(option: any) => option.courseName}
                      onChange={(e: any, selectedCourse: any) =>
                        handleCourseFilter(
                          selectedCourse.courseName,
                          "UnSelectedData"
                        )
                      }
                    />
                  ) : (
                    ""
                  )}
                  <Box mt={"10px"}>
                    <Box>
                      {Children.toArray(
                        selectedCourseData.map((course) => {
                          return (
                            <Checkbox
                              label={course.courseName}
                              checkedColor="#3FB3B0"
                              unCheckedColor="#5D6A71"
                              value={course.isChecked}
                              inputStyleProps={{
                                ...theme?.typography?.body?.default,
                                ...theme?.typography?.body?.body1,
                                ...theme?.typography?.body?.regular,
                                color: theme?.palette?.grayScale[500],
                                ...styles.checkboxStyle,
                              }}
                              onChange={() =>
                                handleCourseFilter(
                                  course.courseName,
                                  "SelectedData"
                                )
                              }
                            />
                          );
                        })
                      )}
                    </Box>
                    {selectedCourseData.length ? (
                      <Divider sx={{ mt: "10px", bgcolor: "#ADB3BD" }} />
                    ) : (
                      ""
                    )}
                    <Box>
                      {Children.toArray(
                        unSelectedCourseData.map((course) => {
                          if (course.isVisible === true) {
                            return (
                              <Checkbox
                                label={course.courseName}
                                checkedColor="#3FB3B0"
                                unCheckedColor="#5D6A71"
                                value={course.isChecked}
                                inputStyleProps={{
                                  ...theme?.typography?.body?.default,
                                  ...theme?.typography?.body?.body1,
                                  ...theme?.typography?.body?.regular,
                                  color: theme?.palette?.grayScale[500],
                                  ...styles.checkboxStyle,
                                }}
                                onChange={() =>
                                  handleCourseFilter(
                                    course.courseName,
                                    "UnSelectedData"
                                  )
                                }
                              />
                            );
                          }
                        })
                      )}
                    </Box>
                    {unSelectedCourseData.length < 4 ? (
                      ""
                    ) : (
                      <Box mt={"4px"}>
                        <Divider
                          sx={{
                            "&::before, &::after": {
                              borderColor: "#26C5D2",
                            },
                          }}
                        >
                          <Button
                            variant="text"
                            sx={{
                              ...theme?.typography?.Components?.button?.default,
                              ...theme?.typography?.Components?.button?.large,
                              color: theme?.palette?.primary[500],
                              padding: "0px",
                              textTransform: "none",
                            }}
                            onClick={onViewMoreCourses}
                          >
                            {viewMoreCoursesFlag ? "View More" : "View Less"}
                          </Button>
                        </Divider>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item width={"100%"}>
          <Box sx={styles.rightContainer}>
            {!hideList && (
              <Box width={"fit-content"}>
                <Box sx={{ position: "relative" }}>
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={handleCloseSortModal}
                  >
                    <Box>
                      <Button
                        variant="outlined"
                        size="medium"
                        sx={styles.filterBtn}
                        onClick={onClickSortModal}
                      >
                        <img
                          src={sortSVG}
                          alt="sortIcon"
                          width={20}
                          height={18}
                        />
                        <Typography
                          component={"span"}
                          sx={styles.filterBtnText}
                        >
                          Sort
                        </Typography>
                      </Button>
                      <Box sx={styles.sortModalContainer}>
                        {openSortModal ? (
                          <SortModal
                            isAscending={isAscending}
                            selSortFieldType={selSortType}
                            setIsAscending={setIsAscending}
                            setSelSortFieldType={setSelSortType}
                          />
                        ) : null}
                      </Box>
                    </Box>
                  </ClickAwayListener>
                </Box>
              </Box>
            )}
            <Box sx={styles.listContainer} className="customScroll">
              {hideList ? (
                <Box sx={styles.boxImportFiles}>
                  <Box sx={styles.image}>
                    <img src={DraftsImageSVG} alt="add_icon" />
                  </Box>
                  <Box>
                    <Typography sx={styles.importText}>
                      You don't have anything yet.
                      <br />
                      Let's get started!
                    </Typography>
                  </Box>
                  <Box sx={styles.importButtonBox}>
                    <Button
                      sx={{ width: "250px", height: "51px" }}
                      variant="contained"
                      color="primary"
                      size="medium"
                      onClick={() => navigate("/drafts/import-checklist")}
                    >
                      <Typography
                        sx={{
                          ...theme?.typography?.Components?.button?.default,
                          ...theme?.typography?.Components?.button?.medium,
                          ...styles.importButton,
                        }}
                      >
                        Import Files
                      </Typography>
                    </Button>
                  </Box>
                  {getPublishedModules.isLoading && (
                    <CircularProgress size={60} sx={styles.loading} />
                  )}
                </Box>
              ) : emptyFilteredList ? (
                <Box sx={{ ...styles.boxImportFiles }}>
                  <Box sx={styles.image}>
                    <img src={DraftsImageSVG} alt="add_icon" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        ...theme?.typography?.h1,
                        lineHeight: "normal",
                        textAlign: "center",
                        color: '#676F7C',
                        mb: "23.91px",
                      }}
                    >
                      You don't have anything yet.
                      <br />
                      Let's get started!
                    </Typography>
                  </Box>
                  <Box sx={styles.importButtonBox}>
                    <Button
                      variant="outlined"
                      size="medium"
                      sx={{
                        ...styles.filterBtn,
                      }}
                      onClick={() => navigate("/drafts/add-new-module")}
                    >
                      <Typography
                        sx={{
                          ...theme?.typography?.Components?.button?.default,
                          ...theme?.typography?.Components?.button?.large,
                          lineHeight: "normal",
                          textTransform: "none",
                          color: "#2C39DA",
                        }}
                      >
                        New Module
                      </Typography>
                    </Button>
                    <Button
                      variant="outlined"
                      size="medium"
                      sx={{
                        ...styles.filterBtn,
                        ml: "12px",
                      }}
                      onClick={() => navigate("/drafts/import-checklist")}
                    >
                      <Typography
                        sx={{
                          ...theme?.typography?.Components?.button?.default,
                          ...theme?.typography?.Components?.button?.large,
                          lineHeight: "normal",
                          textTransform: "none",
                          color: "#2C39DA",
                        }}
                      >
                        Import Module
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              ) : (
                <PublishedModulesList
                  data={getCurrentPageList(currentPage - 1, sortedModuleList)}
                />
              )}
            </Box>
            {!emptyFilteredList && (
              <Box
                sx={styles.paginationContainer}
                display="flex"
                justifyContent={"end"}
              >
                {!hideList && (
                  <Pagination
                    page={currentPage}
                    count={getTotalPageCount(sortedModuleList.length)}
                    siblingCount={1}
                    boundaryCount={2}
                    onChange={handlePageChange}
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Library;
