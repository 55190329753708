import Search from "@mui/icons-material/Search";
import { Box, Typography } from "@mui/material";

const styles = {
  container: {
    position:'absolute',
    width: "239px",
    boxShadow:
      "0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
    padding: "25px 18px 14px 23px",
    zIndex: 1,
    backgroundColor: '#FFFFFF'
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  headerText: {
    fontFamily: "Museo Sans Rounded",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    marginLeft: "5px",
  },
  msg: {
    marginTop: "5px",
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
  },
};
export const NoModuleFound = () => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Search sx={{ color: "#4653F6" }} />
        <Typography sx={styles.headerText}>Module Not Found</Typography>
      </Box>
      <Box>
        <Typography sx={styles.msg}>
          Oops! No modules with that title were found. Try searching another
          Module Name.
        </Typography>
      </Box>
    </Box>
  );
};
