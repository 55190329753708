import { instance as axiosInstance } from 'core/util';

export const getPathwayDetail = async (id: string, headers: any, token: any) => {
    const response = await axiosInstance().get(`/contentcreator/pathways/${id}`, {
      headers: {
        "Authorization": headers.Authorization,
        "Content-Type": "application/json"
      },
      data: {}
    });
    return response.data; 
}

