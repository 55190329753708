import { Box, Card, Checkbox, Typography } from "@mui/material";
import { IPathwayModuleCardDataProps } from "pages/drafts/DraftsModule/types";
import { useNavigate } from "react-router-dom";
import { godzilla as theme } from 'util/themes/godzilla';

const styles = {
    mainContainer: {
        mb: 2,
        cursor: 'pointer',
        boxShadow: 'none',
        borderColor: '#C6CAD1'
    },
    itemContainer: (isDraft: boolean) => ({
        py: 2,
        pl: 2.5,
        pr: 5,
        alignItems: 'center',
        ...(isDraft && { borderColor: '#FFB800' })
    }),
    checkboxStyle: {
        '&:hover': { background: 'rgba(0, 173, 187, 0.08)' },
        '> .MuiTouchRipple-root span': {
            background: 'rgba(0, 173, 187, 0.3)'
        },
    },
    titleTextStyle: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '27px',
        color: '#3D4655',
        ml: 1.25
    },
    statusTextStyle: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#7E8592',
    }
}

interface IDraftModuleItemProps {
    isChecked: boolean,
    item: IPathwayModuleCardDataProps,
    onSelectPathwayData: (cardData: IPathwayModuleCardDataProps, e: React.ChangeEvent<HTMLInputElement>) => void
}

const DraftModuleItem = (props: IDraftModuleItemProps) => {
    const { isChecked, item, onSelectPathwayData } = props;
    const navigate = useNavigate();

    const isReadyToPublish = item?.data?.status?.toLowerCase() === 'readytopublish';

    const onCheckPathway = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        if (onSelectPathwayData) {
            onSelectPathwayData(item, e);
        }
    }

    return (
        <Card sx={{
            ...theme.card.cardBox,
            ...styles.mainContainer
        }}
        onClick={() => navigate(`/pathwayCreator/outline/${item?.header?.id}`)}>
            <Box display={'flex'} flexDirection={'row'} sx={{ ...theme.card.readytopublishLeftBorder, ...styles.itemContainer(!isReadyToPublish) }}>
                <Checkbox sx={styles.checkboxStyle} onClick={e => e.stopPropagation()} onChange={onCheckPathway} checked={isChecked} />
                <Typography noWrap={true} flex={1} sx={styles.titleTextStyle}>{item.data.title}</Typography>
                {isReadyToPublish && <Typography sx={styles.statusTextStyle} >Ready to Publish</Typography>}
            </Box>
        </Card>
    )
}

export default DraftModuleItem;