import {
  UPLOAD_COMPLETED,
  UPLOAD_IN_PROGRESS,
  UPLOAD_ERROR,
  UPLOAD_CANCELLED,
  START_UPLOAD,
  UPLOADING_FILE_DATA,
  UPLOADED_FILE_DATA,
  SET_REVIEW_MODULE_DATA,
  GET_REVIEW_MODULE_DATA,
  RESET_REVIEW_MODULE_DATA,
  SET_PATHWAY_DATA,
  RESET_PATHWAY_DATA,
  SET_PATHWAY_ERROR,
  RESET_PATHWAY_ERROR
} from './actions';

const reducer = (state: any, action: { type: string, payload?: any }) => {
  if (action.type === UPLOADING_FILE_DATA) {
    return {
      ...state,
      uploadingFileData: action.payload
    }
  }
  if (action.type === UPLOADED_FILE_DATA) {
    let newData = state.uploadingFileData.map((file: any) => {
      file['uploadProgress'] = state.uploadProgress;
      file['errorInUpload'] = false;
      return file;
    });
    return {
      ...state,
      uploadingFileData: [],
      uploadProgress: 0,
      uploadedFileData: newData.concat(state.uploadedFileData)
    }
  }
  if (action.type === START_UPLOAD) {
    return {
      ...state,
      isUploading: true
    }
  }
  if (action.type === UPLOAD_IN_PROGRESS) {
    return {
      ...state,
      isUploading: true,
      isUploadComplete: false,
      uploadProgress: action.payload,
      errorInUpload: false
    }
  }
  if (action.type === UPLOAD_COMPLETED) {
    return {
      ...state,
      isUploading: false,
      isUploadComplete: true,
      errorInUpload: false
    }
  }
  if (action.type === UPLOAD_CANCELLED) {
    return {
      ...state,
      isUploading: false,
      uploadProgress: 0,
      isUploadComplete: false,
      errorInUpload: false
    }
  }
  if (action.type === UPLOAD_ERROR) {
    let newData = state.uploadingFileData.map((file: any) => {
      file['uploadProgress'] = state.uploadProgress;
      file['errorInUpload'] = true;
      return file;
    });
    return {
      ...state,
      isUploading: false,
      isUploadComplete: false,
      errorInUpload: true,
      uploadingFileData: [],
      uploadProgress: 0,
      uploadedFileData: newData.concat(state.uploadedFileData)
    }
  }
  if (action.type === GET_REVIEW_MODULE_DATA) {
    return {
      ...state
    }
  }
  if (action.type === SET_REVIEW_MODULE_DATA) {
    return {
      ...state,
      reviewModuleData: action.payload
    }
  }
  if (action.type === RESET_REVIEW_MODULE_DATA) {
    return {
      ...state,
      reviewModuleData: []
    }
  }
  if (action.type === SET_PATHWAY_DATA) {
    return {
      ...state,
      pathwayData: action.payload
    }
  }
  if (action.type === RESET_PATHWAY_DATA) {
    return {
      ...state,
      pathwayData: action.payload
    }
  }
  if (action.type === SET_PATHWAY_ERROR) {
    return {
      ...state,
      pathwayError: action.payload
    }
  }
  if (action.type === RESET_PATHWAY_ERROR) {
    return {
      ...state,
      pathwayError: action.payload
    }
  }
  throw new Error(`no such action: ${action.type}`)
};

export default reducer;