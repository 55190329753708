import { createTheme } from '@mui/material/styles';
import { baseTheme } from './base';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  // allow configuration using `createTheme`
  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

const theme = createTheme(baseTheme, {
  palette: {
    primary: {
      10: '#E9EAFF',
      50: '#A9C8FF',
      100: '#68A0FF',
      200: '#3B7FF4',
      300: '#5B67FF',
      400: '#4653F6',
      500: '#003DA6',
      600: '#00348D',
      700: '#002970',
      800: '#001B4B',
      900: '#00112F',
      A1: '#4B89F4',
      A2: '#476598'
    },
    secondary: {
      10: '#E6FFFF',
      50: '#BFFBFF',
      100: '#E5DBF5',
      200: '#D8C9EF',
      300: '#CBB8EA',
      400: '#26C5D2',
      500: '#00ADBB',
      600: '#A583DB',
      700: '#9072BF',
      800: '#7B62A3',
      900: '#665187',
      A700: '#51406B'
    },
    tertiary: {
      50: '#E6FFFF',
      100: '#BFFBFF',
      200: '#99F3FD',
      300: '#73E8F3',
      400: '#4DD8E5',
      500: '#26C5D2',
      600: '#00ADBB',
      700: '#067C8C',
      800: '#035263',
      900: '#023541',
      red: {
        50: '#FFF3F3',
        100: '#FFB3B5',
        200: '#FF777B',
        300: '#FB5E62',
        400: '#EA4B4F',
        500: '#D53338',
        600: '#CA2C30',
        700: '#A91216',
        800: '#770E11',
        900: '#510407',
        A100_1: '#FF8A80',
        A100_2: '#FFBFC3',
        A200: '#FF99A3',
        A400: '#EE516C',
        A700: '#CB223F',
      },
      green: {
        50: '#CFFFED',
        100: '#B0FBDC',
        200: '#83F3C4',
        300: '#65E3AE',
        400: '#44D095',
        500: '#30BE82',
        600: '#24AB73',
        700: '#208F61',
        800: '#197C52',
        900: '#2D674E',
        A100: '#B0FFDF',
        A200: '#6CF9BD',
        A400: '#41D698',
        A700: '#22BA7A',
      },
      yellow: {
        50: '#FFFDE7',
        100: '#FFF9C4',
        200: '#FFF388',
        300: '#FFEE58',
        400: '#FFE500',
        500: '#FFD02B',
        600: '#FFC11B',
        700: '#FCA600',
        800: '#F58F17',
        900: '#F57F17',
        A100: '#FFE57F',
        A200: '#FFD740',
        A400: '#FFC400',
        A700: '#FFAB00',
      },
    },
    error: {
      main: '#EA1616',
      dark: '#CF0000',
      light: '#FF4F4F',
      contrastText: '#FFF3F3',
    },
    warning: {
      main: '#FFD02A',
      dark: '#FFC11B',
      light: '#FFE500',
      contrastText: '#5C003C',
    },
    info: {
      main: '#2375C0',
      dark: '#1A64A8',
      light: '#3291E9',
      contrastText: '#FFFFFF',
    },
    success: {
      main: '#197C52',
      dark: '#2D674E',
      light: '#24AB72',
      contrastText: '#CFFFED',
    },
    grayScale: {
      10: '#F9FAFC',
      50: '#DEE1E6',
      100: '#B4B5B8',
      200: '#ADB3BD',
      300: '#959CA8',
      400: '#7E8592',
      500: '#4B4D58',
      600: '#505866',
      700: '#3D4655',
      800: '#2C3442',
      900: '#12162A',
      A1: '#D1D4DD'
    },
    background: {
      paper: '#FFFFFF',
      default: '#F5F5F5',
    },
    action: {
      active: 'rgba(43, 54, 64, 0.54)',
      hover: 'rgba(43, 54, 64, 0.04)',
      selected: 'rgba(43, 54, 64, 0.08)',
      disabled: 'rgba(43, 54, 64, 0.26)',
      disabledBackground: 'rgba(43, 54, 64, 0.12)',
      focus: 'rgba(0, 0, 0, 0.12)',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    text: {
      primary: '#293541',
      secondary: '#47515A',
      disabled: '#5D6A71',
    },
  },
  typography: {
    fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
    h1: {
      fontFamily: ['Museo Sans Rounded', 'Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '32px',
      lineHeight: '38px',
    },
    h2: {
      fontFamily: ['Museo Sans Rounded', 'Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '28px',
      lineHeight: '34px',
    },
    h3: {
      fontFamily: ['Museo Sans Rounded', 'Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '29px',
    },
    h4: {
      fontFamily: ['Museo Sans Rounded', 'Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '21px',
      lineHeight: '25px',
    },
    h5: {
      fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '21px',
    },
    h6: {
      fontFamily: ['Museo Sans Rounded', 'Open Sans', 'sans serif'].join(','),
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '19px',
    },
    subtitle1: {
      fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '.15px',
    },
    subtitle2: {
      fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '.1px',
    },
    body1: {
      fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
    },
    body2: {
      fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
    },
    body3: {
      fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(','),
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '20px',
      lineHeight: '26px',
      color: '#202124',
    },
    body: {
      default: {
        fontFamily: ['Nunito', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        color: '#000000',
      },
      body1: {
        fontSize: '16px',
        lineHeight: '22px',
      },
      body2: {
        fontSize: '14px',
        lineHeight: '19px',
      },
      body3: {
        fontSize: '20px',
        lineHeight: '27px',
        color: '#202124',
      },
      light: {
        fontWeight: '300',
      },
      regular: {
        fontWeight: '400',
      },
      medium: {
        fontWeight: '500',
      },
      semibold: {
        fontWeight: '600',
      },
      bold: {
        fontWeight: '700',
      },
    },
    subtitles: {
      default: {
        fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        lineHeight: '19px',
        color: '#000000',
      },
      subtitle1: {
        fontSize: '16px',
        letterSpacing: '0.15px',
      },
      subtitle2: {
        fontSize: '14px',
        letterSpacing: '0.1px',
      },
      regular: {
        fontWeight: '400',
      },
      medium: {
        fontWeight: '500',
      },
      semibold: {
        fontWeight: '600',
      },
      bold: {
        fontWeight: '700',
      },
    },
    captions: {
      default: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        color: '#000000',
      },
      regular: {
        fontWeight: '400',
      },
      medium: {
        fontWeight: '500',
      },
      semibold: {
        fontWeight: '600',
      },
      bold: {
        fontWeight: '700',
      },
    },
    banner: {
      banner1: {
        fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '80px',
        lineHeight: '94px',
      },
      banner2: {
        fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '64px',
        lineHeight: '75px',
      },
      bannerSubtitle: {
        fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '300',
        fontSize: '40px',
        lineHeight: '47px',
        letterSpacing: '8px',
      },
      bannerMobile: {
        fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
        fontWeight: '500',
        fontSize: '40px',
        lineHeight: '47px',
      },
    },
    caption: {
      fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '.4px',
    },
    overline: {
      fontFamily: ['Red Hat Display', 'Open Sans', 'sans serif'].join(
        ','
      ),
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    Components: {
      alertTitle: {
        fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: '#000000',
      },
      avatarInitials: {
        fontFamily: ['Raleway', 'Open Sans', 'sans serif'].join(','),
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '20px',
        letterSpacing: '0.14px',
        textTransform: 'uppercase',
        color: '#000000',
      },
      badgeLabel: {
        fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.14px',
        color: '#000000',
      },
      button: {
        default: {
          fontFamily: [
            'Nunito',
            'Open Sans',
            'sans serif',
          ].join(','),
          fontStyle: 'normal',
          textTransform: 'uppercase',
        },
        large: {
          fontWeight: '700',
          fontSize: '15px',
          lineHeight: '20px',
        },
        medium: {
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '19px',
        },
        small: {
          fontWeight: '700',
          fontSize: '14px',
          lineHeight: '22px',
        },
      },
      breadcrumb: {
        default: {
          px: 5,
          pt: 2,
          pb: 0,
        },
        link: {
          '&  > a': {
            textDecoration: 'none',
            color: '#47515A',
            fontWeight: '500',
          },
        },
        linkFade: {
          '&  > a': {
            textDecoration: 'none',
            color: '#6F858C',
          },
        },
        linkText: {
          '&  > a > span': {
            height: '1 rem',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            position: 'relative',
            '&:after': {
              content: '""',
              backgroundColor: '#B5F5EF',
              position: 'absolute' as const,
              bottom: '0px',
              width: '100%',
              height: '6px',
              zIndex: '-1',
              transition: 'all .3s ease-in-out',
            },
            '&:hover': {
              '&:after': {
                height: '1rem',
              },
            },
          },
        },
      },
      inputLabel: {
        fontFamily: ['Nunito', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.15px',
        color: '#000000',
      },
      helperText: {
        fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '0.4px',
        color: '#000000',
      },
      inputText: {
        fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '16px',
        lineHeight: '22px',
        letterSpacing: '0.15px',
        color: '#000000',
      },
      chip: {
        fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '18px',
        letterSpacing: '0.16px',
        color: '#000000',
      },
      tabBar: {
        tabs: {
          '& .MuiTab-root.Mui-selected': {
            color: '#47515A',
            fontWeight: '500',
          },
          '& .MuiTabs-indicator': {
            backgroundColor: '#B5F5EF',
            bottom: '15px',
            height: '8px',
            zIndex: -1,
          },
        },
        tabHover: {
          '& .MuiTabs-scroller': {
            '& .MuiTabs-flexContainer': {
              '& button': {
                height: '3.5rem',
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                position: 'relative',
                '&[aria-selected = "false"]': {
                  '&:after': {
                    content: '""',
                    backgroundColor: '#B5F5EF',
                    position: 'absolute' as const,
                    left: '.5rem',
                    bottom: '1.125rem',
                    width: 'calc(100% - 1.25rem)',
                    height: '0px',
                    zIndex: '-1',
                    transition: 'all .3s ease-in-out',
                  },
                  '&:hover': {
                    '&:after': {
                      bottom: '1rem',
                      height: 'calc(100% - 2rem)',
                    },
                  },
                },
                '&[aria-selected = "true"]': {
                  '&:after': {
                    content: '""',
                    backgroundColor: '#B5F5EF',
                    position: 'absolute' as const,
                    left: '.5rem',
                    bottom: '1.125rem',
                    width: 'calc(100% - 1.25rem)',
                    height: '.5rem',
                    zIndex: '-1',
                    transition: 'all .3s ease-in-out',
                  },
                  '&:hover': {
                    '&:after': {
                      bottom: '1rem',
                      height: 'calc(100% - 2rem)',
                    },
                  },
                },
              },
            },
          },
        },
        tab: {
          fontFamily: [
            'Red Hat Text',
            'Open Sans',
            'sans serif',
          ].join(','),
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '20px',
          lineHeight: '24px',
          color: '#47515A',
        },
      },
      tooltip: {
        fontFamily: ['Nunito', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '10px',
        lineHeight: '14px'
      },
      tableHeader: {
        fontFamily: ['Source Code Pro', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.17px',
        color: '#000000',
      },
      toast: {
        default: {
          boxShadow: '0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
          borderRadius: '10px',
        },
        success: {
          backgroundColor: '#07A82A',
        },
        error: {
          backgroundColor: '#CF0000',
        },
        successText: {
          fontFamily: 'Nunito',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '22px',
          color: '#DCFFDD',
      },
      errorText: {
        fontFamily: 'Nunito',
          fontWeight: 500,
          fontSize: '16px',
          lineHeight: '22px',
          color: '#FFFFFF',
      }
      },
      menuItem: {
        fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '18px',
        lineHeight: '150%',
        letterSpacing: '0.15px',
        color: '#000000',
      },
      menuItemSelected: {
        fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '18px',
        lineHight: '150%',
        letterSpacing: '0.15px',
        color: '#000000',
      },
      menuItemDense: {
        fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '24px',
        letterSpacing: '0.17px',
        color: '#000000',
      },
      listSubHeader: {
        fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '14px',
        lineHeight: '48px',
        letterSpacing: '0.1px',
        color: '#000000',
      },
      bottomNavigationActiveLabel: {
        fontFamily: ['Red Hat Text', 'Open Sans', 'sans serif'].join(
          ','
        ),
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '14px',
        lineHeight: '166%',
        letterSpacing: '0.4px',
        color: '#000000',
      },
      cardAccordion: {
        cardHeaderText: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '24px',
          letterSpacing: '0.4px',
          color: '#000000'
        },
        headerText: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '18px',
          lineHeight: '25px',
          color: '#4B4D58'
        },
        statusText: {
          fontFamily: 'Open Sans',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '22px',
          color: '#999CAF'
        },
      },
      pagenameHeaderText: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#2D3648'
      },
      deleteText: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#4B4D58'
      },
      tagrequiredText: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#DF2F2F'
      },
      loadingHeaderText: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '25px',
        color: '#999CAF'
      },
      inputfieldErrorText: {
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#DF2F2F'
      }
    },
  },
  card: {
    base: {
      mb: 3,
    },
    default: {
      background: '#FFFFFF',
      border: '2px solid #AABABF',
      borderRadius: '5px',
      boxShadow: 'none',
      overflow: 'hidden',
    },
    deActivate: {
      background: '#F7F9FA',
    },
    active: {
      background: 'rgba(223, 253, 249, 0.3)',
    },
    elevated: {
      border: '2px solid #3FB3B0',
      backgroundColor: 'rgba(223, 253, 249, 0.45)',
    },
    cardPadding: { p: 3 },
    gridWrapper: { mb: 3, flexGrow: 0 },
    milestone: {
      border: '2px solid #69D7D2',
      borderTop: '12px solid #69D7D2',
    },
    cardBox: {
      background: '#FFFFFF',
      border: '1px solid #D6D9E1',
      borderRadius: '8px',
      '&:hover': {
        background: '#F2F5FF',
        border: '2px solid #D6D9E1',
      },
    },
    cardBoxSmall: {
      background: '#FFFFFF',
      border: '1px solid #D1D4DD',
      borderRadius: '8px',
      '&:hover': {
        background: '#F2F5FF',
        border: '2px'
      },
    },
    needreviewandopenLeftBorder: {
      borderLeft: '10px solid #F8B05C'
    },
    readytopublishLeftBorder: {
      borderLeft: '10px solid #00ADBB'
    },
    editingViewBoxLeftBorder: {
      borderLeft: '10px solid #003DA6'
    },
    errorBoxLeftBorder: {
      borderLeft: '10px solid #FF4F4F'
    },
    openCardBox: {
      background: '#FFFFFF',
      border: '1px solid #D1D4DD',
      borderRadius: '12px'
    },
    pagenameBox1: {
      background: '#C178E0',
      borderRadius: '10px',
      width: '7px',
      height: '24px'
    },
    pagenameBox2: {
      background: '#9A9CF8',
      borderRadius: '10px',
      width: '7px',
      height: '24px'
    },
    editingViewBox: {
      background: '#FFFFFF',
      border: '2px solid #003DA6',
      borderRadius: '8px',
      height: '148px',
      '&:hover': {
        background: '#F2F5FF',
      },
    },
    pagenameSelectedBox: {
      background: '#F7E1FF'
    },
    errorBox: {
      background: '#FFFFFF',
      border: '2px solid #FF6363',
      borderRadius: '8px',
      height: '72px',
    },
    loadingBox: {
      background: '#FFFFFF',
      border: '1px dashed #D6D9E1',
      borderRadius: '8px',
      height: '72px',
    }
  },
  imageBackground: {
    display: 'block',
    maxWidth: '100%',
    maxHeight: '65vh',
    height: 'auto',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  table: {
    default: {
      border: '2px solid #AABABF',
      borderRadius: '5px',
    },

    header: {
      background: '#F7F9FA',
      border: 'none',
    },
    headerCell: {
      fontFamily: 'Source Code Pro',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.17px',
      color: '#293541',
    },
    headerCellTitle: {
      fontWeight: 700,
    },
    rows: {
      background: '#FFFFFF',
      borderWidth: '1px 0px',
      borderStyle: 'solid',
      borderColor: '#D2DBDE',
    },
    rowCell: {
      fontFamily: 'Red Hat Text',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '19px',
      color: '#47515A',
    },
    rowCellBold: {
      fontWeight: 600,
    },
    rowExpanded: {
      background: '#F7F9FA',
      border: '1px solid #D2DBDE',
      height: '100%',
    },
    rowExpandedCell: {
      fontSize: '16px',
      lineHeight: '21px',
    },
    rowExpandedCellBold: {
      fontWeight: 700,
    },
  },
  button: {
    default: {
      borderRadius: '100px',
    },
    size: {
      medium: {
        px: 2,
        py: 0.75,
      },
    },
    disabled: {
      border: '1px dashed #AABABF',
      color: '#5D6A71',
      background: '#FFFFFF',
    },
    contained: {
      primary: {
        background: '#003DA6',
        color: '#FFFFFF',
        '&:hover': {
          background: 'linear-gradient(98.91deg, #626DFF 7.1%, #003DA6 90%)'
        },
        '> .MuiTouchRipple-root span': {
          background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(122, 132, 255, 0.7) 99.54%, rgba(255, 255, 255, 0) 100%)'
        },
      },
      error: {
        background: '#EA1616',
        color: '#FFF3F3',
        '&:hover': {
          background: '#CF0000',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      warning: {
        background: '#FFD02A',
        color: '#5C003C',
        '&:hover': {
          background: '#FFC11B',
          boxShadow:
            '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      info: {
        background: '#2375C0',
        color: '#FFFFFF',
        '&:hover': {
          background: '#1A64A8',
          boxShadow:
            '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      success: {
        background: '#197C52',
        color: '#CFFFED',
        '&:hover': {
          background: '#2D674E',
          boxShadow:
            '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 255, 255, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      inherit: {
        background: '#E7ECEE',
        color: '#293541',
        '&:hover': {
          background: '#D2DBDE',
          boxShadow:
            '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(43, 54, 64, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
    },
    outlined: {
      primary: {
        border: '2px solid #1C68EC',
        background: '#FFFFFF',
        color: '#003DA6',
        '&:hover': {
          background: '003DA60a',
          border: '2px solid #003DA6'
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(105, 215, 210, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      secondary: {
        border: '1px solid #8499A0',
        background: '#FFFFFF',
        color: '#47515A',
        '&:hover': {
          background: 'rgba(93, 106, 113, 0.04)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(132, 153, 160, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      error: {
        background: 'rgba(202, 44, 48, 0.04)',
        border: '1px solid #CA2C30',
        color: '#CA2C30',
        '&:hover': {
          background: 'rgba(202, 44, 48, 0.12)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(202, 44, 48, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      warning: {
        border: '1px solid #FFC11B',
        background: '#FFFFFF',
        color: '#7C0338',
        '&:hover': {
          background: 'rgba(255, 208, 42, 0.04)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 208, 42, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      info: {
        border: '1px solid #2375C0',
        background: '#FFFFFF',
        color: '#2375C0',
        '&:hover': {
          background: 'rgba(35, 117, 192, 0.04)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(35, 117, 192, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      success: {
        border: '1px solid #83F3C4',
        background: '#FFFFFF',
        color: '#197C52',
        '&:hover': {
          background: 'rgba(131, 243, 196, 0.09)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(131, 243, 196, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      inherit: {
        border: '1px solid #293541',
        background: '#FFFFFF',
        color: '#293541',
        '&:hover': {
          background: 'rgba(43, 54, 64, 0.04)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(43, 54, 64, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
    },
    text: {
      primary: {
        color: '#003DA6',
        '&:hover': {
          background: 'rgba(0, 61, 166, 0.04))',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(119, 129, 255, 0.50) 99.54%, rgba(255, 255, 255, 0.00) 100%)',
        },
      },
      secondary: {
        color: '#5D6A71',
        '&:hover': {
          background: 'rgba(93, 106, 113, 0.04)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(132, 153, 160, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      error: {
        color: '#CA2C30',
        '&:hover': {
          background: 'rgba(202, 44, 48, 0.04)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(202, 44, 48, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      warning: {
        color: '#7C0338',
        '&:hover': {
          background: 'rgba(255, 208, 42, 0.04)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(255, 208, 42, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      info: {
        color: '#2375C0',
        '&:hover': {
          background: 'rgba(35, 117, 192, 0.04)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(35, 117, 192, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      success: {
        color: '#197C52',
        '&:hover': {
          background: 'rgba(131, 243, 196, 0.09)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.8% at 50% 50%, rgba(131, 243, 196, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)',
        },
      },
      inherit: {
        color: '#293541',
        '&:hover': {
          background: 'rgba(0, 0, 0, 0.04)',
        },
        '> .MuiTouchRipple-root span': {
          background:
            'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(0, 0, 0, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)',
        },
      },
    },
  },
  summaryTable: {
    header: {
      border: '1px solid #DADCE0',
      backgroundColor: '#F5F6F8',
    },
    headerCell: {
      textTransform: 'uppercase',
      py: 1,
      fontSize: '12px',
      color: '#80848D',
      fontWeight: 'bold',
      border: 'none',
    },
    rowCell: {
      fontSize: '14px',
      border: 'none',
    },
    row: {
      backgroundColor: 'white',
      border: '1px solid #DADCE0',
      width: '100%',
    },
    rowExpanded: {
      backgroundColor: 'none',
      border: '1px solid #DADCE0',
      width: '100%',
    },
  },
  input: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px !important',
      '.MuiSelect-select, input': {
        padding: '12px 12px 12px 16px',
      },
      '& fieldset': {
        border: '1px solid #CBD2E0 !important',
      },
      '&:hover fieldset': {
        border: '1.5px solid #6F6FE4 !important',
      },
      '&.Mui-focused fieldset': {
        border: '1.5px solid #6F6FE4 !important',
      },
      '&.Mui-error fieldset': {
        border: '2px solid #FF6363 !important',
      }
    },
  },
  paper: {
    card: {
      default: {
        background: '#ffffff',
        boxShadow: 0,
        borderColor: '#3FB3B0',
      },
      active: {
        background: '#DFFDF9',
        boxShadow: 6,
      },
      disabled: {
        background: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 0,
      },
    },
  }
}) as any;

export { theme, theme as godzilla };
