import PathwayLayout from "../../PathwayLayout/PathwayLayout"
import { getDefaultHeaders } from 'core/util';
import { useAuth } from 'core/contexts';
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Outline from "./Outline";
import { useAppContext } from "components/app-context/appContext";
import { useEffect } from "react";
import { getPathwayDetail } from "util/getPathwayDetail.api";
import { getPathwayCreatorObject } from "../pathwayCreator.helper";

const PathwayOutline = () => {
  const { getToken } = useAuth();
  const { setPathwayData, pathwayData } = useAppContext();
  const headers = getDefaultHeaders(getToken());
  const params = useParams();

  const { data, isLoading } = useQuery('pathways', async () => 
     getPathwayDetail(params?.id || '', headers ,getToken())
  , {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if(!isLoading && !pathwayData.pathway?.data?.title) {
      setPathwayData({...pathwayData, ...getPathwayCreatorObject(data)})
    }
  }, [isLoading])

  const pathwayTitle = isLoading ? null : pathwayData.pathway?.data?.title || data?.pathway?.data?.title || '';

  return (
    <>
      <PathwayLayout
        activeIndex={0}
        buttonText={"Build"}
        exitBtnText={"No, continue creating my pathway"}
        exitNavigation={'/pathway'}
        pathwayTitle={pathwayTitle} >
          <Outline activeIndex={0}/>
      </PathwayLayout >
    </>
  )
}

export default PathwayOutline;