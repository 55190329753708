import React, { Children } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import checklistBadge from '../../../assets/images/checklist_badge.svg';
import { checklist } from './draftImportChecklist.helper';
import { Button } from 'core/components';
import { godzilla as theme } from 'util/themes/godzilla';

const styles = {
  checklistHeading: {
    fontFamily: 'Museo Sans Rounded',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '24px',
    letterSpacing: '-0.01em',
    color: theme?.palette?.grayScale[500]
  },
  checklistItem: {
    fontFamily: 'Nunito Sans',
    fontSize: '20px',
    fontWeight: 400,
    fontStyle: 'normal',
    color: theme?.palette?.grayScale[700]
  },
  checklistBtn: {
    fontWeight: 600,
    fontSize: '21px',
    lineHeight: '29px',
    textTransform: 'none',
    letterSpacing: '-0.01em'
  }
}
const DraftsImportChecklist = () => {
  const navigate = useNavigate();
  return (
    <Box display={'flex'} justifyContent={'center'} sx={{background: '#FFF', height: '100vh'}}>
      <Grid sx={{ my: 21.625 }}>
        <Grid display={'flex'} justifyContent={'center'} mb={'30px'}>
          <Typography sx={styles.checklistHeading}>
            {'Before You Start'}
          </Typography>
        </Grid>
        {
          Children.toArray(
            checklist.map((item: string, i: number) => (
              <Grid flexDirection={'row'} display={'flex'} mt={'16.5px'} width={417} alignItems={'center'}>
                <Grid display={'flex'} alignItems={'center'}>
                  <img src={checklistBadge} alt={`badge_${i}`} height={26} width={26} />
                </Grid>
                <Grid ml={2.375}>
                  <Typography sx={styles.checklistItem}>
                    {item}
                  </Typography>
                </Grid>
              </Grid>
            ))
          )
        }
        <Grid display={'flex'} justifyContent={'center'} mt={5}>
          <Button
            variant='contained'
            size='medium'
            color='primary'
            sx={{ width: '250px', height: '51px' }}
            onClick={() => navigate('/drafts/import-file')}
          >
            <Typography sx={{
              ...theme?.typography?.Components?.button?.default,
              ...theme?.typography?.Components?.button?.medium,
              ...styles.checklistBtn
            }}>
              {'I’m Ready'}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
};

export default DraftsImportChecklist;