import {
  Box,
  ClickAwayListener,
  Step,
  StepIcon,
  Stepper,
  TextField,
  Typography,
  Tooltip,
  Grid,
  CircularProgress
} from "@mui/material";
import LifeTrekLogo from "assets/images/sidenav_logo.svg";
import BuildIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Button } from "core/components";
import { godzilla as theme } from "util/themes/godzilla";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ExitModal from "./components/ExitModal";
import { useEffect, useCallback, useMemo, useState } from "react";
import Toast, { IToastTypes } from 'components/toast/Toast';
import { IContextData, IDemonstrationMilestoneData, IKnowledgeMilestoneData } from "../PathwayCreator/PathwayOutline/outline.helper";
import { RgxPathwayMilestoneTitle } from "../PathwayCreator/PathwayOutline/components/ViewMilestone";
import { useAppContext } from "components/app-context/appContext";

import ErrorIcon from "../../../assets/images/erroricon.svg";
import { getDefaultHeaders, instance as axiosInstance } from "core/util";
import { useAuth } from 'core/contexts';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { containsOnlyWhitespaceOrSpecialChars } from "../PathwayCreator/PathwayBuild/builder.helper";
import moment from "moment";
import { getPathwayCreatorObject } from "../PathwayCreator/pathwayCreator.helper";
import { PublishModal } from "./components/PublishModal";
import { createPortal } from 'react-dom';

interface IPathwayLayoutProps {
  children?: any;
  activeIndex: number;
  pathwayTitle?: string;
  buttonText?: string;
  pathwayDetail?: any;
  isBreadCrumb?: boolean;
  hasExitToBuildButton?: boolean;
  BreadCrumbCmp?: any;
  exitBtnText?: string;
  exitNavigation?: any;
  onClickSaveValidate?: () => boolean;
  onClickNextValidate?: () => boolean;
  onClickPublish?: () => boolean;
}

const styles = {
  centerContentStyle: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    top: 0,
    display: 'flex',
    justifyContent: 'center', 
    alignItems: 'center'
  },
  saveButtonTextStyle: {
    fontWeight: 700,
    fontSize: "19px",
    lineHeight: "26px",
  },
  buildButtonTextStyle: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "25px",
  },
  exitModalContainer: {
    zIndex: 3,
    position: 'absolute',
    top: '30px',
    right: '8px'
  },
  publishButtonTextStyle: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "25px",
  },
  saveAndExitButtonTextStyle: {
    fontWeight: 700,
    fontSize: "19px",
    lineHeight: "26px",
  },
  titleInput: (pathwayTitle: string, saveTimeStamp: string) => ({
    width: saveTimeStamp === "" ? '62vw' : 'calc(62vw - 180px)',
    '& .MuiOutlinedInput-root': {
      fontSize: pathwayTitle.length > 60 ? '24px' : '28px',
      fontWeight: 400,
      fontFamily: 'Museo Sans Rounded',
      p: 0,
      '& fieldset': {
        borderColor: 'rgba(0,0,0,0)',
      },
      '&:hover fieldset': {
        borderColor: theme.palette?.primary[300],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette?.primary[300],
        boxShadow: '0px 0px 3px rgba(0, 118, 222, 0.5)',
      },
      '& textarea ': {
        p: '8px 9px',
        width: '62vw'
      }
    },
  }),
  exitButtonStyle: {
    px: '11px',
    py: '8px',
    '&:hover': { background: 'rgba(0, 0, 0, 0.04)' },
    '> .MuiTouchRipple-root span': {
      background: 'radial-gradient(36.59% 100.80% at 50.00% 50.00%, rgba(0, 0, 0, 0.30) 99.54%, rgba(255, 255, 255, 0.00) 100%)'
    },
  },
  saveTimeContainer: {
    display: "flex",
    alignItems: "center",
    mr: "12px",
  },
  saveTimeTextStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    color: "#676F7C",
  },
};
const errorStyles = {
  title: (title: string) => ({
    '& .MuiOutlinedInput-root': {
      padding: '8px 9px',
      fontSize: title.length > 60 ? '24px' : '28px',
      fontWeight: 400,
      fontFamily: 'Museo Sans Rounded',
      '& fieldset': {
        border: '2px solid red',
      },
      '&:hover fieldset': {
        border: '2px solid red',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid red'
      },
    },
  }),
  titleErrorText: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontsize: '12px',
    color: theme?.palette?.error?.dark,
  }
}

const buildErrorMessage = `You must add at least one milestone before moving to the 'Build' step.`
const settingErrorMessage = 'You must add one module to each Knowledge Milestone and fill out all required Demonstration Milestone fields before moving to the next step.';

const PathwayLayout = (props: IPathwayLayoutProps) => {
  const { children, activeIndex, buttonText, pathwayTitle, exitBtnText, hasExitToBuildButton, exitNavigation, pathwayDetail, isBreadCrumb = false, BreadCrumbCmp, onClickSaveValidate, onClickNextValidate, onClickPublish } =
    props;

  const { pathwayData, pathwayError, setPathwayData, setPathwayError, setToastType, setShowToast, setToastMesage }: IContextData = useAppContext();

  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());
  const params = useParams();
  const { pathname } = useLocation();
  const [title, setTitle] = useState({
    value: '',
    error: false,
    errorMsg: ''
  });
  const [openExitModal, setExitModalState] = useState(false);
  const [showBuildToast, setShowBuildToast] = useState(false);
  const [saveTimeStamp, setSaveTimeStamp] = useState<string>('');
  const [openPublishConfirmModal, setOpenPublishConfirmModal] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [publishButtonLoading, setPublishButtonLoading] = useState(false);
  const navigate = useNavigate();

  const milestoneData = pathwayData.milestones;

  const stepperIconColors = {
    active: {
      bgColor: theme?.palette?.primary[300],
      iconColor: theme?.palette?.secondary[50],
    },
    completed: {
      bgColor: '#FFFFFF',
      iconColor: theme?.palette?.primary[400],
    },
    inActive: {
      bgColor: theme?.palette?.grayScale[50],
      iconColor: '#676F7C',
    }
  }

  const onClickExitModal = () => {
    setExitModalState(!openExitModal);
  }

  const handleCloseExitModal = () => {
    setExitModalState(false);
  };

  useEffect(() => {
    return () => {
      setPathwayError({
        demonstrationMilestoneError: false,
        disableStepper: false,
        knowledgeMilestoneError: false,
        milestoneListError: []
      })
    }
  }, [])

  useEffect(() => {
    if(pathwayTitle){
      setTitle({
        value: pathwayTitle,
        error: false,
        errorMsg: "",
      });
    }
  }, [pathwayTitle])
  

  const milestoneLength = useMemo(() => (milestoneData?.filter((item: any)=> item.action !== "DELETE") || []).length , [milestoneData]);

  const isPathwayTitleError = useMemo((): boolean => (title.value === '' || title.value === null || title.error) , [title]);
  
  const validateMilestonesName = useCallback((): boolean => {
    let isError = true;

    isError = milestoneData.some((item) => {
      return (item?.title?.trim() === "" || item?.title?.length > 100 || !item?.title?.match(RgxPathwayMilestoneTitle)) && item.action !== 'DELETE'
    })

    return isError;
  }, [milestoneData])

  const validateMilestonesContent = useCallback((): Array<number> => {
    const milestoneListError: Array<number> = [];
    milestoneData.filter(item => item.action !== 'DELETE').forEach((item, index) => {
      // validate milestones title 
      if (item?.title?.trim() === "" || item?.title?.length > 100 || !item?.title?.match(RgxPathwayMilestoneTitle)) {
        milestoneListError.push(index);
        return;
      }

      if (item.type === "KNOWLEDGE") {
        !(item as IKnowledgeMilestoneData).modules?.length && milestoneListError.push(index);
      } else {
        const { successDefinition, descriptionOfDuration, checklist, completionTime, completionTimeUnit } = item as IDemonstrationMilestoneData;
        const invalidSuccessDefinition = containsOnlyWhitespaceOrSpecialChars(successDefinition);
        const invalidDescriptionOfDuration = containsOnlyWhitespaceOrSpecialChars(descriptionOfDuration);
        const invalidChecklist = checklist?.length === 0 || !!checklist?.find(item => item.length > 100);
        if(checklist?.length > 0) {

        }
        const invalidCompletionTime = Number(completionTime) <= 0 || Number(completionTime) > 99;

        if (!successDefinition || invalidSuccessDefinition || !descriptionOfDuration || invalidDescriptionOfDuration || invalidChecklist || invalidCompletionTime || !completionTimeUnit) {
          milestoneListError.push(index);
        }
      }
    })

    return milestoneListError;
  }, [milestoneData]);

  const nextButtonFunction = () => {
    if (isPathwayTitleError) {
      setTitle({
        ...title,
        error: true,
        errorMsg: "Please enter a valid title",
      });
      return;
    }
    if (onClickNextValidate && onClickNextValidate()) {
      return;
    }

    if (activeIndex === 0) {
      const milestoneError = pathwayError.knowledgeMilestoneError ||
        pathwayError.demonstrationMilestoneError ||
        pathwayError.milestoneListError.length || validateMilestonesName()
      if (milestoneError) { return }
      if (
        milestoneLength === 0
      ) {
        setShowBuildToast(true);
      } else {
        navigate(`/pathwayCreator/build/${params.id}`)
      }
    } else if (activeIndex === 1) {
      if (buttonText === 'Settings') {
        const milestoneListError = validateMilestonesContent();
        if (milestoneListError.length) {
          setPathwayError({ ...pathwayError, milestoneListError })
          setShowBuildToast(true);
        } else {
          navigate(`/pathwayCreator/settings/${params.id}`)
        }
      }
    }
  }
  const onTitleChange = (e: any) => {
    const { value } = e.target;
    if (value?.trim() === "") {
      setTitle({
        value: '',
        error: true,
        errorMsg: 'Please enter a valid title'
      });
    } else if (!value?.match(/^(?=.*[a-zA-Z0-9])[a-zA-Z0-9!@#$%^&*()'"_+,\-.\/:;<=>?@\[\]\\\{\}|\~\` ]*/)) {
      setTitle({
        value: value,
        error: true,
        errorMsg: 'Please enter a valid title'
      })
    } else if (value?.length > 100) {
      setTitle({
        value: value,
        error: true,
        errorMsg: 'Please enter a valid title'
      })
    }
    else {
      setTitle({
        value: value,
        error: false,
        errorMsg: ''
      });
      setPathwayData({ ...pathwayData, pathway: {...pathwayData.pathway, data: {...pathwayData.pathway.data, title: value} }});
    }
  }

  const getPreparedMilestoneData = (type: string) => {
    let newMilestoneData = JSON.parse(
      JSON.stringify(pathwayData.milestones)
    );
    let filterMilestoneData = newMilestoneData.filter((milestone: any)=> milestone.type === type)
    if(type === "KNOWLEDGE"){
      return filterMilestoneData.map((milestone: IKnowledgeMilestoneData)=>{
        if(milestone.action === "DELETE"){
          return {
            action: milestone?.action,
            header: {
              id: milestone?.sdoId
            },
            data: {
              milestoneId: milestone?.milestoneId,
            }
          }
        }else {
          let sdoId = milestone?.sdoId;
          let action = milestone.action;
          let moduleList = milestone?.modules?.map((module: any)=> module?.id || module?.sdoId) || []
          let order = milestone.order;
          delete milestone?.sdoId
          delete milestone.action
          delete milestone.order
          delete milestone.type
          delete milestone.modules
          delete milestone.uuid
          let obj = {
              action: action,
              data: {
                ...milestone,
                moduleList: moduleList,
                properties: {
                  order: order
                }
              },
          }
          if(sdoId){
            return {
              header: {
                id: sdoId
              },
              ...obj
            }
          }else {
            return obj
          }
        }
      })
    }else {
      return filterMilestoneData.map((milestone: IDemonstrationMilestoneData)=>{
        if(milestone.action === "DELETE"){
          return {
            action: milestone?.action,
            header: {
              id: milestone?.sdoId
            },
            data: {
              milestoneId: milestone?.milestoneId,
            }
          }
        }else {
          let sdoId = milestone?.sdoId;
          let action = milestone.action;
          let order = milestone.order;
          delete milestone?.sdoId
          delete milestone.action
          delete milestone.order
          delete milestone.type
          delete milestone.uuid
          let obj = {
              action: action,
              data: {
                ...milestone,
                checklist: milestone?.checklist || [],
                properties: {
                  order: order
                }
              },
        }
          if(sdoId){
            return {
              header: {
                id: sdoId
              },
              ...obj
            }
          }else {
            return obj
          }
        }
      })
    }
  };

  const savePathway = async (buttonType: string = "Save") => {
    if (isPathwayTitleError) {
      setTitle({
        ...title,
        error: true,
        errorMsg: "Please enter a valid title",
      });
      return;
    }
    setSaveTimeStamp('')
    if (onClickSaveValidate && onClickSaveValidate()) {
      return;
    }else {
      if(buttonType === "Save"){
        setSaveButtonLoading(true);
        setPublishButtonLoading(false);
      }else{
        setSaveButtonLoading(false);
        setPublishButtonLoading(true);
        setOpenPublishConfirmModal(false);
      }
    }
    const url = `/contentcreator/pathways/${params.id}`;
    const data = {
      pathway: {
          ...pathwayData?.pathway,
          data: {
            ...pathwayData?.pathway?.data,
            status: buttonType === 'Publish' ? "Published" : pathwayData?.pathway?.data?.status
          }
        },
      publishedTo: pathwayData?.publishedTo,
      knowledgeMilestones: getPreparedMilestoneData("KNOWLEDGE"),
      demoMilestones: getPreparedMilestoneData("DEMONSTRATION"),
      pathwaySkills: pathwayData?.pathwaySkills
    }
    const header = {
      "Authorization": headers.Authorization,
      "Content-Type": "application/json"
    }
    if (title.error) {
      return
    }
    else {
      await axiosInstance().put(url, data, { headers: header })
        .then((response)=>{
          setPathwayData({...pathwayData, ...getPathwayCreatorObject(response.data)});
          if(buttonType === 'Publish') {
            setToastType(IToastTypes.Success)
            setShowToast(true);
            setToastMesage('Publish successful.')
            navigate('/pathwayLibrary');
          } else {
            setSaveTimeStamp(moment().format("MM/DD/YYYY hh:mmA"))
            setSaveButtonLoading(false);
          }
        }
        ).catch(err => {
          console.log(err, "ERROR");
          setSaveButtonLoading(false);
        });
    }
  }

  const isStepValid = (stepIndex: number): boolean => {
    const isMilestoneError = !milestoneLength || validateMilestonesName() ||
      pathwayError.knowledgeMilestoneError ||
      pathwayError.demonstrationMilestoneError ||
      ((activeIndex === 1 && stepIndex === 0) ? false : pathwayError.milestoneListError.length) || pathwayError.disableStepper || isPathwayTitleError;

    switch (stepIndex) {
      case 0:
        return !isPathwayTitleError;
      case 1:
        return !isMilestoneError;
      case 2:
        return !isMilestoneError && !!milestoneLength && !!!validateMilestonesContent().length;
      default:
        return true;
    }
  }

  const onClickStepIcon = (stepIndex: number) => {
    let path;
    switch (stepIndex) {
      case 0:
        path = `outline/${params.id}`;
        break;
      case 1:
        path = `build/${params.id}`;
        break;
      case 2:
        path = `settings/${params.id}`;
        break;
      default:
        path = `outline/${params.id}`;
    }
    isStepValid(stepIndex) && navigate(`/pathwayCreator/${path}`);
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  };

  const exitToBuild = () => {
    navigate(`/pathwayCreator/build/${params.id}`)
  }

  const onPublishButtonClick = () => {
    if (onClickPublish && onClickPublish()) {
      return;
    }
    setOpenPublishConfirmModal(true)
  }

  const publishPathway = () => {
    savePathway("Publish")
  }

  return (
    <>
      <Box>
        <Box display={"flex"} flexDirection={"column"} height={"100vh"} bgcolor="#fff">
          <Box
            display={"flex"}
            flexDirection={"row"}
            ml={"40px"}
            mt={"29px"}
            alignItems={"center"}
          >
            <Box mt={"2px"}>
              <img src={LifeTrekLogo} alt="ICON" />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Box ml={"18px"}>
                {!pathwayTitle && pathwayTitle !== "" ? (
                  "Loading..."
                ) : isBreadCrumb ? (
                  <BreadCrumbCmp />
                ) : (
                  <TextField
                    multiline={true}
                    onKeyDown={handleKeyDown}
                    onChange={onTitleChange}
                    sx={{
                      ...styles.titleInput(pathwayTitle, saveTimeStamp),
                      ...(title.error && errorStyles.title(pathwayTitle)),
                    }}
                    defaultValue={pathwayTitle}
                    inputProps={{
                      maxLength: 101,
                    }}
                  />
                )}
                {title?.error && (
                  <Box
                    position={"absolute"}
                    display={"flex"}
                    alignItems={"center"}
                    mt={1}
                  >
                    <img
                      src={ErrorIcon}
                      alt="error"
                      height={"10px"}
                      width={"10px"}
                    />
                    <Typography sx={errorStyles.titleErrorText} ml={1}>
                      {title?.errorMsg}
                    </Typography>
                  </Box>
                )}
              </Box>
              {activeIndex === 2 ? null : (
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  mt={"2px"}
                  mr={"40px"}
                >
                  {saveTimeStamp && (
                    <Grid item sx={styles.saveTimeContainer}>
                      <Typography
                        variant="caption"
                        sx={styles.saveTimeTextStyle}
                      >
                        Last saved on {saveTimeStamp}
                      </Typography>
                    </Grid>
                  )}
                  <Box sx={{ position: "relative" }}>
                    <ClickAwayListener
                      onClickAway={handleCloseExitModal}
                      mouseEvent="onMouseDown"
                      touchEvent="onTouchStart"
                    >
                      <Box>
                        <Button
                          variant="text"
                          size="medium"
                          color="primary"
                          sx={styles.exitButtonStyle}
                          onClick={onClickExitModal}
                        >
                          <Typography
                            sx={{
                              ...theme?.typography?.Components?.button?.default,
                              ...theme?.typography?.Components?.button?.large,
                              color: "#202124",
                              textTransform: "none",
                            }}
                          >
                            Exit
                          </Typography>
                        </Button>
                        <Box sx={styles.exitModalContainer}>
                          {openExitModal ? (
                            <ExitModal
                              exitNavigation={exitNavigation}
                              exitBtnText={exitBtnText}
                              handleCloseExitModal={handleCloseExitModal}
                            />
                          ) : null}
                        </Box>
                      </Box>
                    </ClickAwayListener>
                  </Box>
                  {hasExitToBuildButton && (
                    <Box ml={"16px"}>
                      <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        sx={{ width: "135px", height: "42px", px: 0 }}
                        onClick={exitToBuild}
                      >
                        <Typography
                          sx={{
                            ...theme?.typography?.Components?.button?.default,
                            ...styles.saveButtonTextStyle,
                            color: "#003DA6",
                            textTransform: "none",
                          }}
                        >
                          Exit to Build
                        </Typography>
                      </Button>
                    </Box>
                  )}
                  {!hasExitToBuildButton && (
                    <Box ml={"16px"}>
                      <Button
                        variant="outlined"
                        size="medium"
                        color="primary"
                        sx={{ width: `${saveButtonLoading ? '104px' : "87px"}` , height: "42px" }}
                        onClick={() => savePathway()}
                        loading={saveButtonLoading}
                      >
                        <Typography
                          sx={{
                            ...theme?.typography?.Components?.button?.default,
                            ...styles.saveButtonTextStyle,
                            color: "#003DA6",
                            textTransform: "none",
                          }}
                        >
                          Save
                        </Typography>
                      </Button>
                    </Box>
                  )}
                  <Box ml={"15px"}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      sx={{
                        py: "8.5px",
                        px: "22px",
                        "& .MuiButton-endIcon": {
                          color: theme?.palette?.secondary[50],
                        },
                        width: `${hasExitToBuildButton && "120px"}`,
                      }}
                      endIcon={!hasExitToBuildButton && <ArrowForwardIcon />}
                      onClick={() => {
                        if (hasExitToBuildButton) {
                          savePathway();
                        } else {
                          nextButtonFunction();
                        }
                      }}
                      loading={hasExitToBuildButton && saveButtonLoading}
                    >
                      <Typography
                        sx={{
                          ...theme?.typography?.Components?.button?.default,
                          ...styles.buildButtonTextStyle,
                          color: theme?.palette?.secondary[50],
                          textTransform: "none",
                        }}
                      >
                        {buttonText}
                      </Typography>
                    </Button>
                  </Box>
                  <Toast
                    message={
                      activeIndex === 1
                        ? settingErrorMessage
                        : buildErrorMessage
                    }
                    type={IToastTypes.Error}
                    open={showBuildToast}
                    setOpen={setShowBuildToast}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"row"} flex={1} width={'100vw'} sx={{overflowX: 'auto'}}>
            <Box ml={"44px"} mt={"30px"} width={"50px"}>
              <Box sx={{ width: "100%" }}>
                <Stepper
                  orientation="vertical"
                  activeStep={activeIndex}
                  connector={null}
                >
                  <Tooltip
                    title="Outline"
                    placement="right"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: theme?.palette?.grayScale[800],
                          borderRadius: "4px",
                          height: "22px",
                          ...theme?.typography?.Components?.tooltip,
                          color: "#FFFFFF",
                        },
                      },
                      arrow: {
                        sx: {
                          color: theme?.palette?.grayScale[800],
                        },
                      },
                    }}
                  >
                    <Step
                      sx={{
                        cursor: milestoneLength && isStepValid(1) ? "pointer" : "default",
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        backgroundColor: `${
                          activeIndex === 0
                            ? stepperIconColors.active.bgColor
                            : isStepValid(0) 
                            ? stepperIconColors.completed.bgColor
                            : stepperIconColors.inActive.bgColor
                        }`,
                        boxShadow: `${
                          activeIndex >= 0 && isStepValid(0)
                            ? "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)"
                            : "none"
                        }`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        ...(activeIndex !== 0 && {
                          "&:hover": {
                            backgroundColor: `${isStepValid(0) ? theme?.palette?.secondary[10] : theme?.palette?.grayScale[50]}`
                          },
                        }),
                        ...(activeIndex === 0 && {
                          "&:hover": {
                            backgroundColor: '#2C39DA'
                          }
                        }),
                      }}
                      onClick={() => onClickStepIcon(0)}
                    >
                      <StepIcon
                        icon={
                          <FormatListBulletedIcon
                            sx={{
                              color: `${
                                activeIndex === 0
                                  ? stepperIconColors.active.iconColor
                                  : isStepValid(0)
                                  ? stepperIconColors.completed.iconColor
                                  : stepperIconColors.inActive.iconColor
                              }`,
                            }}
                          />
                        }
                      />
                    </Step>
                  </Tooltip>
                  <Tooltip
                    title="Build"
                    placement="right"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: theme?.palette?.grayScale[800],
                          borderRadius: "4px",
                          height: "22px",
                          ...theme?.typography?.Components?.tooltip,
                          color: "#FFFFFF",
                        },
                      },
                      arrow: {
                        sx: {
                          color: theme?.palette?.grayScale[800],
                        },
                      },
                    }}
                  >
                    <Step
                      sx={{
                        cursor:
                          milestoneLength && isStepValid(1)
                            ? "pointer"
                            : "default",
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        backgroundColor: `${
                          activeIndex === 1
                            ? stepperIconColors.active.bgColor
                            : isStepValid(1)
                            ? stepperIconColors.completed.bgColor
                            : stepperIconColors.inActive.bgColor
                        }`,
                        boxShadow: `${
                          activeIndex >= 1 || isStepValid(1)
                            ? "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)"
                            : "none"
                        }`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: "30px",
                        ...(activeIndex !== 1 && {
                          "&:hover": {
                            backgroundColor: `${isStepValid(1) ? theme?.palette?.secondary[10] : theme?.palette?.grayScale[50]}`,
                          },
                        }),
                        ...(activeIndex === 1 && {
                          "&:hover": {
                            backgroundColor: '#2C39DA'
                          }
                        }),
                      }}
                      onClick={() => onClickStepIcon(1)}
                    >
                      <StepIcon
                        icon={
                          <BuildIcon
                            sx={{
                              color: `${
                                activeIndex === 1
                                  ? stepperIconColors.active.iconColor
                                  : isStepValid(1)
                                  ? stepperIconColors.completed.iconColor
                                  : stepperIconColors.inActive.iconColor
                              }`,
                            }}
                          />
                        }
                      />
                    </Step>
                  </Tooltip>
                  <Tooltip
                    title="Settings"
                    placement="right"
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: theme?.palette?.grayScale[800],
                          borderRadius: "4px",
                          height: "22px",
                          ...theme?.typography?.Components?.tooltip,
                          color: "#FFFFFF",
                        },
                      },
                      arrow: {
                        sx: {
                          color: theme?.palette?.grayScale[800],
                        },
                      },
                    }}
                  >
                    <Step
                      sx={{
                        cursor:
                          activeIndex === 2 || isStepValid(2)
                            ? "pointer"
                            : "default",
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        backgroundColor: `${
                          activeIndex === 2
                            ? stepperIconColors.active.bgColor
                            : isStepValid(2)
                            ? stepperIconColors.completed.bgColor
                            : stepperIconColors.inActive.bgColor
                        }`,
                        boxShadow: `${
                          activeIndex === 2 || isStepValid(2)
                            ? "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)"
                            : "none"
                        }`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: "30px",
                        ...(activeIndex !== 2 && {
                          "&:hover": {
                            backgroundColor: `${isStepValid(2) ? theme?.palette?.secondary[10] : theme?.palette?.grayScale[50]}`,
                          },
                        }),
                        ...(activeIndex === 2 && {
                          "&:hover": {
                            backgroundColor: '#2C39DA'
                          }
                        }),
                      }}
                      onClick={() => onClickStepIcon(2)}
                    >
                      <StepIcon
                        icon={
                          <SettingsIcon
                            sx={{
                              color: `${
                                activeIndex === 2
                                  ? stepperIconColors.active.iconColor
                                  : isStepValid(2)
                                  ? stepperIconColors.completed.iconColor
                                  : stepperIconColors.inActive.iconColor
                              }`,
                            }}
                          />
                        }
                      />
                    </Step>
                  </Tooltip>
                </Stepper>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"row"}
              width={"calc(100% - 134px)"}
            >
              <Box sx={{ width: `${activeIndex === 2 ? "calc(100% - 200px)" : "100%"}`}} mt={'40px'}>{children}</Box>
              {activeIndex === 2 ? (
                <Grid
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  mt={"40px"}
                >
                  <Grid position={'relative'}>
                    <Button
                      variant="contained"
                      size="medium"
                      color="primary"
                      sx={{ width: "200px", height: "44px", cursor: `${saveButtonLoading ? "not-allowed" : "pointer"}`}}
                      onClick={onPublishButtonClick}
                      loading={publishButtonLoading}
                      disabled={saveButtonLoading}
                    >
                      <Typography
                        sx={{
                          ...theme?.typography?.Components?.button?.default,
                          ...styles.publishButtonTextStyle,
                          color: theme?.palette?.secondary[50],
                          textTransform: "none",
                        }}
                      >
                        Publish
                      </Typography>
                    </Button>
                    <Grid
                      sx={{
                        zIndex: 2,
                        position: "absolute",
                        top: "56px",
                        right: "0px",
                      }}
                    >
                      {openPublishConfirmModal ? (
                        <PublishModal
                          setOpenPublishConfirmModal={
                            setOpenPublishConfirmModal
                          }
                          publishPathway={publishPathway}
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                  <Grid mt={"20px"}>
                    <Button
                      variant="outlined"
                      size="medium"
                      color="primary"
                      sx={{
                        width: "200px",
                        height: "44px",
                        border: "2px solid #7781FF",
                        cursor: `${publishButtonLoading ? "not-allowed" : "pointer"}`
                      }}
                      onClick={() => savePathway()}
                      loading={saveButtonLoading}
                      disabled={publishButtonLoading}
                    >
                      <Typography
                        sx={{
                          ...theme?.typography?.Components?.button?.default,
                          ...styles.saveAndExitButtonTextStyle,
                          color: theme?.palette?.primary[500],
                          textTransform: "none",
                        }}
                      >
                        Save
                      </Typography>
                    </Button>
                  </Grid>
                  <ClickAwayListener
                      onClickAway={handleCloseExitModal}
                      mouseEvent="onMouseDown"
                      touchEvent="onTouchStart"
                  >
                    <Grid mt={"20px"} position={'relative'}>
                    <Button
                      variant="outlined"
                      size="medium"
                      color="primary"
                      sx={{
                        width: "200px",
                        height: "44px",
                        border: "2px solid rgba(2, 136, 209, 0.5)",
                      }}
                      onClick={onClickExitModal}
                    >
                      <Typography
                        sx={{
                          ...theme?.typography?.Components?.button?.default,
                          ...styles.saveAndExitButtonTextStyle,
                          color: "#004ED8",
                          textTransform: "none",
                        }}
                      >
                        Exit
                      </Typography>
                    </Button>
                    <Box sx={{
                        zIndex: 2,
                        position: "absolute",
                        top: "44px",
                        right: "0px",
                      }}>
                      {openExitModal ? (
                        <ExitModal
                          exitNavigation={exitNavigation}
                          exitBtnText={exitBtnText}
                          handleCloseExitModal={handleCloseExitModal}
                        />
                      ) : null}
                    </Box>
                  </Grid>
                  </ClickAwayListener>
                </Grid>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
      {(publishButtonLoading || saveButtonLoading) && createPortal(
        <>
          <Grid sx={{
            ...styles.centerContentStyle,
            backgroundColor: '#000',
            opacity: '0.4'
          }}
          />
            <Grid sx={styles.centerContentStyle}>
              <CircularProgress variant="indeterminate" size={60} sx={{color: theme?.palette?.primary[500]}}/>
            </Grid>
        </>
        , document.body)}
    </>
  );
};

export default PathwayLayout;