import {
  Box,
  Card,
  ClickAwayListener,
  Grid,
  IconButton,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { godzilla as theme } from "util/themes/godzilla";
import Dots_Vertical from "assets/images/PathwayCreator/Drag_vertical.svg";
import { useEffect, useState } from "react";
import { IPageData } from "../ModuleLayout/moduleLayout.helper";
import { TextRgx } from "pages/pathway/PathwayCreator/PathwayBuild/BuildDemonstrationMilestone/demonstrationmilestone.helper";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Pencil_Icon from "assets/images/PathwayCreator/Pencil_icon.svg";
import Delete_Icon from "assets/images/PathwayCreator/Delete_icon.svg";

interface IAddModuleCard {
  setAddNewPage?: React.Dispatch<React.SetStateAction<boolean>>;
  pagesArr: Array<IPageData>;
  updatePagesArr: (cloneData: any) => void;
}
interface IViewModuleCard {
  pageTitle: string;
  handleSelectPage: (index: number) => void;
  index: number;
  currentSelectedPageIndex: number;
  pagesArr: Array<IPageData>;
  updatePagesArr: (cloneData: any) => void;
}

interface IActionsMenu {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleClose: () => void;
  action: "ADD" | "EDIT";
  setAddNewPage?: React.Dispatch<React.SetStateAction<boolean>>;
  onDeletePage?: () => void;
  id: string | undefined;
  open: boolean;
  anchorEl: null | HTMLElement;
  isEdit: boolean;
  setIsEdit?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IErrorMessageComponentProps {
  errorFlags: {
    requiredError: boolean;
    maxLength: boolean;
  };
}

const RequiredErrorText = "Please enter a valid title";
const MaxErrorText = "Cannot exceed 100 characters";

const styles = {
  inputStyle: {
    width: "100%",
    "& .MuiInputBase-root": {
      padding: "0px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5B67FF",
        borderRadius: "4px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "& .MuiInputBase-input": {
      padding: "6px 9px 7px 9px",
    },
  },
  errorContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mt: "-10px",
    mb: "20px",
  },
};

const TextFieldComponent = (props: any) => {
  const {
    value,
    onChange,
    onKeyDown,
    onBlur,
    autoFocus,
    multiline,
    maxRows,
    ...other
  } = props;

  return (
    <TextField
      sx={{
        ...styles.inputStyle,
      }}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      autoFocus={autoFocus}
      multiline={multiline}
      maxRows={maxRows}
      {...other}
    />
  );
};

const ErrorMessageComponent = (props: IErrorMessageComponentProps) => {
  const { errorFlags } = props;

  let errorMessage;

  if (errorFlags.requiredError) {
    errorMessage = RequiredErrorText;
  } else if (errorFlags.maxLength) {
    errorMessage = MaxErrorText;
  } else {
    errorMessage = null;
  }

  return errorMessage ? (
    <Grid sx={styles.errorContainer}>
      <ErrorOutlineIcon
        sx={{ height: "10px", width: "10px", mt: "-2px", color: "#FF4F4F" }}
      />
      <Typography
        sx={{
          ...theme?.typography?.captions?.default,
          ...theme?.typography?.captions?.regular,
          color: theme?.palette?.error?.dark,
          ml: "4px",
        }}
      >
        {errorMessage}
      </Typography>
    </Grid>
  ) : null;
};

export const ActionsMenu = (props: IActionsMenu) => {
  const {
    handleClick,
    handleClose,
    action,
    setAddNewPage,
    onDeletePage,
    id,
    open,
    anchorEl,
    isEdit,
    setIsEdit,
  } = props;

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          padding: "0px",
          "&:hover": {
            backgroundColor: theme?.palette?.secondary[10],
          },
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        keepMounted={false}
        style={{ zIndex: 1 }}
      >
        <ClickAwayListener
          onClickAway={handleClose}
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"146px"}
            height={"auto"}
            boxShadow={
              "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)"
            }
            borderRadius={"10px"}
            position={"absolute"}
            sx={{ backgroundColor: "#FFFFFF" }}
            ml={"-136px"}
            mt={"-24px"}
          >
            {isEdit || action === "ADD" ? null : (
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                onClick={() => {
                  setTimeout((): void => {
                    setIsEdit?.(true);
                  }, 50);
                  handleClose();
                }}
                sx={{
                  "&:hover": {
                    backgroundColor: "#E9EAFF",
                  },
                  mt: "11px",
                  height: "32px",
                  pl: "26px",
                  cursor: "pointer",
                }}
              >
                <img src={Pencil_Icon} alt="ICON" />
                <Typography
                  sx={{
                    ...theme?.typography?.body?.default,
                    ...theme?.typography?.body?.body1,
                    ...theme?.typography?.body?.regular,
                    color: "#2C3442",
                    ml: "14px",
                  }}
                >
                  Rename
                </Typography>
              </Box>
            )}
            <Box
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              onClick={() => {
                if (action === "ADD") {
                  setAddNewPage?.(false);
                } else {
                  onDeletePage?.();
                  handleClose();
                }
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "#E9EAFF",
                },
                mb: `${!isEdit && action !== "ADD" ? "14px" : "10px"}`,
                mt: `${!isEdit && action !== "ADD" ? "2px" : "10px"}`,
                height: "32px",
                pl: "26px",
                cursor: "pointer",
              }}
            >
              <img src={Delete_Icon} alt="ICON" />
              <Typography
                sx={{
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.regular,
                  color: "#2C3442",
                  ml: "15.5px",
                }}
              >
                Delete
              </Typography>
            </Box>
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

export const ViewModuleCard = (props: IViewModuleCard) => {
  const {
    pageTitle,
    handleSelectPage,
    index,
    currentSelectedPageIndex,
    pagesArr,
    updatePagesArr,
  } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [pageName, setPageName] = useState<string>("");
  const [errorFlags, setErrorFlags] = useState<{
    requiredError: boolean;
    maxLength: boolean;
  }>({
    requiredError: false,
    maxLength: false,
  });

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  useEffect(() => {
    setPageName(pageTitle);
  }, [pageTitle]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeletePage = () => {
    // Delete page logic
  };

  const onChangePageTitle = (e: any) => {
    setPageName(e.target.value);
    setErrorFlags({
      requiredError: false,
      maxLength: false,
    });
  };

  const updatePagesData = (title: string) => {
    let cloneData = [...pagesArr];
    cloneData[index].title = title;
    updatePagesArr(cloneData);
    setErrorFlags({
      requiredError: false,
      maxLength: false,
    });
    setIsEdit(false);
  };

  const handleValidation = (title: string) => {
    if (title.trim() === "") {
      setErrorFlags({
        requiredError: true,
        maxLength: false,
      });
      setPageName("");
      return;
    }
  
    if (title.length > 100) {
      setErrorFlags({
        requiredError: false,
        maxLength: true,
      });
      return;
    }
  
    if (!TextRgx.exec(title)) {
      setErrorFlags({
        requiredError: true,
        maxLength: false,
      });
      return;
    }
  
    setErrorFlags({
      requiredError: false,
      maxLength: false,
    });
    updatePagesData(title);
  };

  const onEditPageName = (e: any, onKeyPress: boolean) => {
    if (onKeyPress) {
      if (e.key === "Enter") {
        handleValidation(pageName);
      }
    } else {
      handleValidation(pageName);
    }
  };

  let borderStyle;
  let borderLeftStyle;

  if (errorFlags.requiredError || errorFlags.maxLength) {
      borderStyle = "2px solid #FF4F4F";
      borderLeftStyle = "10px solid #FF4F4F";
  } else {
      if (currentSelectedPageIndex === index) {
          borderStyle = `2px solid ${theme?.palette?.primary[500]}`;
          borderLeftStyle = `10px solid ${theme?.palette?.primary[500]}`;
      } else {
          borderStyle = "1px solid #C6CAD1";
          borderLeftStyle = "";
      }
  }

  return (
    <>
      <Card
        onClick={() => handleSelectPage(index)}
        sx={{
          border: borderStyle,
          borderLeft: borderLeftStyle,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          width: "300px",
          height: "65px",
          borderRadius: "10px",
          mb: 2,
          "& .editContainer": {
            visibility: "hidden",
          },
          "&:hover, &:active": {
            border: `2px solid ${theme?.palette?.secondary[400]}`,
            cursor: "pointer",
            "& .editContainer": {
              visibility: "initial",
            },
          },
        }}
      >
        <Grid pl={"16px"} className={"editContainer"}>
          <img
            src={Dots_Vertical}
            alt="ICON"
            style={{ height: "24px", width: "8px" }}
          />
        </Grid>
        {isEdit ? (
          <Grid pl={"9px"} width={"100%"}>
            <TextFieldComponent
              value={pageName}
              onChange={(e: any) => onChangePageTitle(e)}
              onKeyDown={(e: any) => onEditPageName(e, true)}
              onBlur={(e: any) => onEditPageName(e, false)}
              autoFocus={true}
              multiline
              maxRows={2}
              onFocus={(e: any) => {
                let value = e.target.value;
                e.target.value = "";
                e.target.value = value;
              }}
            />
          </Grid>
        ) : (
          <Grid pl={"9px"} width={"100%"}>
            <Typography
              sx={{
                ...theme?.typography?.body?.default,
                ...theme?.typography?.body?.body1,
                ...theme?.typography?.body?.medium,
                color: theme?.palette?.grayScale?.[700],
                wordBreak: "break-all",
              }}
            >
              {pageName}
            </Typography>
          </Grid>
        )}
        <Grid pl={"10px"} pt={"2px"} pr={"20px"}>
          <ActionsMenu
            action="EDIT"
            anchorEl={anchorEl}
            id={id}
            open={open}
            handleClick={handleClick}
            handleClose={handleClose}
            onDeletePage={onDeletePage}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        </Grid>
      </Card>
      <ErrorMessageComponent errorFlags={errorFlags} />
    </>
  );
};

export const AddModuleCard = (props: IAddModuleCard) => {
  const { setAddNewPage, pagesArr, updatePagesArr } = props;

  const [pageTitle, setPageTitle] = useState<string>("");
  const [errorFlags, setErrorFlags] = useState<{
    requiredError: boolean;
    maxLength: boolean;
  }>({
    requiredError: false,
    maxLength: false,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangePageTitle = (e: any) => {
    setPageTitle(e.target.value);
    setErrorFlags({
      requiredError: false,
      maxLength: false,
    });
  };

  const updatePagesData = (title: string) => {
    let cloneData = [...pagesArr];
    cloneData.push({
      title: title,
      richText: "",
      estimatedTimeToCompleteInMinutes: 0,
    });
    updatePagesArr(cloneData);
    setAddNewPage?.(false);
    setPageTitle("");
    setErrorFlags({
      requiredError: false,
      maxLength: false,
    });
  };

  const handleValidation = (title: string) => {
    if (title.trim() === "") {
      setErrorFlags({
        requiredError: true,
        maxLength: false,
      });
      setPageTitle("");
      return;
    }
  
    if (title.length > 100) {
      setErrorFlags({
        requiredError: false,
        maxLength: true,
      });
      return;
    }
  
    if (!TextRgx.exec(title)) {
      setErrorFlags({
        requiredError: true,
        maxLength: false,
      });
      return;
    }
  
    setErrorFlags({
      requiredError: false,
      maxLength: false,
    });
    updatePagesData(title);
  };

  const onAddPageName = (e: any, onKeyPress: boolean) => {
    if (onKeyPress) {
      if (e.key === "Enter") {
        handleValidation(pageTitle);
      }
    } else {
      handleValidation(pageTitle);
    }
  };

  return (
    <>
      <Card
        sx={{
          border: `${
            errorFlags.requiredError || errorFlags.maxLength
              ? "2px solid #FF4F4F"
              : `2px solid ${theme?.palette?.primary[500]}`
          }`,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          cursor: "pointer",
          width: "300px",
          height: "65px",
          borderRadius: "10px",
          mb: 2,
          borderLeft: `${
            errorFlags.requiredError || errorFlags.maxLength
              ? "10px solid #FF4F4F"
              : `10px solid ${theme?.palette?.primary[500]}`
          }`,
          "& .editContainer": {
            visibility: "hidden",
          },
          "&:hover, &:active": {
            border: `2px solid ${theme?.palette?.secondary[400]}`,
            borderLeft: `10px solid ${theme?.palette?.secondary[400]}`,
            cursor: "pointer",
            "& .editContainer": {
              visibility: "initial",
            },
          },
        }}
      >
        <Grid pl={"4px"} className={"editContainer"}>
          <img
            src={Dots_Vertical}
            alt="ICON"
            style={{ height: "24px", width: "8px" }}
          />
        </Grid>
        <Grid pl={"9px"} width={"100%"}>
          <TextFieldComponent
            value={pageTitle}
            onChange={(e: any) => onChangePageTitle(e)}
            onKeyDown={(e: any) => onAddPageName(e, true)}
            onBlur={(e: any) => onAddPageName(e, false)}
            autoFocus={true}
            multiline
            maxRows={2}
          />
        </Grid>
        <Grid pl={"10px"} pt={"2px"} pr={"20px"}>
          <ActionsMenu
            action="ADD"
            anchorEl={anchorEl}
            id={id}
            open={open}
            handleClick={handleClick}
            handleClose={handleClose}
            setAddNewPage={setAddNewPage}
            isEdit={false}
          />
        </Grid>
      </Card>
      <ErrorMessageComponent errorFlags={errorFlags} />
    </>
  );
};
