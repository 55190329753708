import React from 'react'
import { Box, Grid, CircularProgress, circularProgressClasses, Typography, LinearProgress, ClickAwayListener } from '@mui/material';
import { useAppContext } from 'components/app-context/appContext';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import ErrorIconSVG from 'assets/images/erroricon.svg';
import CheckIconSVG from 'assets/images/tick.svg';
import { godzilla as theme } from 'util/themes/godzilla';
import { Button } from 'core/components';
import { useNavigate } from 'react-router-dom';

const styles = {
  progressIndicatorContainer: {
    fontFamily: 'Open Sans',
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#999CAF',
    mr: 1
  },
  popupContainer: {
    position: 'absolute',
    top: '24px',
    right: '8px',
    zIndex: 1
  },
  popperContainer: {
    width: '278px',
    height: 'auto',
    background: '#FFFFFF',
    boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
    borderRadius: '10px',
    pb: 2.5
  },
  fileName: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.15px',
    color: theme.palette.grayScale[500],
    pt: 1.5
  },
  errorStyle: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    color: '#999CAF',
    pl: 1

  },
  circularProgressIndicator: {
    position: 'absolute',
    right: '0',
    zIndex: 1,
    cursor: 'pointer',
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
    }
  },
  progressContainer: {
    height: '7px',
    mt: 1.25,
  },
  uploadCompleteIcon: {
    width: '18px',
    height: '14.13px',
    top: '7px',
    right: '6px',
    position: 'absolute' as const
  },
  inProgress: {
    backgroundColor: `#E1E4EE`,
    borderRadius: '10px',
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme?.palette?.tertiary[600],
      borderRadius: '10px'
    }
  },
  success: {
    backgroundColor: `#E1E4EE`,
    borderRadius: '10px',
    "& .MuiLinearProgress-bar": {
      backgroundColor: theme?.palette?.tertiary[600],
      borderRadius: '10px'
    }
  },
  error: {
    backgroundColor: `#E1E4EE`,
    borderRadius: '10px',
    "& .MuiLinearProgress-bar": {
      backgroundColor: `#FF6363`,
      borderRadius: '10px'
    }
  },
  modalButton: (isContained: boolean = false) => ({
    px: '8px',
    height: '27px',
    border: `2px solid ${isContained ? theme?.palette?.primary?.[500] : theme?.palette?.tertiary?.[500]}`,
  }),
  modalButtonText: {
    ...theme?.typography?.Components?.button?.medium,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    textTransform: 'none',
    color: '#2C39DA'
  },
}

interface IProgressProps {
  loadingVariant?: 'determinate' | 'indeterminate' | undefined
}

const UploadProgressIndicator = ({ loadingVariant }: IProgressProps) => {
  const {
    isUploadComplete,
    isUploading,
    errorInUpload,
    uploadProgress,
    uploadingFileData,
    uploadedFileData,
    onCancelFileUpload
  } = useAppContext();

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUploadStatus = () => {
    if (isUploadComplete) {
      return 'Import complete. Please refresh the page.'
    } else if (isUploading || errorInUpload) {
      return 'Import in Progress'
    }
  }

  const getIndicatorColor = () => {
    if (isUploadComplete) {
      return '#D6D9E1'
    } else if (isUploading) {
      return theme.palette.tertiary[600]
    } else if (errorInUpload) {
      return '#FF6363'
    } else {
      return '#D6D9E1'
    }
  }

  const getIndicatorIcon = () => {
    if (isUploadComplete) {
      return <img src={CheckIconSVG} style={styles.uploadCompleteIcon} alt='upload_complete_icon' />
    } else if (isUploading) {
      return null;
    } else if (errorInUpload) {
      return <PriorityHighIcon sx={{ color: '#FF6363', top: '3px', right: '3px', position: 'absolute' }} />
    }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const fileData = isUploading ? uploadingFileData[0] : uploadedFileData[0];

  return (
    <Grid>
      <Grid display={'flex'} flexDirection='row' alignItems={'center'} justifyContent='center'>
        <Typography sx={{
          ...styles.progressIndicatorContainer,
          color: isUploadComplete ? theme.palette.primary[500] : '#999CAF'
        }}>
          {getUploadStatus()}
        </Typography>
        <ClickAwayListener onClickAway={handleClose}>
          <Grid sx={{ position: 'relative' }} display='flex' flexDirection={'row'}>
            {getIndicatorIcon()}
            <CircularProgress
              variant="determinate"
              sx={{
                color: "#D6D9E1",
              }}
              size={30}
              thickness={3}
              value={100}
            />
            <CircularProgress
              sx={{
                color: getIndicatorColor(),
                ...styles.circularProgressIndicator
              }}
              size={30}
              thickness={3}
              variant={loadingVariant || 'determinate'}
              aria-describedby={id}
              value={isUploading ? uploadProgress : fileData.uploadProgress}
              onClick={handleClick}
            />
            {open ? <Box sx={styles.popupContainer}>
              <Grid sx={styles.popperContainer}>
                {fileData ?
                  <Grid px={3.5}>
                    <Grid>
                      <Typography sx={styles.fileName}>
                        {errorInUpload ? 'An error has occurred.' : isUploadComplete ? 'File upload complete.' : 'File upload in progress.'}
                      </Typography>
                    </Grid>
                    <Grid>
                      <LinearProgress
                        variant="determinate"
                        value={isUploading ? uploadProgress : fileData.uploadProgress}
                        sx={{
                          ...(isUploading && styles.inProgress),
                          ...(isUploadComplete && styles.success),
                          ...(errorInUpload && styles.error),
                          ...styles.progressContainer
                        }}
                      />
                    </Grid>
                    {/* {errorInUpload ? <Grid mt={2} display={'flex'} flexDirection='row' alignItems={'center'}>
                      <img src={ErrorIconSVG} alt='error_icon' />
                      <Typography sx={styles.errorStyle}>
                        An error has occured
                      </Typography>
                    </Grid> : null} */}
                    {errorInUpload ? <Grid mt={2} display={'flex'} flexDirection='row' alignItems={'center'} justifyContent={'space-between'}>
                      <Button variant="outlined" size='medium' sx={styles.modalButton()} onClick={() => { onCancelFileUpload() }}>
                        <Typography sx={{
                          ...styles.modalButtonText
                        }}>
                          Clear Message
                        </Typography>
                      </Button>
                      <Button variant="contained" size='medium' sx={styles.modalButton(true)} onClick={() => { navigate('/drafts/import-file') }}>
                        <Typography sx={{
                          ...styles.modalButtonText,
                          color: theme?.palette?.secondary?.[50]
                        }}>
                          Import Page
                        </Typography>
                      </Button>
                    </Grid> : null}
                  </Grid>
                  : null}
              </Grid>
            </Box> : null}
          </Grid>
        </ClickAwayListener>
      </Grid>
    </Grid>
  );
}

export default UploadProgressIndicator;