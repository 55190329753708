import { Box, Button, Typography } from "@mui/material";
import { Button as ControlBtn } from "core/components";
import { godzilla as theme } from "util/themes/godzilla";

const styles = {
  boxStyle: {
    width: "290px",
    height: "193px",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    padding: "20px 18px",
  },  
  yesTextButtonStyle: {
    background: theme?.palette?.primary[500],
    borderRadius: "100px",
    padding: "4px 18px",
    px: '20px'
  },
  buttonTextStyle: {
    ...theme?.typography?.body?.default,
    ...theme?.typography?.body?.body2,
    ...theme?.typography?.body?.bold,
    fontSize: "12px",
    color: "#BFFBFF",
  },
  cancelNoButton: {
    ...theme?.typography?.body?.default,
    ...theme?.typography?.body?.body2,
    ...theme?.typography?.body?.bold,
    borderRadius: '100px',
    color: theme?.palette?.primary[500],
    textTransform: 'none',
    px: 2.5,
    '&:hover': { background: 'rgba(0, 61, 166, 0.04)' },
    '> .MuiTouchRipple-root span': {
      background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(122, 132, 255, 0.7) 99.54%, rgba(255, 255, 255, 0) 100%)'
    },
  },
};

interface IPublishConfirmModalProps {
  setPublishConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  onPublishClick: () => void;
}

export const PublishConfirmModal = (props: IPublishConfirmModalProps) => {
  const { setPublishConfirmModal, onPublishClick } = props;

  return (
    <Box sx={styles.boxStyle}>      
      <Typography sx={{
        ...theme?.typography?.body?.default,
        ...theme?.typography?.body?.body1,
        ...theme?.typography?.body?.regular,
        color: theme?.palette?.grayScale?.[700]
      }}>
        Once you publish, you will not be able to make changes to the pathway(s). Are you sure you’re ready to publish?
      </Typography>
      <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
        <Box display={"flex"} justifyContent={"center"} mt={"13px"}>
          <ControlBtn
            variant="contained"
            color="primary"
            size="medium"
            sx={styles.yesTextButtonStyle}
            onClick={onPublishClick}
          >
            <Typography
              sx={{
                ...theme?.typography?.Components?.button?.default,
                ...theme?.typography?.Components?.button?.small,
                ...styles.buttonTextStyle,
                textTransform: "none",
              }}
            >
              Yes, publish
            </Typography>
          </ControlBtn>
        </Box>
        <Box display={"flex"} justifyContent={"center"} mt={"4px"}>
          <ControlBtn
            variant="text"
            color="primary"
            size="medium"
            sx={styles.cancelNoButton}
            onClick={() => setPublishConfirmModal(false)}
          >
            No, don’t publish
          </ControlBtn>
        </Box>
      </Box>
    </Box>
  );
};
