import { Box, MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import checkCircleSVG from 'assets/images/checkcircle.svg';
const styles = {
    filterDraftBox: {
        background: '#FFFFFF',
        boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px rgb(0 0 0 / 14%), 0px 1px 18px rgb(0 0 0 / 12%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        borderRadius: '10px',
        px: 3,
        py: 2,
        cursor: 'initial',
        padding: '10px 0px'
    },
    filterListItem: (isSelected: boolean) => ({
        pr: '26px',
        '&:hover': {
            backgroundColor: '#E9EAFF'
        },
        '& .MuiListItemIcon-root': {
            minWidth: '16px',
            mr: '12px'
        },
        '& .MuiTypography-root': {
            fontWeight: isSelected ? '600' : '400',
            fontFamily: 'Nunito',
            color: '#3D4655'
        }
    }),
}
export enum IDraftFilterFieldTypes {
    Drafts = 'readyToReview',
    Unpublished = 'unpublished',
    ReadyToPublish = 'readyToPublish',
}
interface IHandleSortModalProps {
    selFilterFieldType?: IDraftFilterFieldTypes,
    setSelFilterFieldType: (type?: IDraftFilterFieldTypes) => void
}
export default function DraftFilterModal({ setSelFilterFieldType, selFilterFieldType }: IHandleSortModalProps) {
    const isDraft = selFilterFieldType === IDraftFilterFieldTypes.Drafts;
    const isReadyToPublish = selFilterFieldType === IDraftFilterFieldTypes.ReadyToPublish;


    return (
        <Box sx={styles.filterDraftBox} onClick={(e) => e.stopPropagation()}>
            <MenuList autoFocusItem={true} id="menu-list-grow">
                <MenuItem sx={styles.filterListItem(isDraft)} onClick={() => setSelFilterFieldType(isDraft ? undefined : IDraftFilterFieldTypes.Drafts)}>
                    <ListItemIcon >
                        {isDraft && <img src={checkCircleSVG} alt="filterDraftIcon" width={16} height={16} />}
                    </ListItemIcon>
                    <ListItemText>Draft</ListItemText>
                </MenuItem>
                <MenuItem sx={styles.filterListItem(isReadyToPublish)} onClick={() => setSelFilterFieldType(isReadyToPublish ? undefined : IDraftFilterFieldTypes.ReadyToPublish)} >
                    <ListItemIcon >
                        {isReadyToPublish && <img src={checkCircleSVG} alt="filterReadyToPublishIcon" width={16} height={16} />}
                    </ListItemIcon>
                    <ListItemText>Ready To Publish</ListItemText>
                </MenuItem>
            </MenuList>
        </Box>
    )
}