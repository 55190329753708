import { Box, MenuList, MenuItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ROUTE_ADD_NEW_MODULE = '/drafts/add-new-module';
const ROUTE_IMPORT_CHECKLIST = '/drafts/import-checklist';

const styles = {
  container: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px rgb(0 0 0 / 14%), 0px 1px 18px rgb(0 0 0 / 12%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    width: '169px',
    cursor: 'initial',

  },
  listStyle: {
    '&.MuiList-root': {
      width: '100%',
      pt: '17px',
      pb: '17px'
    }
  },
  listItem: {
    '&:hover': {
      backgroundColor: '#E9EAFF'
    },
    maxWidth: '169px',
    '&.MuiMenuItem-root': {
      pl: '27px',
      pt: '10px',
      pb: '10px'
    },
    '& .MuiTypography-root': {
      fontFamily: 'Nunito',
      color: '#3D4655',
    }
  },
}

export default function NewModuleModal() {
  const navigate = useNavigate();

  return (
    <Box sx={styles.container} onClick={(e) => e.stopPropagation()}>
      <MenuList sx={styles.listStyle} autoFocusItem={true} id="menu-list-grow">
        <MenuItem sx={styles.listItem}>
          <ListItemText onClick={() => navigate(ROUTE_ADD_NEW_MODULE)}>New Module</ListItemText>
        </MenuItem>
        <MenuItem sx={styles.listItem} onClick={() => navigate(ROUTE_IMPORT_CHECKLIST)}>
          <ListItemText>Import Modules</ListItemText>
        </MenuItem>
      </MenuList>
    </Box>
  )
}