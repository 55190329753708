export const START_UPLOAD = 'START_UPLOAD';
export const UPLOAD_IN_PROGRESS = 'UPLOAD_IN_PROGRESS';
export const UPLOAD_CANCELLED = 'UPLOAD_CANCELLED';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';
export const UPLOAD_COMPLETED = 'UPLOAD_COMPLETED';  
export const UPLOADING_FILE_DATA = 'UPLOADING_FILE_DATA';
export const UPLOADED_FILE_DATA = 'UPLOADED_FILE_DATA';
//ACTIONS FOR REVIEW DATA
export const SET_REVIEW_MODULE_DATA = 'SET_REVIEW_MODULE_DATA';
export const GET_REVIEW_MODULE_DATA = 'GET_REVIEW_MODULE_DATA';
export const RESET_REVIEW_MODULE_DATA = 'RESET_REVIEW_MODULE_DATA';
export const SET_PATHWAY_DATA = 'SET_PATHWAY_DATA';
export const RESET_PATHWAY_DATA = 'RESET_PATHWAY_DATA';
export const SET_PATHWAY_ERROR = 'SET_PATHWAY_ERROR';
export const RESET_PATHWAY_ERROR = 'RESET_PATHWAY_ERROR';