import { Button as MuiButton, Box, CircularProgress } from '@mui/material';
import { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import { godzilla as selectedTheme } from 'util/themes/godzilla';

export function Button(props: MuiButtonProps & {loading?: boolean, disabled?: boolean}) {
  const { size: btnSize, color, variant, onClick, sx, loading = false, disabled = false, ...other } = props;

  const btnStyles = {
    'contained': {
      ...selectedTheme?.button?.default,
      ...selectedTheme?.button?.size[btnSize || 'large'],
      ...selectedTheme?.button?.contained[color || 'primary'],
      '& .progress': {
        color: selectedTheme?.palette?.secondary[50]
      }
    },
    'outlined': {
      ...selectedTheme?.button?.default,
      ...selectedTheme?.button?.size[btnSize || 'large'],
      ...selectedTheme?.button?.outlined[color || 'primary'],
      '& .progress': {
        color: selectedTheme?.palette?.primary[500]
      }
    },
    'text': {
      ...selectedTheme?.button?.default,
      ...selectedTheme?.button?.size[btnSize || 'large'],
      ...selectedTheme?.button?.text[color || 'primary'],
      ...(loading && selectedTheme?.button?.text?.inherit),
      '& .progress': {
        color: selectedTheme?.palette?.primary[500]
      }
    }
  }

  return (
    <Box>
      <MuiButton
        onClick={onClick}
        sx={!!selectedTheme && { ...btnStyles[variant || 'outlined'], ...sx, ...{position: 'relative'}}}
        {...other}
        disabled={disabled}
      >
        {props.children}
        {loading && (<CircularProgress size={20} sx={{display:'flex',marginLeft: '6px'}} className='progress'/>)}
      </MuiButton>
    </Box>
  );
}
