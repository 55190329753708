import { Children } from 'react';
import { useQuery } from 'react-query';
import { Grid, Typography, } from '@mui/material';
import { godzilla as theme } from 'util/themes/godzilla';
import { getDefaultHeaders, instance as axiosInstance } from 'core/util';
import { useAuth } from 'core/contexts';
import { useNavigate, useParams } from "react-router-dom";

const styles = {
  pageTitleContainer: (i: number) => ({
    position: 'relative',
    width: 'calc(100% - 27px)',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& .pageTitleText': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      color: theme?.palette?.grayScale?.[700],

    },
    pageTitleHover: {
      '&:hover': {
        backgroundColor: i % 2 === 0 ? '#F7E1FF' : '#E3E9FF',
        '& .pageTitleText': {
          fontWeight: 600
        }
      }
    },
  }),
}
interface IPageListProps {
  moduleId: string,
  isPageClickable?: boolean
}

export const PageList = (props: IPageListProps) => {
  const { moduleId, isPageClickable } = props;
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());
  const navigate = useNavigate();
  const params = useParams();

  const getPages = useQuery('pages', async () => {
    const response = await axiosInstance().get(`/contentcreator/module/${moduleId}/moduleItem`, {
      headers: {
        "Authorization": headers.Authorization,
        "Content-Type": "application/json"
      },
      data: {}
    });
    return response.data;
  }, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  if (getPages.isLoading) {
    return <Grid display={'flex'} justifyContent={'center'}><Typography sx={{fontFamily: 'Nunito'}} variant='h3'>Loading...</Typography></Grid>
  }

  return (
    <>
      {Children.toArray(getPages.data.map((item: any, i: number) => {
        return (
          <Grid sx={{ py: '9px', }} display={'flex'} flexDirection='row' justifyContent={'flex-start'} alignItems={'center'}>
            <Grid sx={{
              ...styles.pageTitleContainer(i),
              ...(isPageClickable && styles.pageTitleContainer(i).pageTitleHover)
            }}
              onClick={() =>
                isPageClickable ?
                  navigate(`/pathwayCreator/build/${params.id}/${params.mName}/${params.mIndex}/pagePreview?pId=${item.page.pageId}`, { state: { richText: item.page.richText, pageTitle: item.page.title } })
                  : {}
              }
            >
              <Grid sx={{
                ...(i % 2 === 0 ? theme.card.pagenameBox1 : theme.card.pagenameBox2),
                position: 'absolute',
                height: '100%',
                width: '7px',
              }} />
              <Typography sx={{ pl: '16px' }} className='pageTitleText'>
                {item.page.title}
              </Typography>
            </Grid>
          </Grid>
        )
      }))}
    </>
  )
}