import { Box, Typography } from "@mui/material";
import { Button } from "core/components";
import PlusIcon from "assets/images/blue_addIcon.svg";
import { godzilla as theme } from "util/themes/godzilla";
import { useEffect, useMemo, useState } from "react";
import { AddMilestone, ViewMilestone } from "./components";
import {
  IContextData,
  IPathwayData,
  MilestoneDataType,
} from "./outline.helper";
import { useAppContext } from "components/app-context/appContext";
import DragAndDropComponent from "components/drag-and-drop/DragAndDrop";

interface IPathwayOutlineProps {
  activeIndex: number;
}

const styles = {
  button: {
    border: "1px dashed #676F7C",
    borderRadius: "10px",
    width: "500px",
    height: "52.94px",
    '&:hover': {
      border: "1px dashed #676F7C",
      backgroundColor: theme?.palette?.secondary?.[10]
    }
  },
  milestoneTitleTextStyle: {
    ...theme?.typography?.body?.default,
    ...theme?.typography?.body?.body3,
    ...theme?.typography?.body?.regular,
    color: theme?.palette?.grayScale?.[700],
    textTransform: "capitalize",
    ml: "10px",
  }
};

const Outline = (props: IPathwayOutlineProps) => {
  const { activeIndex } = props;
  const { pathwayData, pathwayError, setPathwayData, setPathwayError }: IContextData = useAppContext();
  const [addNewKnowledgeMilestone, setAddNewKnowledgeMilestone] =
    useState(false);
  const [addNewDemonstrationMilestone, setAddNewDemonstrationMilestone] =
    useState(false);
  const [milestoneDeleteData, setMilestoneDeleteData] = useState<
    MilestoneDataType[]
  >([]);
  const [milestoneData, setMileStoneData] = useState<Array<any>>([]);
  const [selectedMilestoneIndex, setSelectedMilestoneIndex] = useState<number | null>(null);

  const milestoneListError = useMemo(() => activeIndex === 1 ? pathwayError.milestoneListError : [], [activeIndex, pathwayError])

  useEffect(() => {
    setMileStoneData(pathwayData?.milestones)
  }, [pathwayData])

  useEffect(() => {
    if (addNewKnowledgeMilestone || addNewDemonstrationMilestone) {
      setPathwayError({ ...pathwayError, disableStepper: true })
    } else {
      setPathwayError({ ...pathwayError, disableStepper: false })
    }
  }, [addNewKnowledgeMilestone, addNewDemonstrationMilestone])

  const onAddKnowledgeMilestone = () => {
    setAddNewKnowledgeMilestone(true);
  };

  const onAddDemonstrationMilestone = () => {
    setAddNewDemonstrationMilestone(true);
  };

  const onUpdateMilestoneData = (items: any) => {
    let updatedActionMilestones = items.map((newMilestone: any, newIndex: number) => {
      pathwayData?.milestones?.forEach((prevMilestone: any, prevIndex: number) => {
        if (newMilestone.action !== "DELETE" && newMilestone.sdoId && newMilestone.sdoId === prevMilestone.sdoId && newIndex !== prevIndex) {
          newMilestone.action = "UPDATE";
        } else if (newMilestone.action !== "DELETE" && !newMilestone.sdoId && newMilestone.uuid === prevMilestone.uuid && newIndex !== prevIndex) {
          newMilestone.action = "NEW";
        }
      })
      if (newMilestone.action === "DELETE") {
        newMilestone.order = "";
      } else {
        newMilestone.order = newIndex + 1;
      }
      return newMilestone;
    })

    setMileStoneData(updatedActionMilestones);
    setPathwayData({
      ...pathwayData,
      milestones: updatedActionMilestones
    })
  }

  const setMilestoneData = (listData: MilestoneDataType[]) => {
    let cloneData = [...listData];
    let newCloneData: any[] = [];
    const arrOfDeletedObjects = cloneData.filter(obj => {
      if (obj.action !== "DELETE") {
        newCloneData.push(obj)
        return false
      } else {
        return true
      }
    });
    newCloneData = [...newCloneData, ...arrOfDeletedObjects]

    onUpdateMilestoneData(newCloneData);
  };

  const isEditCard = useMemo(() => activeIndex === 0, [activeIndex]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box>
          <Typography
            sx={{
              ...theme?.typography?.h1,
              color: "#3D4655",
              mb: "24px",
            }}
          >
            {isEditCard ? "Outline" : "Build"}
          </Typography>
        </Box>
        <Box
          height={"calc(100vh - 192px)"}
          overflow={"auto"}
          className={"customScroll"}
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          alignItems={"center"}
        >
          {addNewKnowledgeMilestone ? (
            <Box mb={`${addNewKnowledgeMilestone ? "16px" : "0px"}`}>
              <AddMilestone
                milestoneType="KNOWLEDGE"
                milestoneData={milestoneData}
                setMilestoneData={setMilestoneData}
                setAddNewDemonstrationMilestone={
                  setAddNewDemonstrationMilestone
                }
                setAddNewKnowledgeMilestone={setAddNewKnowledgeMilestone}
              />
            </Box>
          ) : null}
          {addNewDemonstrationMilestone ? (
            <Box mb={`${addNewDemonstrationMilestone ? "16px" : "0px"}`}>
              <AddMilestone
                milestoneType="DEMONSTRATION"
                milestoneData={milestoneData}
                setMilestoneData={setMilestoneData}
                setAddNewDemonstrationMilestone={
                  setAddNewDemonstrationMilestone
                }
                setAddNewKnowledgeMilestone={setAddNewKnowledgeMilestone}
              />
            </Box>
          ) : null}
          <DragAndDropComponent
            draggableData={milestoneData}
            setDraggableUpdateData={(items) => onUpdateMilestoneData(items)}
            isDraggableDisable={isEditCard && (milestoneData.filter((item: any) => item.action !== "DELETE") || []).length !== 1 === true ? false : true}
            renderItem={(item: any, index: number) => {
              if (item.action !== "DELETE") {
                return (
                  <>
                    <ViewMilestone
                      isEditCard={isEditCard}
                      milestoneType={item.type}
                      milestoneTitle={item.title}
                      activeIndex={activeIndex}
                      index={index}
                      isError={milestoneListError.includes(index)}
                      setMilestoneData={setMilestoneData}
                      milestoneData={milestoneData}
                      setMilestoneDeleteData={setMilestoneDeleteData}
                      selectedMilestoneIndex={selectedMilestoneIndex}
                      setSelectedMilestoneIndex={setSelectedMilestoneIndex}
                    />
                  </>
                );
              } else return null
            }}
          />
          {isEditCard && (
            <Box
              mt={`${addNewDemonstrationMilestone ||
                  addNewKnowledgeMilestone ||
                  milestoneData.length
                  ? "30px"
                  : "0px"
                }`}
              gap={2}
              display={"flex"}
              flexDirection={"column"}
            >
              <Button sx={styles.button} onClick={onAddKnowledgeMilestone}>
                <img src={PlusIcon} alt="ICON" />
                <Typography
                  sx={styles.milestoneTitleTextStyle}
                >
                  Knowledge Milestone
                </Typography>
              </Button>
              <Button sx={styles.button} onClick={onAddDemonstrationMilestone}>
                <img src={PlusIcon} alt="ICON" />
                <Typography
                  sx={styles.milestoneTitleTextStyle}
                >
                  Demonstration Milestone
                </Typography>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Outline;
