import { Box, Button, Typography } from "@mui/material";
import { Button as ControlBtn } from "core/components";
import { godzilla as theme } from "util/themes/godzilla";
import { IModuleCardDataProps } from "../types";
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useAuth } from "core/contexts";
import { useMutation } from "react-query";

const styles = {
  boxStyle: {
    width: "310px",
    height: "197px",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    padding: "20px",
  },
  textStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4B4D58",
    textAlign: 'center',
  },
  yesTextButtonStyle: {
    background: theme?.palette?.primary[500],
    borderRadius: "100px",
    padding: "4px 18px",
    gap: "8px",
  },
  noTextStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#003DA6",
    textTransform: "none",
    py: '6px',
    px: '10px',
    borderRadius: '100px'
  },
  buttonTextStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "19px",
    color: "#FFF",
  },
};

interface IPublishConfirmModalProps {
  setPublishConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
  moduleData?: IModuleCardDataProps[];
  onRefetch?: ()=> void;
  isReviewModule?: boolean;
  onPublishModuleData?: ()=> void;
}

export const PublishConfirmModal = (props: IPublishConfirmModalProps) => {
  const { setPublishConfirmModal, moduleData = [], onRefetch, isReviewModule = false, onPublishModuleData } = props;
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());

  const onPublishReview = useMutation(() => {
    let formData = getPreparedFormData();
    return axiosInstance().put(
      "/contentcreator/modules/publish",
      formData,
      {
        headers: {
          Authorization: headers.Authorization,
        },
      }
    );
  });

  const getPreparedFormData = () => {
    let formData: any[] = [];
    moduleData.forEach((course) => {
      let mCourse = course.modules.map((module) => {
        let obj = {
          sdoId: module?.sdoId,
          status: "published"
        }
        formData.push(obj)
        return module;
      });
      course.modules = mCourse;
      return course;
    });
    return formData;
  };

  const onPublishFiles = () => {
    if(isReviewModule) {
      onPublishModuleData && onPublishModuleData()
    } else {
      onPublishReview
      .mutateAsync()
      .then((res) => {
        setPublishConfirmModal(false);
        onRefetch && onRefetch();
      })
      .catch((err) => {
        console.log("ERROR", err);
      });
    }
  };

  return (
    <Box sx={styles.boxStyle}>
      <Typography sx={styles.textStyle}>
        Once you publish, you will not be able to make changes to the pathway.
        Are you sure you’re ready to publish?
      </Typography>
      <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
        <Box display={"flex"} justifyContent={"center"} mt={"13px"}>
          <ControlBtn
            variant="contained"
            color="primary"
            size="medium"
            sx={styles.yesTextButtonStyle}
            onClick={onPublishFiles}
          >
            <Typography
              sx={{
                ...theme?.typography?.Components?.button?.default,
                ...theme?.typography?.Components?.button?.small,
                ...styles.buttonTextStyle,
                textTransform: "none",
              }}
            >
              Yes, publish.
            </Typography>
          </ControlBtn>
        </Box>
        <Box mt={"4px"}>
          <Button
            sx={styles.noTextStyle}
            onClick={() => setPublishConfirmModal(false)}
          >
            No, continue reviewing my module(s).
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
