import { Theme } from "@emotion/react";
import { Box, Snackbar, SvgIcon, SxProps, Typography } from "@mui/material";
import { ReactComponent as SuccessIcon } from 'assets/images/toast_success.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/toast_error.svg';
import { ReactComponent as CloseIcon } from 'assets/images/toast_close.svg';
import { godzilla as theme } from "util/themes/godzilla";

export enum IToastTypes {
    Success = 'success',
    Error = 'error'
}

interface IToastProps {
    open: boolean,
    setOpen: (open: boolean) => void,
    message: string,
    type?: IToastTypes,
    style?: SxProps<Theme>,
    successMessageStyle?: SxProps<Theme>,
    vertical?: 'top' | 'bottom',
    horizontal?: 'left' | 'center' | 'right',
    icon?: React.FC,
    dismissOnClickAway?: boolean,
    showCloseIcon?: boolean,
}

const styles = {
    successToast: (showCloseIcon: boolean) => ({
        px: '18px',
        py: '13px',
        pr: showCloseIcon ? '53px' : '18px'
    }),
    errorToast: (showCloseIcon: boolean) => ({
        p: '20px',
        pr: showCloseIcon ? '55px' : '20px'
    }),
    successTextStyle: {
        maxWidth: 170,
        ml: '12px'
    },
    errorTextStyle: {    
        maxWidth: 285,
        ml: '12px',
    },
    closeIconContainer: (type: IToastTypes) => ({
        backgroundColor: type === IToastTypes.Success ? '#07A82A' : '#FF4F4F',
        borderRadius: '100px',
        width: '27px',
        height: '27px',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 8,
        right: 8,
        cursor: 'pointer',
        '.MuiSvgIcon-root': {
            width: '14px',
            height: '14px',
            color: type === IToastTypes.Success ? '#DCFFDD' : '#FFF3F3'
        }
    })
}

const Toast = (props: IToastProps) => {
    const {
        open,
        setOpen,
        message,
        type = IToastTypes.Success,
        style = {},
        successMessageStyle = {},
        vertical = 'top',
        horizontal = 'center',
        icon,
        dismissOnClickAway = false,
    } = props;

    const autoHideDuration = type === IToastTypes.Success ? 3000 : null; //null means stays forever
    const showCloseIcon = props.showCloseIcon || type === IToastTypes.Error;

    const defaultBoxStyle = type === IToastTypes.Success ?
        { ...theme?.typography?.Components?.toast?.success, ...styles.successToast(showCloseIcon) } :
        { ...theme?.typography?.Components?.toast?.error, ...styles.errorToast(showCloseIcon) };

    const defaultMessageStyle = type === IToastTypes.Success ?
        { ...theme?.typography?.Components?.toast?.successText, ...styles.successTextStyle, ...successMessageStyle } :
        { ...theme?.typography?.Components?.toast?.errorText, ...styles.errorTextStyle };

    const toastIcon = icon ?? type === IToastTypes.Success ? SuccessIcon : ErrorIcon;

    const handleClose = (
        event?: React.SyntheticEvent | Event,
        reason?: string
    ) => {
        if (!dismissOnClickAway && reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const renderCloseIcon = () => {
        return (
            <Box sx={styles.closeIconContainer(type)} onClick={() => setOpen(false)}>
                <SvgIcon component={CloseIcon} inheritViewBox />
            </Box>
        )
    }

    return (
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
            <Box display={'flex'} flexDirection={'row'} sx={{ ...theme?.typography?.Components?.toast?.default, ...defaultBoxStyle, ...style }}>
                <SvgIcon component={toastIcon} inheritViewBox />
                <Typography sx={defaultMessageStyle}>{message}</Typography>
                {showCloseIcon && renderCloseIcon()}
            </Box>
        </Snackbar >
    )
}

export default Toast;