import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import SideNavBar from './components/sideNavBar/SideNavBar';

import { AppRoutes } from "./components/app-routes.tsx/AppRoutes";
import { useAppContext } from "components/app-context/appContext";
import { IContextData } from "pages/drafts/DraftsModule/types";

export const App = () => {
  const {pathname} = useLocation();
  const { resetReviewModuleData, reviewModuleData,  resetPathwayData}: IContextData = useAppContext();
  
  useEffect(() => {
    window.scrollTo(0,0);
    
    if(pathname !== '/drafts/review-module' && reviewModuleData.length){
      resetReviewModuleData();
    }
    if(!pathname.includes('pathwayCreator')) {
      resetPathwayData()
    }

  }, [pathname])
  
  return (
    <>
      <div className="App">
        <SideNavBar>
          <Box>
            <AppRoutes />
          </Box>
        </SideNavBar>
      </div>
    </>
  );
};
