import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Typography, IconButton, ClickAwayListener, CircularProgress, Backdrop } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useQuery } from "react-query";

import { Button } from 'core/components';
import { UploadProgressIndicator } from "./components";
import UnPublishedModuleList from "./DraftsModule/UnpublishedModulesList";

import { useAppContext } from "components/app-context/appContext";
import { godzilla as theme } from "util/themes/godzilla";
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useAuth } from "core/contexts";

import OnlyIconSVG from 'assets/images/only_plus.svg';
import sortSVG from 'assets/images/sort.svg';
import filterSVG from 'assets/images/filter.svg';
import deleteSVG from 'assets/images/delete.svg';
import publishSVG from 'assets/images/publish.svg';
import editSVG from 'assets/images/edit.svg';
import emptyDraftsFilterImage from '../../assets/images/contentDrafts.svg';
import PublishWarningModal from "./DraftsModule/components/PublishWarningModal";
import DeleteModal from "./components/DeleteModal";
import { IModuleCardDataProps } from './DraftsModule/types';
import { PublishConfirmModal } from './DraftsModule/components/PublishConfirmModal';
import { draftWarningModalText } from './draft.helper';
import DraftFilterModal, { IDraftFilterFieldTypes } from './components/DraftFilterModal';
import SortModal, { ISortFieldTypes } from './components/SortModal';
import NewModuleModal from './components/NewModuleModal';
import emptyDraftsImageSVG from 'assets/images/draftsimg.svg';

const ROUTE_ADD_NEW_MODULE = '/drafts/add-new-module';

const styles = {
  boxHeader: {
    alignItems: 'center',
    display: 'flex',
    pb: 2,
    width: '100%'
  },
  headerLeftContainer: {
    '&.MuiGrid-root>.MuiGrid-item': {
      pl: 0
    }
  },
  draftsHeader: {
    fontFamily: 'Museo Sans Rounded',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '32px',
    lineHeight: '38px',
    color: theme.palette.grayScale[500],
    pr: 1.5
  },
  newModuleButtonStyle: {
    py: '5px',
    pr: '16px',
  },
  newModuleBtnTextStyle: {
    ...theme?.typography?.Components?.button?.large,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    textTransform: 'none',
    ml: '11px',
    color: theme?.palette?.secondary[50]
  },
  filterBtnText: {
    ...theme?.typography?.Components?.button?.default,
    ...theme?.typography?.Components?.button?.medium,
    textTransform: 'none',
    pl: 1,
    color: theme?.palette?.grayScale[700],
  },
  filterBtn: {
    p: '12px 20px',
    height: '36px',
    border: `2px solid ${theme.palette.tertiary[600]}`,
    '&:hover': {
      background: '#ffffff',
    }
  },
  image: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    mb: '30px',
  },
  boxImportFiles: {
    border: `2px solid ${theme?.palette?.grayScale['A1']}`,
    borderRadius: '5px',
    py: '50px',
    position: 'relative',
    background: '#F9FAFC'
  },
  importText: {
    textAlign: "center",
    color: '#676F7C',
  },
  importButtonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  importButtonText: {
    ...theme?.typography?.Components?.button?.large,
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    textTransform: 'none',
    color: '#2C39DA'
  },
  container: {
    width: "100%",
    height: '100vh',
    display: 'flex',
    px: '10%',
    pt: '8vh',
    pb: 6.25,
    background: '#FFFFFF'
  },
  warningModalContainer: {
    zIndex: 2,
    position: 'absolute',
    top: '30px',
    right: '8px'
  },
  filterModalContainer: {
    zIndex: 2,
    position: 'absolute',
    top: '36px',
    left: '5px'
  },
  sortModalContainer: {
    zIndex: 2,
    position: 'absolute',
    top: '36.5px',
    left: '15px'
  },
  newModuleModalContainer: (isEmpty: boolean) => ({
    zIndex: 2,
    position: 'absolute',
    top: isEmpty ? '30px' : '47px',
    right: 0
  }),
  listContainer: {
    borderRadius: '20px',
    backgroundColor: '#F9FAFC',
    height: 'calc(92vh - 180px)',
    position: 'relative',
    px: 5.25,
    py: 4.75,
    overflow: 'auto'
  },
  warningTextStyle: {
    textStyle: {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '143%',
      color: '#FF5C00',
      pl: '4px',
      pt: 0
    },
    iconStyle: {
      width: '20px',
      height: '20px',
      color: '#FF5C00'
    }
  },
  listIconContainer: {
    display: 'flex',
    '& .MuiIconButton-root': {
        width: 40,
        height: 40
    }
  },
  listIconButtonStyle: (isOpen: boolean) => ({
    background: isOpen ? theme?.palette?.secondary?.[50] : '',
    '&:hover': { background: 'rgba(0, 173, 187, 0.04)' },
    '> .MuiTouchRipple-root span': {
        background: 'rgba(0, 173, 187, 0.3)'
    },
}),
};

const Drafts = () => {
  const [selectedModuleData, setSelectedModuleData] = useState<IModuleCardDataProps[]>([]);
  const [deletedModuleData, setDeletedModuleData] = useState<any[]>([]);
  const [openWarningModal, setWarningModalState] = useState(false);
  const [openDeleteModal, setDeleteModalState] = useState(false);
  const [openPublishConfirmModal, setPublishConfirmModal] = useState(false);
  const [openSortModal, setSortModalState] = useState(false);
  const navigate = useNavigate();
  const { isUploading, isUploadComplete, errorInUpload, setReviewModuleData } = useAppContext();
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());
  const [isLoader, setIsLoader] = useState(true);
  const [isDataUpdated, setDataUpdated] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [openNewModuleModal, setOpenNewModuleModal] = useState(false);
  const [fieldType, setFieldType] = useState<IDraftFilterFieldTypes>();

  const [isAscending, setIsAscending] = useState<boolean>(true);
  const [selSortType, setSelSortType] = useState<ISortFieldTypes>(ISortFieldTypes.CourseDate);

  const getCourses = useQuery('courses', async () => {
    setIsLoader(true)
    const response = await axiosInstance().get('/contentcreator/course/unpublishedModule', {
      headers: {
        "Authorization": headers.Authorization,
        "Content-Type": "application/json"
      },
      data: {}
    });
    return response.data;
  }, {
    cacheTime: 0,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setIsLoader(false);
      setSelectedModuleData([]);
      setDeletedModuleData([]);
    },
    onError: () => {
      setIsLoader(false);
      throw new Error('There is some issue in fetching modules');
    },
  });

  const getFilteredArray = useMemo((): any[] => {
    const courseList = fieldType ? getCourses.data.map((i: any) => ({
      ...i, modules: i.modules.filter((x: any) => {
      if (fieldType === IDraftFilterFieldTypes.ReadyToPublish) {
        if (x.status === fieldType) {
          return true
        }
        else { return false }
      } else {
        if (!x.status || x.status === IDraftFilterFieldTypes.Drafts || x.status === IDraftFilterFieldTypes.Unpublished) {
          return true
        }
        else { return false }
      }
    }
      )
    })).filter((item: any) => item.modules.length) : getCourses.data;
    
    return courseList?.map((course: any) => {
      return ({...course, modules: course.modules.map((module: any) => {
        module.savedSkills = module.listModuleSkills;
        delete module.listModuleSkills;
        return module;
      })})
    })
  }, [fieldType, getCourses.data])

  const sortList = (list: any[], fieldName: string, isDate: boolean, isAscending: boolean) => {
    return [...list].sort((a: any, b: any) => {
      let fieldA = a[fieldName];
      let fieldB = b[fieldName];

      fieldA = isDate ? new Date(fieldA).getTime() : fieldA.toLowerCase();
      fieldB = isDate ? new Date(fieldB).getTime() : fieldB.toLowerCase();

      if (isDate) {
        fieldA = new Date(fieldA).getTime();
        fieldB = new Date(fieldB).getTime();

        if (!isAscending)
          return fieldA - fieldB;
        else
          return fieldB - fieldA;
      } else {
        fieldA = fieldA.toLowerCase();
        fieldB = fieldB.toLowerCase();

        if (isAscending)
          return fieldA < fieldB ? -1 : Number(fieldA < fieldB);
        else
          return fieldA > fieldB ? -1 : Number(fieldA > fieldB);
      }
    })
  }

  const isModuleSorting = useMemo(() => [ISortFieldTypes.ModuleDate, ISortFieldTypes.ModuleName].includes(selSortType), [selSortType])

  const sortedCourseList = useMemo(() => {
    setDataUpdated(true);
    if (getCourses?.data?.length) {
      const isDate = [ISortFieldTypes.CourseDate, ISortFieldTypes.ModuleDate].includes(selSortType);
      const fieldName = isDate ? 'createDate' : 'title';

      if (!isModuleSorting) {
        return sortList(getFilteredArray, fieldName, isDate, isAscending);
      } else {
        let newList: any[] = [];
        getFilteredArray.forEach((course: IModuleCardDataProps) => {
          course.modules.forEach(module => {
            newList.push({ ...module, courseId: course.courseId })
          });
        });

        newList = sortList(newList, fieldName, isDate, isAscending);

        return newList.map(module => {
          const course: IModuleCardDataProps = getFilteredArray.find((item: IModuleCardDataProps) => item.courseId === module.courseId);
          delete module.courseId;
          return { ...course, modules: [module] };
        })
      }
    } else {
      return [];
    }
  }, [getCourses?.data, isAscending, isModuleSorting, selSortType, getFilteredArray]);

  const showFilters = !!getCourses?.data?.length;
  const showEmptyState = getCourses.isLoading || !getCourses?.data || getCourses?.data?.length === 0;
  const emptyFilteredList = getFilteredArray?.length === 0;

  const onSelectReviewData = (id: string, cardData: IModuleCardDataProps, e: React.ChangeEvent<HTMLInputElement>) => {

    if (openWarningModal || openPublishConfirmModal) {
      handleCloseWarningModal();
    }
    let newModuleData: IModuleCardDataProps[];
    if (!e.target.checked) {
      newModuleData = selectedModuleData.filter(course => {
        if (course.courseId === cardData.courseId) {
          course.modules = course.modules.filter(module => {
            return module.sdoId !== id
          })
          return !!course.modules.length;
        }
        return true
      })
      setSelectedModuleData(newModuleData);
    } else {
      setSelectedModuleData(prev => {
        let clonePrev: IModuleCardDataProps[] = JSON.parse(JSON.stringify(prev));
        if (prev.length) {
          let findIndex = clonePrev.findIndex(course => course.courseId === cardData.courseId);
          if (findIndex > -1) {
            clonePrev[findIndex].modules = [...clonePrev[findIndex].modules, ...cardData.modules];
          } else {
            clonePrev.push(cardData);
          }
          return clonePrev;
        } else {
          return [cardData]
        }
      });
    }
  };

  const handleCloseWarningModal = () => {
    setWarningModalState(false);
    setPublishConfirmModal(false);
  }

  const handleCloseDeleteModal = () => {
    setDeleteModalState(false);
  }

  const onClickDeleteModal = () => {
    setDeleteModalState(true);
  }

  const handleCloseFilterModal = () => {
    setOpenFilterModal(false);
  }

  const onClickFilterModal = () => {
    setOpenFilterModal(!openFilterModal);
  }

  const handleCloseNewModuleModal = () => {
    setOpenNewModuleModal(false);
  }
  const onClickNewModuleModal = () => {
    setOpenNewModuleModal(!openNewModuleModal);
  }

  const handleCloseSortModal = () => {
    setSortModalState(false);
  }

  const onClickSortModal = () => {
    setSortModalState(!openSortModal);
  }

  const onClickPublishModule = () => {
    if (selectedModuleData.length > 0) {
      let flag = false;
      selectedModuleData.forEach((course) => {
        course.modules.forEach((module) => {
          if (module.status !== 'readyToPublish') {
            flag = true
          }
          return module
        })
        return course
      })
      if (flag) {
        setWarningModalState(true);
      } else {
        setPublishConfirmModal(true);
      }
    }
  }

  const onSelectCardToNavigate = (module: IModuleCardDataProps) => {
    setTimeout(() => {
      setReviewModuleData([module])
      navigate('/drafts/review-module')
    }, 500);
  };

  const onClickEditModules = () => {
    setReviewModuleData(selectedModuleData)
    navigate('/drafts/review-module');
  }

  const fetchCourseData = useCallback(() => {
    getCourses.refetch();
    setIsLoader(true);
    setSelectedModuleData([]);
    setDataUpdated(true);
  }, [getCourses])

  useEffect(() => {
    let selectedModuleCount = 0;
    selectedModuleData.forEach(item => {
      selectedModuleCount += item.modules.length;
    });

    if (selectedModuleCount === deletedModuleData.length && isDeleting) {
      setIsDeleting(false);
      fetchCourseData();
    }
  }, [selectedModuleData, deletedModuleData, isDeleting, fetchCourseData])

  const removeModuleFromList = useCallback((course: IModuleCardDataProps) => {
    const module = course.modules[0];
    if (module) {
      setDeletedModuleData(prv => [...prv, module])
    }
  }, [])

  const renderNewModuleBtn = () => {
    return (
      <Grid item sx={{ position: 'relative', ml: 2.5 }} display={'flex'} alignItems={'center'}>
        <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleCloseNewModuleModal}>
          <Box>
            <Button
              sx={styles.newModuleButtonStyle}
              variant="contained"
              color='primary'
              size="medium"
              onClick={onClickNewModuleModal}
            >
              <img src={OnlyIconSVG} alt="Plus Icon" />
              <Typography sx={styles.newModuleBtnTextStyle}>New Module</Typography>
            </Button>
            <Box sx={styles.newModuleModalContainer(showEmptyState)}>
              {openNewModuleModal ? <NewModuleModal /> : null}
            </Box>
          </Box>
        </ClickAwayListener>
      </Grid>
    )
  }

  const renderUploadProgressIndicator = () => (
    <Grid item display={'flex'} alignItems={'center'}>
      {isUploading || isUploadComplete || errorInUpload ? <UploadProgressIndicator loadingVariant={(errorInUpload || isUploadComplete) ? 'determinate' : 'indeterminate'} /> : null}
    </Grid>
  );

  return (
    <Box sx={styles.container} justifyContent="center" flexDirection="column" >
      <Grid flexDirection={'row'} display='flex' alignItems={'center'} justifyContent={'space-between'}>
        <Typography sx={styles.draftsHeader}>Drafts</Typography>
        <Grid display={'flex'} flexDirection={'row'}>
          {showEmptyState && renderUploadProgressIndicator()}
          {showEmptyState && renderNewModuleBtn()}
        </Grid>
      </Grid>
      <Grid sx={styles.boxHeader}>
        {showFilters && <Grid container display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'} width={'100%'} sx={{ mt: '12px' }}>
          <Grid md={5} item display={'flex'} flexDirection='row'>
            <Grid item sx={{ position: 'relative' }}>
              <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleCloseFilterModal}>
                <Box>
                  <Button variant="outlined" size='medium' sx={styles.filterBtn} onClick={onClickFilterModal} >
                    <img src={filterSVG} alt="filterIcon" width={18} height={12} />
                    <Typography sx={{
                      ...styles.filterBtnText
                    }}>
                      Filter
                    </Typography>
                  </Button>
                  <Box sx={styles.filterModalContainer}>
                    {openFilterModal ? <DraftFilterModal selFilterFieldType={fieldType} setSelFilterFieldType={setFieldType} /> : null}
                  </Box>
                </Box>
              </ClickAwayListener>
            </Grid>
            <Grid item sx={{ position: 'relative' }}>
              <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleCloseSortModal}>
                <Box>
                  <Button
                    variant="outlined"
                    size='medium' sx={{
                      ...styles.filterBtn,
                      ml: '10px'
                    }}
                    onClick={onClickSortModal}>
                    <img src={sortSVG} alt="sortIcon" width={20} height={18} />
                    <Typography component={'span'} sx={styles.filterBtnText}>
                      Sort
                    </Typography>
                  </Button>
                  <Box sx={styles.sortModalContainer}>
                    {openSortModal ? <SortModal isAscending={isAscending} selSortFieldType={selSortType} setIsAscending={setIsAscending} setSelSortFieldType={setSelSortType} /> : null}
                  </Box>
                </Box>
              </ClickAwayListener>
            </Grid>
          </Grid >
          <Grid item md={7} flexDirection={'row'} display={'flex'} spacing={2} justifyContent={'flex-end'} sx={styles.headerLeftContainer}>
            {!showEmptyState && renderUploadProgressIndicator()}
            {selectedModuleData.length > 0 ?
              <Grid item sx={{...styles.listIconContainer, ml: 1.5 }}>
                <Grid item sx={{ width: '40px', height: '40px'}}>
                  <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleCloseDeleteModal}>
                    <Box position={'relative'}>
                      <IconButton onClick={onClickDeleteModal} sx={styles.listIconButtonStyle(openDeleteModal)}>
                        <img src={deleteSVG} alt="delele_module" />
                      </IconButton>
                      <Box sx={styles.warningModalContainer}>
                        {openDeleteModal ? <DeleteModal handleCloseDeleteModal={handleCloseDeleteModal} moduleData={selectedModuleData} removeModuleFromList={removeModuleFromList} isMultipleDelete={true} setIsDeleting={setIsDeleting} /> : null}
                      </Box>
                    </Box>
                  </ClickAwayListener>
                </Grid>
                <Grid item >
                  <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleCloseWarningModal}>
                    <Box position={'relative'}>
                      <IconButton onClick={onClickPublishModule} sx={styles.listIconButtonStyle(openWarningModal || openPublishConfirmModal)}>
                        <img src={publishSVG} alt="publish_module" />
                      </IconButton>
                      <Box sx={styles.warningModalContainer}>
                        {openWarningModal ? <PublishWarningModal sx={styles.warningTextStyle} warningText={draftWarningModalText} handleCloseWarningModal={handleCloseWarningModal} /> : null}
                        {openPublishConfirmModal ? <PublishConfirmModal setPublishConfirmModal={setPublishConfirmModal} moduleData={selectedModuleData} onRefetch={fetchCourseData} /> : null}
                      </Box>
                    </Box>
                  </ClickAwayListener>
                </Grid>
                <Grid item >
                  <IconButton onClick={onClickEditModules} sx={styles.listIconButtonStyle(false)}>
                    <img src={editSVG} alt="edit_modules" />
                  </IconButton>
                </Grid>
              </Grid>
              : null}
            {!showEmptyState && renderNewModuleBtn()}
          </Grid>
        </Grid >}
      </Grid >
      {showEmptyState ?
        <Box sx={{ ...styles.boxImportFiles, background: theme?.palette?.info?.contrastText }}>
          <Box sx={styles.image}>
            <img src={emptyDraftsImageSVG} alt="add_icon" />
          </Box>
          <Box>
            <Typography sx={{ ...theme?.typography?.h1, ...styles.importText, mb: '21px' }}>
              You don't have anything yet.
              <br />Let's get started!
            </Typography>
          </Box>
          <Box sx={styles.importButtonBox}>
            <Button
              variant="outlined"
              size='medium'
              sx={{
                ...styles.filterBtn,
              }}
              onClick={() => navigate('/drafts/add-new-module')}>
              <Typography sx={{ ...theme?.typography?.subtitle2, ...styles.filterBtnText, color: '#2C39DA' }}>
                New Module
              </Typography>
            </Button>
            <Button
              variant="outlined"
              size='medium'
              sx={{
                ...styles.filterBtn,
                ml: '12px'
              }}
              onClick={() => navigate('/drafts/import-checklist')}>
              <Typography sx={{ ...theme?.typography?.subtitle2, ...styles.filterBtnText, color: '#2C39DA', }}>
                Import Module
              </Typography>
            </Button>
          </Box>
          {isLoader && <CircularProgress size={60} sx={{ position: 'absolute', top: 'calc(50% - 80px)', right: 'calc(50% - 42px)', color: theme?.palette?.tertiary[600] }} />}
        </Box>
        : emptyFilteredList ?
        <Box sx={styles.boxImportFiles}>
          <Box sx={styles.image}>
              <img src={emptyDraftsFilterImage} alt="add_icon"
              width={'165px'}
              height={'141px'} />
          </Box>
          <Box>
            <Typography sx={{ ...theme?.typography?.h1, ...styles.importText, pb: '25px' }}>
              We don't have anything that fits that criteria.
            </Typography>
            <Typography sx={{ ...theme?.typography?.h1, ...styles.importText, fontSize: '20px', lineHeight: '28px' }}>
              Adjust your filters to see more content drafts.
            </Typography>
          </Box>
          {isLoader && <CircularProgress size={60} sx={{ position: 'absolute', top: 'calc(50% - 80px)', right: 'calc(50% - 42px)', color: theme?.palette?.tertiary[600] }} />}
        </Box>
        :
          <Box sx={styles.listContainer} className={'customScroll reviewModulesMainContainer  '}>
          <Box sx={{ opacity: `${isLoader ? '0.5' : '1'}` }}>
            <UnPublishedModuleList
              setDataUpdated={setDataUpdated}
              hasDeleteSelection={false}
              hasSelection={true}
              data={sortedCourseList}
              selectedModuleData={selectedModuleData}
              setModuleData={onSelectReviewData}
              onSelectCard={onSelectCardToNavigate}
              isDataUpdated={isDataUpdated}
              isModuleSorting={isModuleSorting} />
          </Box >
          {isLoader && <CircularProgress size={60} sx={{ position: 'absolute', top: '50%', right: '50%', color: theme?.palette?.primary[500] }} />}
        </Box >
      }
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isDeleting}
      >
        <CircularProgress size={60} sx={{ color: theme?.palette?.primary[500] }} />
      </Backdrop>

    </Box >
  );
};

export default Drafts;