import { ReactComponent as HomeIcon } from 'assets/images/sidenav_home.svg';
import { ReactComponent as DraftIcon } from 'assets/images/sidenav_draft.svg';
import { ReactComponent as LibraryIcon } from 'assets/images/sidenav_library.svg';
import { ReactComponent as MapMarkerIcon } from 'assets/images/sidenav_map_marker.svg';
import { ReactComponent as PathwayLibraryIcon } from 'assets/images/side_nav_pathway_library.svg';

interface ISideNavSubMenu {
  title: string;
  urlKey: string;
  icon: React.FunctionComponent;
}

interface ISideNavMenu {
  sectionTitle?: string;
  subMenu: ISideNavSubMenu[];
}

const sideMenuConfig: ISideNavMenu[] = [
  {
    sectionTitle: 'Home',
    subMenu: [
      {
        title: 'Home',
        urlKey: '/home', //update this home pathway route is created
        icon: HomeIcon
      }
    ]
  },
  {
    sectionTitle: 'Content Management',
    subMenu: [
      {
        title: 'Content Drafts',
        urlKey: '/drafts',
        icon: DraftIcon
      },
      {
        title: 'Content Library',
        urlKey: '/library',
        icon: LibraryIcon
      },
    ]
  },
  {
    sectionTitle: 'Pathway Management',
    subMenu: [
      {
        title: 'Pathway Drafts',
        urlKey: '/pathway', //update this when pathway route is created
        icon: MapMarkerIcon
      },
      {
        title: 'Pathway Library',
        urlKey: '/pathwayLibrary', //update this when pathway route is created
        icon: PathwayLibraryIcon
      }
    ]
  },
];

export {
  sideMenuConfig,
  type ISideNavMenu
}