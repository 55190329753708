import { Children } from "react";
import { Box, Divider, Grid, SvgIcon, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ISideNavMenu } from "util/sidemenuconfig";
import { godzilla as theme } from 'util/themes/godzilla';
import { useAuth } from "core/contexts";

const styles = {
    sectionTitleStyle: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 600,
        whiteSpace: 'pre-line',
        textAlign: 'center'
    },
    subMenuContainer: (isSelected: boolean) => ({
        height: '38px',
        my: '13px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: isSelected ? 'rgba(233, 234, 255, 0.3)' : 'initial',
        '&:hover': {
            cursor: 'pointer',
            height: '46px',
            width: '231px',
            backgroundColor: theme?.palette?.primary[500],
            borderRadius: '100px',
            boxShadow: '0px 3px 3px -2px rgba(0, 0, 0, 0.2)',
            filter: 'drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.14)) drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.12))',
            transition: 'all .3s ease-in-out',
            '& .MuiGrid-root': {
                justifyContent: 'start',
            },
            '& .MuiSvgIcon-root': {
                ml: '38px',
                color: '#FFFFFF'
            },
            '& .MuiDivider-root': {
                display: 'none'
            },
            '& .MuiTypography-root': {
                display: 'inline'
            }
        }
    }),
    containerDividerStyle: {
        mx: '20px',
        mt: '4px',
        mb: '24px',
        border: '1px solid #C6CAD1',
    },
    selectedDividerStyle: {
        borderRadius: '100px',
        borderColor: '#5B67FF',
        borderWidth: '3px',
    },
    iconContainer: (isSelected: boolean) => ({
        py: '7px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            color: isSelected ? theme?.palette?.primary[500] : '#7E8592',
        },

    }),
    subMenuTitleStyle: {
        display: 'none',
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '20px',
        lineHeight: '27px',
        ml: '8px',
        color: theme?.palette?.info?.contrastText
    }
}

interface INavBarItemProps {
    item: ISideNavMenu,
    isFirst: boolean
}

const NavBarItem = ({ item: { sectionTitle, subMenu }, isFirst }: INavBarItemProps) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { logout } = useAuth();

    const onTabClick = (url: string) => {
        if (url.includes('logout')) {
            logout();
            return;
        }
        navigate(url);
    };

    return (
        <Box display={'flex'} flexDirection={'column'}>
            {!isFirst && sectionTitle && <Divider sx={styles.containerDividerStyle} />}
            {sectionTitle && <Typography variant="caption" sx={styles.sectionTitleStyle}>{sectionTitle}</Typography>}
            <>
                {Children.toArray(subMenu.map(x => {
                    const isSelected = pathname.split('/')[1] === x.urlKey.split('/')[1];                
                    return (
                        <Grid sx={styles.subMenuContainer(isSelected)} onClick={() => onTabClick(x.urlKey)}>
                            {isSelected && <Divider orientation="vertical" sx={styles.selectedDividerStyle} />}
                            <Grid flex={1} sx={styles.iconContainer(isSelected)}>
                                <SvgIcon component={x.icon} inheritViewBox />
                                <Typography sx={styles.subMenuTitleStyle}>{x.title}</Typography>
                            </Grid>
                        </Grid>
                    )
                }))}
            </>
        </Box>
    )
}

export default NavBarItem;