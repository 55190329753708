import { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import PathwayLayout from "../../PathwayLayout/PathwayLayout"
import Outline from "../PathwayOutline/Outline";
import { useAppContext } from "components/app-context/appContext";
import { IContextData } from '../PathwayOutline/outline.helper';
import { getDefaultHeaders } from 'core/util';
import { useAuth } from 'core/contexts';
import { useQuery } from 'react-query';
import { getPathwayDetail } from 'util/getPathwayDetail.api';
import { getPathwayCreatorObject } from '../pathwayCreator.helper';

const PathwayMilestones = () => {
  const { pathwayData, pathwayError, setPathwayError, setPathwayData }: IContextData = useAppContext();
  const navigate = useNavigate();
  const params = useParams();

  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());

  const { data, isLoading } = useQuery('pathways', async () => 
    getPathwayDetail(params?.id || '', headers ,getToken())
  , {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() =>{
    return () => {
      setPathwayError({ ...pathwayError, milestoneListError: [] })
    }
  }, [])

  useEffect(() => {
    if (!pathwayData.pathway?.data?.title) {
      if(data?.knowledgeMilestones.length === 0 && data?.demoMilestones.length === 0){
        navigate(`/pathwayCreator/outline/${params.id}`)
      }else {
        setPathwayData({...pathwayData, ...getPathwayCreatorObject(data)})
      }
    }
  }, [isLoading]);

  return (
    <>
      <PathwayLayout
        activeIndex={1}
        exitBtnText={"No, continue creating my pathway"}
        exitNavigation={'/pathway'}
        buttonText={"Settings"}
        pathwayTitle={pathwayData.pathway?.data?.title} >
          <Outline activeIndex={1}/>
      </PathwayLayout >
    </>
  )
}

export default PathwayMilestones;