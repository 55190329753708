import { useAuth } from "core/contexts";
import { Navigate } from "react-router-dom";

interface IProtectedRoute {
  requiredRoles?: string[];
  children: any;
}
// This decides if the user has the right to see a specific route
export const ProtectedRoute = (props: IProtectedRoute) => {
  const { hasRequiredRoles } = useAuth();
  const { children, requiredRoles = [] } = props;

  if (requiredRoles.length && !hasRequiredRoles(requiredRoles)) {
    console.error("InsufficientPermissionsError");
    return <Navigate to="/InsufficientPermissionsError" replace />;
  }

  return children;
};
