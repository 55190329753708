import { Box, Divider, Typography } from '@mui/material';
import { Button, Checkbox, SearchField } from 'core/components';
import { godzilla as theme } from "util/themes/godzilla";
import clearSVG from 'assets/images/clear.svg';
import { Children, useCallback, useState } from 'react';
import { IPathwayModuleSkillData } from '../builder.helper';


const styles = {
  modalContainer: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px rgb(0 0 0 / 14%), 0px 1px 18px rgb(0 0 0 / 12%)',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    cursor: 'initial',
    width: '300px',
    maxHeight: '440px',
    px: '30px',
    pt: '21px',
    pb: '10px'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    mr: '10px'
  },
  titleTextStyle: {
    ...theme?.typography?.h3,
    fontSize: '21px',
    lineHeight: '25px',
    fontStyle: 'normal',
    color: theme?.palette?.grayScale?.[700]
  },
  clearBtn: {
    textTransform: 'none',
    color: theme?.palette?.primary[500],
  },
  clearBtnTextStyle: {
    ...theme?.typography?.Components?.button?.medium,
    ...theme?.typography?.Components?.button?.default,
    textTransform: 'none',
    ml: '4px'
  },
  serchContainer: {
    mt: '16px'
  },
  searchFieldStyle: {
    '& input::placeholder': {
      color: theme?.palette?.grayScale?.[600],
      opacity: 1,
    },
    '& .MuiOutlinedInput-root': {
      p: '7px 6px 7px 16px',
    },
    '& .MuiTextField-root .MuiInputBase-root .MuiInputBase-input': {
      pl: '10px',
    },
    '& .MuiOutlinedInput-input': {
      fontFamily: 'Nunito',
      fontWeight: 300,
      lineHeight: '22px',
      color: '#505866'
    },
    '& .MuiInputAdornment-root': {
      mr: '0.5px'
    },
    '& .MuiTextField-root': {
      borderColor: theme?.palette?.grayScale?.[300],
      '& .MuiOutlinedInput-notchedOutline': {
        p: 0,
        borderColor: theme?.palette?.grayScale?.[300],
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.palette?.grayScale?.[300],
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme?.palette?.grayScale?.[300],
      },
    },
  },
  searchFieldInputStyle: {
    "& .MuiInputBase-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.grayScale?.[300]}`,
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.grayScale?.[300]}`,
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: `1px solid ${theme?.palette?.grayScale?.[300]}`,
      },
    }
  },
  noTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    mt: '23px'
  },
  noTextTitleStyle: {
    ...theme?.typography?.h6,
    color: theme?.palette?.grayScale?.[700]
  },
  noTextDescriptionStyle: {
    ...theme?.typography?.body?.default,
    ...theme?.typography?.body?.body1,
    ...theme?.typography?.body?.regular,
    mt: '8px',
    color: theme?.palette?.grayScale?.[700]
  }
}

interface IHandleFilterModalProps {
  unselectedSkills: IPathwayModuleSkillData[],
  selectedSkills: IPathwayModuleSkillData[],
  onChangeSkill: (skillId: string) => void,
  onClickClear: () => void,
}

export default function FilterModal({ unselectedSkills, selectedSkills, onChangeSkill, onClickClear }: IHandleFilterModalProps) {
  const [isViewMore, setViewMore] = useState(false);
  const [showNoOptionText, setShowNoOptionText] = useState(false);

  const onClickViewMore = useCallback(() => {
    setViewMore(prv => !prv);
  }, [])

  return (
    <Box sx={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
      <Box sx={styles.titleContainer}>
        <Typography sx={styles.titleTextStyle}>Skills Filter</Typography>
        <Button variant='text' sx={styles.clearBtn} onClick={onClickClear}>
          <img src={clearSVG} alt='clear' />
          <Typography sx={styles.clearBtnTextStyle}>Clear</Typography>
        </Button>
      </Box>
      <Box sx={styles.serchContainer}>
        <SearchField
          placeholder='Search skills'
          height='38px'
          width='100%'
          sx={styles.searchFieldStyle}
          inputStyle={styles.searchFieldInputStyle}
          listboxStyle={{ maxHeight: isViewMore ? 300 : 220 }}
          options={unselectedSkills}
          keyName={'skillId'}
          optionKey={'skillName'}
          showCancelIcon
          other={{
            noOptionsText: <></>,
            filterOptions: (options: any, state: any) => {
              let newOptions: any[] = [];
              options.forEach((element: any) => {
                if (
                  element.skillName
                    .replace(",", "")
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                )
                  newOptions.push(element);
              });
              setShowNoOptionText(!!!newOptions.length);
              return newOptions;
            }
          }}
          optionLabel={(option: any) => option.skillName}
          onChange={(e: any, selectedSkill: IPathwayModuleSkillData) => onChangeSkill(selectedSkill.skillId)}
        />
        {showNoOptionText && <Box sx={styles.noTextContainer}>
          <Typography sx={styles.noTextTitleStyle}>Skill Not Found</Typography>
          <Typography sx={styles.noTextDescriptionStyle}>Oops! No Skill with that title was found in this Pathway. Try searching another Skill Name.</Typography>
          <Divider sx={{ bgcolor: theme?.palette?.grayScale?.[200], mt: '24px', mb: '10px' }} />
        </Box>}
      </Box>
      <Box className='customScroll' sx={{ overflow: 'auto' }}>
        {!!selectedSkills.length && <Box>
          <Box sx={{ ml: '11px', py: '10px' }}>
            {Children.toArray(
              selectedSkills.map((skill) => {
                return (
                  <Checkbox
                    label={skill.skillName}
                    checkedColor="#3FB3B0"
                    unCheckedColor="#5D6A71"
                    value={true}
                    inputStyleProps={{
                      ...theme?.typography?.body?.default,
                      ...theme?.typography?.body?.body1,
                      ...theme?.typography?.body?.regular,
                      color: theme?.palette?.grayScale[700],
                    }}
                    onChange={() => onChangeSkill(skill.skillId)}
                  />
                )
              })
            )}
          </Box>
          {!!unselectedSkills.length && <Divider sx={{ bgcolor: theme?.palette?.grayScale?.[200] }} />}
        </Box>}
        {!!unselectedSkills.length && <Box sx={{ ml: '11px', py: '10px' }}>
          {Children.toArray(
            (isViewMore ? unselectedSkills : unselectedSkills.slice(0, 4)).map((skill) => {
              return (
                <Checkbox
                  label={skill.skillName}
                  checkedColor="#3FB3B0"
                  unCheckedColor="#5D6A71"
                  value={skill.isChecked}
                  inputStyleProps={{
                    ...theme?.typography?.body?.default,
                    ...theme?.typography?.body?.body1,
                    ...theme?.typography?.body?.regular,
                    color: theme?.palette?.grayScale[700],
                  }}
                  onChange={() => onChangeSkill(skill.skillId)}
                />
              )
            })
          )}
        </Box>}
        {unselectedSkills.length > 4 && <Divider sx={{
          mb: '19px',
          "&::before, &::after": {
            borderColor: "#26C5D2",
          },
          '& .MuiButtonBase-root': {
            px: '15px'
          }
        }}>
          <Button
            variant='text'
            sx={{
              ...theme?.typography?.Components?.button?.default,
              ...theme?.typography?.Components?.button?.large,
              color: theme?.palette?.primary[500],
              padding: '0px',
              textTransform: 'none'
            }}
            onClick={onClickViewMore}>
            {isViewMore ? 'View Less' : 'View More'}
          </Button>
        </Divider>}
      </Box>
    </Box>
  )
}