import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Button } from 'core/components';
import { useNavigate } from 'react-router-dom';
import { theme } from 'util/themes/godzilla';

const styles = {
    popperContainer: {
        position: 'relative',
        width: '318px',
        height: '205px',
        background: '#FFFFFF',
        boxShadow: '0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)',
        borderRadius: '10px',
        py: 3,
        px: 2.5
    },
    popperContentText: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '22px',
        color: '#3D4655'
    },
    closeIconStyle: {
        position: 'absolute',
        right: '11px',
        top: '11px'
    },
    exitBtn: {
        mt: '12px',
        mb: '6px',
        width: '112.68px',
        height: '28px',
        background: theme.palette.error.main,
        '&:hover': {
            background: theme.palette.error.dark,
        },
        '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(122, 132, 255, 0.7) 99.54%, rgba(255, 255, 255, 0) 100%)'
        },
    },
    exitTextStyle: {
        textTransform: 'none',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '-0.01em',
        color: '#FFFFFF'
    },
    continueTextStyle: {
        textTransform: 'none',
        fontFamily: 'Open Sans',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '150%',
        letterSpacing: '-0.01em',
        color: theme?.palette?.primary[500]
    },
    continueButtonStyle: {
        pl: '15px',
        pr: '15px',
        '&:hover': { background: 'rgba(0, 61, 166, 0.04)' },
        '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(122, 132, 255, 0.7) 99.54%, rgba(255, 255, 255, 0) 100%)'
        },
    }
}

interface IHandleCloseExitModalProps {
    handleCloseExitModal: () => void,
    exitBtnText?: string,
    exitNavigation?: any
}

export default function ExitModal(props: IHandleCloseExitModalProps) {
    const { exitBtnText, handleCloseExitModal, exitNavigation } = props
    const navigate = useNavigate();

    return (
        <Grid sx={styles.popperContainer} display={'flex'} flexDirection={'column'}>
            <Grid width={278} >
                <Typography sx={styles.popperContentText}>
                    Wait! Are you sure you want to exit? If you haven’t saved, you will lose any changes you have made up to this point. You cannot undo this action.
                </Typography>
            </Grid>
            <Grid display={'flex'} alignItems='center' flexDirection={'column'}>
                <Button variant='contained' color='error' sx={styles.exitBtn} onClick={() => navigate(exitNavigation)}>
                    <Typography sx={styles.exitTextStyle}>
                        Yes, exit
                    </Typography>
                </Button>
                <Button variant='text' color='primary' sx={styles.continueButtonStyle} onClick={handleCloseExitModal}>
                    <Typography sx={styles.continueTextStyle}>
                        {exitBtnText}
                    </Typography>
                </Button>
            </Grid>
        </Grid>
    )
}