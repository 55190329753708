import { Grid } from "@mui/material";
import { SuggestedDependencies, TagComponent } from "../components";
import { IModuleCardDataProps, IContextData} from "../DraftsModule/types";
import { useQuery } from "react-query";
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useAuth } from "core/contexts";
import { useAppContext } from "components/app-context/appContext";
import { useEffect } from "react";

interface IReviewModuleDependencyProps {
  selectedModuleData: IModuleCardDataProps | null,
  dependenciesList: Array<any>,
  setDependenciesList: React.Dispatch<React.SetStateAction<Array<any>>>,
  skillData: Array<any>,
  setSkillData: React.Dispatch<React.SetStateAction<Array<any>>>,
  skillError: boolean,
  setSkillError: React.Dispatch<React.SetStateAction<boolean>>,
  setIsModuleEdit: React.Dispatch<React.SetStateAction<boolean>>,
  selectedCourseId?: string;
  isMessageStyle?:boolean;
}

const styles = {
  container: {
    width: '100%',
    // py: 4.75,
    overflow: 'auto'
  }
};

export const ReviewModuleDependencyContainer = (props: IReviewModuleDependencyProps) => {
  const { selectedModuleData, dependenciesList, setDependenciesList, skillData, setSkillData, skillError, setSkillError, selectedCourseId ,isMessageStyle} = props;
  const moduleTitle = selectedModuleData?.modules[0].title;
  const moduleSdoId = selectedModuleData?.modules[0].sdoId;
  const moduleId = selectedModuleData?.modules[0].moduleId;
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());
  const { setReviewModuleData, reviewModuleData }: IContextData = useAppContext();
  
  const { data, isLoading } = useQuery(['moduleDetail', moduleSdoId], async () => {
    const response = await axiosInstance().get(`/contentcreator/skillAndRelationship/${moduleSdoId}`, {
      headers: {
        "Authorization": headers.Authorization,
        "Content-Type": "application/json"
      },
      data: {}
    });
      return response.data;
    }, {
      enabled: !!moduleSdoId,
      refetchOnWindowFocus: false,
      cacheTime: 0
    });

    useEffect(() => {
      let mCourse = reviewModuleData.map((course)=>{
        if(selectedCourseId === course.courseId){
          let mCourse = course.modules.map((module)=>{
            if(module.sdoId === moduleSdoId){
              if(!module.savedRelations?.length){
                module.savedRelations = data?.savedRelationShips || [];
              }
              if(data?.skills?.length){ 
                if(module.savedSkills?.length) {
                  module.savedSkills = module.savedSkills.filter((skill) => skill.skillId === data.skills.find((item: any) => [skill.skillId, skill.id].includes(item.skillId)))
                }
                module.savedSkills = [...(module.savedSkills || []), ...data.skills]
              }

              if(module.savedSkills?.length){
                module['isTagRequired'] = false;
              }
            }
            return module
          })
          course.modules = mCourse
        }
        return course
      });
      setReviewModuleData(mCourse);
      // if(data?.savedRelationShips.length || data?.skills.length){
      //   setIsModuleEdit(true)
      // }else{
      //   setIsModuleEdit(false)
      // }
    }, [moduleSdoId, isLoading])
    
  return (
    <Grid sx={styles.container} item display={'flex'} flexDirection={'column'} className='customScroll'>
      <SuggestedDependencies moduleName={moduleTitle} savedRelationShips={data?.savedRelationShips || []} dependenciesList={dependenciesList} setDependenciesList={setDependenciesList} isLoading={isLoading} selectedCourseId={selectedCourseId} moduleSdoId={moduleSdoId} moduleId={moduleId}/>
      <TagComponent savedSkills={data?.skills || []} skillData={skillData} skillError={skillError} setSkillData={setSkillData} setSkillError={setSkillError} isLoading={isLoading} selectedCourseId={selectedCourseId} moduleId={moduleSdoId} isMessageStyle={isMessageStyle}/>
    </Grid>
  )
}