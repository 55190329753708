import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography,
  Autocomplete,
  ClickAwayListener,
  Grid,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { godzilla as theme } from "util/themes/godzilla";
import LifeTrekLogo from "assets/images/sidenav_logo.svg";
import BreadCrumbComponent from "pages/pathway/components/BreadCrumb";
import { Button } from "core/components";
import FilterSVG from "assets/images/filter.svg";
import SortSVG from "assets/images/sort.svg";
import {
  Children,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { useAuth } from "core/contexts";
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { IPublishedModuleCardDataProps } from "pages/drafts/DraftsModule/types";
import PublishedModulesList from "./BuildModules/PublishedModulesList";
import { IModuleItem } from "./BuildModules/components/ModuleItem";
import { useAppContext } from "components/app-context/appContext";
import Search from "@mui/icons-material/Search";
import { SortModulesModal } from "pages/pathway/PathwayCreator/PathwayBuild/components/SortModulesModal";
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import closeIcon from "assets/images/closeIcon.svg";
import {
  IContextData,
  IKnowledgeMilestoneData,
  IPathwayData,
} from "../PathwayOutline/outline.helper";
import FilterModal from "./components/FilterModal";
import { IPathwayModuleSkillData } from "./builder.helper";
import { NoModuleFound } from "./components/NoModuleFound";
import Toast, { IToastTypes } from "components/toast/Toast";

const styles = {
  mainContainer: {
    display: "flex",
    height: "100vh",
    backgroundColor: "#F9FAFC",
  },
  modalStyle: {
    "& .MuiPaper-root": {
      height: "100vh",
      width: "900px",
      maxWidth: "inherit",
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
      borderRadius: "0px",
      margin: "0px",
      maxHeight: "100vh",
      pl: "90px",
      pt: "40px",
    },
    "& .MuiDialogTitle-root": {
      padding: 0,
      pr: "90px",
    },
    "& .MuiDialogContent-root": {
      p: 0,
      pr: "80px",
    },
  },
  modalTitleContiner: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    mt: "5px",
  },
  exitButtonStyle: {
    mr: "12px",
    "&:hover": { background: "rgba(0, 0, 0, 0.04)" },
    "> .MuiTouchRipple-root span": {
      background:
        "radial-gradient(36.59% 100.8% at 50% 50%, rgba(0, 0, 0, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)",
    },
  },
  exitButtonTextStyle: {
    ...theme?.typography?.Components?.button?.default,
    ...theme?.typography?.Components?.button?.large,
    color: "#202124",
    textTransform: "none",
  },
  nextButtonStyle: {
    py: "9.5px",
    px: "34px",
    "& .MuiButton-endIcon": {
      color: theme?.palette?.secondary[50],
    },
  },
  nextButtonTextStyle: {
    ...theme?.typography?.Components?.button?.default,
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "25px",
    textTransform: "none",
    color: theme?.palette?.secondary[50],
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    mt: "30px",
  },
  searchInputStyle: {
    width: "240px",
    "& .MuiInputBase-root::placeholer": {
      opacity: 1
    },
    "&.MuiAutocomplete-popper": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      padding: 0,
      paddingLeft: "12px",
      paddingRight: "5px",
    },
    "& .MuiAutocomplete-input": {
      paddingLeft: `0px !important`,
    },
    "& fieldset": {
      border: "1px solid #959CA8",
      borderRadius: "100px",
      fontFamily: "Nunito",
      color: "#505866",
    },
    "& .MuiButtonBase-root .MuiSvgIcon-root": {
      height: "16px",
      width: "16px",
      color: "#959CA8",
    },
  },
  filterBtnText: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "none",
    pl: 1,
    color: theme.palette.grayScale[700],
  },
  filterBtn: {
    p: "12px 20px",
    height: "36px",
    border: `2px solid ${theme.palette.tertiary[600]}`,
    "&:hover": {
      background: "#ffffff",
    },
  },
  skillTagContainer: (isShow: boolean) => ({
    mt: isShow ? "16px" : 0,
    mb: "20px",
  }),
  skillTagInnerContainer: {
    display: "flex",
    flexDirection: "row",
  },
  skillTagStyle: {
    height: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    background: theme?.palette?.tertiary?.[600],
    borderRadius: "100px",
    px: "4px",
    py: "3px",
    mx: "5px",
  },
  skillTagTextStyle: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "13px",
    lineHeight: "18px",
    color: theme?.palette?.info?.contrastText,
    mx: "6px",
  },
  skillTagCloseIconStyle: {
    width: 16,
    height: 16,
    display: "flex",
    opacity: 0.7,
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  filterModalContainer: {
    zIndex: 2,
    position: "absolute",
    top: "36px",
  },
  list_expand_icon: {
    open: {
      transform: "rotate(-180deg)",
    },
  },
  expandIconStyle: (open: boolean) => ({
    display: "flex",
    cursor: "pointer",
    color: theme.palette.primary[500],
    transition: "ease 0.2s",
    ...(!open && styles.list_expand_icon.open),
  }),
  viewMoreBtnStyle: (showViewMore: boolean) => ({
    ...theme?.typography?.Components?.button?.default,
    ...theme?.typography?.Components?.button?.large,
    color: theme?.palette?.primary[500],
    padding: "0 5px",
    textTransform: "none",
    visibility: showViewMore ? "visible" : "hidden",
  }),
};

const SKILL_FILTER_CONTAINER_HEIGHT = 58;

const AddModule = () => {
  const params = useParams();
  const { pathwayData, setPathwayData }: IContextData = useAppContext();
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());

  const [modules, setModules] = useState<IModuleItem[]>([]);
  const [selectedModuleId, setSelectedModuleId] = useState<string>("");
  const [sortModule, setSortModule] = useState({
    dateCreated: { checked: true, asec: true },
    moduleName: { checked: false, asec: true },
  });
  const [skills, setSkills] = useState<IPathwayModuleSkillData[]>([]);
  const [selectedSkills, setSelectedSkills] = useState<
    IPathwayModuleSkillData[]
  >([]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [isViewMore, setIsViewMore] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const [visibleSkillFilterLength, setVisibleSkillFilterLength] = useState(-1);
  const skillFilterContainerRef = useRef<HTMLDivElement>(null);
  const [loader, setLoader] = useState(true);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [unfilteredModules, setUnfilteredModules] = useState<IModuleItem[]>([]);
  const [searchValue, setSearchValue] = useState("");

  const breadCrumbPath = [
    { name: "Drafts", url: "/pathway" },
    { name: "Pathway Creator", url: `/pathwayCreator/build/${params.id}` },
    { name: "Add Modules", url: "" },
  ];

  useEffect(() => {
    if (!pathwayData.pathway?.data?.title) {
      navigate(`/pathwayCreator/build/${params.id}`)
    }
  }, []);

  const getPublishedModules = useQuery(
    "modules",
    async () => {
      const response = await axiosInstance().get<
        IPublishedModuleCardDataProps[]
      >("/contentcreator/getPublishedModule", {
        headers: {
          Authorization: headers.Authorization,
          "Content-Type": "application/json",
        },
        data: {},
      });
      return response.data;
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  );

  const milestoneIndex = useMemo(() => Number(params.mIndex) ?? -1, [params]);

  const milestoneModules = useMemo(() => {
    return (pathwayData.milestones.at(milestoneIndex) as IKnowledgeMilestoneData)?.modules || [];
  }, [pathwayData.milestones, milestoneIndex]);

  const unselectedSkills = useMemo(
    () => skills.filter((item) => !item.isChecked),
    [skills]
  );

  const showList = useMemo(
    () => !getPublishedModules.isLoading && !!getPublishedModules?.data?.length,
    [getPublishedModules]
  );

  useEffect(() => {
    const list = (!milestoneModules.length
      ? getPublishedModules.data
      : getPublishedModules.data?.filter(
        (item) => milestoneModules.every((x) => (x?.id || x.sdoId) !== item.id)
      )) ?? [];
    setUnfilteredModules(list);
  }, [getPublishedModules.data, milestoneModules]);

  const skillFilters = useMemo(
    () =>
      visibleSkillFilterLength === -1 || isViewMore
        ? selectedSkills
        : selectedSkills.slice(0, visibleSkillFilterLength),
    [selectedSkills, visibleSkillFilterLength, isViewMore]
  );
  const filteredModules = useMemo(() => {
    let listData = [...unfilteredModules];

    if (listData?.length && selectedSkills.length) {
      listData = listData.filter((item) => {
        let isValid = false;

        selectedSkills.forEach((x) => {
          const skill = item.skills.find((y) => y.skillId === x.skillId);

          skill && (isValid = true);
        });

        return isValid;
      });
    }

    return listData;
  }, [selectedSkills, unfilteredModules]);

  useEffect(() => {
    if (showList && !searchValue) {
      setModules([...filteredModules]);
      setLoader(false);
    }
  }, [
    showList,
    filteredModules,
    searchValue
  ]);

  useEffect(() => {
    let list: IPathwayModuleSkillData[] = [];
    if (unfilteredModules.length) {
      unfilteredModules.forEach((item) => {
        const filterList = item.skills.filter(
          (x) => !list.find((y) => y.skillId === x.skillId)
        );
        list = [...list, ...filterList];
      });
    }
    setSkills(list);
  }, [unfilteredModules]);

  useLayoutEffect(() => {
    const containerHeight = skillFilterContainerRef.current?.scrollHeight || 0;
    const selectedSkillsLength = selectedSkills.length - 1;

    if (
      containerHeight > SKILL_FILTER_CONTAINER_HEIGHT ||
      (visibleSkillFilterLength !== -1 &&
        selectedSkillsLength >= visibleSkillFilterLength)
    ) {
      visibleSkillFilterLength !== selectedSkillsLength &&
        setVisibleSkillFilterLength((prv) =>
          prv < 0 ? selectedSkillsLength : prv
        );
      setShowViewMore(true);
    } else {
      visibleSkillFilterLength >= 0 &&
        setVisibleSkillFilterLength((prv) => (prv >= 0 ? -1 : prv));
      setShowViewMore(false);
      setIsViewMore(false);
    }
  }, [
    skillFilterContainerRef.current?.clientHeight,
    visibleSkillFilterLength,
    selectedSkills,
  ]);

  const handleItemOnChange = useCallback((item: IModuleItem) => {
    setUnfilteredModules((prv) =>
      prv.map((x) => (x.id === item.id ? { ...item } : { ...x })))

    setModules((prv) =>
      prv.map((x) => (x.id === item.id ? { ...item } : { ...x }))
    );
  }, []);

  const handleOnNextClick = () => {
    const checkedModules = [...modules.filter((item) => item.isChecked)];
    if (checkedModules.length) {
      const cloneListData: IModuleItem[] = JSON.parse(
        JSON.stringify(milestoneModules)
      );
      checkedModules.forEach((item) => {
        const moduleItem = milestoneModules.find((x) => x.id === item.id);
        if (!moduleItem) {
          const data = { ...item };
          delete data.isChecked;
          cloneListData.push(data);
        }
      });
      if (milestoneModules.length !== cloneListData.length) {
        const updatedData: IPathwayData = JSON.parse(
          JSON.stringify(pathwayData)
        );
        (updatedData.milestones[milestoneIndex] as IKnowledgeMilestoneData).modules = cloneListData;
        if (updatedData?.milestones[milestoneIndex]?.sdoId) {
          updatedData.milestones[milestoneIndex].action = "UPDATE"
        }
        setPathwayData(updatedData);
      }
      navigate(-1);
    } else {
      setShowErrorToast(true);
    }
  }

  const handleCloseFilterModal = useCallback(() => {
    setOpenFilterModal(false);
  }, []);

  const onClickFilterModal = useCallback(() => {
    setOpenFilterModal(!openFilterModal);
  }, [openFilterModal]);

  const onChangeSkill = useCallback(
    (skillId: string) => {
      setSkills((prv) =>
        prv.map((item) =>
          item.skillId === skillId
            ? { ...item, isChecked: !item.isChecked }
            : { ...item }
        )
      );

      let skill = selectedSkills.find((item) => item.skillId === skillId);
      if (skill) {
        setVisibleSkillFilterLength(-1);
        setSelectedSkills(
          selectedSkills.filter((item) => item.skillId !== skillId)
        );
      } else {
        skill = skills.find((item) => item.skillId === skillId);
        skill && setSelectedSkills([...selectedSkills, skill]);
      }
    },
    [selectedSkills, skills]
  );

  const onClickClear = useCallback(() => {
    setSkills((prv) => prv.map((item) => ({ ...item, isChecked: false })));
    setSelectedSkills([]);
    setIsViewMore(false);
  }, []);

  const onClickViewMore = useCallback(() => {
    setIsViewMore((prv) => !prv);
  }, []);

  const filterOptions = (e: any) => {
    const { value } = e.target;
    setSearchValue(value || "")
    const filteredArray = value
      ? filteredModules.filter((item: any) =>
        item?.title.toLowerCase().includes(value?.toLowerCase())
      )
      : filteredModules;
    if (filteredArray) {
      setModules([...filteredArray]);
    }
  };
  const [openSortModal, setOpenSortModal] = useState(false);
  const handleCloseSortModal = () => {
    setOpenSortModal(false);
  };
  return (
    <>
      <Box display={"flex"} sx={styles.mainContainer}>
        <Toast
          message={'Please select at least one module.'}
          type={IToastTypes.Error}
          open={showErrorToast}
          setOpen={setShowErrorToast}
          style={{ width: '295px' }}
        />
        <Box sx={{ position: "absolute", top: "40px", left: "40px" }}>
          <img src={LifeTrekLogo} alt="ICON" />
        </Box>
        <Dialog
          open={true}
          aria-labelledby="module-list"
          sx={styles.modalStyle}
          hideBackdrop
        >
          <DialogTitle>
            <Box display={"flex"} flexDirection={"column"}>
              <BreadCrumbComponent breadCrumbPath={breadCrumbPath} />
              <Box sx={styles.modalTitleContiner}>
                <Typography
                  sx={{
                    ...theme?.typography?.h1,
                    color: theme?.palette?.grayScale?.[700],
                  }}
                >
                  Add Modules
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  <Button
                    variant="text"
                    size="medium"
                    color="primary"
                    sx={styles.exitButtonStyle}
                    onClick={() => navigate(-1)}
                  >
                    <Typography sx={styles.exitButtonTextStyle}>
                      Back
                    </Typography>
                  </Button>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    sx={styles.nextButtonStyle}
                    onClick={handleOnNextClick}
                  >
                    <Typography sx={styles.nextButtonTextStyle}>
                      Next
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box sx={styles.searchContainer}>
                <Box>
                  <Autocomplete
                    open={false}
                    onInputChange={filterOptions}
                    freeSolo
                    id="free-solo-2-demo"
                    sx={{
                      ...styles.searchInputStyle,
                    }}
                    clearIcon={<CancelIcon />}
                    options={[]}
                    inputValue={searchValue}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          "& .MuiInputBase-input::placeholder": {
                            opacity: 0.8
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          type: "text",
                          startAdornment: (
                            <>
                              <InputAdornment position="start">
                                <Search sx={{ color: "#4653F6" }} />
                              </InputAdornment>
                            </>
                          ),
                        }}
                        placeholder="Search Modules"
                      />
                    )}
                  />
                  {modules.length <= 0 && !loader && <NoModuleFound />}
                </Box>
                <Box sx={{ position: "relative", ml: "12px" }}>
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={handleCloseFilterModal}
                  >
                    <Box>
                      <Button
                        variant="outlined"
                        size="medium"
                        sx={styles.filterBtn}
                        onClick={onClickFilterModal}
                      >
                        <img
                          src={FilterSVG}
                          alt="filterIcon"
                          width={18}
                          height={12}
                        />
                        <Typography
                          sx={{
                            ...theme?.typography?.subtitle2,
                            ...styles.filterBtnText,
                          }}
                        >
                          Filter
                        </Typography>
                      </Button>
                      <Box sx={styles.filterModalContainer}>
                        {openFilterModal ? (
                          <FilterModal
                            unselectedSkills={unselectedSkills}
                            selectedSkills={selectedSkills}
                            onChangeSkill={onChangeSkill}
                            onClickClear={onClickClear}
                          />
                        ) : null}
                      </Box>
                    </Box>
                  </ClickAwayListener>
                </Box>
                <Box sx={{ marginLeft: "10px" }}>
                  <ClickAwayListener onClickAway={handleCloseSortModal}>
                    <Box>
                      <Button
                        onClick={() => setOpenSortModal((prev) => !prev)}
                        variant="outlined"
                        size="medium"
                        sx={{
                          ...styles.filterBtn,
                        }}
                      >
                        <img
                          src={SortSVG}
                          alt="sortIcon"
                          width={20}
                          height={18}
                        />
                        <Typography
                          sx={{
                            ...theme?.typography?.subtitle2,
                            ...styles.filterBtnText,
                          }}
                        >
                          Sort
                        </Typography>
                      </Button>
                      {openSortModal && (
                        <SortModulesModal
                          modules={modules}
                          setModules={setModules}
                          sortModule={sortModule}
                          setSortModule={setSortModule}
                        />
                      )}
                    </Box>
                  </ClickAwayListener>
                </Box>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent
            className="customScroll"
            onScroll={handleCloseSortModal}
          >
            <Box>
              <Box sx={styles.skillTagContainer(!!selectedSkills.length)}>
                {!!selectedSkills.length && (
                  <Box sx={styles.skillTagInnerContainer}>
                    <img
                      src={FilterSVG}
                      alt="filterIcon"
                      width={18}
                      height={24}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          ml: "8px",
                          mt: 0,
                          flex: 1,
                          maxHeight: isViewMore ? "auto" : "58px",
                        }}
                        rowGap={"10px"}
                        ref={skillFilterContainerRef}
                      >
                        {Children.toArray(
                          skillFilters.map((skill) => (
                            <Grid sx={styles.skillTagStyle}>
                              <Typography
                                variant="subtitle2"
                                sx={styles.skillTagTextStyle}
                              >
                                {skill.skillName}
                              </Typography>
                              <Box
                                sx={styles.skillTagCloseIconStyle}
                                onClick={() => onChangeSkill(skill.skillId)}
                              >
                                <img src={closeIcon} alt="closeIcon" />
                              </Box>
                            </Grid>
                          ))
                        )}
                      </Grid>
                      <Button
                        variant="text"
                        sx={styles.viewMoreBtnStyle(showViewMore)}
                        onClick={onClickViewMore}
                      >
                        {isViewMore ? "View Less" : "View More"}
                        <ExpandMoreIcon
                          sx={{ ...styles.expandIconStyle(isViewMore) }}
                        />
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
              {showList && (
                <PublishedModulesList
                  selectedItemId={selectedModuleId}
                  data={modules}
                  onSelectItem={(moduleId) =>
                    setSelectedModuleId(
                      selectedModuleId === moduleId ? "" : moduleId
                    )
                  }
                  onChangeItem={handleItemOnChange}
                />
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default AddModule;
