import React, {
  ChangeEvent,
  useEffect,
  useState,
} from "react";
import {
  Grid,
  Typography,
  Box,
  TextField,
  InputAdornment,
  CircularProgress,
  Skeleton,
  Tooltip,
  ClickAwayListener,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "../../../assets/images/searchicon.svg";
import ErrorIcon from "../../../assets/images/erroricon.svg";
import InfoIcon from "../../../assets/images/info_icon.svg";
import { instance as axiosInstance, getDefaultHeaders } from "core/util";
import { useAuth } from "core/contexts";
import { debounce } from "util/debounce";
import { useAppContext } from "components/app-context/appContext";
import { IContextData } from "../DraftsModule/types";
import { godzilla as theme } from "util/themes/godzilla";
import { inputTextStyle } from "../draft.helper";

const styles = {
  tagHeaderStyle: {
    fontFamily: "Museo Sans Rounded",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "29px",
    color: "#4B4D58",
  },
  messageStyle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#4B4D58",
  },
  autocompleteField: {
    width: "303px",
    mb: '4px',
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "0px",
      },
    },
  },
  chipStyle: {
    background: "#4653f6",
    borderRadius: "100px",
    mx: "5px",
    my: 1,
    "& .MuiChip-label": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "150%",
      letterSpacing: "-0.01em",
      color: "#FFFFFF",
    },
    "& .MuiChip-deleteIcon": {
      color: "#E9EAFF",
      borderRadius: "50%",
      "&:hover": {
        color: "#E9EAFF",
      },
    },
  },
  NoTagFoundComponentBox: {
    height: "146px",
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
  },
  tagNotFoundText: {
    fontFamily: "Museo Sans Rounded",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "none",
    letterSpacing: "-0.01em",
    color: "#4B4D58",
    pl: '6.5px'
  },
  tagNotFoundOopsText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22px",
    color: "#4B4D58",
  },
  errorText: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#DF2F2F",
  },
  errorMaxSkillText: {
    fontFamily: "Nunito",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#CF0000",
  },
  optionsComponentBox: {
    background: "#FFFFFF",
    boxShadow:
      "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)",
    borderRadius: "8px",
  },
};

const NoTagFoundComponent = () => {
  return (
    <Box
      sx={styles.NoTagFoundComponentBox}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"flex-start"}
    >
      <Box my={"24px"} mx={"36px"}>
        <Box display={"flex"} flexDirection={"row"}>
          <Typography>
            <img src={SearchIcon} alt='Search icon' />
          </Typography>
          <Typography sx={styles.tagNotFoundText}>Tag Not Found</Typography>
        </Box>
        <Box mt={"8px"}>
          <Typography sx={styles.tagNotFoundOopsText}>
            Oops! That tag does not exist. Please try searching for an
            alternative tag.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

interface ITagModuleProps {
  savedSkills: Array<any>;
  skillData: Array<any>,
  setSkillData: React.Dispatch<React.SetStateAction<Array<any>>>,
  skillError: boolean,
  setSkillError: React.Dispatch<React.SetStateAction<boolean>>,
  isLoading: boolean;
  selectedCourseId?: string;
  moduleId?: string;
  isMessageStyle?:boolean;
}

const TagComponent = (props: ITagModuleProps) => {
  const { savedSkills = [], skillData: value, setSkillData: setValue, skillError, setSkillError, isLoading, selectedCourseId, moduleId ,isMessageStyle} = props;
  const OptionsPopoverComponent = React.useCallback((props: any) => {
    return (
      <Popper {...props} sx={styles.optionsComponentBox} placement="bottom" />
    );
  }, []);

  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());
  const [skills, setSkills] = useState([]);
  const [noDataFlag, setNoDataFlag] = useState(false);
  const [maxSkillsErrorFlag, setMaxSkillsErrorFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uniqueTagError, setUniqueTagError] = useState(false);
  const [showTaggingTooltip, setShowTaggingTooltip] = useState(false);

  const { setReviewModuleData, reviewModuleData }: IContextData = useAppContext();

  useEffect(() => {
    reviewModuleData.forEach((course) => {
      if (selectedCourseId === course.courseId) {
        let mCourse = course.modules.map((module) => {
          if (module.sdoId === moduleId) {
            if (module?.savedSkills?.length) {
              let skills = module?.savedSkills?.map((skill) => {
                skill['name'] = skill?.skillName || skill.name;
                return skill
              })
              setValue(skills)
              if (module?.savedSkills?.length > 3) {
                setMaxSkillsErrorFlag(true)
              } else {
                setMaxSkillsErrorFlag(false)
              }
            } else {
              if (!isLoading && savedSkills?.length) {
                setValue(savedSkills.map((skill) => {
                  skill['name'] = skill.skillName;
                  return skill
                }));
                if (savedSkills?.length > 3) {
                  setMaxSkillsErrorFlag(true)
                } else {
                  setMaxSkillsErrorFlag(false)
                }
              } else {
                setValue([]);
                if (!!module.isTagRequired) {
                  setSkillError(true);
                } else {
                  setSkillError(false);
                }
              }
            }
          }
          return module
        })
        course.modules = mCourse
      }
      return course
    })
  }, [isLoading])

  const handleInputChange = debounce((event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (value.length <= 2) {
      setMaxSkillsErrorFlag(false);
      if (inputValue.length > 2) {
        setLoading(true);
        handleSearch(inputValue);
      } else {
        setSkills([]);
        setNoDataFlag(false);
      }
    } else {
      setMaxSkillsErrorFlag(true);
    }
  }, 500);

  const handleSearch = async (inputValue: string) => {
    await axiosInstance()
      .get(
        `/contentcreator/pathway/skills?searchSkill=${inputValue}&searchType=ST1&limit=20`,
        {
          headers: {
            Authorization: headers.Authorization,
            "Content-Type": "application/json",
          },
          data: {},
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.length) {
          setSkills(response.data);
          setNoDataFlag(false);
        } else if(response.data.length === 0){
          setSkills([]);
          setNoDataFlag(true);
        } else {
          setNoDataFlag(true);
        }
      })
      .catch((error) => {
        setSkills([]);
        setLoading(false);
      });
  };
  const onDelete = (id: string) => () => {
    setSkillError(false);
    const filteredValue = value.filter((x: any) => {
      if(x.id && x.id === id) return false;
      else if(x.skillId && x.skillId === id) return false;
      return true
    });
    if (filteredValue.length < 4) {
      setMaxSkillsErrorFlag(false)
    }
    onChangeSetReviewData(filteredValue);
  };

  const onChangeSetReviewData = (newValue: any) => {
    let mNewValue: any[] = [];
    const map = new Map(newValue.map((item: any) => [item["skillId"] || item["id"], item]));
    const valuesIterator = map.values();
    for (let i = 0; i < map.size; i++) {
      mNewValue.push(valuesIterator.next().value);
    }

    if (newValue.length !== mNewValue.length) {
      setUniqueTagError(true)
    } else {
      setUniqueTagError(false)
    }

    let mCourse = reviewModuleData.map((course) => {
      if (selectedCourseId === course.courseId) {
        let mCourse = course.modules.map((module) => {
          if (module.sdoId === moduleId) {
            module.savedSkills = mNewValue;
            if (module.savedSkills?.length) {
              module.isTagRequired = false;
            }
          }
          return module
        })
        course.modules = mCourse
      }
      return course
    })
    setReviewModuleData(mCourse);
    setValue(mNewValue);
  };

  const handleTaggingTooltip = () => {
    setShowTaggingTooltip(!showTaggingTooltip);
  };

  return (
    <Grid mt={"42px"} ml={"41px"} display={"flex"} flexDirection={"column"}>
      <Grid display={"flex"} flexDirection={"row"}>
        <Typography sx={styles.tagHeaderStyle}>Tagging</Typography>
        <Typography color={"#FF6363"}>*</Typography>
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => setShowTaggingTooltip(false)}
        >
          <Box ml={"2px"} mt={"7px"}>
            <Tooltip
              title="You must select and assign at least one tag, but no more than 3, per module. Assigned tags apply to all pages in this module."
              placement="top"
              arrow
              onClose={() => setShowTaggingTooltip(false)}
              open={showTaggingTooltip}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              PopperProps={{
                disablePortal: true,
              }}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: theme?.palette?.grayScale[800],
                    borderRadius: "4px",
                    ...theme?.typography?.body?.default,
                    ...theme?.typography?.body?.body2,
                    ...theme?.typography?.body?.light,
                    color: "#FFFFFF",
                  },
                },
              }}
            >
              <img
                src={InfoIcon}
                alt="ICON"
                style={{ cursor: "pointer" }}
                onClick={handleTaggingTooltip}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === 'Space') {
                    handleTaggingTooltip();
                  }
                }}
              />
            </Tooltip>
          </Box>
        </ClickAwayListener>
      </Grid>
      <Grid mt={"16px"}>
        <Autocomplete
          disableClearable
          options={skills}
          filterOptions={(x) => x}
          getOptionLabel={(option: any) => option.name}
          multiple
          ListboxProps={{ style: { maxHeight: 300, overflow: "auto" } }}
          PopperComponent={OptionsPopoverComponent}
          forcePopupIcon={false}
          sx={styles.autocompleteField}
          getOptionDisabled={() => (value.length > 2 ? true : false)}
          noOptionsText={noDataFlag ? <NoTagFoundComponent /> : ""}
          renderTags={() => null}
          value={value}
          onChange={(e: any, newValue: any) => {
            if (e?.target.value !== "") {
              onChangeSetReviewData(newValue);
              setSkills([]);
              setSkillError(false);
            }
          }}
          onFocus={() => {
            setNoDataFlag(false);
          }}
          onBlur={() => {
            setSkills([]);
            setUniqueTagError(false);
            if (value.length < 4) {
              setMaxSkillsErrorFlag(false);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search Tags"
              onChange={handleInputChange}
              sx={{
                ...inputTextStyle(
                  `1px solid ${theme?.palette?.grayScale?.[200]}`
                ),
                ...(maxSkillsErrorFlag && inputTextStyle("2px solid #FF4F4F")),
              }}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <>
                    <InputAdornment
                      position="start"
                      sx={{ ml: "7px", mr: "10px" }}
                    >
                      <img src={SearchIcon} alt="ICON" />
                    </InputAdornment>
                    {params.InputProps.startAdornment}
                  </>
                ),
                endAdornment: (
                  <>
                    <InputAdornment position="end">
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                    </InputAdornment>
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
        {skillError && value.length === 0 && !isLoading ? (
          <Typography sx={styles.errorText} display={"flex"}>
            <img
              src={ErrorIcon}
              style={{ marginRight: "8px", marginTop: "1px" }}
              alt="ICON"
            />
            You must select and assign at least one tag per module.
          </Typography>
        ) : (
          ""
        )}
        {maxSkillsErrorFlag ? (
          <Typography sx={styles.errorMaxSkillText} display={"flex"}>
            <img
              src={ErrorIcon}
              style={{
                marginRight: "8px",
                marginTop: "3px",
                height: "10px",
                width: "10px",
              }}
              alt="ICON"
            />
            You can only have three tags. Remove a tag to add a new one.
          </Typography>
        ) : (
          ""
        )}
        {uniqueTagError ? (
          <Typography sx={styles.errorMaxSkillText} display={"flex"}>
            <img
              src={ErrorIcon}
              style={{
                marginRight: "8px",
                marginTop: "3px",
                height: "10px",
                width: "10px",
              }}
              alt="ICON"
            />
            You’ve already added this skill
          </Typography>
        ) : (
          ""
        )}
        {isLoading ? (
          <Box mt={"15px"} display={"flex"} flexDirection={"row"}>
            <Skeleton
              width={"100px"}
              height={"50px"}
              sx={{ borderRadius: "20px" }}
            />
            <Skeleton
              width={"100px"}
              height={"50px"}
              sx={{ borderRadius: "20px", ml: 1 }}
            />
            <Skeleton
              width={"100px"}
              height={"50px"}
              sx={{ borderRadius: "20px", ml: 1 }}
            />
          </Box>
        ) : (
          <Box
            mt={"15px"}
            sx={{
              "& > :not(:last-child)": { mr: 1 },
              "& > *": { mr: 1 },
            }}
          >
            {value.map((v: any) => (
              <Chip
                sx={styles.chipStyle}
                deleteIcon={<CancelIcon />}
                key={v.id}
                label={v.name}
                onDelete={onDelete(v.id || v.skillId)}
              />
            ))}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default TagComponent;
