import { Box, Grid, Typography } from "@mui/material";
import { IPublishedModuleCardDataProps } from "pages/drafts/DraftsModule/types";
import { Children } from "react";

const styles = {
    itemContainer: {
        border: '1px solid #C6CAD1',
        borderRadius: '8px',
        background: '#FFFFFF',
        padding: '30px 30px 29px 27px',
        mt: '16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row'
    },
    titleStyle: {
        fontFamily: 'Museo Sans Rounded',
        fontStyle: 'normal',
        fontWeight: 400,
        color: '#3D4655'
    },
    subtitleStyle: {
        fontFamily: 'Nunito Sans',
        fontStyle: 'normal',
        textTransform: 'uppercase',
        letterSpacing: '0.1em',
        color: '#3D4655',
        mt: '6px'
    },
    skillTagContainer: {
        justifyContent: 'end',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        rowGap: '10px',
        flex: 1
    },
    skillTagStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #7C45DD',
        borderRadius: '100px',
        px: '10px',
        py: '7px',
        mx: '4px',
        maxWidth: '100%'
    },
    skillTagTextStyle: {
        fontFamily: 'Nunito',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '13px',
        lineHeight: '18px',
        color: '#7C45DD',
        textAlign: 'center',
        display: "-webkit-box", 
        overflow: 'hidden', 
        whiteSpace: 'initial', 
        WebkitBoxOrient: 'vertical', 
        WebkitLineClamp: 2, 
        textOverflow: 'ellipsis', 
        maxHeight: '36px'
    }
}

interface IModuleItemProps {
    item: IPublishedModuleCardDataProps
}

const ModuleItem = (props: IModuleItemProps) => {
    const { item } = props;

    return (
        <Grid sx={styles.itemContainer}>
            <Grid display={'flex'} flexDirection={'column'} width="60%" pr='20px'>
                <Typography variant="h3" sx={styles.titleStyle}>{item.title}</Typography>
                {item.courseTitle && <Typography variant="subtitle2" sx={styles.subtitleStyle}>{item.courseTitle}</Typography>}
            </Grid>
            <Grid sx={styles.skillTagContainer}>
                {item?.skills?.length && Children.toArray(item?.skills?.slice(0, 3).map((skill) => (
                    <Box sx={styles.skillTagStyle}>
                        <Typography                            
                            variant="subtitle2"
                            sx={{...styles.skillTagTextStyle}}>
                            {skill.skillName}
                        </Typography>
                    </Box>
                )))}
            </Grid>
        </Grid>
    );
}

export default ModuleItem;
