import ImportFileIcon from 'assets/images/importFiles.svg';
import ContentDrafts from 'assets/images/contentDrafts.svg';
import ContentLibrary from 'assets/images/contentLibrary.svg';

interface IHomePageIcons {
    buttonText: string;
    icon: any;
    routePath: string;
}

const homePageConfig: IHomePageIcons[] = [
    {
        buttonText: 'Import Files',
        icon: ImportFileIcon,
        routePath: '/drafts/import-checklist'
    },
    {
        buttonText: 'Content Drafts',
        icon: ContentDrafts,
        routePath: '/drafts'
    },
    {
        buttonText: 'Content Library',
        icon: ContentLibrary,
        routePath: '/library'
    }

];

export {
    homePageConfig,
    type IHomePageIcons
}