import Popover, {
  PopoverProps as MuiPopoverProps,
} from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { exitModalStyles } from "../moduleLayout.helper";
import { Box } from "@mui/material";
import { Button } from "core/components";

type IExitModal = {
  exitModalId: string | undefined;
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  handleExit: () => void;
  anchorOrigin: MuiPopoverProps["anchorOrigin"];
  transformOrigin: MuiPopoverProps["transformOrigin"];
  contentText: string;
  confirmBtnText: string;
  cancelBtntext: string;
};

const ExitModal = (props: IExitModal) => {
  const {
    exitModalId,
    open,
    anchorEl,
    handleClose,
    handleExit,
    anchorOrigin,
    transformOrigin,
    contentText,
    confirmBtnText,
    cancelBtntext,
  } = props;
  return (
    <Popover
      id={exitModalId}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      elevation={6}
      sx={exitModalStyles.container}
    >
      <Box>
        <Typography sx={exitModalStyles.contentText}>{contentText}</Typography>
      </Box>
      <Box sx={exitModalStyles?.actionButtonsContainer}>
        <Button
          sx={exitModalStyles?.exitBtn}
          variant="contained"
          color={"error"}
          onClick={handleExit}
        >
          <Typography sx={exitModalStyles?.exitBtnText}>
            {confirmBtnText}
          </Typography>
        </Button>
        <Button variant="text" color="primary" onClick={handleClose}>
          <Typography sx={exitModalStyles?.cancelBtnText}>
            {cancelBtntext}
          </Typography>
        </Button>
      </Box>
    </Popover>
  );
};

export default ExitModal;
