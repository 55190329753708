import { Children, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Grid, Typography, } from '@mui/material';
import { godzilla as theme } from 'util/themes/godzilla';
import { getDefaultHeaders, instance as axiosInstance } from 'core/util';
import { useAuth } from 'core/contexts';
import { IModulePageDataProps } from '../types';

const styles = {
  pageTitleContainer: (i: number, hasSelection: boolean, hasPageSelection: boolean) => ({
    pl: '14px',
    height: 'auto',
    width: `calc(100% - ${hasSelection ? '42px' : '27px'})`,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: hasPageSelection ? i % 2 === 0 ? '#F7E1FF' : '#E3E9FF' : '',
    '& .pageTitleText': {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: hasPageSelection ? 600 : 400,
      fontSize: '16px',
      lineHeight: '22px',
      color: '#2D3648',
    },
    '&:hover': {
      backgroundColor: i % 2 === 0 ? '#F7E1FF' : '#E3E9FF',
      '& .pageTitleText': {
        fontWeight: 600
      }
    },
  }),
}

interface IModuleItem {
  hasSelection: boolean,
  onClickOpenPageLayout: (moduleId: string, pageData: IModulePageDataProps) => void,
  moduleId: string,
  pageModuleId: string
}

export const ModuleItem = ({ hasSelection, onClickOpenPageLayout, moduleId, pageModuleId }: IModuleItem) => {
  const [data, setData] = useState<any[]>([]);
  const [selectedPageIndex, setSelectedPageIndex] = useState<number>(0);
  const [isLoading, setLoading] = useState(false);
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());

  useEffect(() => {
    (async function () {
      try{
        setLoading(true);
        const response = await axiosInstance().get(`/contentcreator/module/${moduleId}/moduleItem`, {
          headers: {
            "Authorization": headers.Authorization,
            "Content-Type": "application/json"
          },
          data: {}
        });
        setLoading(false);
        setData(response.data);
      } catch(e) {
        setLoading(false);
        throw new Error('There is some error while calling module item');
      }
    })();
  }, []);
  
  if (isLoading) {
    return <Grid display={'flex'} justifyContent={'center'}><Typography variant='h3'>Loading...</Typography></Grid>
  }

  return (
    <>
      {Children.toArray(data.map((item: any, i: number) => {
        return (
          <Grid sx={{ pt: '10px' }} display={'flex'} flexDirection='row' justifyContent={'flex-start'} alignItems={'flex-start'}>
            <Grid width={7} height={24} sx={{
              ...(i % 2 === 0 ? theme.card.pagenameBox1 : theme.card.pagenameBox2)
            }} />
            <Grid sx={styles.pageTitleContainer(i, hasSelection, moduleId === pageModuleId && selectedPageIndex === i)}
              onClick={(e) => {
                e.stopPropagation();
                onClickOpenPageLayout(moduleId, item.page);
                setSelectedPageIndex(i);
              }}
            >
              <Typography className='pageTitleText'>
                {item.page.title}
              </Typography>
            </Grid>
          </Grid>
        )
      }))}
    </>
  )
}