import { Typography, Box, Button } from '@mui/material';
import { godzilla as theme } from 'util/themes/godzilla';
import { Button as ControlBtn } from 'core/components';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { getDefaultHeaders, instance as axiosInstance } from 'core/util';
import { IModuleCardDataProps } from '../DraftsModule/types';
import { useAuth } from 'core/contexts';
import { useAppContext } from 'components/app-context/appContext';

const styles = {
  cancelUploadBox: {
    background: '#FFFFFF',
    boxShadow: '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px rgb(0 0 0 / 14%), 0px 1px 18px rgb(0 0 0 / 12%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    px: 3,
    py: 2,
    width: '21vw',
    cursor: 'initial'
  },
  cancelText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
    mb: 2,
    color: theme?.palette?.grayScale[500],
  },
  cancelYesButton: {
    background: theme?.palette?.error?.main,
    borderRadius: '100px',
    padding: '6px 20px',
    gap: '8px',
    mb: '6px',
  },
  removeBtnText: {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '-0.01em',
    textTransform: 'none',
    color: theme?.palette?.error?.contrast
  },
  cancelNoButton: {
    borderRadius: '100px',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    color: theme?.palette?.primary[500],
    textTransform: 'none',
    px: 2,
    py: '4px',
    '&:hover': { background: 'rgba(0, 61, 166, 0.04)' },
    '> .MuiTouchRipple-root span': {
      background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(122, 132, 255, 0.7) 99.54%, rgba(255, 255, 255, 0) 100%)'
    },
  },
}

interface IHandleCloseDeleteModalProps {
  handleCloseDeleteModal: () => void,
  moduleData: IModuleCardDataProps[],
  removeModuleFromList: (course: IModuleCardDataProps) => void,
  isMultipleDelete: boolean,
  onSelectCard?: (data: IModuleCardDataProps) => void,
  setIsDeleting?: (flag: boolean) => void
}

export default function DeleteModal({ handleCloseDeleteModal, moduleData, removeModuleFromList, isMultipleDelete, setIsDeleting, onSelectCard }: Readonly<IHandleCloseDeleteModalProps>) {
  const { getToken } = useAuth();
  const { reviewModuleData } = useAppContext();
  const headers = getDefaultHeaders(getToken());
  const navigate = useNavigate();

  const deleteModule = useMutation((course: IModuleCardDataProps) => {
    handleCloseDeleteModal();
    return axiosInstance().delete(`/contentcreator/course/${course.sdoId}/module/${course.modules[0].sdoId}`, {
      headers: {
        'Authorization': headers.Authorization,
        "Content-Type": "application/json"
      },
      data: {}
    })
  }, {
    onSuccess: (_, course) => {
      removeModuleFromList(course);
      !isMultipleDelete && setIsDeleting && setIsDeleting(false);
      onSelectCard?.(reviewModuleData[0]);
      if (!isMultipleDelete && reviewModuleData.length === 1) {
        if(reviewModuleData[0]?.modules?.length === 0){
          navigate('/drafts');
        }
      }
    },
    onError: (_, course) => {
      removeModuleFromList(course);
      !isMultipleDelete && setIsDeleting && setIsDeleting(false);
      throw new Error('There is some issue in deleting module');
    },
  });

  const removeFiles = () => {
    setIsDeleting && setIsDeleting(true);
    if (isMultipleDelete) {
      moduleData.forEach(course => {
        course.modules.forEach(module => {
          deleteModule.mutate({...course, modules: [module]});
        })
      });
    } else {
      deleteModule.mutate(moduleData[0]);
    }
  }

  const reviewModalLength = reviewModuleData.length;

  const modalContextConfig = {
    title: (!isMultipleDelete && reviewModalLength === 1) ?
      'Deleting this module will send you back to the drafts page. Are you sure you want to continue?' :
      'Are you sure you want to delete the selected modules?',
    yesBtnText: 'Yes, delete.',
    noBtnText: (!isMultipleDelete && reviewModalLength === 1) ? 'No, continue editing' : `No, don't delete.`
  }

  return (
    <Box sx={styles.cancelUploadBox} onClick={(e) => e.stopPropagation()}>
      <Typography sx={styles.cancelText}>{modalContextConfig.title}</Typography>
      <ControlBtn
        variant='contained'
        color='error'
        size='medium'
        sx={styles.cancelYesButton}
        onClick={removeFiles}
      >
        <Typography sx={{
          ...theme?.typography?.Components?.button?.default,
          ...theme?.typography?.Components?.button?.medium,
          ...styles.removeBtnText
        }}>
          {modalContextConfig.yesBtnText}
        </Typography>
      </ControlBtn>
      <Button sx={styles.cancelNoButton}
        onClick={handleCloseDeleteModal}
      >
        {modalContextConfig.noBtnText}
      </Button>
    </Box>
  )
}