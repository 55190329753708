
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { IAgreedTerms, IAppTerm } from 'pages/terms-and-conditions/termsAndConditionsHelper';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'core/contexts';
import { getUserDataFromLocalStorage } from 'core/util/getUserData';
import { instance as axiosInstance, getDefaultHeaders } from 'core/util';

interface IAppInitializerProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const AppInitializer = (props: IAppInitializerProps) => {
    const { setLoading } = props;
    const navigate = useNavigate();
    const { isInitialized, getToken, setLoginTermAccepted } = useAuth();
    const headers = getDefaultHeaders(getToken());

    const [isFetching, setIsFetching] = useState({
        terms: true,
        acceptedTerms: true
    });
    const [loginTerms, setLoginTerms] = useState<IAppTerm>()
    const [agreedTerms, setAgreedTerms] = useState<IAgreedTerms[]>()

    const handleLoginTermsAccepted = (isAccepted: boolean) => {
        if (!isAccepted) {
            if (loginTerms) {
                navigate('/terms-and-conditions', {
                  state: {
                    from: {
                      pathname: '/login',
                    },
                    terms: loginTerms,
                  },
                });
            }
        }
        setLoginTermAccepted(isAccepted);
        setLoading(false);
    }

    const fetchTerms = () => {
        axiosInstance().get('/contentcreator/terms', {
          headers: {
            Authorization: headers.Authorization,
            'Content-Type': 'application/json',
          },
        }).then(res => {
          res.data?.data?.forEach((item: IAppTerm) => {
              setIsFetching(prv => ({ ...prv, terms: false }))
              if (item.active && item.type.includes('CONTENT_CREATOR_LOGIN')) {
                  setLoginTerms(item);
              }
          })
      }).catch(err => {
          console.log(err)
          setIsFetching(prv => ({ ...prv, terms: false }))
      });
    }

    const fetchAgreedTerms = () => {
      axiosInstance().get('/contentcreator/terms/accepted-terms', {
        headers: {
          Authorization: headers.Authorization,
          'Content-Type': 'application/json',
        },
      }).then(res => {
            setIsFetching(prv => ({ ...prv, acceptedTerms: false }))
            const termsAgreed: IAgreedTerms[] = res.data?.data?.termsAgreed;
            setAgreedTerms(termsAgreed ?? []);
        }).catch(err => {
            console.log(err)
            setIsFetching(prv => ({ ...prv, acceptedTerms: false }))
        });
    }

    useEffect(() => {
        if (isInitialized && !!getToken()) {
            const userData = getUserDataFromLocalStorage();
            
            let isFetch = false;
            if (userData?.isLoginTermAccepted) {
                setLoginTermAccepted(true)
            } else {
                isFetch = true;
            }

            if(isFetch) {
                fetchTerms();
                fetchAgreedTerms();
            } else {
                setLoading(false);
            }        
        }
    }, [isInitialized])

    useEffect(() => {
        if (!isFetching.terms && !isFetching.acceptedTerms && agreedTerms) {
            if (agreedTerms.length && loginTerms) {

                const isTermAccepted = !!agreedTerms.find(item => item.termsId === loginTerms.termsId);
                handleLoginTermsAccepted(isTermAccepted)
            } else {
                handleLoginTermsAccepted(false)
            }
        }
    }, [loginTerms, agreedTerms, isFetching])

    return <Box sx={{ height: '100vh', width: '100vw', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <CircularProgress sx={{ color: '#003DA6' }} />
    </Box>
}

export default AppInitializer;