import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography, Grid } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { godzilla as theme } from 'util/themes/godzilla';
import LifeTrekLogo from 'assets/images/sidenav_logo.svg';
import BreadCrumbComponent from 'pages/pathway/components/BreadCrumb';
import CrossIcon from "assets/images/crossicon.svg";
import { Button } from "core/components";
import DraftsImageSVG from 'assets/images/draftsimg.svg';
import { useEffect } from 'react';
import { IContextData } from '../PathwayOutline/outline.helper';
import { useAppContext } from "components/app-context/appContext";

const styles = {
    mainContainer: {
        display: 'flex',
        height: '100vh',
        backgroundColor: '#F9FAFC'
    },
    modalStyle: {
        '& .MuiPaper-root': {
            height: '100vh',
            width: '900px',
            maxWidth: 'inherit',
            backgroundColor: '#FFFFFF',
            boxShadow: 'none',
            borderRadius: '0px',
            margin: '0px',
            maxHeight: '100vh',
            pl: '90px',
            pt: '40px'
        },
        '& .MuiDialogTitle-root': {
            padding: 0,
            pr: '90px'
        },
        '& .MuiDialogContent-root': {
            mt: '20px',
            p: 0,
            pr: '80px'
        }
    },
    modalTitleContiner: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: '5px'
    },
    refreshButtonStyle: {
        width: '250px',
        py: '12.5px',
        px: '58.5px',
        '&:hover': { background: 'rgba(0, 0, 0, 0.04)' },
        '> .MuiTouchRipple-root span': {
            background: 'radial-gradient(36.59% 100.8% at 50% 50%, rgba(0, 0, 0, 0.3) 99.54%, rgba(255, 255, 255, 0) 100%)'
        },
    },
    boxImportFiles: {
        borderRadius: '5px',
        pt: '47px',
        pb: '51px',
        mt: 2,
        position: 'relative',
    },
    image: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        mb: 3,
    },
    importText: {
        fontFamily: "Museo Sans Rounded",
        fontStyle: "normal",
        fontWeight: "400",
        textAlign: "center",
        color: "#676F7C",
        mb: '16px'
    },
    pagePreviewErrorButtonBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    goBackButtonStyle: {
        py: '12.5px',
        px: '58.5px',
        width: '250px',
        mb: '12px'
    },
    createPathwayBtnTextStyle: {
        fontFamily: 'Nunito',
        textTransform: 'none',
        color: theme?.palette?.secondary[50]
    },
    pageContent: {
        container: {
            width: '100%',
            px: 5.25,
            py: 4.75,
            overflow: 'auto'
        },
        pageContainer: {
            overflow: 'auto',
            backgroundColor: '#F9FAFC',
            borderRadius: '20px',
            width: 'auto'
        },
    },
};

const PagePreview = () => {
    const params = useParams()
    const navigate = useNavigate();
    const location = useLocation();
    const { pathwayData }: IContextData = useAppContext();

    useEffect(() => {
        if (location.state === null) {
            navigate(-1)
        }
    }, [])

    const breadCrumbPath = [
        { name: 'Drafts', url: '/pathway' },
        { name: pathwayData.pathway?.data?.title, url: `/pathwayCreator/build/${params.id}` },
        { name: params.mName, url: `/pathwayCreator/build/${params.id}/${params.mName}/${params.mIndex}/module` },
        { name: location?.state?.pageTitle, url: '' }
    ];

    return (
        <>
            <Box display={'flex'} sx={styles.mainContainer}>
                <Box sx={{ position: 'absolute', top: '40px', left: '40px' }}>
                    <img src={LifeTrekLogo} alt='ICON' />
                </Box>
                <Dialog
                    open={true}
                    aria-labelledby='module-list'
                    sx={styles.modalStyle}
                    hideBackdrop
                >
                    <DialogTitle>
                        <Box display={'flex'} flexDirection={'column'} >
                            <Box position={"absolute"} right={"40px"}>
                                <IconButton
                                    sx={{
                                        "&.MuiIconButton-root": {
                                            height: "44px",
                                            width: "44px",
                                            backgroundColor: "#F9FAFC",
                                            "&:hover": {
                                                backgroundColor: "#F9FAFC",
                                            },
                                        },
                                    }}
                                    onClick={() => navigate(-1)}
                                >
                                    <img src={CrossIcon} alt="CROSSICON" />
                                </IconButton>
                            </Box>
                            <BreadCrumbComponent breadCrumbPath={breadCrumbPath} />

                            <Box sx={styles.modalTitleContiner}>
                                <Typography sx={{ ...theme?.typography?.h1, color: theme?.palette?.grayScale?.[700] }}>Page Preview</Typography>
                            </Box>

                        </Box>
                    </DialogTitle>
                    <DialogContent className='customScroll'>
                        {location.state.richText !== '' ?
                            <Grid height={'100%'} width={'100%'} ml={'10px'} sx={styles.pageContent.pageContainer} className='customScroll'>

                                <Grid sx={styles.pageContent.container} item>
                                    <div
                                        dangerouslySetInnerHTML={{ __html: location.state.richText }}
                                    />
                                </Grid>
                            </Grid>
                            :
                            <Box sx={styles.boxImportFiles}>
                                <Box sx={styles.image}>
                                    <img src={DraftsImageSVG} alt="add_icon" />
                                </Box>
                                <Box>
                                    <Typography sx={{ ...theme?.typography?.h1, ...styles.importText }}>
                                        Page Preview is Unavailable
                                    </Typography>
                                    <Typography sx={{ ...theme?.typography?.h3, ...styles.importText, mb: '30px' }}>
                                        Sorry, this page is currently unavailable.
                                    </Typography>
                                </Box>
                                <Box sx={styles.pagePreviewErrorButtonBox}>
                                    <Button
                                        sx={styles.goBackButtonStyle}
                                        variant="contained"
                                        color='primary'
                                        size="large"
                                        onClick={() => navigate(-1)}
                                    >
                                        <Typography sx={{
                                            ...theme?.typography?.Components?.cardAccordion?.headerText,
                                            ...styles.createPathwayBtnTextStyle
                                        }}>Go Back</Typography>
                                    </Button>
                                    <Button
                                        variant="text"
                                        size="medium"
                                        color="primary"
                                        sx={styles.refreshButtonStyle}
                                    >
                                        <Typography
                                            sx={{
                                                ...theme?.typography?.Components?.button?.default,
                                                ...theme?.typography?.Components?.button?.large,
                                                color: "#202124",
                                                textTransform: "none",
                                            }}
                                        >
                                            Refresh
                                        </Typography>
                                    </Button>
                                </Box>
                            </Box>
                        }
                    </DialogContent>
                </Dialog>
            </Box>
        </>
    )
}

export default PagePreview;