import React, { useContext, useEffect, useReducer, useRef, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { AxiosProgressEvent } from 'axios';
import {
  START_UPLOAD,
  UPLOAD_CANCELLED,
  UPLOAD_ERROR,
  UPLOAD_IN_PROGRESS,
  UPLOAD_COMPLETED,
  UPLOADING_FILE_DATA,
  UPLOADED_FILE_DATA,
  SET_REVIEW_MODULE_DATA,
  RESET_REVIEW_MODULE_DATA,
  SET_PATHWAY_DATA,
  RESET_PATHWAY_DATA,
  SET_PATHWAY_ERROR,
  RESET_PATHWAY_ERROR
} from './actions';
import reducer from './reducer';
import { getDefaultHeaders, instance as axiosInstance } from 'core/util';
import { useAuth } from 'core/contexts';
import Toast, { IToastTypes } from 'components/toast/Toast';

export interface IintialState {
  isUploading: boolean,
  isUploadComplete: boolean,
  uploadProgress: number,
  errorInUpload: boolean,
  uploadingFileData: Array<any>,
  uploadedFileData: Array<any>,
  reviewModuleData: Array<any>,
  pathwayError: IPathwayError,
  pathwayData: any,
}

export interface IPathwayError {
  knowledgeMilestoneError: boolean,
  demonstrationMilestoneError: boolean,
  milestoneListError: number[];
  disableStepper: boolean;
}

const initialPathwayData = {
  pathway: {},
  milestones: [],
  publishedTo: []
}


const initialPathwayError: IPathwayError = {
  knowledgeMilestoneError: false,
  demonstrationMilestoneError: false,
  milestoneListError: [],
  disableStepper: false
}

const initialState: IintialState = {
  isUploading: false,
  isUploadComplete: false,
  uploadProgress: 0,
  errorInUpload: false,
  uploadingFileData: [],
  uploadedFileData: [],
  reviewModuleData: [],
  pathwayError: initialPathwayError,
  pathwayData: initialPathwayData,
};

const AppContext = React.createContext<any>({});

const ToastMessage = {
  success: 'Import successful.',
  error: 'An error has occurred while uploading your files. Please check your files and try again.'
}

const AppProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState<IToastTypes>();
  const [toastMessage, setToastMesage] = useState('');
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const { getToken } = useAuth();
  const headers = getDefaultHeaders(getToken());
  let abortController = useRef<any>(null);
  
  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };
  
    const handleOffline = () => {
      setIsOnline(false);
    };
  
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
  
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    abortController.current = new AbortController();
    return () => (abortController.current.abort());
  }, []);

  useEffect(() => {
    if(!isOnline){
      if(state.isUploading){
        setShowToast(true);
        setToastType(IToastTypes.Error);
        setToastMesage(ToastMessage.error);
        abortController.current.abort();
        abortController.current = new AbortController();
        dispatch({ type: UPLOAD_ERROR });
      }
    }
  }, [isOnline])
  

  const fileUploadMutation = useMutation((formData: FormData) => {
    dispatch({ type: START_UPLOAD });
    return axiosInstance().post('/contentcreator/course/upload', formData, {
      headers: {
        'Authorization': headers.Authorization
      },
      signal: abortController.current.signal,
      onUploadProgress(progressEvent: AxiosProgressEvent) {
        const { loaded, total = 0 } = progressEvent;
        const percent = Math.floor(loaded * 100 / total);
        dispatch({ type: UPLOAD_IN_PROGRESS, payload: percent });
      }
    })
  },
    {
      onSuccess: () => {
        setShowToast(true);
        setToastType(IToastTypes.Success);
        setToastMesage(ToastMessage.success);
        abortController.current = new AbortController();
        dispatch({ type: UPLOAD_COMPLETED });
        dispatch({ type: UPLOADED_FILE_DATA });
      },
      onError: (error) => {
        setShowToast(true);
        setToastType(IToastTypes.Error);
        setToastMesage(ToastMessage.error);
        abortController.current = new AbortController();
        if (error !== 'canceled') {
          dispatch({ type: UPLOAD_ERROR });
        }
      }
    });

  const setFileUploadingData = (data: Array<any>) => {
    dispatch({ type: UPLOADING_FILE_DATA, payload: data })
  }

  const onCancelFileUpload = () => {
    dispatch({ type: UPLOAD_CANCELLED });
  }

  const setReviewModuleData = (data: Array<any>) => {
    dispatch({ type: SET_REVIEW_MODULE_DATA, payload: data })
  }

  const resetReviewModuleData = () => {
    dispatch({ type: RESET_REVIEW_MODULE_DATA, payload: [] })
  }

  const setPathwayData = (data: any) => {
    dispatch({ type: SET_PATHWAY_DATA, payload: data })
  }

  const resetPathwayData = () => {
    dispatch({ type: RESET_PATHWAY_DATA, payload: initialPathwayData })
  }

  const setPathwayError = (data: IPathwayError) => {
    dispatch({ type: SET_PATHWAY_ERROR, payload: data })
  }

  const resetPathwayError = () => {
    dispatch({ type: RESET_PATHWAY_ERROR, payload: initialPathwayError })
  }

  const getContextValue = useMemo(() => {
    return {
      ...state,
      fileUploadMutation,
      abortController,
      onCancelFileUpload,
      setFileUploadingData,
      setReviewModuleData,
      resetReviewModuleData,
      setPathwayData,
      resetPathwayData,
      setPathwayError,
      resetPathwayError,
      setShowToast,
      setToastType,
      setToastMesage,
      showToast
    }
  }, [state])

  
  return (
    <AppContext.Provider value={getContextValue}>
      <Toast message={toastMessage} type={toastType} open={showToast} setOpen={setShowToast} />
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext, initialState }