import { useCallback, Children, useMemo, memo } from "react";
import { Box, Typography } from "@mui/material";
import DragDropImageSVG from '../../../assets/images/dragdropimage.svg';
import { useDropzone } from "react-dropzone";
import { useNavigate } from 'react-router-dom';
import { godzilla as theme } from "util/themes/godzilla";
import { Button } from "core/components";
import FileProgress from './FileProgress';
import { v4 as uuidv4 } from 'uuid';
import { useAppContext } from "components/app-context/appContext";

const styles = {
  importFilesHeader: {
    fontFamily: 'Museo Sans Rounded',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '32px',
    lineHeight: '38px',
    color: theme?.palette?.grayScale[500],
    pt: 17.125
  },
  fileUploadContainer: {
    width: '100%',
    height: '103px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column'
  },
  flexContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100vh'
  },
  upload: {
    default: {
      mt: 2.5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '4px',
      border: `1px dashed ${theme?.palette?.grayScale?.[700]}`,
      width: '60%',
      background: theme?.palette?.grayScale?.[10]
    },
    textBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      ml: 3.25,
    },
    heading: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '32px',

      color: theme?.pallete?.text?.primary,
    },
    subHeading: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '21px',
      letterSpacing: '0.15px',
      color: '#253858',
      mt: 1
    },
    browseText: {
      color: theme?.palette?.primary[500],
      fontWeight: 'bold',
      textDecorationLine: 'underline'
    },
  },
  fileList: {
    container: {
      mt: 5,
      width: '60%',
      border: '1px solid #D1D4DD',
      height: 'auto',
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column'
    },
    headerContainer: {
      backgroundColor: theme?.palette?.primary[500],
      px: 2,
      pt: 1,
      pb: 1.5,
      borderRadius: '4px 4px 0px 0px',
      borderBottom: '1px solid #8E9096'
    },
    header: {
      ...theme?.typography?.body?.default,
      ...theme?.typography?.body?.body1,
      ...theme?.typography?.body?.bold,
      color: '#FFFFFF',
    }
  },
  backToDrafts: {
    button: {
      padding: '12px 20px',
      gap: '8px',
    },
    buttonText: {
      fontFamily: 'Nunito',
      fontSize: '18px',
      fontWeight: 700,
      letterSpacing: '-0.01em',
      color: theme?.palette?.secondary?.[50],
      textTransform: 'none'
    },
    messageText: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      letterSpacing: '0.15px',
      color: theme?.palette?.grayScale[500]
    },
  },
  acceptStyle : {
    borderColor: theme?.palette?.primary[500],
    backgroundColor: theme?.palette?.tertiary[600],
    opacity: 0.5
  },
  rejectStyle : {
    borderColor: theme?.palette?.error?.main
  }
};

const DraftsImportFile = () => {
  const { isUploading, uploadingFileData, setFileUploadingData, uploadedFileData, onCancelFileUpload } = useAppContext();

  const onDrop = useCallback((acceptedFiles: any) => {
    const newFile = acceptedFiles.map((file: any) => {
      file.id = uuidv4();
      return file
    });

    let fileExt;
    let isFileExtensionAccepted = newFile.every((file: any) => {
      if(file.name.split(".").length === 1) {
        fileExt = "-";
      } else {
        fileExt = file.name.split(".").pop();
      }
      return fileExt === 'imscc'
    });

    isFileExtensionAccepted && setFileUploadingData(newFile);
  }, []);

  const navigate = useNavigate();
  const removeFileFromState = useCallback((file: any) => {
    const findIndex = uploadingFileData.findIndex((item: any) => item.id === file.id);
    if(findIndex > -1) {
      const newFile = [...uploadingFileData];
      newFile.splice(findIndex, 1);
      setFileUploadingData(newFile);
      onCancelFileUpload();
    }
  }, [uploadingFileData, setFileUploadingData, onCancelFileUpload]);
  
  const { 
    getRootProps, 
    getInputProps,
    isDragAccept,
    isDragReject,
    open 
  } = useDropzone({onDrop, multiple: false, noClick: true, disabled: isUploading});

  const uploadBoxStyle = useMemo(() => ({
    ...(isDragAccept ? styles.acceptStyle : {}),
    ...(isDragReject ? styles.rejectStyle : {})
  }), [
    isDragAccept,
    isDragReject
  ]);

  return (
    <>
      <Box sx={styles.flexContainer} {...getRootProps({style: uploadBoxStyle, className: isDragAccept ? 'dropzone' : ''})}>
        <Typography sx={styles.importFilesHeader}>Import Files</Typography>
        <Box sx={{
          ...styles.upload.default,
          cursor: isUploading ? 'default' : 'pointer'
          }} onClick={open}>
          <input {...getInputProps()} />
          <Box display={'flex'} flexDirection={'row'} sx={{py: 4}}>
            <img src={DragDropImageSVG} alt="Drag Drop img" />
            <Box sx={styles.upload.textBox}>
              <Typography sx={styles.upload.heading}>Drag and drop imscc files here</Typography>
              <Typography sx={styles.upload.subHeading}>Or <span style={styles.upload.browseText}>browse</span> from your computer</Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.fileList.container}>
          <Box sx={styles.fileList.headerContainer}>
            <Typography sx={styles.fileList.header}>File Name</Typography>
          </Box>
          <Box maxHeight={264} minHeight={130} className={'customScroll'} sx={{
            overflowY: 'auto'
          }}>
            {
              uploadingFileData.length > 0 ?  
                Children.toArray(
                  uploadingFileData.map((file: any) => {
                    return <FileProgress isUploading={true} file={file} removeFileFromState={removeFileFromState}/>
                  })
                ) :
                uploadedFileData.length > 0 ? null : <Box sx={styles.fileUploadContainer}/>
            }
            {
              uploadedFileData.length > 0 ?  
                Children.toArray(
                  uploadedFileData.map((file: any) => {
                    return <FileProgress isUploading={false} file={file} removeFileFromState={removeFileFromState}/>
                  })
                ) : null
            }
          </Box>
        </Box>
        <Box mt={3.5}>
          <Button
            variant='contained'
            size='medium'
            color='primary'
            sx={styles.backToDrafts.button}
            onClick={() => navigate('/drafts')}
          >
            <Typography sx={{
              ...theme?.typography?.Components?.button?.default,
              ...theme?.typography?.Components?.button?.medium,
              ...styles.backToDrafts.buttonText
            }}>
              {'Back to Drafts'}
            </Typography>
          </Button>
        </Box>
        <Box mt={2} pb={17.125}>
          <Typography sx={styles.backToDrafts.messageText} >Your import will continue even if you <br /> navigate away from this page</Typography>
        </Box>
      </Box>
    </>
  );
};

export default memo(DraftsImportFile);