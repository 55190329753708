import React, { Children } from 'react'
import { Box } from '@mui/material';
import { IPublishedModuleCardDataProps } from 'pages/drafts/DraftsModule/types';
import ModuleItem from './components/ModuleItem';

interface IPublishedModuleProps {
    data: IPublishedModuleCardDataProps[]
}

const PublishedModulesList = (props: IPublishedModuleProps) => {
    const { data } = props;

    return (
        <Box width={'100%'}>
            {Children.toArray(data.map((item: IPublishedModuleCardDataProps) => (<ModuleItem item={item} />)))}
        </Box>
    )
}

export default PublishedModulesList;