import { Box, IconButton, TextField, Typography, Popper, ClickAwayListener } from "@mui/material";
import { godzilla as theme } from "util/themes/godzilla";
import Dots_Vertical from "assets/images/PathwayCreator/Drag_vertical.svg";
import { useEffect, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IChecklistItemProps,
  MaxErrorText,
  RequiredErrorText,
  TextRgx,
} from "../../demonstrationmilestone.helper";
import Error_Icon from "assets/images/erroricon.svg";
import Pencil_Icon from "assets/images/PathwayCreator/Pencil_icon.svg";
import Delete_Icon from "assets/images/PathwayCreator/Delete_icon.svg";

const styles = {
  inputStyle: {
    width: "100%",
    "& .MuiInputBase-root": {
      padding: "0px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #5B67FF",
        borderRadius: "4px",
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #5B67FF",
      borderRadius: "4px",
    },
    "& .MuiInputBase-input": {
      padding: "6px 9px 7px 9px",
    },
  },
};

const ChecklistItem = (props: IChecklistItemProps) => {
  const {
    action,
    checklistData,
    setChecklistData,
    setOnAddNewChecklistItem,
    checklistItemName = "",
    index = 0,
    selectedChecklistIndex = null,
    setSelectedChecklistIndex,
    setChecklistDeleteData,
    setErrorMessage,
    hasScroll
  } = props;

  const [checklistItem, setChecklistItem] = useState<string>(checklistItemName);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [errorFlags, setErrorFlags] = useState<{
    requiredError: boolean;
    maxLength: boolean;
  }>({
    requiredError: false,
    maxLength: false,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    handleValidation(checklistItemName);
  }, [checklistItemName])

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null)
  };

  const onChangeChecklistItem = (e: any) => {
    setChecklistItem(e.target.value?.replace(/\n/g, ''));
    setErrorFlags({
      requiredError: false,
      maxLength: false,
    });
  };

  const updateChecklistData = (title: string) => {
    if (action === "ADD") {
      setChecklistData([...checklistData, checklistItem]);
      setOnAddNewChecklistItem && setOnAddNewChecklistItem(false);
      setErrorMessage((prv: any) => ({
        ...prv,
        checklistError: ""
      }))
    } else {
      const cloneData = [...checklistData];
      cloneData[index] = title;
      setChecklistData(cloneData);
      setIsEdit(false);
    }
  };

  const handleValidation = (title: string) => {
    if (title.trim() === "") {
      setErrorFlags({
        requiredError: true,
        maxLength: false,
      });
      setChecklistItem("");
    } else {
      if (title.length > 100) {
        setErrorFlags({
          requiredError: false,
          maxLength: true,
        });
      } else if (!title.match(TextRgx)) {
        setErrorFlags({
          requiredError: true,
          maxLength: false,
        });
      } else {
        setErrorFlags({
          requiredError: false,
          maxLength: false,
        });
      }
      if(checklistItemName !== title) {
        updateChecklistData(title);
      }
    }
  };

  const onAddChecklistItem = (e: any, onKeyPress: boolean) => {
    if (onKeyPress) {
      if (e.key === "Enter") {
        handleValidation(checklistItem);
      }
    } else {
      handleValidation(checklistItem);
    }
  };

  const onDeleteChecklistItem = () => {
    let updatedData = checklistData.filter((item: any, indexItem: number) => index !== indexItem) || [];
    setChecklistData(updatedData);
  }

  return (
    <>
      <Box display={"flex"} flexDirection={"column"}>
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          border={`${errorFlags.requiredError || errorFlags.maxLength
              ? "2px solid #FF4F4F"
              : `2px solid ${theme?.palette?.grayScale[200]}`
            }`}
          borderRadius={"8px"}
          sx={{
            cursor: "pointer",
            "& .editContainer": {
              visibility: "hidden",
            },
            "&:hover, &:active": {
              border: "2px solid #26C5D2",
              cursor: "pointer",
              "& .editContainer": {
                visibility: "initial",
              },
            },
          }}
        >
          <Box ml={"10.5px"} mt={"4px"} className={"editContainer"}>
            <img
              src={Dots_Vertical}
              alt="ICON"
              style={{ height: "24px", width: "8px" }}
            />
          </Box>
          {action === "ADD" || isEdit ? (
            <ClickAwayListener onClickAway={(e: any) => onAddChecklistItem(e, false)}>
              <Box pl={"4.5px"} mt={"8px"} mb={"8px"} width={'calc(100% - 47px)'}>
                <TextField
                  sx={{
                    ...styles.inputStyle,
                  }}
                  autoFocus={true}
                  value={checklistItem}
                  onChange={(e: any) => onChangeChecklistItem(e)}
                  onKeyDown={(e: any) => onAddChecklistItem(e, true)}
                  onBlur={(e: any) => onAddChecklistItem(e, false)}
                  onFocus={(e) => {
                    let value = e.target.value;
                    e.target.value = "";
                    e.target.value = value;
                  }}
                  multiline
                  maxRows={4}
                />
              </Box>
            </ClickAwayListener>
          ) : (
            <Box pl={"8px"} height={"auto"} width={'calc(100% - 47px)'}>
              <Typography
                sx={{
                  ...theme?.typography?.body?.default,
                  ...theme?.typography?.body?.body1,
                  ...theme?.typography?.body?.medium,
                  color: "#3D4655",
                  wordBreak: "break-all",
                  py: "14px",
                }}
              >
                {checklistItem}
              </Typography>
            </Box>
          )}
          <Box mr={"9.5px"}>
            <IconButton
              onClick={handleClick}
              sx={{
                padding: "0px",
                "&:hover": {
                  backgroundColor: `${(selectedChecklistIndex === index || selectedChecklistIndex === null) ? theme?.palette?.secondary[10] : "transparent"}`
                },
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl} disablePortal={hasScroll} keepMounted={false}
              modifiers={
                [
                  { name: 'flip', enabled: true, options: { flipVariations: true } },
                  {
                    name: 'offset', enabled: true, options: { offset: (hasScroll && (checklistData.length - 1) === index) ? [-3, -70] : [-3, 0] }
                  }
                ]
              }
              style={{ zIndex: 1 }}
            >
              <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  width={"146px"}
                  height={"auto"}
                  boxShadow={
                    "0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12)"
                  }
                  borderRadius={"10px"}
                  position={"absolute"}
                  sx={{ backgroundColor: "#FFFFFF" }}
                  ml={"-136px"}
                  mt={"-24px"}
                >
                  {isEdit || action === "ADD" ? null : (
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={"center"}
                      onClick={() => {
                        setTimeout((): void => {
                          setIsEdit(true);
                        }, 50);
                        handleClose();
                      }}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#E9EAFF",
                        },
                        mt: "11px",
                        height: "32px",
                        pl: '26px',
                        cursor: "pointer"
                      }}
                    >
                      <Box>
                        <img src={Pencil_Icon} alt="ICON" />
                      </Box>
                      <Box ml={"14px"}>
                        <Typography
                          sx={{
                            ...theme?.typography?.body?.default,
                            ...theme?.typography?.body?.body1,
                            ...theme?.typography?.body?.regular,
                            color: "#2C3442",
                          }}
                        >
                          Rename
                        </Typography>
                      </Box>
                    </Box>
                  )}
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    onClick={() => {
                      if (action === "ADD") {
                        setOnAddNewChecklistItem && setOnAddNewChecklistItem(false);
                      } else {
                        onDeleteChecklistItem()
                        handleClose();
                      }
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#E9EAFF",
                      },
                      mb: `${!isEdit && action !== "ADD" ? "14px" : "10px"}`,
                      mt: `${!isEdit && action !== "ADD" ? "2px" : "10px"}`,
                      height: "32px",
                      pl: '26px',
                      cursor: "pointer"
                    }}
                  >
                    <Box>
                      <img src={Delete_Icon} alt="ICON" />
                    </Box>
                    <Box ml={"15.5px"}>
                      <Typography
                        sx={{
                          ...theme?.typography?.body?.default,
                          ...theme?.typography?.body?.body1,
                          ...theme?.typography?.body?.regular,
                          color: "#2C3442",
                        }}
                      >
                        Delete
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </ClickAwayListener>
            </Popper>
          </Box>
        </Box>
        {errorFlags.requiredError || errorFlags.maxLength ? (
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Box mt={"-2px"}>
              <img
                src={Error_Icon}
                alt="ICON"
                style={{ height: "10px", width: "10px" }}
              />
            </Box>
            <Box ml={"4px"}>
              <Typography
                sx={{
                  ...theme?.typography?.captions?.default,
                  ...theme?.typography?.captions?.regular,
                  color: theme?.palette?.error?.dark,
                }}
              >
                {errorFlags.requiredError ? RequiredErrorText : MaxErrorText}
              </Typography>
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ChecklistItem;
